import * as React from 'react'
import { Field, reduxForm } from 'redux-form'
import { map } from 'lodash'
import { FormDropdown } from 'components'
import {
  Wrapper, Controls, Input, CompanyNameInput, DoubleInputs,
  DropdownWrapper, SaveButton, ZipCodeInput
} from './company-settings-form.styles'
import { countriesList } from 'utils'

interface CompanySettingsFormProps {
  industries: any[]
  handleSubmit?: any
  initialValues?: any
  onSubmit?: any
}

// @ts-ignore
@reduxForm({
  form: 'companySettingsForm',
})
export class CompanySettingsForm extends React.PureComponent<CompanySettingsFormProps> {
  public render() {
    const industries = this.props.industries

    return (
      <Wrapper>
        <form onSubmit={this.props.handleSubmit}>
          <Field
            name="name"
            component={CompanyNameInput as any}
            type="text"
            placeholder="Company Name"
          />
          <Field
            name="address1"
            component={Input as any}
            type="text"
            placeholder="Street Name"
          />
          <Field
            name="address2"
            component={Input as any}
            type="text"
            placeholder="Address Line 2"
          />
          <DoubleInputs>
            <Field
              name="zipCode"
              component={ZipCodeInput as any}
              type="text"
              placeholder="Zip"
            />
            <Field
              name="city"
              component={Input as any}
              type="text"
              placeholder="City"
            />
          </DoubleInputs>
          <DropdownWrapper>
            <Field
              name="country"
              component={FormDropdown(countriesList, 'Choose Country', true)}
              // @ts-ignore
              valueField="value"
              textField="label"
            />
          </DropdownWrapper>
          <Field
            name="industry"
            component={FormDropdown(industries, 'Choose Industry', true)}
            // @ts-ignore
            valueField="value"
            textField="label"
          />
          <Controls>
            <SaveButton type="submit">
            Save
            </SaveButton>
          </Controls>
        </form>
      </Wrapper>
    )
  }
}
