import * as React from 'react'
import { Query, Mutation } from 'react-apollo'

import { Spinner } from '../../../../../../components/index'
import { linkCrmCompany } from '../../../../../../gql/superoffice/superoffice.mutation'
import { getLinkedCrmCompany } from '../../../../../../gql/superoffice/superoffice.query'
import { SearchCompanyCard } from '../search-company-card/search-company-card.component'
import { TextWrapper } from '../../crm-insight.styles'
import { LinkedCompanyCard } from '../linked-company-card/linked-company-card.component';
import { SearchCompanyModal } from '../search-company-modal/search-company-modal.component'
import { CreateCompanyModal } from '../create-company-modal/create-company-modal.component';
import { Wrapper } from './connected-card.styles'

interface ConnectedCardProps {
  company: any
  companyId: string
}

export class ConnectedCard extends React.PureComponent<ConnectedCardProps> {
  public state = {
    searchModalIsOpen: false,
    createModalIsOpen: false,
  }
  public toggleSearchModal = () => {
    this.setState({
      searchModalIsOpen: !this.state.searchModalIsOpen
    })
  }
  public toggleCreateModal = () => {
    this.setState({
      createModalIsOpen: !this.state.createModalIsOpen
    })
  }
  public switchDialog = () => {
    this.toggleCreateModal()
    this.toggleSearchModal()
  }
  public getCard = (data, company, companyId, refetch, mutation) => {
    if (data.getLinkedCrmCompany === null) {
      return (
        <SearchCompanyCard
          id={companyId}
          company={company}
          onMatch={refetch}
          mutation={mutation}
          toggleCreateModal={this.toggleCreateModal}
          toggleSearchModal={this.toggleSearchModal}

        />
      )
    }
    const { crmSummary } = data.getLinkedCrmCompany
    return (
      <LinkedCompanyCard
        id={companyId}
        company={crmSummary}
        onUnlink={refetch}
        toggleSearchModal={this.toggleSearchModal}
      />
    )
  }
  public render() {
    const { company, companyId } = this.props
    const { searchModalIsOpen, createModalIsOpen } = this.state
    return (
      <Mutation
        mutation={linkCrmCompany}
      >
        {(mutation) => (
          <Query query={getLinkedCrmCompany} variables={{ companyId }}>
            {({ loading, error, data, refetch }) => {
              if (loading) {
                return <Spinner />
              }

              if (error) {
                return <TextWrapper>Can't connect to CRM due to unknown error</TextWrapper>
              }
              return (
                <Wrapper>
                  {this.getCard(data, company, companyId, refetch, mutation)}
                  <SearchCompanyModal
                    company={company}
                    isOpen={searchModalIsOpen}
                    onRequestClose={this.toggleSearchModal}
                    onAction={(id) => {
                      this.toggleSearchModal()
                      mutation({ variables: {
                          crmCompanyId: id,
                          companyId,
                        }}).then(() => refetch())
                    }}
                    onSwitchDialog={this.switchDialog}
                  />
                  <CreateCompanyModal
                    companyId={companyId}
                    company={company}
                    isOpen={createModalIsOpen}
                    onRequestClose={this.toggleCreateModal}
                    onAction={() => {
                      this.toggleCreateModal()
                      refetch()
                    }}
                    onSwitchDialog={this.switchDialog}
                  />
                </Wrapper>
                )
              }}
            </Query>
          )}
      </Mutation>
    )
  }
}
