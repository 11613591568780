import * as React from 'react'
import { compose } from 'react-apollo'
import { times, find, get } from 'lodash'

import { Show, LoadingBlock, LoadingOverlay } from 'components'
import { countriesList, success, gicsDefault } from 'utils'
import { CompanySettingsForm } from './components/company-settings-form/company-settings-form.component'
import { ChangeLogoModal } from './components/change-logo-modal/change-logo-modal.component'
import { companySettingsQueries } from './company-settings.query'
import {
  Wrapper, Title, RightColumn, CardTitle, ChangeLogoButton, CompanyLogo, FormContainer,
  LoadingContainer, LogoWrapper, SettingsFormCard
} from './company-settings.styles'

interface CompanySettingsProps {
  updateCompanyInformation: any
  data: any
}

@compose(...companySettingsQueries)
export class CompanySettings extends React.PureComponent<CompanySettingsProps> {

  public state = {
    isChangeLogoModalOpen: false,
    submitting: false,
  }

  private readonly industries: any[]

  constructor(props) {
    super(props)
    this.industries = gicsDefault.getIndustryGroupsOptionsList()
  }

  public getDefaultFormValues = () => {
    const { data = {} } = this.props

    const country = find(countriesList, { value: get(data, 'getTenantCompanyInformation.countryCode') })
    const industry = find(this.industries, { value: get(data, 'getTenantCompanyInformation.industry') })

    return {
      ...data.getTenantCompanyInformation,
      industry: {
        value: get(industry, 'value') || '',
        label: get(industry, 'label') || '',
      },
      country: {
        value: get(country, 'value') || '',
        label: get(country, 'label') || '',
      },
    }
  }

  public toggleChangeLogoModal = () => {
    this.setState({ isChangeLogoModalOpen: !this.state.isChangeLogoModalOpen })
  }

  public uploadImage = (image) => {
    this.setState({ isChangeLogoModalOpen: false, submitting: true })

    this.updateCompanyInformation({
      ...this.props.data.getTenantCompanyInformation,
      logo: image,
    }).then(() => {
      this.setState({ submitting: false })
      success('Successfully uploaded new company logo')
    })
  }

  public submitForm = (values) => {
    const { data } = this.props

    this.setState({ submitting: true })

    this.updateCompanyInformation({
      ...values,
      industry: get(values, 'industry.value'),
      country: get(values, 'country.value'),
      logo: get(data, 'getTenantCompanyInformation.logo'),
    }).then(() => {
      this.setState({ submitting: false })
      success('Successfully saved company information')
    })
  }

  public updateCompanyInformation = data => this.props.updateCompanyInformation({ variables: data })

  public render() {
    const { data = {} } = this.props

    return (
      <Wrapper>
        <Title>Company Information</Title>
        <SettingsFormCard>
          <CardTitle>
            COMPANY NAME & ADDRESS
          </CardTitle>
          <FormContainer>
            <Show if={!data.loading}>
              <CompanySettingsForm
                initialValues={this.getDefaultFormValues()}
                industries={this.industries}
                onSubmit={this.submitForm}
              />
              <RightColumn>
                <LogoWrapper>
                  <CompanyLogo src={get(data, 'getTenantCompanyInformation.logo') || '/images/company-logo.png'} />
                  <ChangeLogoButton onClick={this.toggleChangeLogoModal}>Change Logo</ChangeLogoButton>
                </LogoWrapper>
              </RightColumn>
            </Show>
            <Show if={data.loading}>
              <LoadingContainer>
                {times(5, i => (
                  <LoadingBlock key={i} height="50px" width="100%" />
                ))}
              </LoadingContainer>
              <RightColumn />
            </Show>
          </FormContainer>
          <Show if={this.state.submitting}>
            <LoadingOverlay
              active={true}
              spinner={true}
              background="rgba(0,0,0,0.4)"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 20,
              }}
            />
          </Show>
          <ChangeLogoModal
            toggle={this.toggleChangeLogoModal}
            isOpen={this.state.isChangeLogoModalOpen}
            onDone={this.uploadImage}
          />
        </SettingsFormCard>
      </Wrapper>
    )
  }
}
