import * as React from 'react'
import { InputWrapper, OKIcon, ErrorMessage, Label, Show } from 'components'
import { CodeWrapper } from './vat-input.styles'
import { getVATIdCode } from 'utils'

interface InputProps {
  className: any
  input: any
  meta: any
  label: any
  placeholder: any
  type: any
  code: string
}

export class VatInput extends React.PureComponent<InputProps> {
  public getValidationStatus = ({ touched, valid, error }, { value }) => {
    if (touched) {
      if (!valid) {
        return <ErrorMessage error={error} />
      } else if (value) {
        return <OKIcon />
      }
    }
    return null
  }

  public render() {
    const {
      className,
      input: { id, ...inputProps },
      meta,
      label,
      placeholder,
      type,
      code,
      ...otherProps
    } = this.props

    return (
      <InputWrapper
        className={className}
      >
        <Show if={label}>
          <Label
            htmlFor={id}
          >
            {label}
            {this.getValidationStatus(meta, inputProps)}
          </Label>
        </Show>
        <CodeWrapper>
          <div>{getVATIdCode(code)}</div>
          <input
            id={id}
            placeholder={placeholder}
            type={type}
            {...otherProps}
            {...inputProps}
          />
        </CodeWrapper>
      </InputWrapper>
    )
  }
}
