import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 17px;
`

export const CheckboxInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  outline: 0;
  width: 17px;
  height: 17px;
  z-index: -1;

  & ~ label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 8px;
    border-right: 3px solid;
    border-bottom: 3px solid;
    transform: scale(0.0) rotate(45deg);
    opacity: 0;
    transition: opacity 300ms, transform 300ms 100ms;
  }
  
  &:checked ~ label:after {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }
`

export const CheckboxLabel = styled.label`
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  padding-left: 26px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    content: '';
    background: #fff;
    border-radius: 1px;
    border: 1px solid #d4d4d5;
  }
`
