import * as React from 'react'

interface ShowProps {
  if: any
  children: React.ReactNode
}

export const Show: React.SFC<ShowProps> = (props): any => {
  if (props.if) {
    return props.children
  }
  return null
}
