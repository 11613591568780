import * as Raven from 'raven-js'

export enum Events {
  SIGNUP_PAGE_VISIT = 'LE - EnterRegPage',
  SIGNUP_START = 'LE - SignUpStart',
  SIGNUP_EMAIL_CONFIRMED = 'LE - SignupEmailConfirmed',
  SIGNUP_NAME_PASS = 'LE - SignupNamePass',
  SIGNUP_COMPANY_DOMAIN = 'LE - SignupCompanyDomain',
  SIGNUP_TENANT_CREATED = 'LE - TenantCreated',
  USER_LOGIN = 'LE - UserLogin',
  USER_PROFILE_UPLOAD_PHOTO = 'LE - UserProfilePhotoUpload',
  USER_INVITE = 'LE - UserInvited',
  USER_INVITE_ACCEPTED = 'LE - UserInvitedAccepted',
  LEADLIMITER_CLICKED = 'LE - LeadLimiterClicked',
  SUBSCRIPTION_PAGE_VISIT = 'LE - VisitSubscriptionPage',
  SUBSCRIPTION_PLAN_PAGE_VISIT = 'LE - VisitPlanPage',
  SUBSCRIPTION_UPGRADE = 'LE - SubscriptionUpgrade',
  SUBSCRIPTION_CANCELATION = 'LE - SubscriptionCancel',
  FILTER_CREATED = 'LE - FilterCreated',
  COMPANY_PAGE_VISIT = 'LE - VisitCompanyViewPage',
  COMPANY_ASSIGN = 'LE - AssignCompany',
  COMPANY_CRM_MATCH = 'LE - CRMMatchCompany',
  MARKETING_TIMELINE_PAGE_VISIT = 'LE - VisitMarketingTimelinePage',
  GA_CONNECTED = 'LE - GAConnected',
  GA_CONNECT_FAILED = 'LE - GAConnectFailed',
  EMARKETEER_CONNECTED = 'LE - eMarketeerConnected',
  EMARKETEER_CONNECT_FAILURE = 'LE - eMarketeerConnectFailed',
  EMARKETEER_CONNECT_STARTED = 'LE - eMarketeerConnectStarted',
  EMARKETEER_CONNECT_STOPPED = 'LE - eMarketeerConnectStopped',

  SUPEROFFICE_ONLINE_CONNECTED = 'LE - SuperOfficeConnected',
  SUPEROFFICE_ONLINE_CONNECT_STARTED = 'LE - SuperOfficeConnectStarted',
  SUPEROFFICE_ONLINE_CONNECT_STOPPED= 'LE - SuperOfficeConnectStopped',
  PAGE_VISIT = 'LE - PageVisit',
  VIDEO_PLAYED = 'LE - VideoPlayed',
  REDIRECTED_FROM_LANDINGPAGE = 'LE - RedirectedToProductPage',


/*
LE - EnterRegPage	                User comes to register email. If visit is from ladning page, userId is merged
LE - SignUpStart	                User starts signup process by entering email
LE - TenantCreated	                User has created the tenant and finished signup
LE - GAConnected	                User successfully connects GA
LE - UserInvited	                User invites new user
LE - UserInvitedAccepted	        Invited user signs up
LE - LeadLimiterClicked	            Limit reached and clicks link to upgrade
LE - VisitSubscriptionPage	        User visits subscription page
LE - VisitPlanPage	                User visits plan page (upgrade step 2)
LE - SubscriptionUpgrade	        User upgrades
LE - SubscriptionCancel	            User cancels subscription
LE - FilterCreated	                User creates a filter
LE - VisitCompanyViewPage	        User visits the company page
LE - AssignCompany	                User assigns company
LE - CRMMatchCompany	            User matches/creates company in crm
LE - VisitCompanyViewPage	        User visits the company page
LE - VisitMarketingTimelinePage	    User views a marketing timeline item
LE - UserProfilePhotoUpload	        User uploads a personal avatar image
LE - eMarketeerConnected	        Integrated eM successfully
LE - SuperOfficeConnected	        Integrated SO successfully
LE - eMarketeerConnectFailed	    Integrated eM unsuccessfully
LE - SuperOfficeConnectFailed	    Integrated SO unsuccessfully
LE - UserLogin	                    User logs in
LP - VideoPlayed	                Fires when video is played
LP - PageVisit	                    Fires when page is loaded
LP - RedirectedToProductPage	    Fire when users goes to lead explorer page from landing page
*/

  LIVE_VIEW_LOAD = 'LE - LiveViewLoad',
  LIVE_VIEW_PLAY = 'LE - LiveViewPlay',

  GRANTED_NOTIFICATION_PERMISSION = 'LE - GrantedNotificationPermission',
}

const env = process.env.REACT_APP_ENV || 'dev'
if ((window as any).amplitude) {
  if (env === 'prod') {
    (window as any).amplitude.getInstance().init('78fa1cfb19dbbf00660cd277dfa5ac31');
  } else {
    (window as any).amplitude.getInstance().init('fc6b718cbdff3d9c2c09dc912faef08b');
  }
}


export const registerAnalyticEvent = (event: Events, properties = {}) => {
  try {
    (window as any).amplitude.getInstance().logEvent(event, properties);
  } catch (e) {
    Raven.captureException(e)
  }
}

export const setAnalyticUser = (id: string) => {
  try {
    const amplitude = (window as any).amplitude.getInstance();
    amplitude.setUserId(id);
  } catch (e) {
    Raven.captureException(e)
  }
}

export const setAnalyticTenantId = (tenantId: string) => {
  try {
    const amplitude = (window as any).amplitude.getInstance();
    amplitude.setUserProperties({'tenantId': tenantId})
  } catch (e) {
    Raven.captureException(e)
  }
}

export const startSession = (id: string, tenantId: string) => {
  try {
    const amplitude = (window as any).amplitude.getInstance();
    amplitude.setUserId(id);
    amplitude.setUserProperties({'tenantId': tenantId})
  } catch (e) {
    Raven.captureException(e)
  }
}
