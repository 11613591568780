import * as React from 'react'
import { Query } from 'react-apollo'
import { History } from 'history'

import {
  Table,
  TableRow,
  TableRowItem,
  Button,
} from '../../../../../components/index'
import { BottomCardWrapper, NoDataWrapper } from '../../../settings-container.styles'
import { getTenantBillingDetails } from '../../../../../gql/subscriptions/subscriptions.query'

interface CreditCardProps {
  history: History
}

export class BillingAddressCard extends React.PureComponent<CreditCardProps> {
  public onUpdateBillingAddress = () => {
    this.props.history.push('/settings/billing')
  }

  public enoughData = x => x.name && x.address1 && x.zipCode && x.city

  public render() {
    return (
      <BottomCardWrapper>
        <Query query={getTenantBillingDetails}>
          {
            ({ loading, error, data }) => {
              if (loading) {
                return null
              }
              if (error) {
                throw error
              } else {
                if (!this.enoughData(data.getTenantBillingDetails)) {
                  return (
                    <div>
                      <h3>
                        BILLING ADDRESS
                      </h3>
                      <Table>
                        <TableRow>
                          <TableRowItem maxWidth={120}>
                            Company
                          </TableRowItem>
                          <TableRowItem>
                            -
                          </TableRowItem>
                        </TableRow>
                        <TableRow>
                          <TableRowItem maxWidth={120}>
                            VATno
                          </TableRowItem>
                          <TableRowItem>
                            -
                          </TableRowItem>
                        </TableRow>
                        <TableRow>
                          <TableRowItem maxWidth={120}>
                            Billing email
                          </TableRowItem>
                          <TableRowItem>
                            -
                          </TableRowItem>
                        </TableRow>
                      </Table>
                      <Button
                        color="primary"
                        onClick={this.onUpdateBillingAddress}
                      >
                        Add billing address
                      </Button>
                    </div>
                  )
                }
                return (
                  <div>
                    <h3>
                      BILLING ADDRESS
                    </h3>
                    <Table>
                      <TableRow>
                        <TableRowItem maxWidth={120}>
                          Company
                        </TableRowItem>
                        <TableRowItem>
                          {data.getTenantBillingDetails.name}
                        </TableRowItem>
                      </TableRow>
                      <TableRow>
                        <TableRowItem maxWidth={120}>
                          VATno
                        </TableRowItem>
                        <TableRowItem>
                          {data.getTenantBillingDetails.vatID}
                        </TableRowItem>
                      </TableRow>
                      <TableRow>
                        <TableRowItem maxWidth={120}>
                          Billing email
                        </TableRowItem>
                        <TableRowItem>
                          {data.getTenantBillingDetails.emailAddress}
                        </TableRowItem>
                      </TableRow>
                    </Table>
                    <Button
                      color="primary"
                      onClick={this.onUpdateBillingAddress}
                    >
                      UPDATE BILLING INFO
                    </Button>
                  </div>
                )
              }
            }
          }
        </Query>
      </BottomCardWrapper>
    )
  }
}
