import * as React from 'react'
import { SidebarLayout } from '../../../components'

import { Wrapper, Title, Text } from './404.styles'

export const NotFoundContainer = () => (
  <SidebarLayout>
    <Wrapper>
      <Title>404</Title>
      <Text>We are sorry but the page you are looking for does not exist.</Text>
    </Wrapper>
  </SidebarLayout>
)
