import styled from 'styled-components'

import { Row } from '../../../../../../../../components'

export const Form = styled.form`
  position: relative;

  .simple-select {
    width: 100%;
  }

  .react-selectize.default.root-node .react-selectize-control,
  .react-selectize.default.root-node.open .react-selectize-control {
    border: none;
    background: #fafafa;

    .react-selectize-placeholder {
      color: rgba(49,69,80,0.6);
    }

    input {
      display: none;
    }
  }

  ${Row} {
    align-items: center;
    margin-bottom: 8px;
  }
`

export const Label = styled.div`
  width: 100px;
  font-weight: 700;
  font-size: 14px;
`
