import gql from 'graphql-tag'

export const getFilters = gql`
  query getFilters {
    getFilters {
      id
      name
      editable
      favorite
      filterDefinition {
        orClauses {
          andClauses {
            field
            operator
            value
          }
        }
      }
    }
  }
`

export const getPageSuggestions = gql`
    query getPageSuggestions($search: String) {
        getPageSuggestions(search: $search) {
            url
            views
        }
    }
`

export const getDomainSuggestions = gql`
    query getDomainSuggestions($search: String) {
      getDomainSuggestions(search: $search) {
            domain
            views
        }
    }
`
