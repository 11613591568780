export const getDeviceIcon = (deviceCategory, operatingSystem) => {
  switch (deviceCategory) {
    case 'mobile':
      return operatingSystem === 'iOS' ? 'iphone' : 'android'
    case 'tablet':
      return 'tablet'
    case 'desktop':
      return operatingSystem === 'Macintosh' ||  operatingSystem === 'Mac OS' ? 'desktopMac' : 'desktopWindows'
    default:
      return deviceCategory
  }
}

export const getDeviceText = (deviceCategory, operatingSystem) => {
  switch (deviceCategory) {
    case 'mobile':
      return operatingSystem === 'iOS' ? 'iPhone' : 'Android Smartphone'
    case 'tablet':
      return operatingSystem === 'iOS' ? 'iPad' : 'Android Tablet'
    case 'desktop':
      return operatingSystem === 'Macintosh' || operatingSystem === 'Mac OS' ? 'Desktop MAC' : 'Desktop PC'
    default:
      return deviceCategory
  }
}
