import * as React from 'react'
import { get, map, isEqual, compact } from 'lodash'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import InfiniteScroll from 'react-infinite-scroller'

import { CompanyListItem } from '../../../components/list-items/company-list-item.component'
import { ListView } from '../../../components/list-view/list-view.component'
import { EmptyProspectsListComponent } from './empty-prospects-list/empty-prospects-list.component'
import selector from '../webleads.selector'

interface CompaniesListProps {
  prospectsList?: any,
  users: any[],
  data: any,
  status: any
  push?: (url: string) => null
}

@connect(selector, { push })
export class CompaniesList extends React.PureComponent<CompaniesListProps> {
  public state = {
    loading: false,
    filterLoading: false,
    error: false,
    loadMore: false,
  }

  public componentWillReceiveProps = (newProps) => {
    const { prospectsList, data: { loading } } = this.props

    if (get(newProps, 'data.getCompanies.length') !== get(this.props, 'data.getCompanies.length')) {
      this.setState({ loading: false })
    }

    if (!isEqual(newProps.prospectsList.dateRange, prospectsList.dateRange)) {
      this.setState({ filterLoading: true })
      setTimeout(this.stopFilterLoading, 200)
    }

    if (!newProps.data.loading && newProps.data.loading !== loading) {
      this.setState({ filterLoading: false })
    }
  }

  public onCompanyClick = (company) => {
    this.props.push(`/webleads/company/${company.id}/dashboard`)
  }

  public stopFilterLoading = () => {
    if (!this.props.data.loading) {
      this.setState({ filterLoading: false })
    }
  }

  public loadMoreCompanies = () => {
    const { data } = this.props

    if (this.state.loading
      || this.state.filterLoading
      || data.loading
      || data.error
      || this.state.error) {
      return
    }

    this.setState({
      loading: true,
      loadMore: true,
    }, () => {
      data.fetchMore({
        variables: { offset: String(this.getDomains(data).length) },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newDomains = this.getDomains(fetchMoreResult)

          if (!newDomains) {
            return previousResult
          }

          return {
            getCompanies: {
              ...previousResult.getCompanies,
              domains: [
                ...previousResult.getCompanies.domains, ...fetchMoreResult.getCompanies.domains,
              ],
            },
          }
        },
      }).then((res) => {
        if (res.errors) {
          this.setState({ error: true })
        } else {
          this.setState({ loading: false, loadMore: false })
        }
      })
    })
  }

  public render() {
    const { data, users, status } = this.props
    const { filterLoading } = this.state
    const domains = this.getDomains(data)
    const total = this.getTotal(data)

    if (!filterLoading && !data.loading && domains.length === 0) {
        return <EmptyProspectsListComponent status={status}/>
    }

    return (
      <InfiniteScroll
        loadMore={this.loadMoreCompanies}
        hasMore={total > domains.length}
        threshold={500}
        useWindow={true}
      >
        <ListView
          loading={data.loading || filterLoading}
          loadMore={this.state.loadMore}
        >
          {map(domains, (x, i) => (
            <CompanyListItem
              key={x.id}
              company={x}
              index={i}
              users={users}
              onClick={this.onCompanyClick}
            />
          ))}
        </ListView>
      </InfiniteScroll>
    )
  }

  private getDomains = data => compact<any>(get(data, 'getCompanies.domains', []))
  private getTotal = data => get(data, 'getCompanies.total', 0)
}
