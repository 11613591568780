import { TOGGLE_INVITE_USER_MODAL } from '../actions/invite-user-modal.actions'

const defaultState = {
  visible: false,
}

export const inviteUserModal = (state = defaultState, action) => {
  switch (action.type) {

    case TOGGLE_INVITE_USER_MODAL:
      return {
        ...state,
        email: action.email,
        visible: !state.visible
      }

    default:
      return state
  }
}
