import styled from 'styled-components'

import { Flag, Icon } from '../../../../../../components'

export const Wrapper = styled.section`
  color: #314550;
  background: white;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  background: ${({ theme }) => theme.WEB_VISIT_BACKGROUND_COLOR};
  color: white;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 250px;
  @media (max-width: 800px) {
    height: 200px;
  };
`

export const DurationBlock = styled.div`
  padding: 15px;
  border-right: #A5A6A6 1px solid;
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const InterestingPageBlock = styled.div`
  padding: 15px;
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const Time = styled.div`
  font-size: 72px;
  height: 72px;
  margin-bottom: 13px;
  @media (max-width: 800px) {
    font-size: 54px;
    height: 54px;
  };
`

export const Description = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`

export const PagePreview = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 13px;
`

export const Content = styled.div`
  padding: 40px;
  @media (max-width: 800px) {
    padding: 20px;
  };
`

export const Visitor = styled.div`
  display: flex;
  flex-direction: row;
`

interface LogoProps {
  image: string
}

export const Logo = styled.div<LogoProps>`
  background: ${({ image }) => `url("${image}") no-repeat center/contain`};
  min-width: 38px;
  min-height: 38px;
  margin-right: 25px;
`

export const Name = styled.div`
  font-size: 16px;
  padding-bottom: 4px;
`

export const VisitorDetails = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`

export const CountryFlag = styled(Flag)`
  margin-left: 9px;
`

export const Source = styled.div`
  padding: 18px 0;
  display: flex;
  align-items: center;
`

export const SourceIcon = styled(Icon)`
  margin-right: 25px;
`

export const PagesSection = styled.div`
  display: flex;
  flex-direction: row;
`

export const Arrow = styled.div`
  margin-right: 25px;
  background: url('/images/components/web-visits-panel/arrow.svg') left 17px top 0 no-repeat;
  width: 38px;
  height: 50px;
`

export const PageViews = styled.div`
  width: 100%;
`
