import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: #314550;
  margin-bottom: 18px;
  margin-left: auto;
`

interface CategoryTabsItemProps {
  active: boolean
}

export const CategoryTabsItem = styled.div<CategoryTabsItemProps>`
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 12px;
  margin-left: 12px;
  background-color: ${({ active }) => active ? '#ffffff' : 'transparent'};
`
