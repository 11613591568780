import * as React from 'react'
import { Mutation } from 'react-apollo'
import { find, filter } from 'lodash'

import { Modal, Show, LoadingOverlay } from '../../../../../../components'
import { deletePendingInvitation, getPendingInvitations } from '../../../../../../gql'
import { AddButton, CancelButton, Controls, Title } from './delete-invitation-modal.styles'

interface DeleteUserModalProps {
  toggle?: any
  isOpen?: boolean
  onDone?: any
  email?: string
  onRequestClose?: () => void
}

export class DeleteInvitationModal extends React.Component<DeleteUserModalProps> {
  public state = {
    loading: false,
  }

  public revokeInvitation = async (email, mutation) => {
    this.setState({ loading: true })
    await mutation({
      variables: { email },
      update: (cache) => {
        const data: any = cache.readQuery({ query: getPendingInvitations })
        cache.writeQuery({
          query: getPendingInvitations,
          data: {
            getInvitations: filter(data.getInvitations, (x: any) => x.email !== email),
          }
        })
      }
    })
    this.setState({ loading: false })
    this.props.onDone()
  }

  public render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal style={{ content: { minHeight: '0', maxWidth: '400px' }}} isOpen={isOpen} onRequestClose={toggle}>
        <Show if={this.state.loading}>
          <LoadingOverlay
            active={true}
            spinner={true}
            background="rgba(0,0,0,0.4)"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 20,
            }}
          />
        </Show>
        <Title>Revoke invitation for {this.props.email}?</Title>
        <Controls>
          <CancelButton onClick={this.props.onDone}>Cancel</CancelButton>
          <Mutation
            mutation={deletePendingInvitation}
          >
            {(mutation) => (
              <AddButton onClick={() => this.revokeInvitation(this.props.email, mutation)}>Delete</AddButton>
            )}
          </Mutation>
        </Controls>
      </Modal>
    )
  }
}
