import * as React from 'react'
import RcSelect, { Option, OptGroup } from 'rc-select'

import { EmbeddedSpinner, Arrow } from './select.styles'
import { Show } from 'components'

interface SelectProps {
  showSearch?: boolean
  placeholder?: string
  onChange?: any
  value?: any
  disabled?: boolean
  filterOption?: any
  fullWidth?: boolean
  defaultValue?: any
  combobox?: boolean
  loading?: boolean
  notFoundContent?: string
}

export class Select extends React.PureComponent<SelectProps> {
  public static Option = Option
  public static OptGroup = OptGroup
  public static defaultProps = {
    showSearch: false
  }

  public container: any

  public render(): React.ReactNode {
    const { fullWidth = false, loading, ...rest } = this.props
    const wrapperStyle: any = { position: 'relative' }
    const selectStyle: any = {}
    if (fullWidth) {
      wrapperStyle.flex = 1
      selectStyle.width = '100%'
    }
    return (
      <div
        ref={this.saveContainer}
        style={wrapperStyle}
      >
        <RcSelect
          animation="slide-up"
          showSearch={this.props.showSearch}
          getPopupContainer={() => this.container}
          inputIcon={<Arrow />}
          prefixCls="ant-select"
          optionLabelProp="children"
          optionFilterProp="children"
          style={selectStyle}
          {...rest}
        />
        <Show if={loading}>
          <EmbeddedSpinner />
        </Show>
      </div>
    )
  }

  private saveContainer = (node: any) => {
    this.container = node
  }
}
