import * as React from 'react'
import { map } from 'lodash'

import { StagesWrapper, StageItem } from './stages.styles'

interface StagesProps {
  currentStageId: string
  stages: any[]
}

export const Stages: React.SFC<StagesProps> = ({ stages, currentStageId }) => {
  let markAsSelected = Boolean(currentStageId)
  return (
    <StagesWrapper>
      {map(stages, (stage) => {
        const currentSelected = markAsSelected
        if (stage.id === currentStageId) {
          markAsSelected = false
        }
        return (
          <StageItem
            key={stage.id}
            selected={currentSelected}
            probability={stage.probability}
          />
        )
      })}
    </StagesWrapper>
  )
}
