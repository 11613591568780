import styled from 'styled-components'
import { connect } from 'react-redux'

import { Icon, PredefinedDatePicker } from '../../../../components/index'

export const ActiveFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ClickableArea = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  color: #314550;
`
export const DatePicker = styled(PredefinedDatePicker)`
  margin-right: 5px;
  font-size: 14px;
`
export const FilterIcon = styled(Icon)`
  margin-left: 8px;
`
