import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  
  .company-wrapper {
    position: relative;
    display: flex;
    	
    .company-circle {
      display: flex;
      flex: 1;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: white;
      justify-content: center;
      align-items: center;
      transition: transform 0.3s;
    }
    
    .company-wrapper-logo {
      max-width: 32px;
      max-height: 32px;
    }
    
    .company-arrow-right,
    .company-arrow-left {
      display: none;
      position: absolute;
      top: 50%;
      border: 10px solid transparent;
      margin-top: -10px;
      cursor: pointer!important;
      opacity: 0;
      transition: all 300ms;
    }

    .company-arrow-right {
      right: -38px;
      border-left-color: #314550;
    }
    
    .company-arrow-left {
      left: -38px;
      border-right-color: #314550;
    }
  }
  
  .company-screenshot {
    position: absolute;
    top: 84px;
    left: -128px;
    width: 300px;
    height: 180px;
    margin-right: auto;
    border: 8px solid white;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
    background-size: 35px 35px;
    transition: all 400ms;
    pointer-events: none;
    transform: translateY(-60px) scale(0.5);
    opacity: 0;
    
    img {
      width: 300px;
      height: auto;
    }
  }
  
  .company-name {
    position: absolute;
    bottom: 84px;
    left: -123px;
    width: 300px;
    text-align: center;
    transition: all 400ms;
    transform: translateY(60px) scale(0.5);
    opacity: 0;
    
    span {
      display: inline-block;
      font-size: 16px;
      padding: 4px 8px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  
  .has-other-companies {
    .company-arrow-right,
    .company-arrow-left {
      display: block;
    }
  }
  
  .show-info {
    .company-arrow-right,
    .company-arrow-left {
      opacity: 1;
    }
    
    .company-name,
    .company-screenshot {
      transform: translateY(0px) scale(1);
      opacity: 1;
    }
  }
`
