import styled from 'styled-components'
import { map } from 'lodash'
import { spring, TransitionMotion } from 'react-motion'

import { OutsideClickHandler } from '../../utils/'

export const Wrapper = styled(OutsideClickHandler)`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  &:focus {
    outline: 0;
  }
`
export const Menu = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  min-width: 160px;
  padding: 0;
  box-shadow: #686868 0 1px 3px;
  overflow: hidden;
  z-index: 10;
  background: #ffffff;
`
export const MenuItem = styled.div`
  position: relative;
  display: block;
  border: none;
  align-items: center;
  background: white;
  padding: 8px;
  height: 18px;
  color: #5f5f5f;
  z-index: 15;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  
  &:focus {
    outline: 0;
  }
  
  &:hover {
    background-color: #edf1f3;
  }
`
