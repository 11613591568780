import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  border-radius: 4px;
  background: #D85251;
  font-size: 11px;
  color: white;
`
