import * as React from 'react'
import { connect } from 'react-redux'
import { reduce, map, filter } from 'lodash'

import { Icon, Dropdown, MaxLengthText } from 'components'
import { switchFilterPanel, setFilterId, setFilterIdForEdit } from 'actions'
import { Wrapper, CreateButton, FilterEntry, FiltersSection, SectionName } from './filter-list.styles'
import { getFilters } from '../../../../../../gql/filters'
import { filterListSelector } from './filter-list.selector'

interface FilterListProps {
  data: any[],
  dispatch?: any
  filterId?: string
  mutation: any
}

@connect(filterListSelector)
export class FilterList extends React.PureComponent<FilterListProps> {
  public getSettingsButton = (filterItem) => {
    const items = [
      {
        title: 'Edit',
        onClick: () => {
          this.props.dispatch(setFilterIdForEdit(filterItem.id))
          this.switchTo('edit')()
        },
      },
      {
        title: 'Delete',
        onClick: () => {
          if (this.props.filterId === filterItem.id) {
            this.props.dispatch(setFilterId('allLeads'))
          }
          this.props.mutation({
            variables: { id: filterItem.id },
            update: (cache) => {
              const cachedData: any = cache.readQuery({ query: getFilters });
              cache.writeQuery({
                query: getFilters,
                data: { getFilters: filter(cachedData.getFilters, x => x.id !== filterItem.id) }
              });
            },
          })
        }
      },
    ]

    return (
      <Dropdown items={items}>
        <Icon icon="filterSettings" />
      </Dropdown>
    )
  }

  public switchTo = tab => () => {
    this.props.dispatch(switchFilterPanel(tab))
  }

  public render() {
    const { data } = this.props
    const { basic, editable } = reduce(data, (result, item) => {
      if (item.editable) {
        result.editable.push(item)
      } else {
        result.basic.push(item)
      }
      return result
    }, {
      basic: [],
      editable: []
    })
    return (
      <Wrapper>
        <CreateButton color="primary" onClick={this.switchTo('create')}>
          Create new filter
        </CreateButton>
        <FiltersSection>
          <SectionName>
            Basic filters
          </SectionName>
          {map(basic, (filterItem) => (
            <FilterEntry
              key={filterItem.id}
              onClick={() => this.props.dispatch(setFilterId(filterItem.id))}
            >
              {filterItem.name}
            </FilterEntry>
          ))}
        </FiltersSection>
        <FiltersSection>
          {editable.length ?
            (
              <SectionName>
                My Filters
              </SectionName>
            ) : null
          }
          {map(editable, (filterItem) => (
            <FilterEntry
              key={filterItem.id}
              onClick={() => this.props.dispatch(setFilterId(filterItem.id))}
            >
              <MaxLengthText length={50}>{filterItem.name}</MaxLengthText>
              {this.getSettingsButton(filterItem)}
            </FilterEntry>
          ))}
        </FiltersSection>
      </Wrapper>
    )
  }
}

