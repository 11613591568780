import * as React from 'react'
import styled from 'styled-components'

import { Show, Icon } from '../../'
import { getDeviceIcon } from 'utils'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  height: 16px;
`
const MediumText = styled.span`
  font-weight: 500
`
const Text = styled.div`
  font-size: 12px;
`

const getIcon = (company, isLive) => isLive ? 'watching' : getDeviceIcon(company.deviceCategory, company.operatingSystem)

const getDeviceString = (deviceCategory) => {
  switch (deviceCategory) {
    case 'mobile':
      return 'mobile device'
    case 'tablet':
      return 'tablet'
    case 'desktop':
      return 'desktop computer'
    default:
      return deviceCategory
  }
}

export const Attribution = ({ company, isLive }) => {
  if (!isLive && !company.deviceCategory) {
    return null
  }

  return (
    <Wrapper>
      <Icon size={14} icon={getIcon(company, isLive)} />
      <Text>
        <Show if={isLive}>
          Now watching: <MediumText>{company.pageTitle}</MediumText>
        </Show>
        <Show if={!isLive}>
          Using a <MediumText>{getDeviceString(company.deviceCategory)} </MediumText>
          <Show if={company.operatingSystem !== '(not set)'}>
            running <MediumText>{company.operatingSystem}</MediumText>
          </Show>
        </Show>
      </Text>
    </Wrapper>
  )
}
