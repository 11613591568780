import * as React from 'react'
import { Modal, ImagePicker } from '../../../../components'

interface UpdateAvatarModalProps {
  isOpen: boolean
  onAvatarSubmit: any
  onRequestClose: any
}

export class UpdateAvatarModal extends React.PureComponent<UpdateAvatarModalProps> {
  public state = {
    image: null,
  }

  public onImage = image => this.setState({ image })

  public upload = () => {
    this.props.onAvatarSubmit(this.state.image)
  }

  public render() {
    const { isOpen, onRequestClose } = this.props
    const { image } = this.state

    return (
      <Modal
        style={{ content: { maxWidth: '500px' } }}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <Modal.Title>Change photo</Modal.Title>
        <ImagePicker
          onImage={this.onImage}
        />
        <Modal.Controls>
          <Modal.CancelButton
            onClick={onRequestClose}
          >
            Cancel
          </Modal.CancelButton>
          <Modal.ApproveButton
            onClick={this.upload}
            disabled={!image}
          >
            Upload
          </Modal.ApproveButton>
        </Modal.Controls>
      </Modal>
    )
  }
}

