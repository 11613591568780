let pushServiceWorkerRegistration = null

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.register('/sw.js')

    pushServiceWorkerRegistration = registration
  }
}

export const getRegistration = async () => {
  if (!pushServiceWorkerRegistration && 'serviceWorker' in navigator) {
    pushServiceWorkerRegistration = await navigator.serviceWorker.register('/sw.js')
  }

  return pushServiceWorkerRegistration
}

