import * as React from 'react'
import { connect } from 'react-redux'
import {
  TrackingScriptText,
  TrackingScriptButton,
  TrackingScriptHelpLink,
} from 'components'
import {
  Wrapper,
  ScriptTitle,
  Description,
  ButtonWrapper,
} from './empty-prospects-list.styles'

import { tenantIdSelector } from '../../../../selectors'

interface EmptyProspectsListProps {
  status: {
    refetch: () => void
    loading: boolean
  }
  tenantId?: string
  dispatch?: any
}

@connect(state => ({
  tenantId: tenantIdSelector(state),
}))
export class EmptyProspectsListComponent extends React.Component<EmptyProspectsListProps> {
  public render() {
    const { tenantId } = this.props

    return (
      <Wrapper>
        <h3>No leads found within date range</h3>
        <h4>Try changing the date range to another time period</h4>
        <ScriptTitle>Still no leads?</ScriptTitle>
        <Description>Make sure that you installed the Leadexplorer web script on your site.
          Copy the code below and paste it into your website header.</Description>
        <TrackingScriptText tenantId={tenantId}/>
        <ButtonWrapper>
          <TrackingScriptButton tenantId={tenantId}/>
          <TrackingScriptHelpLink>
            How to install the script
          </TrackingScriptHelpLink>
        </ButtonWrapper>
      </Wrapper>
    )
  }
}
