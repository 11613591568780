import * as React from 'react'
import styled from 'styled-components'
import { PageHeader } from 'components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  color: #314550;
`

interface Layout {
  style?: any
}

export const Layout: React.SFC<Layout> = ({ children, ...props }) => (
  <Wrapper {...props}>
    <PageHeader />
    {children}
  </Wrapper>
)
