import * as React from 'react'
import { map } from 'lodash'
import { Query } from 'react-apollo'

import { Card, TableRow, TableRowItem, TableHeaderItem, Alignment, Spinner } from '../../../../components'
import { MainWrapper, Title, Table } from '../../settings-container.styles'
import { InvoicesCard } from './invoices.styles'
import { InvoiceListItem } from './components/invoice-list-item.component'
import { Invoice } from './models/invoice.model'
import { getPaymentInvoices } from '../../../../gql/subscriptions/subscriptions.query'

const CardTitle = (Card as any).Title
const CardFooter = (Card as any).Footer

export class Invoices extends React.PureComponent {
  public render() {
    return (
      <MainWrapper>
        <Title>Invoices</Title>
        <Query
          query={getPaymentInvoices}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Spinner />
            }
            if (error) {
              throw error
            }
            const invoices = map(data.getPaymentInvoices, x => new Invoice(x))
            if (!invoices.length) {
              return (
                <div>
                  No invoices found
                </div>
              )
            }
            return (
              <InvoicesCard>
                <CardTitle>
                  ALL PAYMENTS
                </CardTitle>
                <Table>
                  <TableRow>
                    <TableHeaderItem>
                      DATE
                    </TableHeaderItem>
                    <TableHeaderItem size={2}>
                      INVOICE#
                    </TableHeaderItem>
                    <TableHeaderItem>
                      STATUS
                    </TableHeaderItem>
                    <TableHeaderItem>
                      Amount
                    </TableHeaderItem>
                    <TableHeaderItem alignment={Alignment.right}>
                      Download
                    </TableHeaderItem>
                  </TableRow>
                  {map(invoices, invoice =>
                    <InvoiceListItem
                      key={invoice.id}
                      invoice={invoice}
                    />
                  )}
                </Table>
                <CardFooter />
              </InvoicesCard>
            )
          }}
        </Query>
      </MainWrapper>
    )
  }
}
