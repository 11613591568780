import styled from 'styled-components'
import superOfficeLogo from './images/superoffice-logo.svg'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 200px;
  flex: 1;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`
export const MainText = styled.div`
  margin-bottom: 8px;
  padding: 0 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
`
export const Bold = styled.span`
  font-weight: 500;
`
export const Text = styled.div`
  margin-bottom: 32px;
  padding: 0 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
`

export const SuperOfficeLogo = styled.img.attrs({
  src: superOfficeLogo,
})`
  position: absolute;
  top: -40px;
  right: 30px;
`

