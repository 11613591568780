import * as React from 'react'

import { Avatar } from '../../../../common'
import { Wrapper, StyledDropdown } from './avatar-menu.styles'

export const AvatarMenu = ({ isAuthenticated, onLogout, onMyProfile, user, ...props }) => {
  const items = [
    {
      title: 'My Profile',
      onClick: onMyProfile,
    },
    {
      title: 'Logout',
      onClick: onLogout,
    },
  ]
  if (isAuthenticated) {
    return (
      <Wrapper {...props}>
        <StyledDropdown items={items}>
          <Avatar user={user} />
        </StyledDropdown>
      </Wrapper>
    )
  }
  return null
}
