import * as React from 'react'
import * as moment from 'moment'
import { DateRange } from 'react-date-range'

import defaultTheme from '../../datepicker.theme'
import { Wrapper, ApplyButton, RangeTitle, RangeValue, RangeWrapper } from './custom-datepicker.styles'

interface CustomDatePickerProps {
  onDateChange?: any
  firstDayOfWeek?: number
  calendars?: number
  theme?: any
  dateRange?: any
  onOutsideClick?: any
  position?: any
  onInit?: any
}

export class CustomDatePicker extends React.PureComponent<CustomDatePickerProps> {
  public static defaultProps = {
    calendars: 1,
    firstDayOfWeek: 1,
  }

  public state = {
    dateRange: this.props.dateRange,
  }

  public onDateChange = (dateRange) => {
    this.setState({ dateRange })
  }

  public onDateApply = (dateRange) => {
    this.setState({ dateRange })
    this.props.onDateChange(dateRange)
  }

  public formatRange = ({ startDate, endDate }) => {
    const start = moment(startDate).format('MMM Do YYYY')
    const end = moment(endDate).format('MMM Do YYYY')

    return start === end ? start : `${start} to ${end}`
  }

  public render() {
    const { firstDayOfWeek, calendars, theme, onOutsideClick, position } = this.props
    const { dateRange } = this.state

    return (
      <Wrapper position={position} onOutsideClick={onOutsideClick}>
        <DateRange
          firstDayOfWeek={firstDayOfWeek}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          calendars={calendars}
          onInit={this.onDateChange}
          onChange={this.onDateChange}
          theme={{ ...defaultTheme, theme }}
        />
        <RangeWrapper>
          <RangeTitle>Show leads from</RangeTitle>
          <RangeValue>{this.formatRange(dateRange)}</RangeValue>
          <ApplyButton onClick={() => this.onDateApply(dateRange)} color="success">
            Apply
          </ApplyButton>
        </RangeWrapper>
      </Wrapper>
    )
  }
}
