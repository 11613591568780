import * as React from 'react'
import * as moment from 'moment'
import { chain } from 'lodash'
import { Show, LoadingBlock, LoadingCircle } from 'components'
import {
  Header, Wrapper, PersonIcon, ClockIcon, DataRow, Description, PersonPhoto, Persons,
  Summary, SummaryRow, SummaryValue, Total, TotalDescriptionWrapper, TotalValue
} from './engagement.styles'

export const Engagement = ({ analytics, activeEmployees, loading, events = [] }) => (
  <Wrapper>
    <DataRow>
      <Summary>
        <SummaryRow>
          <Show if={!loading}>
              Total web visits: <SummaryValue>{analytics.visits}</SummaryValue>
          </Show>
          <Show if={loading}>
            <LoadingBlock />
          </Show>
        </SummaryRow>
        <SummaryRow>
          <Show if={!loading}>
              Unique pages visited: <SummaryValue>{analytics.totalPageViews}</SummaryValue>
          </Show>
          <Show if={loading}>
            <LoadingBlock />
          </Show>
        </SummaryRow>
        <SummaryRow>
          <Show if={Boolean(!loading && events.length)}>
              Marketing Interactions: <SummaryValue>{events.length}</SummaryValue>
          </Show>
          <Show if={loading}>
            <LoadingBlock />
          </Show>
        </SummaryRow>
      </Summary>
      <Persons>
        <Show if={!loading}>
          {chain(activeEmployees)
            .orderBy(['avatar'], ['desc'])
            .slice(0, 3)
            .map((employee, i) => (
              <div key={i}>
                <Show if={employee.avatar}>
                  <PersonPhoto image={employee.avatar} />
                </Show>
                <Show if={!employee.avatar}>
                  <PersonIcon icon="person" size={55} />
                </Show>
              </div>
            ))
            .value()
          }
        </Show>
        <Show if={loading}>
          <LoadingCircle size={55} />
        </Show>
      </Persons>
    </DataRow>
    <Total>
      <TotalDescriptionWrapper>
        <Header>
          <Show if={!loading}>
              Total time
          </Show>
          <Show if={loading}>
            <LoadingBlock />
          </Show>
        </Header>
        <Description>
          <Show if={!loading}>
              Spent on your brand&nbsp;
            <div>since {analytics.visitsStartDate}</div>
          </Show>
          <Show if={loading}>
            <LoadingBlock />
          </Show>
        </Description>
      </TotalDescriptionWrapper>
      <TotalValue>
        <Show if={!loading}>
          <ClockIcon icon="clock" size={30} />
          {moment.utc(analytics.totalPageViewDuration * 1000)
            .format('H[h] mm[m]')}
        </Show>
        <Show if={loading}>
          <LoadingBlock height="30px" />
        </Show>
      </TotalValue>
    </Total>
  </Wrapper>
)
