import styled from 'styled-components'

export const CardWrapper = styled.div<{ current: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 160px;
  margin-right: 16px;
  border: ${({ current }) => current ? '5px solid #61869e' : '1px solid #c4c4c4'};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  
  &:last-child {
    margin-right: 0;
  }
`
interface CardHeaderProps {
  selected?: boolean
  current?: boolean
}
export const CardHeader = styled.div<CardHeaderProps>`
  width: 100%;
  padding: ${({ current }) => current ? '0 0 4px' : '4px 0'};
  margin-bottom: 16px;
  color: #ffffff;
  text-align: center;
  background: ${({ selected, current }) => selected ? '#7aab59' : current ? '#61869e' : '#e3e3e3'};
  border-bottom: 1px solid #c4c4c4;
  transition: background-color 300ms ease;
`

export const AfterHeader = styled.div`
  margin-bottom: 4px;
  font-size: 13px;
  color: #a5a6a6;
`

export const Leads = styled.div`
  margin-bottom: 4px;
  font-size: 36px;
  font-weight: 500;
  color: #314550;
`

export const AfterLeads = styled.div`
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 18px;
  color: #314550;
`

export const Price = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #314550;
`

export const Period = styled.div`
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 18px;
  color: #314550;
`
