import * as React from 'react'

import { Modal } from 'components'
import {
  Title,
  SubTitle,
} from './analytics-modal.styles'

interface AlreadyInUseModalProps {
  isOpen: boolean
  toggle: any
}

export class AlreadyInUseModal extends React.Component<AlreadyInUseModalProps> {
  public render() {
    const { isOpen, toggle } = this.props

    return (
      <Modal isOpen={isOpen} onRequestClose={toggle}>
        <Title>Account already in use</Title>
        <SubTitle>
          Your Google account is already connected to a different Leadexplorer account
        </SubTitle>
        <SubTitle>
          Please use a different Google account, or disconnect it from any other Leadexplorer account using it.
        </SubTitle>
        <Modal.Controls>
          <Modal.CancelButton onClick={toggle}>
            Ok
          </Modal.CancelButton>
        </Modal.Controls>
      </Modal>
    )
  }
}
