import * as React from 'react'
import { CompanyEvent } from './company-event.component'

export const UserType = ({ revisit }) => (
  revisit ? (
    <CompanyEvent
      text="Returning visitor"
      icon="returningUser"
    />
  ) : (
    <CompanyEvent
      text="Visiting for the first time"
      icon="newUser"
    />
  )
)
