import * as React from 'react'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { map, times, slice, get } from 'lodash'
import { Show, LoadingBlock, SidePanel, MarketingIntegrationPlaceholderWrapper } from 'components'
import { activeEmployeesSelector } from './active-employees.selector'
import { closeSidePanel, showSidePanel } from 'actions'
import { ActiveEmployeesSelection } from './components'
import {
  Wrapper,
  EmployeeRows,
  EmployeeRow,
  PersonPhoto,
  PersonIcon,
  Description,
  Name,
  Title,
  Source,
  LoadingAvatar,
  Footer,
  NoData,
  Placeholder,
  Triangle
} from './active-employees.styles'
import { getActiveEmployees } from '../../../../gql/emarketeer'

interface ActiveEmployeesProps {
  dispatch?: any
  loading?: boolean
  sidePanelVisible?: boolean
  domain?: any
  emarketeerConnected?: boolean
}

@connect(activeEmployeesSelector)
export class ActiveEmployees extends React.Component<ActiveEmployeesProps> {
  public showSidePanel = () => {
    this.props.dispatch(showSidePanel('activeEmployeesSelection'))
  }

  public closeSidePanel = () => {
    this.props.dispatch(closeSidePanel('activeEmployeesSelection'))
  }

  public render() {
    const { loading, sidePanelVisible, domain, emarketeerConnected } = this.props

    return (
      <Wrapper>
        <Show if={emarketeerConnected || loading}>
          <Show if={loading}>
            <EmployeeRows>
              {times(3, i => (
                <EmployeeRow key={i}>
                  <LoadingAvatar size={56} />
                  <Description>
                    {times(3, index => <LoadingBlock key={index} width="40%" />)}
                  </Description>
                </EmployeeRow>
              ))}
            </EmployeeRows>
          </Show>
          <Show if={!loading && emarketeerConnected}>
            <Query query={getActiveEmployees} variables={{ domain }}>
              {(activeEmployees) => {
                const employees = get(activeEmployees, 'data.getActiveEmployees', [])
                if (activeEmployees.loading) {
                  return (
                    <EmployeeRows>
                      {times(3, i => (
                        <EmployeeRow key={i}>
                          <LoadingAvatar size={56} />
                          <Description>
                            {times(3, index => <LoadingBlock key={index} width="40%" />)}
                          </Description>
                        </EmployeeRow>
                      ))}
                    </EmployeeRows>
                  )
                } else if (employees.length) {
                  return (
                    <Show if={!activeEmployees.loading}>
                      <EmployeeRows>
                        {map(slice(employees, 0, 5) as any, (employee, i) => (
                          <EmployeeRow key={i}>
                            <Show if={employee.avatar}>
                              <PersonPhoto image={employee.avatar} />
                            </Show>
                            <Show if={!employee.avatar}>
                              <PersonIcon icon="person" size={56} />
                            </Show>
                            <Description>
                              <Name>{employee.fullName}</Name>
                              <Show if={employee.title}>
                                <Title>{employee.title}</Title>
                              </Show>
                              <Source>Source: {employee.source}</Source>
                            </Description>
                          </EmployeeRow>
                        ))}
                        <Show if={!loading && !employees.length}>
                          <NoData>
                            No active employees found
                          </NoData>
                        </Show>
                      </EmployeeRows>
                      <Show if={employees.length > 5}>
                        <Footer onClick={() => this.showSidePanel()}>
                          Show all {employees.length} contacts <Triangle icon="triangleRight" size={10} />
                        </Footer>
                      </Show>
                      <SidePanel isVisible={sidePanelVisible} handleClose={this.closeSidePanel} title="Selection">
                        <ActiveEmployeesSelection employees={employees} />
                      </SidePanel>
                    </Show>
                  )
                }
                return (
                  <EmployeeRows>
                    <NoData>
                      No active employees found
                    </NoData>
                  </EmployeeRows>
                )
              }}
            </Query>
          </Show>
        </Show>
        <Show if={!emarketeerConnected && !loading}>
          <MarketingIntegrationPlaceholderWrapper>
            <Placeholder
              componentName="Active Employees"
              description="Integrate Leadexplorer with your marketing tool to fetch the active employees from each company."
            />
          </MarketingIntegrationPlaceholderWrapper>
        </Show>
      </Wrapper>
    )
  }
}
