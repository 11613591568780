import * as React from 'react'
import { get } from 'lodash'
import styled, { keyframes } from 'styled-components'

import { Button, LoadingOverlay } from 'components'
import { Row, Alignment } from '../../../../components'

import googleAnalyticsLogo from '../images/59f42461cdaaff3923d130b3ddff991e.png'
import leadexplorerLogo from '../images/ff89a954faac6523ebb62f243cd2f906.png'
import bg from './images/google-analytics.png'
import arrow from '../images/arrow.svg'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background: white ;
  margin-bottom: 32px;
  border-radius: 10px;
  padding: 24px;
  
  ${Row} {
    margin-bottom: 24px;
  }

  p, h3, div {
    text-align: center;
  }
  
  h3 {
    margin: 0 0 12px;
    font-size: 48px; 
    font-weight: 200;
  }
  
  h4 {
    font-size: 24px;
    font-weight: 300;
    margin: 0 auto 64px;
    text-align: center;
  }
`

export const GoogleAnalyticsLogo = styled.img.attrs({
  src: googleAnalyticsLogo,
})`
  width: 135px;
  height: 135px;
`
export const LeadexplorerLogo = styled.img.attrs({
  src: leadexplorerLogo,
})`
  width: 100px;
  height: 105px;
  padding: 15px;
`
export const ArrowsWrapper = styled.div`
  position: relative;
  width: 160px;
  height: 135px;
  overflow: hidden;
`
const arrowAnimation = keyframes`
  0% {
    left: 0;
    transform: scale(0.5);
    opacity: 0;
  }

  20% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    left: 130px;
    transform: scale(0.5);
    opacity: 0;
  }
`
export const Arrow = styled.img.attrs({
  src: arrow,
})`
  position: absolute;
  width: 30px;
  height: 25px;
  top: 55px;
  opacity: 0;
  animation: ${arrowAnimation} 2s linear infinite;
`
export const ArrowDelayed = styled(Arrow)`
  animation-delay: 1s;
`
export const ScriptTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #2A3B44;
  margin: 16px;
`
export const Description = styled.p`
  font-weight: 300;
  font-size: 14px;
  width: 512px;
  margin: 0 auto 16px;
`
export const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding: 16px 0 8px;
  
  a {
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    text-decoration-line: underline;
    color: #61869E;
  }
`
