import * as React from 'react'
import * as Autocomplete from 'react-autocomplete'
import { find, includes, toLower } from 'lodash'
import { WrappedFieldInputProps } from 'redux-form'

import { ItemWrapper, Input, menuStyle, wrapperStyle } from './autocomplete.styles'

export interface AutocompleteInputItem {
  value: string,
  label: string
}

interface AutocompleteInputProps {
  input: WrappedFieldInputProps,
  items: AutocompleteInputItem[]
}

export class AutocompleteInput extends React.PureComponent<AutocompleteInputProps, {}> {
  public state = {
    value: '',
  }

  public onValueChange = (_, value) => {
    const { input: { onChange }, items } = this.props
    this.setState({
      value,
    }, () => {
      const item = find<AutocompleteInputItem>(items, { label: value })
      if (item) {
        onChange(item.value)
      } else {
        onChange('')
      }
    })
  }

  public onValueSelect = (value, item) => {
    this.setState({
      value,
    }, () => {
      this.props.input.onChange(item.value)
    })
  }

  public shouldItemRender = ({ label }, value) => includes(toLower(label), toLower(value))

  public renderInput = ({ ref, ...props }) => (
    <Input
      {...props}
      ref={ref}
    />
  )

  public renderItem = ({ label }, isHighlighted) => (
    <ItemWrapper
      key={label}
      isHighlighted={isHighlighted}
    >
      {label}
    </ItemWrapper>
  )

  public getItemValue = item => item.label

  public render() {
    return (
      <Autocomplete
        onChange={this.onValueChange}
        onSelect={this.onValueSelect}
        value={this.state.value}
        items={this.props.items}
        getItemValue={this.getItemValue}
        renderItem={this.renderItem}
        renderInput={this.renderInput}
        shouldItemRender={this.shouldItemRender}
        wrapperStyle={wrapperStyle}
        menuStyle={menuStyle}
      />
    )
  }
}
