import * as React from 'react'

import { validation } from 'utils'
import { debounce } from 'lodash'
import { RegistrationApi } from '../../../../api'
import { Wrapper, Input, Label, Button, Error, MobileMessage } from '../../signup.styles'
import { DesktopTitle, Logo, MobileTitle, Title } from './email-input.styles'

const emailValidator = validation.email()

interface EmailInputProps {
  onDone: (email: string) => void
  email?: string
  loading: boolean
}

export class EmailInput extends React.PureComponent<EmailInputProps> {
  public state = {
    email: '',
    emailUpdating: false,
    userExist: null,
    emailIsValid: false
  }

  private readonly debouncedOnChange

  public constructor(props) {
    super(props)
    this.state = {
      email: props.email || '',
      emailUpdating: false,
      userExist: null,
      emailIsValid: false
    }

    if (props.email) {
      this.onChange(props.email, true)
    }

    this.debouncedOnChange = debounce(this.onChange, 100)
  }

  public onChange = (email, autoSubmit = false) => {
    const error = emailValidator(email)
    if (!error && email !== '') {
      this.setState({ emailUpdating: true })
      RegistrationApi
        .emailIsRegistered(email)
        .then(({ userExist }) => {
          this.setState({
            emailUpdating: false,
            emailIsValid: true,
            userExist,
          })

          if (autoSubmit && !userExist) {
            this.props.onDone(this.props.email)
          }
        })
    } else {
      this.setState({ emailIsValid: false })
    }
  }

  public render(): React.ReactNode {
    const { onDone, loading } = this.props
    const { email, emailIsValid, userExist, emailUpdating } = this.state

    const shouldShowInvalidEmail = this.props.email && this.props.email === email && !emailIsValid
    const submitDisabled = !(email && emailIsValid && !userExist) || loading || emailUpdating

    return (
      <Wrapper>
        <Logo />
        <Title>
          <DesktopTitle>
            Only a few steps away from seeing your leads
          </DesktopTitle>
          <MobileTitle>
            You’re almost there
          </MobileTitle>
        </Title>
        <MobileMessage>
          For the best Leadexplorer experience, we recommend switching to a desktop device.
        </MobileMessage>
        <Label>
          Confirm your work email address
          <Error show={userExist}>User already exists</Error>
          <Error show={shouldShowInvalidEmail}>Invalid email</Error>
        </Label>
        <Input
          type="email"
          placeholder="name@domain.com"
          value={email}
          onChange={({ target }) => {
            this.setState({ email: target.value })
            this.debouncedOnChange(target.value)
          }}
          onKeyPress={({ charCode }) => {
            if (charCode === 13 && !submitDisabled) {
              onDone(email)
            }
          }}
        />
        <Button
          color="primary"
          onClick={() => onDone(email)}
          disabled={submitDisabled}
          updating={loading || emailUpdating}
        >
          CONFIRM
        </Button>
      </Wrapper>
    )
  }
}
