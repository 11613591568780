import * as React from 'react'
import { InputWrapper, OKIcon, ErrorMessage, Label } from './input.styles'
import { Show } from '../..'

interface InputProps {
  className: any
  input: any
  meta: any
  label: any
  placeholder: any
  type: any
}

export class Input extends React.PureComponent<InputProps> {
  public getValidationStatus = ({ touched, valid, error }, { value }) => {
    if (touched) {
      if (!valid) {
        return <ErrorMessage error={error} />
      } else if (value) {
        return <OKIcon />
      }
    }
    return null
  }

  public render() {
    const {
      className,
      input: { id, ...inputProps },
      meta,
      label,
      placeholder,
      type,
      ...otherProps
    } = this.props

    return (
      <InputWrapper
        className={className}
      >
        <Show if={label}>
          <Label
            htmlFor={id}
          >
            {label}
            {this.getValidationStatus(meta, inputProps)}
          </Label>
        </Show>
        <input
          id={id}
          placeholder={placeholder}
          type={type}
          {...otherProps}
          {...inputProps}
        />
      </InputWrapper>
    )
  }
}
