import styled from 'styled-components'
import { Button } from 'components'

export const Wrapper = styled.div`
  width: 460px;
  height: calc(100vh - 60px);
  background: #ffffff;
  overflow-y: scroll;
  border-left: 1px solid #d6d6d6;
`

export const Title = styled.div`
  padding: 18px 32px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: ${({ theme }) => theme.PANEL_TOP_COLOR};
`

export const Header = styled.div`
  position: relative;
  padding: 24px;
  color: white;
  background: ${({ theme }) => theme.WEB_VISIT_BACKGROUND_COLOR};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
`

export const Counter = styled.div`
  font-weight: 500;
  line-height: normal;
  font-size: 90px;
`

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 64px;
  padding: 0 16px;
  box-sizing: border-box;
  background: #ffffff;
  cursor: pointer;
  
  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin: 0;
    font-size: 14px;
    color: #314550;
  }
`

export const CompanyLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  margin-right: 12px;
  
  img {
    max-width: 48px;
    max-height: 48px;
  }
`

export const CompaniesWrapper = styled.div`
  flex: 1;
`

export const CompanyInfo = styled.div`
  border-bottom: 1px solid #d6d6d6;
  background: #f7f7f7;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
`

export const CompanyInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
  
  p:first-child {
    display: flex;
    align-items: center;
  }
  
  p {
    margin: 0 0 12px;
    
    img {
      max-width: 24px;
      max-height: 12px;
      margin-left: 6px;
    }
  }
  
  ${Button} {
    height: 32px;
    font-size: 12px;
    font-weight: normal;
    margin-right: auto;
  }
`

export const LiveText = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px 12px;
  background: #d85251;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
`

export const Footer = styled.div`
  display: flex;
`

export const LeftColumn = styled.div`
  flex: 1;
`

export const RightColumn = styled.div`
  width: 70px;
`

export const NewIndicator = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d85251;
  top: 50%;
  margin-top: -4px;
  left: 12px;
`
