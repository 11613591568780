import styled from 'styled-components'

export const StagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 80px;
`

interface StageItemProps {
  selected: boolean
  probability: string
}

export const StageItem = styled.div<StageItemProps>`
  width: 28px;
  min-height: 5px;
  border-left: 1px solid white;
  height: ${({ probability }) => `${probability}%`};
  background-color: ${({ selected }) => selected ? '#61869e' : '#eaeaea'};
`
