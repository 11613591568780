import styled from 'styled-components'
import { chain, map } from 'lodash'
import { Tooltip } from 'react-tippy'
import { LoadingCircle } from '../../../../components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 30px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
`
export const RowInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
`
export const RowTitle = styled.div`
  font-size: 12px;
  padding-bottom: 4px;
`
export const RowValue = styled.div`
  font-size: 18px;
  font-weight: 300;
`
export const LoadingIcon = styled(LoadingCircle)`
  margin-right: 13px;
`
export const PageTitle = styled.div`
  font-size: 16px;
  margin: 4px 0;
  cursor: pointer;
`
export const PageTooltip = styled(Tooltip)`
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
  max-width: 100%;
  box-sizing: border-box;
`
export const TooltipText = styled.div`
  font-size: 12px;
`
export const Link = styled.a`
  color: inherit; 
  text-decoration: inherit;
`
