import * as React from 'react'

import { SidebarLayout } from '../../components'
import { PurchasePlan as PurchasePlanInner } from '../settings/components/purchase-plan/purchase-plan.component'

export class PurchasePlan extends React.PureComponent {

  public render() {
    return (
      <SidebarLayout>
        <PurchasePlanInner />
      </SidebarLayout>
    )
  }
}
