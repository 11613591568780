import gql from 'graphql-tag'

export const setupSuperOfficeIntegration = gql`
  mutation(
  $customerContext: String!,
  $systemUserToken: String!
  ) {
    setupSuperOfficeIntegration(serviceId: "leadexplorer", customerContext: $customerContext, systemUserToken: $systemUserToken) {
      connected
      tenantName
    }
  }
`

export const deleteSuperOfficeIntegration = gql`
  mutation {
    deleteSuperOfficeIntegration(serviceId: "leadexplorer") {
      connected
      tenantName
    }
  }
`

export const linkCrmCompany = gql`
  mutation linkCrmCompany($companyId: String!, $crmCompanyId: String!) {
    linkCrmCompany(companyId: $companyId, crmCompanyId: $crmCompanyId) {
      companyId
      tenantId
      crmCompanyId
      crmSummary {
        name
        owner
        startDate
        currency
        totalAmount
        latestSale {
          currentStageId
          saleName
          saleTypeName
          sold
          stages {
            id
            name
            probability
          }
        }
      }
    }
  }
`

export const createCrmCompany = gql`
  mutation createCrmCompany(
    $companyId: String!,
    $categoryId: Int!,
    $businessId: Int!,
    $userId: String!
  ) {
    createCrmCompany(
      companyId: $companyId,
      categoryId: $categoryId,
      businessId: $businessId,
      userId: $userId,
      serviceId: "leadexplorer"
    )
  }
`

export const unlinkCrmCompany = gql`
  mutation unlinkCrmCompany($companyId: String!) {
    unlinkCrmCompany(companyId: $companyId)
  }
`
