import * as React from 'react'
import { map } from 'lodash'
import { spring, TransitionMotion } from 'react-motion'

import { Wrapper, MenuItem, Menu, } from './dropdown.styles'

interface DropdownItem {
  title: any
  onClick: () => void
}

interface DropdownProps {
  items: DropdownItem[]
  className?: string
  itemHeight?: number
}

export class Dropdown extends React.Component<DropdownProps> {
  public static Menu = Menu
  public static MenuItem = MenuItem

  public state = {
    open: false,
  }

  public toggle = (e) => {
    e.stopPropagation()
    this.setState({ open: !this.state.open })
  }
  public close = () => this.setState({ open: false })

  public render() {
    const { children, items, className, itemHeight = 34, ...other } = this.props
    const { open } = this.state

    return (
      <Wrapper
        className={className}
        onClick={this.toggle as any}
        onOutsideClick={this.close}
        {...other}
      >
        {children}
        <TransitionMotion
          willLeave={() => ({
            opacity: spring(0),
            transform: spring(-20),
            height: spring(0),
          })}
          willEnter={() => ({
            opacity: 0.1,
            transform: -20,
            height: 0,
          })}

          styles={open ? [{
            key: 'someId',
            style: {
              opacity: spring(1),
              transform: spring(0),
              height: spring(itemHeight * items.length),
            },
          }] : []}
        >
          {(interpolatedStyles) => {
            if (interpolatedStyles.length) {
              return (
                <Menu
                  style={{
                    transform: `translateY(${interpolatedStyles[0].style.transform}px)`,
                    opacity: interpolatedStyles[0].style.opacity,
                    height: interpolatedStyles[0].style.height,
                  }}
                >
                  {map(items, ({ title, onClick }, index) => (
                    <MenuItem
                      role="button"
                      key={index}
                      tabIndex={0}
                      aria-current="false"
                      onClick={onClick}
                    >
                      {title}
                    </MenuItem>
                  ))}
                </Menu>
              )
            }
            return null
          }}
        </TransitionMotion>
      </Wrapper>
    )
  }
}
