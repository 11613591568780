import styled from 'styled-components'

interface WrapperProps {
  selected: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  box-sizing: border-box;
  min-height: 40px;
  padding: 12px 16px;
  margin-bottom: 2px;
  background: ${({ selected }) => selected ? '#e0e1df' : '#fafafa'};
  cursor: pointer;
`

export const Name = styled.div`
  min-width: 200px;
  font-size: 14px;
  font-weight: 700;
  color: #314550;
`

export const Address = styled.div`
  font-size: 14px;
`

export const Owner = styled.div`
  font-size: 14px;
  margin-left: auto;
`
