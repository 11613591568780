import gql from 'graphql-tag'

export const userUpdateProfile = gql`
  mutation(
    $companyName: String!,
    $domain: String!,
    $affiliateId: String
  ) {
    registerDomain(tenantId: $domain, companyName: $companyName, affiliateId: $affiliateId) {
      url
    }
  }
`
export const updateSendOutConfiguration = gql`
  mutation(
    $isActivated: Boolean!,
  ) {
    updateSendOutConfiguration(
      isActivated: $isActivated,
    ) {
      isActivated
    }
  }
`

export const updateUser = gql`
  mutation(
    $firstName: String,
    $lastName: String,
    $picture: String,
  ) {
    updateUser(
      firstName: $firstName,
      lastName: $lastName,
      picture: $picture
    ) {
      userId
      email
      createdAt
      emailVerified
      firstName
      lastName
      picture
      groups {
        _id
        description
        name
      }
    }
  }
`

export const updateOtherUser = gql`
  mutation(
    $firstName: String,
    $lastName: String,
    $picture: String,
    $email: String,
    $groups: [GroupInput]
  ) {
    updateOtherUser(
      firstName: $firstName,
      lastName: $lastName,
      picture: $picture,
      email: $email,
      groups: $groups
    ) {
      userId
      email
      createdAt
      emailVerified
      firstName
      lastName
      picture
      groups {
        _id
        description
        name
      }
    }
  }
`

export const deleteUser = gql`
  mutation deleteUser(
    $email: String!
  ) {
    deleteUser(email: $email) {
      email
    }
  }
`

export const inviteUser = gql`
  mutation inviteUser(
    $email: String!,
    $message: String,
    $isAdmin: Boolean!,
    $inviter: String!
    $companyName: String!
    $tenantId: String
  ) {
    inviteUser(
      email: $email,
      customText: $message,
      isAdmin: $isAdmin,
      inviter: $inviter,
      companyName: $companyName,
      tenantId: $tenantId
    ) {
      email
    }
  }
`

export const changePassword = gql`
  mutation(
    $email: String!
  ) {
    requestPasswordChange(email: $email)
  }
`

export const updateUsersCount = gql`
  mutation($addon_quantity: Int!) {
    updateUsersCount(addon_quantity: $addon_quantity) {
      users
    }
  }
`

export const resendVerificationEmail = gql`
  mutation {
    resendVerificationEmail
  }
`
