export const SET_RESOLVER_VALUE = 'SET_RESOLVER_VALUE'
export const SET_RESOLVER_RESULT = 'SET_RESOLVER_RESULT'
export const SET_RESOLVER_ERROR = 'SET_RESOLVER_ERROR'
export const SET_RESOLVER_LOADING = 'SET_RESOLVER_LOADING'
export const CLEAR_RESOLVER = 'CLEAR_RESOLVER'

export const setResolverValue = (payload: string) => ({
  type: SET_RESOLVER_VALUE,
  payload
})

export const setResolverResult = (payload: any[]) => {
  return ({
    type: SET_RESOLVER_RESULT,
    payload
  })
}

export const setResolverError = () => ({
  type: SET_RESOLVER_ERROR
})

export const setResolverLoading = () => ({
  type: SET_RESOLVER_LOADING
})

export const clearResolver = () => ({
  type: CLEAR_RESOLVER
})
