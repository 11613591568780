import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as FontFaceObserver from 'fontfaceobserver'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle, RcSwitchStyles, RcSelectStyles, DefaultTheme, SuperOfficeTheme, EmarketeerTheme } from 'styles'
import { Application } from './containers/application'
import { registerServiceWorker } from './registerServiceWorker'

// che pacany, Roboto?
const Roboto = new FontFaceObserver('Roboto')

let Theme = DefaultTheme
if (/^so\./g.test((window as any).location.host)) {
  Theme = SuperOfficeTheme
} else if (/^em\./g.test((window as any).location.host)) {
  Theme = EmarketeerTheme
}

Roboto.load().then(() => {
  document.body.className += ' roboto'
})

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <>
      <Application />
      <GlobalStyle />
      <RcSelectStyles />
      <RcSwitchStyles />
    </>
  </ThemeProvider>,
  document.getElementById('root')
)

registerServiceWorker()
