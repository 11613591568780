import * as React from 'react'
import { connect } from 'react-redux'
import { ApolloConsumer } from 'react-apollo'

import { SidebarLayout, Show, Spinner, PageSubHeader, BackButton } from 'components'
import { setSearchValue, setSearchError, setSearchLoading, setSearchResult, clearSearch } from 'actions'
import { Title, Error, CenterBox } from './search.styles'
import { SearchForm, SearchResult } from './components'
import { searchSelector } from '../../selectors'

interface SearchProps {
  history: any
  value?: string
  error?: string
  loading?: boolean
  result?: any[]
  isEmpty?: boolean
  setSearchValue?: (value: string) => void
  setSearchError?: () => void
  setSearchLoading?: () => void
  setSearchResult?: () => void
  clearSearch?: () => void
}

@connect(searchSelector, { setSearchValue, setSearchError, setSearchLoading, setSearchResult, clearSearch })
export class Search extends React.PureComponent<SearchProps> {
  public componentWillUnmount(): void {
    this.props.clearSearch()
  }

  public onCompanyClick = (id: string) => {
    this.props.history.push(`webleads/company/${id}/dashboard`)
  }

  public render() {
    const { value, error, loading, result, isEmpty } = this.props
    return (
      <SidebarLayout
        noSearch={true}
        noPadding={true}
      >
        <PageSubHeader>
          <BackButton onClick={() => this.props.history.push('/webleads')} />
        </PageSubHeader>
        <Title>Search for companies</Title>
        <ApolloConsumer>
          {(client) => (
            <SearchForm
              value={value}
              client={client}
              onValueChange={this.onValueChange}
              onResult={this.props.setSearchResult}
              onLoading={this.props.setSearchLoading}
              onError={this.props.setSearchError}
            />
          )}
        </ApolloConsumer>
        <Show if={result.length}>
          <SearchResult
            onCompanyClick={this.onCompanyClick}
            result={result}
          />
        </Show>
        <Show if={error}>
          <CenterBox>
            <Error>Something went wrong, please try again later</Error>
          </CenterBox>
        </Show>
        <Show if={isEmpty}>
          <CenterBox>
            <Error>Nothing was found</Error>
          </CenterBox>
        </Show>
        <Show if={loading}>
          <CenterBox>
            <Spinner />
          </CenterBox>
        </Show>
      </SidebarLayout>
    )
  }

  private onValueChange = (event) => {
    this.props.setSearchValue(event.target.value)
  }
}
