import { DefaultTheme } from './default.theme'
export const SuperOfficeTheme = {
  ...DefaultTheme,
  THEME_ID: 'SUPER_OFFICE',
  TOP_MENU_COLOR: '#ffffff',
  TOP_MENU_ITEM_ACTIVE: '#ffffff',
  TOP_MENU_ITEM_HOVER: '#f5f5f5',
  TOP_MENU_FONT_COLOR: '#314550',
  TOP_MENU_ACTIVE_COLOR: '#2f9ccf',
  TOP_MENU_DISABLED_FONT_COLOR: '#96a2a8',
  SUB_MENU_BACKGROUND_COLOR: '#e9eef1',
  SUB_MENU_FONT_COLOR: '#314550',
  HEADER_SEARCH_BACKGROUND: '#f3f3f3',
  CANVAS_COLOR: '#f2f5f7',
  PANEL_TOP_COLOR: '#27617d',
  STANDARD_BUTTON_COLOR: '#2f9ccf',
  WEB_VISIT_BACKGROUND_COLOR: '#2F9CCF',
  HR_COLOR: '#b7c3c9',
  BUTTONS: {
    default: {
      background: '#e0e1e2',
      backgroundHover: '#d7d9da',
      color: 'rgba(0, 0, 0, .6)',
      border: '1px solid #e0e1e2',
      backgroundDisabled: '#D2D9DE',
      borderDisabled: '#D2D9DE',
    },
    primary: {
      background: '#2f9ccf',
      backgroundHover: '#5b7d93',
      color: 'white',
      border: '1px solid #2f9ccf',
      backgroundDisabled: '#D2D9DE',
      borderDisabled: '#D2D9DE',
    },
    success: {
      background: '#7AAB59',
      backgroundHover: '#72a152',
      color: 'white',
      border: '1px solid #7AAB59',
      backgroundDisabled: '#e3ecd6',
      borderDisabled: '#e3ecd6',
    },
    white: {
      background: '#ffffff',
      backgroundHover: '#f7f7f7',
      color: '#61869E',
      border: '1px solid #61869E',
      backgroundDisabled: '#8B929E',
      borderDisabled: '#8B929E',
    },
    alert: {
      background: '#d9534f',
      backgroundHover: '#dc3732',
      color: 'white',
      border: '1px solid #d9534f',
      backgroundDisabled: '#8B929E',
      borderDisabled: '#8B929E',
    },
  }
}
