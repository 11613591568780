import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import { Button } from '../../../button'
import { OutsideClickHandler } from '../../../../utils'

export const Wrapper = styled(OutsideClickHandler)`
  position: absolute;
  top: 120%;
  right: ${({ position: { right } }) => right};
  left: ${({ position: { left } }) => left};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 620px;
  max-width: calc(100vw - 40px);
  padding: 10px;
  border-top: 1px solid rgba(0,0,0, 0.05);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 2;
`
export const RangeWrapper = styled.div`
  height: 300px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    height: 100px;
  }
`
export const RangeTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`
export const RangeValue = styled.div`
  margin-bottom: 35px;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`
export const ApplyButton = styled(Button)`
  width: 100px;
`
