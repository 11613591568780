import styled from 'styled-components'

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    border: 1px solid #bfbfbf;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    max-width: 40px;
    padding: 0 12px;
    font-size: 18px;
  }

  input {
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
