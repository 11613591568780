import { createGlobalStyle } from 'styled-components'

export const RcSelectStyles = createGlobalStyle`
  .ant-select {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    position: relative;
    outline: 0;
    min-width: 100px;
  }
  .ant-select ul,
  .ant-select ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-select > ul > li > a {
    padding: 0;
    background-color: #fff;
  }
  .ant-select-arrow {
    display: inline-block;
    font-style: normal;
    vertical-align: -0.125em;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    position: absolute;
    top: 50%;
    right: 11px;
    line-height: 1;
    margin-top: -6px;
    transform-origin: 50% 50%;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
  .ant-select-arrow > * {
    line-height: 1;
  }
  .ant-select-arrow svg {
    display: inline-block;
  }
  .ant-select-arrow:before {
    display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    display: block;
  }
  .ant-select-arrow .ant-select-arrow-icon svg {
    transition: transform .3s;
  }
  .ant-select-selection {
    outline: none;
    user-select: none;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection:hover {
    border-color: ${({ theme }: any) => theme.SELECT_HOVER};
    border-right-width: 1px !important;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: ${({ theme }: any) => theme.SELECT_HOVER};
    outline: 0;
    box-shadow: 0 0 0 2px ${({ theme }: any) => theme.SELECT_HOVER_SHADOW};
    border-right-width: 1px !important;
  }
  .ant-select-selection__clear {
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    opacity: 0;
    position: absolute;
    right: 11px;
    z-index: 1;
    background: #fff;
    top: 50%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    line-height: 12px;
    cursor: pointer;
    transition: color 0.3s ease, opacity 0.15s ease;
  }
  .ant-select-selection__clear:before {
    display: block;
  }
  .ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select-selection:hover .ant-select-selection__clear {
    opacity: 1;
  }
  .ant-select-selection-selected-value {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding-right: 20px;
  }
  .ant-select-no-arrow .ant-select-selection-selected-value {
    padding-right: 0;
  }
  .ant-select-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-disabled .ant-select-selection {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-disabled .ant-select-selection:hover,
  .ant-select-disabled .ant-select-selection:focus,
  .ant-select-disabled .ant-select-selection:active {
    border-color: #d9d9d9;
    box-shadow: none;
  }
  .ant-select-disabled .ant-select-selection__clear {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    background: #f5f5f5;
    color: #aaa;
    padding-right: 10px;
  }
  .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
    display: none;
  }
  .ant-select-selection--single {
    height: 32px;
    position: relative;
    cursor: pointer;
  }
  .ant-select-selection__rendered {
    display: block;
    margin-left: 11px;
    margin-right: 11px;
    position: relative;
    line-height: 30px;
  }
  .ant-select-selection__rendered:after {
    content: '.';
    visibility: hidden;
    pointer-events: none;
    display: inline-block;
    width: 0;
  }
  .ant-select-lg {
    font-size: 16px;
  }
  .ant-select-lg .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-lg .ant-select-selection__rendered {
    line-height: 38px;
  }
  .ant-select-lg .ant-select-selection--multiple {
    min-height: 40px;
  }
  .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear {
    top: 20px;
  }
  .ant-select-sm .ant-select-selection--single {
    height: 24px;
  }
  .ant-select-sm .ant-select-selection__rendered {
    line-height: 22px;
    margin: 0 7px;
  }
  .ant-select-sm .ant-select-selection--multiple {
    min-height: 24px;
  }
  .ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear {
    top: 12px;
  }
  .ant-select-sm .ant-select-selection__clear,
  .ant-select-sm .ant-select-arrow {
    right: 8px;
  }
  .ant-select-disabled .ant-select-selection__choice__remove {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
  }
  .ant-select-disabled .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-search__field__wrap {
    display: inline-block;
    position: relative;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    left: 0;
    right: 9px;
    color: #bfbfbf;
    line-height: 20px;
    height: 20px;
    max-width: 100%;
    margin-top: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .ant-select-search__field__placeholder {
    left: 12px;
  }
  .ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    pointer-events: none;
    opacity: 0;
  }
  .ant-select-search--inline {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .ant-select-search--inline .ant-select-search__field__wrap {
    width: 100%;
    height: 100%;
  }
  .ant-select-search--inline .ant-select-search__field {
    border-width: 0;
    font-size: 100%;
    height: 100%;
    width: 100%;
    background: transparent;
    outline: 0;
    border-radius: 4px;
    line-height: 1;
  }
  .ant-select-search--inline > i {
    float: right;
  }
  .ant-select-selection--multiple {
    min-height: 32px;
    cursor: text;
    padding-bottom: 3px;
    zoom: 1;
  }
  .ant-select-selection--multiple:before,
  .ant-select-selection--multiple:after {
    content: "";
    display: table;
  }
  .ant-select-selection--multiple:after {
    clear: both;
  }
  .ant-select-selection--multiple .ant-select-search--inline {
    float: left;
    position: static;
    width: auto;
    padding: 0;
    max-width: 100%;
  }
  .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    max-width: 100%;
    width: 0.75em;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-left: 5px;
    margin-bottom: -3px;
    height: auto;
  }
  .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px;
  }
  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 3px;
    height: 24px;
    line-height: 22px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    float: left;
    margin-right: 4px;
    max-width: 99%;
    position: relative;
    overflow: hidden;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 20px 0 10px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__disabled {
    padding: 0 10px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-style: normal;
    vertical-align: -0.125em;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    line-height: inherit;
    cursor: pointer;
    font-weight: bold;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: inline-block;
    font-size: 12px;
    transform: scale(0.83333333) rotate(0deg);
    position: absolute;
    right: 4px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove > * {
    line-height: 1;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
    display: inline-block;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove:before {
    display: none;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
    display: block;
  }
  :root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 12px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
    color: #404040;
  }
  .ant-select-selection--multiple .ant-select-selection__clear {
    top: 16px;
  }
  .ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
    padding-right: 16px;
  }
  .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-right: 20px;
  }
  .ant-select-open .ant-select-arrow-icon svg {
    transform: rotate(180deg);
  }
  .ant-select-open .ant-select-selection {
    border-color: ${({ theme }) => theme.SELECT_HOVER};
    outline: 0;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.SELECT_HOVER_SHADOW};
    border-right-width: 1px !important;
  }
  .ant-select-combobox .ant-select-arrow {
    display: none;
  }
  .ant-select-combobox .ant-select-search--inline {
    height: 100%;
    width: 100%;
    float: none;
  }
  .ant-select-combobox .ant-select-search__field__wrap {
    width: 100%;
    height: 100%;
  }
  .ant-select-combobox .ant-select-search__field {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
    box-shadow: none;
  }
  .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 20px;
  }
  .ant-select-dropdown {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    font-variant: initial;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1050;
    left: -9999px;
    top: -9999px;
    position: absolute;
    outline: none;
    font-size: 14px;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-menu {
    outline: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto;
  }
  .ant-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 20px;
  }
  .ant-select-dropdown-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }
  .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
  .ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
    border-radius: 0;
  }
  .ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    line-height: 22px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background 0.3s ease;
  }
  .ant-select-dropdown-menu-item:hover {
    background-color: ${({ theme }) => theme.SELECT_ITEM_HOVER};
  }
  .ant-select-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0;
  }
  .ant-select-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px;
  }
  .ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-selected:hover {
    background-color: #fafafa;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-select-dropdown-menu-item-active {
    background-color: ${({ theme }: any) => theme.SELECT_ITEM_HOVER};
  }
  .ant-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 32px;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    color: transparent;
    display: inline-block;
    font-size: 12px;
    transform: scale(0.83333333) rotate(0deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    font-weight: bold;
    text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  }
  :root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    font-size: 12px;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
    color: #ddd;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
    display: none;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    color: #1890ff;
    display: inline-block;
  }
  .ant-select-dropdown-container-open .ant-select-dropdown,
  .ant-select-dropdown-open .ant-select-dropdown {
    display: block;
  }
  @keyframes antSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
  }

  @keyframes antSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0.8);
    }
  }

  @keyframes antSlideDownIn {
    0% {
      opacity: 0;
      transform-origin: 100% 100%;
      transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      transform-origin: 100% 100%;
      transform: scaleY(1);
    }
  }

  @keyframes antSlideDownOut {
    0% {
      opacity: 1;
      transform-origin: 100% 100%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 100% 100%;
      transform: scaleY(0.8);
    }
  }

  @keyframes antSlideLeftIn {
    0% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleX(0.8);
    }
    100% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleX(1);
    }
  }

  @keyframes antSlideLeftOut {
    0% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleX(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleX(0.8);
    }
  }

  @keyframes antSlideRightIn {
    0% {
      opacity: 0;
      transform-origin: 100% 0;
      transform: scaleX(0.8);
    }
    100% {
      opacity: 1;
      transform-origin: 100% 0;
      transform: scaleX(1);
    }
  }

  @keyframes antSlideRightOut {
    0% {
      opacity: 1;
      transform-origin: 100% 0;
      transform: scaleX(1);
    }
    100% {
      opacity: 0;
      transform-origin: 100% 0;
      transform: scaleX(0.8);
    }
  }
`
