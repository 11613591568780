import styled from 'styled-components'

interface Wrapper {
  size: number
}

export const Wrapper = styled.div<Wrapper>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => Math.round(size / 2.6)}px;
  border-radius: 50%;
  color: white;
  background-color: #cdcdcd;
`
