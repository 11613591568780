import styled from 'styled-components'
import { Icon } from '../../../../components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
export const DataRow = styled.div`
  height: 52px;
  padding: 20px 30px;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
`
export const Total = styled.div`
  background: rgba(236, 241, 245, 0.4);
  padding: 20px 30px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TotalDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding-right: 10px;
`
export const Header = styled.div`
  font-size: 18px;
`
export const Description = styled.div`
  font-size: 12px;
  font-weight: 300;
  min-width: 110px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`
export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Persons = styled.div`
  display: flex;
  flex-direction: row;
`
export const SummaryRow = styled.div`
  min-width: 100px;
`
export const SummaryValue = styled.span`
  font-weight: 500;
`
export const TotalValue = styled.div`
  font-size: 38px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  @media (max-width: 800px) {
    font-size: 24px;
  }
`
export const PersonIcon = styled(Icon)`
  margin: 0 0 0 4px;
`

interface PersonPhotoProps {
  image: string
}

export const PersonPhoto = styled.div<PersonPhotoProps>`
  min-width: 54px;
  min-height: 54px;
  height: 54px;
  border-radius: 50%;
  background: ${({ image }) => `url("${image}") center no-repeat`};
  background-size: cover;
  margin: 0 0 0 4px;
`
export const ClockIcon = styled(Icon)`
  @media (max-width: 800px) {
    margin-right: 8px;
  }
`
