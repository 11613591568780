import * as React from 'react'

import { CardWrapper, CardHeader, AfterHeader, Leads, AfterLeads, Period, Price } from './plan-card.styles'
import { Plan } from '../../plan.model'

interface PlanCardProps {
  plan: Plan
  current: boolean
  selected: boolean
  onClick: (selectedPlan: string) => void
}

export class PlanCard extends React.PureComponent<PlanCardProps> {
  public getHeader = () => {
    const { current, selected } = this.props
    if (current) {
      return (
        <CardHeader
          current={true}
        >
          Current
        </CardHeader>
      )
    }
    if (selected) {
      return (
        <CardHeader
          selected={true}
        >
          Selected
        </CardHeader>
      )
    }
    return (
      <CardHeader>
        Click to select
      </CardHeader>
    )
  }

  public onCardClick = () => {
    this.props.onClick(this.props.plan.code)
  }

  public render() {
    const { leads, price } = this.props.plan
    return (
      <CardWrapper
        onClick={this.onCardClick}
        current={this.props.current}
      >
        {this.getHeader()}
        <AfterHeader>for up to</AfterHeader>
        <Leads>{leads}</Leads>
        <AfterLeads>leads/month</AfterLeads>
        <Price>{price} EUR</Price>
        <Period>per month</Period>
      </CardWrapper>
    )
  }
}
