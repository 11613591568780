import * as React from 'react'
import * as qs from 'query-string'

export class Callback extends React.PureComponent {
  public componentDidMount() {
    const { pathname } = window.location
    const { current, next } = qs.parse(window.location.search)
    switch (pathname) {

      case '/callback': {
        localStorage.setItem('callbackUrl', current)
        window.location.href = next
        break
      }

      case '/callback/done': {
        const callbackUrl = localStorage.getItem('callbackUrl')
        if (callbackUrl) {
          window.location.href = `${callbackUrl}/callback/done${window.location.search}`
        }
        break
      }

      case '/superoffice/callback': {
          const callbackUrl = localStorage.getItem('callbackUrl')
          if (callbackUrl) {
              window.location.href = `${callbackUrl}/superoffice/callback${window.location.search}`
          }
          break
      }
    }
  }
  public render() {
    return (
      <div />
    )
  }
}
