import * as React from 'react'
import { chain, join, get } from 'lodash'

import { Icon, TreesRating, Show, Slideshow } from '../../../../components'
import {
  Wrapper,
  LogoContainer,
  Name,
  Description,
  Industry,
  Line,
  Title,
  Address,
  CompanyInformation,
  DetailsContainer,
  Detail,
  DetailTitle,
  Age,
  SocialIconList,
  LogoLoading,
  NameLoading,
  LoadingText,
  WebsiteLink,
  CompanySizeTitle,
  PhoneDetail,
} from './basic-info.styles'
import { getScreenshotUrl } from '../../../../utils'

interface BasicInfoProps {
  company: any
  loading: boolean
}

export const BasicInfo: React.SFC<BasicInfoProps> = ({ company = {}, loading }) => {
  const { logo, name, website, founded, phone, bio, employeesCount } = company

  const location = get(company, 'locations[0]', {})

  let formattedAddress = location.addressLine1

  if (formattedAddress) {
    formattedAddress += ` ${join([location.city, location.country], ', ')}`
  }

  const industryText = chain(company.industries).map('name').join(', ').value()
  const socialProfiles = company.profiles || {}

  return (
    <Wrapper>
      <LogoContainer>
        <Show if={!loading}>
          <Slideshow
            images={[
              { src: logo || '/images/company-logo.png', styles: { backgroundSize: 'contain' } },
              { src: getScreenshotUrl(website), styles: { backgroundSize: 'cover', position: 'top center' } }]}
            duration={5000}
          />
        </Show>
        <Show if={loading}>
          <LogoLoading />
        </Show>
      </LogoContainer>
      <Name>
        <Show if={!loading}>
          {name}
        </Show>
        <Show if={loading}>
          <NameLoading />
        </Show>
      </Name>

      <Show if={!loading && bio}>
        <Description>
          {bio}
        </Description>
      </Show>
      <Show if={loading}>
        <Description>
          <LoadingText />
          <LoadingText />
          <LoadingText half={true} />
        </Description>
      </Show>

      <Line />

      <Show if={!loading && industryText}>
        <CompanyInformation>
          <Title>Industry</Title>
          <Industry>{industryText}</Industry>
          <Show if={loading}>
            <Title>
              <LoadingText half={true} />
            </Title>
            <Industry>
              <LoadingText />
              <LoadingText half={true} />
            </Industry>
          </Show>
        </CompanyInformation>
        <Line />
      </Show>
      <Show if={loading}>
        <CompanyInformation>
          <Title>
            <LoadingText half={true} />
          </Title>
          <Industry>
            <LoadingText />
            <LoadingText half={true} />
          </Industry>
        </CompanyInformation>
        <Line />
      </Show>

      <CompanyInformation>
        <Show if={!loading}>
          <Age>
            <Title>Years in business</Title>
            <TreesRating value={founded ? (new Date()).getFullYear() - founded : -1} />
          </Age>
          <Title>Company Information</Title>
          <DetailsContainer>
            <Detail>
              <DetailTitle>Founded:</DetailTitle>
              {founded || 'N/A'}
            </Detail>
            <Address>{formattedAddress}</Address>
            <PhoneDetail>
              <DetailTitle>Phone:</DetailTitle>
              {phone || 'N/A'}
            </PhoneDetail>
            <Detail>
              <DetailTitle>Website:</DetailTitle>
              <WebsiteLink href={website} target="_blank">{website}</WebsiteLink>
            </Detail>
          </DetailsContainer>
          <Show if={employeesCount}>
            <CompanySizeTitle>Company Size</CompanySizeTitle>
            <DetailsContainer>
              <Detail>
                {`${employeesCount} employees`}
              </Detail>
            </DetailsContainer>
          </Show>
          <SocialIconList>
            <Show if={get(socialProfiles, 'twitter.url')}>
              <a href={get(socialProfiles, 'twitter.url')} rel="noopener noreferrer" target="_blank">
                <Icon icon="twitterAlt" />
              </a>
            </Show>
            <Show if={get(socialProfiles, 'linkedincompany.url')}>
              <a href={get(socialProfiles, 'linkedincompany.url')} rel="noopener noreferrer" target="_blank">
                <Icon icon="linkedIn" />
              </a>
            </Show>
            <Show if={get(socialProfiles, 'youtube.url')}>
              <a href={get(socialProfiles, 'youtube.url')} rel="noopener noreferrer" target="_blank">
                <Icon icon="youtubeAlt" />
              </a>
            </Show>
            <Show if={get(socialProfiles, 'facebook.url')}>
              <a href={get(socialProfiles, 'facebook.url')} rel="noopener noreferrer" target="_blank">
                <Icon icon="facebookAlt" />
              </a>
            </Show>
          </SocialIconList>
        </Show>
        <Show if={loading}>
          <Title><LoadingText half={true} /></Title>
          <LoadingText />
          <LoadingText />
          <LoadingText />
          <LoadingText />
        </Show>
      </CompanyInformation>
    </Wrapper>
  )
}
