import * as React from 'react'
import { connect } from 'react-redux'
import { get, slice } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'

import { closeSidePanel, showSidePanel } from 'actions'
import { Show, SidePanel } from '../../../../components'
import { marketingTimelineSelector } from './marketing-timeline.selector'
import { MarketingEvents } from './components/marketing-events'
import { MarketingIntegrationPlaceholder, MarketingIntegrationPlaceholderWrapper } from '../../../../components/common'
import { MarketingEventDetails } from './components/marketing-event-details'
import { Footer, Triangle, Wrapper } from './marketing-timeline.styles'
import { Events, registerAnalyticEvent } from 'utils';

const eventPanelTitles = {
  emailLinkClicked: 'Email Details',
  emailUnsubscribed: 'Unsubscrbied Details',
  formAnswered: 'Form Details',
  landingPageVisited: 'Landingpage Details',
}

interface MarketingTimelineProps {
  fetchMore?: any
  events?: any
  loading?: boolean
  marketingEventPanelVisible?: any
  emarketeerConnected?: any
  domain?: any
  sidePanelVisible?: boolean
  showSidePanel?: any
  closeSidePanel?: any
  companyName?: any
}

// @ts-ignore
@connect(marketingTimelineSelector, { closeSidePanel, showSidePanel })
export class MarketingTimeline extends React.Component<MarketingTimelineProps> {
  public state = {
    activeEvent: null,
    hasMore: true,
    loading: false,
  }

  public showSidePanel = () => {
    this.props.showSidePanel('marketingTimelineSelection')
    registerAnalyticEvent(Events.MARKETING_TIMELINE_PAGE_VISIT, { companyName: this.props.companyName })
  }

  public closeSidePanel = () => {
    this.props.closeSidePanel('marketingTimelineSelection')
  }

  public showEventDetails = (event) => {
    this.setState({ activeEvent: event })
    this.props.showSidePanel('marketingEventPanel')
  }

  public closeEventDetails = () => {
    this.props.closeSidePanel('marketingEventPanel')
  }

  public fetchMore = async () => {
    const { fetchMore, events, loading, sidePanelVisible } = this.props

    if (loading || this.state.loading || !this.state.hasMore || !sidePanelVisible) {
      return
    }

    this.setState({ loading: true })

    await fetchMore({
      variables: { offset: events.length || 0 },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult.marketingEvents.length < 10) {
          this.setState({ hasMore: false })
        }

        if (!fetchMoreResult.marketingEvents) {
          return previousResult
        }

        return {
          marketingEvents: [
            ...previousResult.marketingEvents,
            ...fetchMoreResult.marketingEvents,
          ],
        }
      },
    })

    this.setState({ loading: false })
  }

  public render() {
    const { loading, sidePanelVisible, marketingEventPanelVisible, emarketeerConnected, events = [], domain } = this.props

    const { activeEvent } = this.state
    const eventPanelTitle = eventPanelTitles[get(activeEvent, 'type')] || ''

    return (
      <Wrapper>
        <Show if={emarketeerConnected || loading}>
          <MarketingEvents events={slice(events, 0, 5)} loading={loading} showEventDetails={this.showEventDetails} />

          <SidePanel isVisible={sidePanelVisible} handleClose={this.closeSidePanel} title="Selection">
            <InfiniteScroll
              loadMore={this.fetchMore}
              hasMore={this.state.hasMore}
              threshold={500}
              useWindow={false}
            >
              <MarketingEvents events={events} loading={this.state.loading} showEventDetails={this.showEventDetails} />
            </InfiniteScroll>
          </SidePanel>

          <SidePanel
            isVisible={marketingEventPanelVisible}
            handleClose={this.closeEventDetails}
            title={eventPanelTitle}
          >
            <MarketingEventDetails event={activeEvent} domain={domain} />
          </SidePanel>

          <Show if={!loading && events.length > 5 }>
            <Footer onClick={() => this.showSidePanel()}>
              Show more <Triangle icon="triangleRight" size={10} />
            </Footer>
          </Show>
        </Show>


        <Show if={!emarketeerConnected && !loading}>
          <MarketingIntegrationPlaceholderWrapper>
            <MarketingIntegrationPlaceholder
              componentName="Leadexplorer's timeline"
              description="Integrate Leadexplorer with your marketing tool to see the companies interaction with your marketing campaigns."
            />
          </MarketingIntegrationPlaceholderWrapper>
        </Show>
      </Wrapper>
    )
  }
}
