import styled from 'styled-components'
import { Icon } from '../'

const defaultIcon = '/images/company-logo.png'

export const smallTableScreen = '950px'

export const ItemRow = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
   background: #ffffff;
   padding: 0 8px;
   border-top: 1px solid #CBCBCB;
   box-sizing: border-box;
   cursor: pointer;
   transition: background-color .3s;
   outline: none;
   &:hover {
     background-color: rgba(49, 69, 80, .03);
   }
   &:last-child {
     border-bottom: 1px solid #CBCBCB;
   }
   @media (max-width: ${smallTableScreen}) {
     flex-wrap: wrap;
     padding: 10px 8px;
   }
`
export const ItemCol = styled.div`
  position: relative;
  padding: 15px 8px;
  font-size: 18px;
  @media (max-width: ${smallTableScreen}) {
    padding: 5px 8px;
  }
`
export const Engagement = styled(ItemCol)`
  width: 100px;
  @media (max-width: ${smallTableScreen}) {
    width: auto;
    margin-top: -15px;
  }
`
export const EngagementContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`
export const Info = styled(ItemCol)`
  display: flex;
  flex: 1;
  flex-direction: column;
  word-break: break-word;
`
export const DateColumn = styled(ItemCol)`
  font-size: 10px;
  width: 90px;
  line-height: 1.4;
  @media (max-width: ${smallTableScreen}) {
    width: 100%;
  }
`
export const WhatHappened = styled(ItemCol)`
  display: flex;
  flex: 2;
  flex-direction: column;
  @media (max-width: ${smallTableScreen}) {
    min-width: calc(100% - 10px);
    order: 5;
  }
`
export const DateView = styled.div`
  margin-top: 13px;
  @media (max-width: ${smallTableScreen}) {
    display: flex;
    flex-direction: row;
    margin-top: 0;
  }
`
export const Header = styled.div`
  position: absolute;
  top: -35px;
  font-size: 14px;
  pointer-events: none;
  @media (max-width: ${smallTableScreen}) {
     display: none;
  }
`
export const EngagementHeader = styled.div`
  position: absolute;
  top: -35px;
  right: 8px;
  font-size: 14px;
  pointer-events: none;
  @media (max-width: ${smallTableScreen}) {
     display: none;
  }
`

export const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`

export const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #314550;
  @media (max-width: ${smallTableScreen}) {
    font-size: 13px;
  }
`

export const CrmIcon = styled(Icon)`
  margin-left: 8px;
  margin-right: 0;
`

export const Time = styled.div`
  margin-top: 3px;
  @media (max-width: ${smallTableScreen}) {
    margin: 0 0 0 5px;
  }
`
export const Location = styled.div`
  height: 16px;
  margin-top: 4px;
  font-size: 12px;
  color: #314550;
  display: flex;
  align-items: center;
`
export const OnSiteNow = styled.div`
  color: #e14c54;
  font-weight: 500;
`

interface LogoProps {
  src: string
}

export const Logo = styled.div<LogoProps>`
  width: 62px;
  height: 62px;
  background: ${({ src }) => src ? `white url("${src}") no-repeat 100%` : `white url("${defaultIcon}") no-repeat 100%`};
  background-size: contain;
`
export const LogoContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  width: 62px;
  height: 62px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const User = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`
export const UserIcon = styled(Icon)`
  margin-right: 8px;
`

export const Assigned = styled(ItemCol)`
  width: 230px;
  @media (max-width: ${smallTableScreen}) {
    width: auto;
    margin-top: -15px;
  }
`
export const AssignedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  padding-right: 10px;
`
