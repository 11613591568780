import * as React from 'react'
import { map } from 'lodash'

import { Card, TableRow, TableWrapper, Button, TableHeaderItem, Alignment, Show } from '../../../../../components/index'
import { SubscriptionInfoCard, Table, CancelLink } from '../../../settings-container.styles'
import { CurrentSubscriptionTableRow } from './current-subscription-table-row'

const CardTitle = (Card as any).Title
const CardContent = (Card as any).Content
const CardFooter = (Card as any).Footer

interface CurrentSubscriptionProps {
  data: any
  onUpgradeClick: () => void
  onCancelClick: () => void
}

export class CurrentSubscription extends React.PureComponent<CurrentSubscriptionProps> {
  public render() {
    const { data } = this.props
    return (
      <SubscriptionInfoCard>
        <CardTitle>
          Current subscription
        </CardTitle>
        <CardContent>
          <TableWrapper>
            <Table>
              <TableRow>
                <TableHeaderItem size={2}>
                  Plan & Addon Details
                </TableHeaderItem>
                <TableHeaderItem alignment={Alignment.right}>
                  Qty
                </TableHeaderItem>
                <TableHeaderItem alignment={Alignment.right}>
                  Rate
                </TableHeaderItem>
                <TableHeaderItem alignment={Alignment.right}>
                  Amount
                </TableHeaderItem>
              </TableRow>
              <CurrentSubscriptionTableRow
                plan={data.plan.name}
                qty={data.plan.quantity}
                rate={data.plan.price}
                amount={data.plan.total}
              />
              {map(data.addons, ({ name, quantity, price, tax_id, total }) => (
                <CurrentSubscriptionTableRow
                  key={name}
                  plan={name}
                  qty={quantity}
                  rate={price}
                  amount={total}
                />
              ))}
            </Table>
          </TableWrapper>
          <CardFooter>
            <Button
              onClick={this.props.onUpgradeClick}
              color="primary"
            >
              UPGRADE PLAN
            </Button>
            <Show if={data.status === 'live'}>
              <CancelLink
                onClick={this.props.onCancelClick}
              >
                Cancel Subscription
              </CancelLink>
            </Show>
          </CardFooter>
        </CardContent>
      </SubscriptionInfoCard>
    )
  }
}
