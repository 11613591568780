import * as React from 'react'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ApolloConsumer, graphql } from 'react-apollo'
import { get } from 'lodash'

import { userLogout } from 'actions'
import { AvatarMenu, HeaderMenu, Search } from 'components'
import { Show } from '../../'
import { pageHeaderSelector } from './page-header.selector'
import { Wrapper, RightSide, LogoText, LeftSide, HeaderLogo, Help, MobileWrapper, BurgerWrapper, BurgerPart } from './page-header.styles'
import { userQuery } from '../../../gql'
import { User } from '../../../models/user.model'

interface PageHeaderProps {
  dispatch?: (action) => null
  history?: any
  location?: {
    pathname: string
  }
  isSmallScreen?: boolean
  onHeaderClick?: () => null
  auth?: { isAuthenticated: boolean }
  data?: any
  noSearch?: boolean
}

@withRouter
@connect(pageHeaderSelector)
// @ts-ignore
@graphql(userQuery, {
  skip: ({ auth }) => !auth.isAuthenticated,
})
export class PageHeader extends PureComponent<PageHeaderProps> {
  public onLogout = () => {
    this.props.dispatch(userLogout())
  }

  public onMyProfile = () => {
    this.props.history.push('/my-profile')
  }

  public goToSearch = () => {
    this.props.history.push('/search')
  }

  public goToCompany = (companyId: string) => {
    this.props.history.push(`/webleads/company/${companyId}/dashboard`)
  }

  public onHelp = () => {
    window.open('https://help.leadexplorer.com/')
  }

  public render() {
    const { isSmallScreen, onHeaderClick, auth: { isAuthenticated }, data, noSearch = false } = this.props
    const user = data && data.getUser ? new User(data.getUser) : null
    const picture = get(data, 'getUser.picture', null)

    if (isSmallScreen) {
      return (
        <MobileWrapper>
          <BurgerWrapper
            onClick={onHeaderClick}
          >
            <BurgerPart />
            <BurgerPart />
            <BurgerPart />
          </BurgerWrapper>
          <HeaderLogo>
            <img src="/images/logo.svg" alt="home" width="30" height="34" />
            <LogoText>Leadexplorer</LogoText>
          </HeaderLogo>
          <RightSide>
            {(data && !data.loading) ? (
              <AvatarMenu
                onLogout={this.onLogout}
                onMyProfile={this.onMyProfile}
                isAuthenticated={isAuthenticated}
                picture={picture}
                user={user}
              />
            ) : null}
          </RightSide>
        </MobileWrapper>
      )
    }

    return (
      <Wrapper>
        <LeftSide>
          <HeaderLogo>
            <img src="/images/logo.svg" alt="home" width="30" height="34" />
            <LogoText>Leadexplorer</LogoText>
          </HeaderLogo>
          <Show if={isAuthenticated} >
            <HeaderMenu
              pathname={this.props.location.pathname}
              user={user}
              isSmallScreen={isSmallScreen}
            />
          </Show>
        </LeftSide>
        <RightSide>
          <Show if={isAuthenticated} >
            <ApolloConsumer>
              {(client) => (
                <Search
                  noSearch={noSearch}
                  client={client}
                  navigateToSearch={this.goToSearch}
                  navigateToCompany={this.goToCompany}
                />
              )}
            </ApolloConsumer>
          </Show>
          <Help onClick={this.onHelp}/>
          <Show if={data && !data.loading} >
            <AvatarMenu
              onLogout={this.onLogout}
              onMyProfile={this.onMyProfile}
              isAuthenticated={isAuthenticated}
              picture={picture}
              user={user}
            />
          </Show>
        </RightSide>
      </Wrapper>
    )
  }
}
