import * as React from 'react'

import { Modal } from '../../../../../../components/modal/modal.component'
import { MainText, BottomText, Controls } from './cancellation-modal.styles'

interface CancellationModalProps {
  currentTerm: string,
  isOpen: boolean
  onRequestClose: () => void
  onYesClick: () => void
  onNoClick: () => void
}

export const CancellationModal: React.SFC<CancellationModalProps> = ({
  currentTerm,
  isOpen,
  onRequestClose,
  onYesClick,
  onNoClick
}) => (
  <Modal
    onRequestClose={onRequestClose}
    isOpen={isOpen}
    style={{
      content: {
        maxWidth: '650px',
      },
    }}
  >
    <Modal.Title>
      Cancel subscription
    </Modal.Title>
    <MainText>
      <p>
        By cancelling this subscription you will turn off the automatic renewal of the subscription.
      </p>
      <p>
        Your subscription will continue working until {currentTerm}. After this the subscription will be terminated.
      </p>
    </MainText>
    <BottomText>
      Would you like to cancel your subscription?
    </BottomText>
    <Controls>
      <Modal.ApproveButton
        onClick={onYesClick}
      >
        Yes
      </Modal.ApproveButton>
      <Modal.CancelButton
        onClick={onNoClick}
      >
        No
      </Modal.CancelButton>
    </Controls>
  </Modal>
)

