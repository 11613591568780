import styled from 'styled-components'
import { Row, Button } from '../../../../components'

export const PlanCardInner = styled.div`
  padding: 24px 32px;

  p {
    margin: 0 0 32px;
    font-weight: 300;
    font-size: 18px;
  }
  
  footer {
    p {
      font-size: 14px;
      margin: 0 0 4px;
    }
  }
  
  ${Row} {
    margin: 0 0 32px;
  }
  
  ${Button} {
    padding-right: 24px;
    padding-left: 24px;
  }
`

export const PlanLink = styled.div<{ active: boolean }>`
  margin: 0 16px 0 0;
  padding: 8px 32px;
  text-decoration: none;
  font-size: 12px;
  color: #314550;
  border-radius: 5px;
  background: ${({ active }) => active ? '#e3e3e3' : 'none'};
  cursor: pointer;
`

export const BillingFormWrapper = styled.div`
  padding: 0 24px 32px;
`
