import * as React from 'react'
import { compose, withApollo } from 'react-apollo'
import queryString from 'query-string'
import { get } from 'lodash'

import { Events, getRedirectUri, registerAnalyticEvent } from 'utils'
import { Card, LoadingBlock, Show } from 'components'
import { superOfficeIntegrationQueries } from './superoffice-integration.query'
import {
  ConnectButton,
  ConnectedAccount,
  Content,
  DisconnectButton,
  InfoCard,
  Title,
  Wrapper,
  WrappingContent
} from './superoffice-integeration.styles'

interface SuperOfficeIntegrationProps {
  setupSuperOfficeIntegration: any
  disconnect: any
  data: any
  auth: any
}

@compose(...superOfficeIntegrationQueries)
// @ts-ignore
@withApollo
export class SuperOfficeIntegration extends React.PureComponent<SuperOfficeIntegrationProps> {
  public static instance
  public static timer

  public state = {
    updating: false,
  }

  public connect() {
    const { setupSuperOfficeIntegration, data, auth } = this.props

    if (SuperOfficeIntegration.instance) {
      SuperOfficeIntegration.instance.close()
      clearInterval(SuperOfficeIntegration.timer)
    }

    this.setState({ updating: true })

    const current = window.location.origin
    const popupUrl = getRedirectUri(`/callback?current=${current}&next=${encodeURIComponent(get(auth, 'getSuperOfficeAuthUrl.url'))}`)

    SuperOfficeIntegration.instance = window.open(popupUrl, '', 'top=100,left=100,width=500,height=800')
    registerAnalyticEvent(Events.SUPEROFFICE_ONLINE_CONNECT_STARTED)
    SuperOfficeIntegration.timer = setInterval(() => {
      const { instance, timer } = SuperOfficeIntegration
      if (!instance || instance.closed) {
        this.setState({ updating: false })
        registerAnalyticEvent(Events.SUPEROFFICE_ONLINE_CONNECT_STOPPED)
        return
      }
      try {
        if (instance.document.URL.includes('/callback/done')) {
          const queryParams = queryString.parse(new URL(instance.document.URL).search)

          this.setState({ updating: true })

          setupSuperOfficeIntegration({ variables: { ...queryParams } })
            .then((result) => {
              registerAnalyticEvent(Events.SUPEROFFICE_ONLINE_CONNECTED, {tenantName: result.data.setupSuperOfficeIntegration.tenantName})
              data.refetch()
            })
            .finally(() => this.setState({ updating: false }))

          clearInterval(timer)
          instance.close()
        }
      } catch (e) {
        console.log('Failed', e)
        if (!instance || instance.closed) {
          clearInterval(timer)
        }
      }
    }, 150)
  }

  public disconnect = async () => {
    const { disconnect, data } = this.props

    this.setState({ updating: true })
    await disconnect()
    await data.refetch()
    this.setState({ updating: false })
  }

  public render() {
    const { data, auth } = this.props
    const { updating } = this.state
    const loading = data.loading || auth.loading

    const connected = get(data, 'getSuperOfficeIntegrationStatus.connected')

    return (
      <Wrapper>
        <Title>SuperOffice</Title>
        <Content>
          <InfoCard>
            <Card.Title>
              Connected SuperOffice Account
            </Card.Title>

            <WrappingContent>
              <ConnectedAccount>
                <Show if={!loading && connected}>
                  {get(data, 'getSuperOfficeIntegrationStatus.tenantName') || ''}
                </Show>
                <Show if={!loading && !connected}>
                  No account connected
                </Show>
                <Show if={loading}>
                  <LoadingBlock width="200px" height="40px" />
                </Show>
              </ConnectedAccount>

              <Show if={!loading && connected}>
                <DisconnectButton onClick={() => this.disconnect()} updating={updating} disabled={updating}>
                Disconnect
                </DisconnectButton>
              </Show>
              <Show if={!loading && !connected}>
                <ConnectButton onClick={() => this.connect()} updating={updating} disabled={updating}>
                Connect an account
                </ConnectButton>
              </Show>
            </WrappingContent>
          </InfoCard>
        </Content>
      </Wrapper>
    )
  }
}
