export const SET_SELECTED_COMPANY_ID = 'SET_SELECTED_COMPANY_ID'
export const ADD_VIEWED_COMPANY_IDS = 'ADD_VIEWED_COMPANY_IDS'

export const setSelectedCompanyId = (id: string) => ({
  type: SET_SELECTED_COMPANY_ID,
  payload: id
})

export const addViewedCompanyIds = (ids: any[]) => ({
  type: ADD_VIEWED_COMPANY_IDS,
  payload: ids
})
