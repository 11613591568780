import * as React from 'react'
import { upperCase } from 'lodash'

import { Icon } from '../'
import { CloseIcon, Header, Panel } from './side-panel.styles'

interface SidePanelProps {
  title: string
  handleClose: () => void
  isVisible: boolean
}

export class SidePanel extends React.Component<SidePanelProps> {
  public render() {
    const { children, title, handleClose, isVisible } = this.props

    return (
      <Panel
        onOutsideClick={handleClose}
        show={isVisible}
      >
        <Header>
          {upperCase(title)}
          <CloseIcon icon="close" onClick={handleClose} color="white" />
        </Header>
        { isVisible ? children : null }
      </Panel>
    )
  }
}
