import * as React from 'react'
import { connect } from 'react-redux'
import { get, debounce, take } from 'lodash'

import { setSearchValue, setSearchResult, setSearchLoading, clearSearch } from 'actions'
import { Spinner } from 'components'
import { SearchButton, SearchResult } from './components'
import { SearchForm, SearchInput, SearchSpinnerWrapper } from './search.styles'
import { searchSelector } from '../../../../../selectors'
import { searchByName } from '../../../../../gql/company'
import { OutsideClickHandler } from '../../../../utils'

interface SearchProps {
  client: any
  value?: string
  result?: any[]
  loading?: boolean
  noSearch?: boolean
  setSearchLoading?: () => void
  setSearchValue?: (value: string) => void
  setSearchResult?: (result: any[]) => void
  clearSearch?: () => void
  navigateToSearch: () => void
  navigateToCompany: (companyId: string) => void
}

@connect(searchSelector, { setSearchValue, setSearchResult, setSearchLoading, clearSearch })
export class Search extends React.PureComponent<SearchProps> {
  public state = {
    isOpen: false
  }

  public input
  private readonly searchFirstFive

  constructor(props) {
    super(props)
    this.searchFirstFive = debounce((name: string) => {
      if (!name || name.length < 3) {
        return
      }
      this.props.setSearchLoading()
      this.props.client.query({
        query: searchByName,
        variables: { name }
      }).then((data) => {
        this.props.setSearchResult(get(data, 'data.getCompaniesByName', []))
      })
    }, 700)
  }

  public onButtonClick = () => {
    if (this.state.isOpen) {
      if (this.props.value) {
        this.props.navigateToSearch()
      } else {
        this.setState({
          isOpen: false
        })
      }
    } else {
      this.setState({
        isOpen: true
      }, () => {
        this.input.focus()
      })
    }
  }

  public onSubmit = (event) => {
    event.preventDefault()
    this.props.navigateToSearch()
  }

  public onInputChange = (event) => {
    const { value } = event.target
    this.props.setSearchValue(value)
    this.searchFirstFive(value)
  }

  public onBlur = () => {
    this.setState({
      isOpen: false
    })
    if (this.props.value) {
      this.props.clearSearch()
    }
  }

  public render(): React.ReactNode {
    const { isOpen } = this.state
    const { value, result, loading, navigateToSearch, navigateToCompany, noSearch } = this.props
    if (noSearch) {
      return (
        <SearchButton disabled={true}/>
      )
    }
    return (
      <OutsideClickHandler
        onOutsideClick={this.onBlur}
      >
        <SearchForm
          onSubmit={this.onSubmit}
        >
          <SearchInput
            ref={node => this.input = node}
            isOpen={isOpen}
            onChange={this.onInputChange}
            placeholder="Search company"
            value={value}
          />
          <SearchResult
            isOpen={isOpen && result.length}
            result={take(result, 5)}
            navigateToSearch={navigateToSearch}
            navigateToCompany={(companyId: string) => {
              this.props.clearSearch()
              navigateToCompany(companyId)
            }}
          />
          <SearchSpinnerWrapper show={loading}>
            <Spinner size={16}/>
          </SearchSpinnerWrapper>
          <SearchButton onClick={this.onButtonClick}/>
        </SearchForm>
      </OutsideClickHandler>
    )
  }
}
