import styled from 'styled-components'

import { Card, Button } from '../../../../components'

export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  margin: 45px 0 26px 0;
`
export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`
export const InfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 7px;
  flex: 1;
  @media (min-width: 800px) {
    min-width: 450px;
    margin: 7px 45px 7px 7px;
    flex: 1 0 auto;
  }
`
const CardContent = styled(Card.Content)`
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
`
export const WrappingContent = styled(CardContent)`
  align-items: center;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`
export const ConnectedAccount = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 10px 10px 0;
  font-weight: 300;
  font-size: 24px;
`
export const DisconnectButton = styled(Button)`
  background: #D85251;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
`
export const ConnectButton = styled(Button)`
  background: #61869E;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
export const Motivation = styled.div`
  display: flex;
  flex-direction: column;
  margin: 7px;
`
export const MotivationHeader = styled.div`
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 15px;
`
export const MotivationContent = styled.div`
  font-size: 14px;
  line-height: 1.4;
  max-width: 380px;
`
