import styled from 'styled-components'

import { Icon } from '../../'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 13px;
  font-size: 16px;
  color: ${({ theme }) => theme.SUB_MENU_FONT_COLOR};
  cursor: pointer;
`
export const Arrow = styled(Icon)`
  background-size: contain;

  div > & {
    fill: ${({ theme }) => theme.SUB_MENU_FONT_COLOR}
  }
`
