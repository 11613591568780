export const appsync = {
  dev: {
    graphqlEndpoint: 'https://appsync.dev-emarketeer.io/graphql',
    region: 'eu-west-1',
    authenticationType: 'OPENID_CONNECT',
    apiKey: null
  },
  prod: {
    graphqlEndpoint: 'https://appsync.leadexplorer.com/graphql',
    region: 'eu-west-1',
    authenticationType: 'OPENID_CONNECT',
    apiKey: null
  }
}
