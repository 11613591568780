import * as React from 'react'
import styled from 'styled-components'
import { map, times, inRange } from 'lodash'

import { Icon } from '../'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
  margin-left: -5px;
`
const Rating = styled.div`
  padding-right: 0;
`
const Tree = styled(Icon)`
  div > & {
    margin-left: 3px;
    margin-right: 0;
  }
`
const Years = styled.div`
  font-size: 12px;
  text-align: center;
`

const YEARS = [
  [0, 2],
  [3, 5],
  [6, 15],
  [16, 50],
  [51, Number.POSITIVE_INFINITY],
]

export const TreesRating = ({ value = 0, ...other }) => (
  <Wrapper {...other}>
    {map(times(5), (d, i) => (
      <Rating key={i}>
        <Tree
          size={30 + ((i + 1) * 7)}
          icon={`tree${i + 1}`}
          color={inRange(value, YEARS[i][0], YEARS[i][1] + 1) ? '#7AAB59' : '#A5A6A6'}
        />
        <Years>{YEARS[i].join(' - ').replace(' - Infinity', '+')}</Years>
      </Rating>
    ))}
  </Wrapper>
)
