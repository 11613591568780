import { map, some } from 'lodash'
import { Group } from './group.model'

const OWNER_NAME = 'Owner LE'
const ADMIN_NAME = 'Admin Lead Explorer'

export class User {
  public externalId: string
  public email: string
  public emailVerified: boolean
  public firstName: string
  public lastName: string
  public groups: Group[]
  public picture: string

  private isOwnerValue: boolean = null
  private isAdminValue: boolean = null

  constructor(data: any) {
    this.externalId = data.externalId
    this.email = data.email
    this.emailVerified = data.emailVerified
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.groups = map(data.groups, x => new Group(x))
    this.picture = data.picture
  }

  public isAdmin(): boolean {
    return this.checkGroup('isAdminValue', ADMIN_NAME)
  }

  public isOwner(): boolean {
    return this.checkGroup('isOwnerValue', OWNER_NAME)
  }

  private checkGroup(key, groupName): boolean {
    if (this[key] === null) {
      this[key] = some(this.groups, x => x.name === groupName)
    }
    return this[key]
  }
}
