import { endsWith, replace, get } from 'lodash'
import * as moment from 'moment'

import { HeaderBlockItemProps } from '../components/header-block-item.component'

export class HeaderBlocksModel {
  public TRIAL_STATUS = 'trial'
  public DATE_FORMAT = 'MMMM D, YYYY'
  public data: any
  constructor(data) {
    this.data = data
  }

  public getPriceBlock = (): HeaderBlockItemProps => {
    let value
    let title = `${this.data.interval} ${this.formatPeriod(this.data.interval_unit)}`

    // If amount is 0 and this is trial plan show special text
    if (this.data.amount === 0) {
      value = 'Free'
      if (this.data.status === this.TRIAL_STATUS) {
        title = 'trial version'
      }
    } else {
      value = `${this.data.currency_code} ${get(this.data, 'plan.price')}`
    }

    return this.createReturnValue(value, title)
  }

  public getNextBilling = (): HeaderBlockItemProps => {
    const { next_billing_at } = this.data
    return this.getDate(next_billing_at, 'Next billing date')
  }

  public getLastBilling = (): HeaderBlockItemProps => {
    const { last_billing_at } = this.data
    return this.getDate(last_billing_at, 'Last billing date')
  }

  public getRenewal = (): HeaderBlockItemProps => {
    const { status, current_term_ends_at } = this.data
    if (status !== 'live') {
      return this.getDate(current_term_ends_at, 'Available until')
    }
    return this.createReturnValue('∞', 'Renews forever')
  }

  private createReturnValue = (value: string, title: string): HeaderBlockItemProps => ({ value, title })

  private formatPeriod = (unit: string) => {
    if (endsWith(unit, 's')) {
      return replace(unit, /s$/, '(s)')
    }
    return unit
  }

  private getDate = (date, title) => {
    let value = '---'
    const momentDate = moment(date)
    if (momentDate.isValid()) {
      value = momentDate.format(this.DATE_FORMAT)
    }
    return this.createReturnValue(value, title)
  }
}
