import * as React from 'react'
import { map, times, get } from 'lodash'
import { compose, graphql } from 'react-apollo'
import * as moment from 'moment'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'

import { updateFeedState } from 'actions'
import { getScreenshotUrl } from 'utils'
import { Count, LoadingBlock, LoadingCircle, ProgressiveImage, Show } from '../../../../../../components'
import { marketingEventDetails, marketingEventsCount } from '../../../../../../gql/emarketeer/emarketeer.query'
import { marketingEventDetailsSelector } from './marketing-event-details.selector'

import {
  Content,
  Description,
  DurationBlock,
  EntityName,
  Header,
  InterestingPageBlock,
  Name,
  PersonDescription,
  PersonIcon,
  PersonPhoto,
  PersonRow,
  ShowAllButton,
  Source,
  SubHeader,
  Text,
  Thumbnail,
  Times,
  Title,
  Wrapper,
} from './marketing-event-details.styles'

const actionNames = {
  emailLinkClicked: 'Clicked On Links',
  formAnswered: 'Form Submissions',
  landingPageVisited: 'Landingpage Visits',
  emailUnsubscribed: 'Unsubscribed',
}

const subheaderEventTexts = {
  emailLinkClicked: 'Clicked on link(s)',
  formAnswered: 'Submitted this form',
  landingPageVisited: 'Visited Landingpage',
  emailUnsubscribed: 'Unsubscribed from sendouts',
}

const format = {
  sameDay: '[Today]',
  lastDay: 'MMM Do, YYYY',
  nextWeek: 'MMM Do, YYYY',
  lastWeek: 'MMM Do, YYYY',
  sameElse: 'MMM Do, YYYY',
}

const shortFormat = {
  sameDay: '[Today]',
  lastDay: 'MMM Do',
  nextWeek: 'MMM Do',
  lastWeek: 'MMM Do',
  sameElse: 'MMM Do',
}

interface MarketingEventDetailsProps {
  data?: any
  event?: any
  count?: any
  domain?: any
  feedState?: any
  updateFeedState?: any
}

// @ts-ignore
@connect(marketingEventDetailsSelector, { updateFeedState })
// @ts-ignore
@compose(graphql(marketingEventDetails, {
  name: 'data',
// @ts-ignore
  options: ({ event, domain, feedState }) => ({
    variables: {
      domain,
      type: event.type,
      name: event.name,
      date: feedState.useDate ? moment(event.date).format('YYYY-MM-DD') : null,
      offset: 0,
    },
  }),
}), graphql(marketingEventsCount, {
  name: 'count',
// @ts-ignore
  options: ({ event, domain }) => ({
    variables: {
      domain,
      type: event.type,
      name: event.name,
    },
  }),
}))
export class MarketingEventDetails extends React.Component<MarketingEventDetailsProps> {
  public state = {
    loading: false,
    reloading: false,
    hasMore: false
  }

  public componentDidUpdate() {
    const { data, event, feedState: { loading }, feedState } = this.props

    if (loading && data.getMarketingEventDetails && data.getMarketingEventDetails.length <= 10) {
      if (data.getMarketingEventDetails.length < 10) {
        this.props.updateFeedState(event, { ...feedState, hasMore: false })
      } else {
        this.props.updateFeedState(event, { ...feedState })
      }
    }
  }

  public showAllDates = async () => {
    const { event, domain, data, feedState } = this.props

    this.props.updateFeedState(event, { ...feedState, reloading: true, useDate: false })

    await data.refetch({
      domain,
      type: event.type,
      name: event.name,
      date: null,
      offset: 0,
    })

    this.props.updateFeedState(event, { ...feedState, reloading: false, useDate: false })
  }

  public showSpecificDate = async () => {
    const { event, domain, data, feedState } = this.props

    this.props.updateFeedState(event, { ...feedState, reloading: true, useDate: true })

    await data.refetch({
      domain,
      type: event.type,
      name: event.name,
      date: moment(event.date)
        .format('YYYY-MM-DD'),
      offset: 0,
    })

    this.props.updateFeedState(event, { ...feedState, reloading: false, useDate: true })
  }

  public fetchMore = async () => {
    const { data: { fetchMore }, data, feedState, event } = this.props

    if (data.loading
      || this.state.loading
      || this.state.reloading
      || !this.state.hasMore) {
      return
    }

    this.props.updateFeedState(event, { ...feedState, loading: true })

    await fetchMore({
      variables: { offset: data.getMarketingEventDetails.length },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult.getMarketingEventDetails.length < 10) {
          this.setState({ hasMore: false })
        }

        if (!fetchMoreResult.getMarketingEventDetails) {
          return previousResult
        }

        if (!previousResult.getMarketingEventDetails) {
          return fetchMoreResult
        }

        return {
          marketingEventDetails: [
            ...previousResult.getMarketingEventDetails,
            ...fetchMoreResult.getMarketingEventDetails,
          ],
        }
      },
    })

    this.props.updateFeedState(event, { ...feedState, loading: false })
  }

  public render() {
    const { event, data, count, feedState: { useDate, reloading, hasMore } } = this.props
    const eventsCount = get(count, 'getMarketingEventsCount.count')

    return (
      <Wrapper>
        <Header>
          <DurationBlock>
            <Times>
              <Show if={!count.loading}>
                {eventsCount}
              </Show>
              <Show if={count.loading}>
                <LoadingCircle />
              </Show>
            </Times>
            <Description>
              {actionNames[event.type]}
            </Description>
          </DurationBlock>
          <InterestingPageBlock>
            <Thumbnail>
              <ProgressiveImage
                src={getScreenshotUrl(event.url)}
                styles={{ backgroundSize: 'cover', position: 'top center' }}
                loadingAnimation={true}
              />
            </Thumbnail>
            <EntityName>
              {event.name}
            </EntityName>
          </InterestingPageBlock>
        </Header>
        <Content>
          <SubHeader>
            <Text>
              {subheaderEventTexts[event.type]}
              <Show if={useDate}>
                &nbsp;-&nbsp;
                {moment(event.date, 'YYYY-MM-DD')
                  .calendar(null, format)}
              </Show>
              <Show if={useDate}>
                <ShowAllButton onClick={this.showAllDates}>Show all dates</ShowAllButton>
              </Show>
              <Show if={!useDate}>
                <ShowAllButton onClick={this.showSpecificDate}>Show for {moment(event.date, 'YYYY-MM-DD')
                  .calendar(null, shortFormat)}
                </ShowAllButton>
              </Show>
            </Text>
            <Show if={!count.loading}>
              <Count>
                {eventsCount}
              </Count>
            </Show>
          </SubHeader>
          <Show if={!reloading}>
            <InfiniteScroll
              loadMore={this.fetchMore}
              hasMore={hasMore}
              threshold={500}
              useWindow={false}
            >
              {map(data.getMarketingEventDetails, (entry, i) => (
                <PersonRow key={i}>
                  <Show if={entry.person.photo}>
                    <PersonPhoto image={entry.person.photo} />
                  </Show>
                  <Show if={!entry.person.photo}>
                    <PersonIcon icon="person" size={56} />
                  </Show>
                  <PersonDescription>
                    <Name>{entry.person.name || entry.person.email}</Name>
                    <Show if={entry.person.title}>
                      <Title>{entry.person.title}</Title>
                    </Show>
                    <Source>
                      {moment(entry.eventDate, 'YYYY-MM-DD')
                        .calendar(null, format)}
                    </Source>
                  </PersonDescription>
                </PersonRow>
              ))}
            </InfiniteScroll>
          </Show>
          <Show if={data.loading || reloading}>
            {times(3, i => (
              <PersonRow key={i}>
                <LoadingCircle size={56} />
                <PersonDescription>
                  <Name><LoadingBlock /></Name>
                  <Title><LoadingBlock /></Title>
                  <Source>
                    <LoadingBlock />
                  </Source>
                </PersonDescription>
              </PersonRow>
            ))}
          </Show>
        </Content>
      </Wrapper>
    )
  }
}
