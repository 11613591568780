import styled from 'styled-components'
import { Button } from '../../../../components'

export const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  max-width: 674px;
`
export const AgreementWrapper = styled.div`
  margin-top: 48px;
`
export const SubmitButton = styled(Button)`
  margin-top: 64px;
  padding-left: 64px;
  padding-right: 64px;
  font-size: 14px;

  @media (max-width: 700px) {
    width: 100%;
  }
`
