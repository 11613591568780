import * as React from 'react'
import * as ReactModal from 'react-modal'
import styled from 'styled-components'
import { get } from 'lodash'
import { Button } from '../index'

interface ModalProps {
  style?: any
  isOpen: boolean
  onRequestClose: () => void
}

export class Modal extends React.PureComponent<ModalProps> {
  public static Title = styled.div`
    font-weight: 300;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
  `
  public static Controls = styled.div`
    padding-top: 20px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-left: 8px;
    }
  `
  public static CancelButton = styled(Button)`
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #A5A6A6;
    background: #EAEAEA;
  `
  public static ApproveButton = styled(Button).attrs({
    color: 'primary'
  })`
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: white;
    margin: 0;
  `
  public render() {
    const defaultContentStyle = {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      width: '80%',
      maxWidth: '800px',
    }

    return (
      <ReactModal
        {...this.props}
        style={{
          ...this.props.style,
          content: { ...defaultContentStyle, ...get(this.props, 'style.content') },
        }}
      />
    )
  }
}



