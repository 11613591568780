import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  font-size: 44px;
  font-weight: 300;
  text-align: center;
`

export const Text = styled.p`
  text-align: center;
`
