import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { getRedirectUri } from 'utils'
import { googleStatus, getGoogleAnalyticsData } from '../../../gql/google/google.query'

const redirectUri = getRedirectUri('/callback/done')

const googleAuthUrl = gql`query getGoogleAuthUrl {
    getGoogleAuthUrl(redirectUri: "${redirectUri}")
}`

const submitGoogleToken = gql`
    mutation submitGoogleToken($oAuthCode: String!) {
        setupGoogleAnalytics(redirectUri: "${redirectUri}", oAuthCode: $oAuthCode) {
            success
            error
        }
    }
`

export const integrateGoogleAnalyticsQueries = [
  graphql(getGoogleAnalyticsData, {
    name: 'data',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(googleAuthUrl, {
    name: 'auth',
  }),

  graphql(submitGoogleToken, {
    name: 'submitGoogleToken',
    options: {
      awaitRefetchQueries: true,
      refetchQueries: () => ['getGoogleAnalyticsData'],
    },
  }),
  graphql(googleStatus, {
    name: 'googleStatus',
  }),
]
