import * as React from 'react'
import { find } from 'lodash'
import { Tooltip } from 'react-tippy'
import styled from 'styled-components'

import { Avatar } from '../../common/avatar'

const Text = styled.div`
  font-size: 12px;
`

interface AssignedAvatarProps {
  assignedTo: any
  users: any[]
}

export const AssignedAvatar: React.SFC<AssignedAvatarProps> = ({ assignedTo, users }) => {
  if (assignedTo && assignedTo.email && users) {
    const user = find(users, { email: assignedTo.email })

    if (user) {
      return (
        <Tooltip
          html={<Text>{user.firstName} {user.lastName}</Text>}
        >
          <Avatar
            size={36}
            user={user}
          />
        </Tooltip>
      )
    }
  }
  return null
}
