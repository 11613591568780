import styled from 'styled-components'

export const Title = styled.h1`
  margin-top: 64px;
  font-weight: 300;
  font-size: 28px;
  color: #314550;
  text-align: center;
`

export const CenterBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
export const Error = styled.div`
  text-align: center;
  margin: 32px auto;
`
