import { IntegrationProps } from './models'
import trackingScriptLogo from './images/tracking-script.png'
import superofficeLogo from './images/superoffice.png'
import emarketeerLogo from './images/emarketeer.png'
import slackLogo from './images/slack.png'

const WEBSITE_VISITS = 'Website Visits'
const CRM = 'CRM'
const MARKETING_AUTOMATION = 'Marketing Automation'
const OTHER = 'Other'

export const integrationCategories = [
  WEBSITE_VISITS,
  CRM,
  MARKETING_AUTOMATION,
  OTHER
]

export const integrationsData: IntegrationProps[] = [{
  id: 'script',
  name: 'Tracking Script',
  status: 'active',
  category: WEBSITE_VISITS,
  logo: trackingScriptLogo,
  url: '/settings/tracking-script',
}, {
  id: 'superoffice',
  name: 'SuperOffice',
  status: null,
  category: CRM,
  logo: superofficeLogo,
  url: '/settings/superoffice-integration',
}, {
  id: 'emarketeer',
  name: 'Emarketeer',
  status: null,
  category: MARKETING_AUTOMATION,
  logo: emarketeerLogo,
  url: '/settings/emarketeer-integration',
}, {
  id: 'slack',
  name: 'Slack',
  status: null,
  category: OTHER,
  logo: slackLogo,
  url: '/settings/slack-integration'
}]
