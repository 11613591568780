import styled from 'styled-components'


export const SearchForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface SearchInputProps {
  isOpen: boolean
}

export const SearchInput = styled.input<SearchInputProps>`
  width: ${({ isOpen }) => isOpen ? '256px' : 0};
  height: 32px;
  padding: 0 12px;
  margin-right: 8px;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 14px;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  transition: all 0.5s;
  outline: none;
  background-color: ${({ theme }) => theme.HEADER_SEARCH_BACKGROUND};
`

interface SearchSpinnerWrapper {
  show: boolean
}

export const SearchSpinnerWrapper = styled.div<SearchSpinnerWrapper>`
  display: ${({ show }) => show ? 'block' : 'none'};
  position: absolute;
  width: 16px;
  height: 16px;
  right: 54px;
`
