import styled from 'styled-components'

import { Icon, Button } from '../../../../../../components'

export const PersonRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 56px;
  padding: 20px 0;
  border-bottom: 1px #ECF1F5 solid;
  &:last-child {
    border-bottom: none;
  } 
`

interface PersonPhotoProps {
  image: string
}

export const PersonPhoto = styled.div<PersonPhotoProps>`
  min-width: 56px;
  min-height: 56px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: ${({ image }) => `url("${image}") center no-repeat`};
  background-size: cover;
  margin: 0 10px 0 0;
`
export const PersonIcon = styled(Icon)`
  margin: 0;
  padding: 0 10px 0 0;
`
export const PersonDescription = styled.div`
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const Name = styled.div`
  margin-bottom: 5px;
`
export const Title = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`
export const Source = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
`
export const Wrapper = styled.section`
  color: #314550;
  background: white;
  display: flex;
  flex-direction: column;
`
export const Header = styled.div`
  background: ${({ theme }) => theme.WEB_VISIT_BACKGROUND_COLOR};
  color: white;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 250px;
  @media (max-width: 800px) {
    height: 200px;
  };
`
export const DurationBlock = styled.div`
  padding: 15px;
  border-right: #A5A6A6 1px solid;
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`
export const InterestingPageBlock = styled.div`
  padding: 15px;
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`
export const Times = styled.div`
  padding-top: 27px;
  font-size: 96px;
  height: 96px;
  margin-bottom: 13px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    font-size: 54px;
    height: 54px;
  };
`
export const Description = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`
export const EntityName = styled.div`
  font-size: 12px;
`
export const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 13px;
`
export const Content = styled.div`
  padding: 40px;
  @media (max-width: 800px) {
    padding: 20px;
  };
`
export const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #61869E;
  padding-bottom: 40px;
  font-size: 18px;
`
export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ShowAllButton = styled(Button)`
  width: 140px;
  height: 30px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: 300;
  padding: 5px 10px;
`
