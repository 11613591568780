const ZOOM_OUT_LEVEL = 4
const ZOOM_IN_LEVEL = 9
const ANIMATION_TIME = 300

export class LiveViewMapNavigator {
  private map: any
  private pointToShow: any
  private zoomToShow: number
  private currentTimeout: NodeJS.Timer

  constructor(map) {
    this.map = map
  }

  public setPointToShow = (type: string, point: any) => {
    const zoom = this.map.getZoom()
    this.pointToShow = point
    if (type === 'company') {
      this.zoomToShow = ZOOM_IN_LEVEL
    } else {
      this.zoomToShow = ZOOM_OUT_LEVEL
    }
    this.currentTimeout = this.performAnimationStep()
  }

  public performAnimationStep = () => {
    const zoom = this.map.getZoom()
    const isInBounds = this.map.getBounds().contains(this.pointToShow)
    const isCentered = this.map.getCenter().equals(this.pointToShow)

    if (isCentered && zoom === this.zoomToShow) {
      return null
    }

    // if new company is not visible and zoom level is not minimum
    if (!isInBounds && zoom > ZOOM_OUT_LEVEL) {
      this.map.setZoom(zoom - 1)
    } else if (this.zoomToShow < zoom) {
      this.map.setZoom(zoom - 1)
    } else if (!isCentered) {
      this.map.panTo(this.pointToShow)
    } else if (this.zoomToShow > zoom) {
      this.map.setZoom(zoom + 1)
    }
    return setTimeout(this.performAnimationStep, ANIMATION_TIME)
  }
}
