import gql from 'graphql-tag'

export const userQuery = gql`
  query {
    getUser {
      email
      tenantId
      userId
      emailVerified
      firstName
      lastName
      picture
      groups {
        _id
        description
        name
      }
    }
  }
`
export const usersCountQuery = gql`
  query {
    getUsers {
      email
    }
    getPaymentSubscriptionStatus {
      users
    }
  }
`

export const getUsers = gql`
  query {
    getUsers {
      externalId
      firstName
      lastName
      picture
      email
      groups {
        _id
        description
        name
      }
    }
  }
`
