import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 300px;
  margin-right: 16px;
  background-color: #ffffff;
  cursor: pointer;
`

interface LogoProps {
  logo: string
}

export const Logo = styled.div<LogoProps>`
  flex: 1;
  margin: 32px;
  background-image: url(${({ logo }) => logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const Title = styled.div`
  box-sizing: border-box;
  height: 70px;
  background: #314550; 
  padding: 14px 8px;
  
  h3 {
    margin: 0 0 4px;
    font-weight: 300;
    line-height: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }
  
  p {
    margin: 0;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }
`
