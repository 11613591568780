import * as React from 'react'
import { forEach } from 'lodash'
import { ProgressiveImage } from './progressive-image.component'
import { loadImage } from '../../utils/index'

interface SlideshowProps {
  images: any[]
  duration?: number
}

export class Slideshow extends React.Component<SlideshowProps> {
  public timeout: any
  public state = {
    imageIndex: 0,
    readyImages: [],
  }

  public componentWillMount() {
    forEach(this.props.images, (image) => {
      loadImage(image.src).then(() => {
        this.setState({
          ...this.state,
          readyImages: [...this.state.readyImages, image],
        })
      })
    })
    this.switchToNextImage()
  }

  public componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  public switchToNextImage = () => {
    const { readyImages, imageIndex } = this.state

    const nextImageIndex = imageIndex + 1 >= readyImages.length ? 0 : imageIndex + 1

    this.setState({ imageIndex: nextImageIndex })

    this.timeout = setTimeout(() => this.switchToNextImage(), this.props.duration)
  }

  public render() {
    const currentImage = this.state.readyImages[this.state.imageIndex]
    if (currentImage) {
      return (
        <ProgressiveImage
          src={currentImage.src}
          styles={currentImage.styles}
          loading={!currentImage}
        />
      )
    }
    return null
  }
}
