export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const HANDLE_AUTHENTICATION = 'HANDLE_AUTHENTICATION'
export const HANDLE_API_AUTHENTICATION = 'HANDLE_API_AUTHENTICATION'
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO'
export const RECEIVE_TOKEN_DATA = 'RECEIVE_TOKEN_DATA'
export const REMOVE_TOKEN_DATA = 'REMOVE_TOKEN_DATA'
export const UPDATE_TOKEN_DATA = 'UPDATE_TOKEN_DATA'
export const SAVE_REDIRECT_URI = 'SAVE_REDIRECT_URI'
export const CLEAR_REDIRECT_URI = 'CLEAR_REDIRECT_URI'

export const userLogin = () => ({ type: USER_LOGIN })

export const userLogout = () => ({ type: USER_LOGOUT })

export const handleAuthentication = () => ({ type: HANDLE_AUTHENTICATION })

export const handleAPIAuthentication = payload => ({ type: HANDLE_API_AUTHENTICATION, payload })

export const receiveUserToken = payload => ({ type: RECEIVE_TOKEN_DATA, payload })

export const removeUserToken = () => ({ type: REMOVE_TOKEN_DATA })

export const updateUserToken = payload => ({ type: UPDATE_TOKEN_DATA, payload })

export const receiveUserInfo = payload => ({
  type: RECEIVE_USER_INFO,
  payload,
})

export const saveRedirectUri = payload => ({ type: SAVE_REDIRECT_URI, payload })

export const clearRedirectUri = () => ({ type: CLEAR_REDIRECT_URI })
