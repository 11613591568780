import styled, { keyframes } from 'styled-components'

const bgColor = 'rgba(0, 0, 0, 0.12)'
const progress = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 35px 35px;
  }
`
export const Animated = styled.div`
  background: ${bgColor};
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
  animation: ${progress} 2s linear infinite;
`

interface LoadingBlockProps {
  width?: string
  height?: string
}

export const LoadingBlock = styled(Animated)<LoadingBlockProps>`
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '14px' }) => height};
  margin-bottom: 8px;
  border-radius: 8px;
`

interface LoadingCircleProps {
  size?: number
}

export const LoadingCircle = styled(LoadingBlock)<LoadingCircleProps>`
  border-radius: 50%;
  width: ${({ size = 55 }) => `${size}px`};
  height: ${({ size = 55 }) => `${size}px`};
  min-width: ${({ size = 55 }) => `${size}px`};
  min-height: ${({ size = 55 }) => `${size}px`};
`
