import styled from 'styled-components'

export const Small = styled.div`
  width: 300px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #314550;
`

interface PasswordStatusProp {
  valid: boolean
}

export const PasswordStatus = styled.span<PasswordStatusProp>`
  color: ${({ valid }) => valid ? '#7AAB59' : '#D85251'}
`
