import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Card, Table as BasicTable, TableRowItem, TableHeaderItem, Link, Row, TableWrapper, Button } from '../../components'

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 80px 0 0;
  font-size: 14px;
  white-space: nowrap;

  @media (max-width: 1100px) {
    padding: 56px 32px 0 0;
  };

  @media (max-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 56px 0 0 0;
  };
`
export const NavigationItem = styled(NavLink)`
  margin-bottom: 20px;
  text-decoration: none;
  color: #314550;
  line-height: 14px;

  @media (max-width: 800px) {
    margin-bottom: 5px;
    margin-right: 10px;
  };

  &.active {
    font-weight: 500;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 60px;

  @media (max-width: 1100px) {
    padding: 0 32px;
  };

  @media (max-width: 800px) {
    padding: 0 8px;
    flex-direction: column;
  };
`
export const MainWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`
export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  margin: 45px 0 26px 0;
`
export const InfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 300px;
  margin: 7px;
  @media (min-width: 800px) {
    min-width: 450px;
  }
`
export const SubscriptionInfoCard = styled(InfoCard)`
  ${TableWrapper} {
    min-height: 100px;
  }
  ${TableRowItem} {
    font-size: 21px;
  }
  ${TableHeaderItem} {
    font-size: 12px;
  }
`
export const SmallCard = styled(Card)`
  width: 24%;
  padding: 32px 4px;
  text-align: center;
  box-sizing: border-box;

  h3 {
    margin: 0 0 4px 0;
  }

  div {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 49%;
    margin-bottom: 14px;
  }

  @media (max-width: 413px) {
    width: 100%;
  }
`
export const Table = styled(BasicTable)`
  padding: 4px 8px;
`

export const CancelLink = styled(Link)`
  margin-left: auto;
`

export const BottomCardWrapper = styled.div`
  min-width: 300px;
  padding: 24px 40px;
  min-height: 160px;

  &:last-child {
    flex: 1
  }

  ${TableRowItem} {
    padding: 4px 0;
  }

  ${TableRowItem}:first-child {
    font-weight: 300;
  }

  ${BasicTable} {
    min-height: 80px;
    margin-bottom: 24px;
  }
  
  ${Button} {
    margin-right: 16px;
  }
`

export const PlanWrapper = styled.div`
  padding-bottom: 24px;
  ${Row} {
    margin: 0 0 32px;
  }
`

export const PlanCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 50px 0 70px;
  align-items: center;
  margin-right: 8px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 32px;
  }

  img {
    margin: 0 0 32px;
  }

  h4 {
    position: relative;
    right: -24px;
    margin: 0 0 32px;
    font-size: 72px;
    font-weight: 500;
  }

  h4:before {
    position: absolute;
    top: 0;
    left: -48px;
    font-size: 24px;
    content: "EUR"
  }
`

export const NoDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`

export const AddCardWrapper = styled(BottomCardWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`
