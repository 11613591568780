import * as React from 'react'
import { Query } from 'react-apollo'
import { map } from 'lodash'
import { isInvalid, submit } from 'redux-form'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'

import { Spinner } from 'components'
import { CommonModal } from '../common-modal/common-modal.component'
import { LinkWrapper, Link, FormWrapper } from './create-company-modal.styles'
import { getCreateCompanyMetadata } from '../../../../../../gql/superoffice'
import { createCrmCompany } from '../../../../../../gql/superoffice'
import { CreateCompanyModalForm } from './components/create-company-modal-form/create-company-modal-form.component'
import { Events, registerAnalyticEvent } from 'utils'

interface CreateCompanyModalProps {
  company: any
  isOpen: boolean
  onRequestClose: () => void
  onAction: (id) => void
  onSwitchDialog: () => void
  companyId: string
  dispatch?: any
  createCrmCompany?: any
  invalid?: any
}

// @ts-ignore
@connect(state => ({
  invalid: isInvalid('createCompanyModalForm')(state),
}))
// @ts-ignore
@graphql(createCrmCompany, {
  name: 'createCrmCompany'
})
export class CreateCompanyModal extends React.PureComponent<CreateCompanyModalProps> {
  public state = {
    submitting: false,
  }
  public transformToDropdownData = ({ id, value }) => ({ value: id, label: value })
  public onSubmit = (values) => {
    this.setState({ submitting: true })
    return this.props.createCrmCompany({
      variables: {
        companyId: this.props.companyId,
        categoryId: Number(values.categoryId.value),
        businessId: Number(values.businessId.value),
        userId: values.userId.value,
      }
    })
      .then(() => {
        registerAnalyticEvent(Events.COMPANY_CRM_MATCH, {
          'companyName': this.props.company.name,
        })
        this.setState({ submitting: false })
      })
      .then(this.props.onAction)
  }
  public onAction = () => {
    this.props.dispatch(submit('createCompanyModalForm'))
  }
  public render() {
    const {
      isOpen,
      onRequestClose,
      company,
      onSwitchDialog,
      invalid,
    } = this.props
    return (
      <CommonModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        company={company}
        onCancel={onRequestClose}
        onAction={this.onAction}
        actionButtonDisabled={invalid}
        actionButtonText="create"
      >
        <div>
          <LinkWrapper>
            <div>Creating {company.name}</div>
            <Link
              onClick={onSwitchDialog}
            >
              Change company?
            </Link>
          </LinkWrapper>
          <FormWrapper>
            <Query
              query={getCreateCompanyMetadata}
            >
              {({ loading, error, data }) => {
                if (loading || this.state.submitting) {
                  return <Spinner />
                }
                if (error) {
                  throw error
                }
                return (
                  <CreateCompanyModalForm
                    onSubmit={this.onSubmit}
                    users={map(data.getCreateCompanyMetadata.users, this.transformToDropdownData)}
                    categories={map(data.getCreateCompanyMetadata.categories, this.transformToDropdownData)}
                    businesses={map(data.getCreateCompanyMetadata.business, this.transformToDropdownData)}
                  />
                )
              }}
            </Query>
          </FormWrapper>
        </div>
      </CommonModal>
    )
  }
}
