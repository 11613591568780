import styled from 'styled-components'
import { map, filter, join } from 'lodash'

export const Label = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 15px;
  font-size: 12px;
  color: #314550;
  font-weight: 300;
  line-height: 1.5;
  z-index: 10;
`
export const CompanyName = styled.div`
  font-weight: normal;
`
export const Line = styled.div`
  white-space: nowrap;
`
