import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-top: 60px;
`
export const Content = styled.section`
  display: flex;
  flex: 1;
  color: #314550;
`
