export const randomString = (length) => {
  // eslint-disable-next-line
  const bytes = new Uint8Array(length)
  const result = []
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  // @ts-ignore
  const crypto = window.crypto || window.msCrypto

  const random = crypto.getRandomValues(bytes)

  for (const a of random) {
    result.push(charset[a % charset.length])
  }

  return result.join('')
}
