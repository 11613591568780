import * as React from 'react'
import { Query } from 'react-apollo'
import { map, times } from 'lodash'
import { connect } from 'react-redux'

import { LoadingBlock } from 'components'

import {
  Buttons,
  Email,
  Invitation,
  InvitationsCard,
  InvitationsCardContent,
  InvitationsCardTitle,
  StyledButton,
  Wrapper
} from './pending-invitations-list.styles'
import { getPendingInvitations } from '../../../../../gql/invitations'
import { toggleInviteUserModal } from 'actions'
import { DeleteInvitationModal } from '../components/delete-invitation-modal/delete-invitation-modal.component'

interface PendingInvitationsListProps {
  toggleInviteUserModal: any
}

// @ts-ignore
@connect(null, {toggleInviteUserModal})
export class PendingInvitationsList extends React.Component<PendingInvitationsListProps> {
  public state = {
    isDeleteDialogVisible: false,
    deleteEmail: null,
  }

  public toggleDeleteModal = () => {
    this.setState({ isDeleteDialogVisible: !this.state.isDeleteDialogVisible })
  }

  public openRevokeModal = (deleteEmail) => {
    this.setState({
      deleteEmail,
      isDeleteDialogVisible: true,
    })
  }

  public render() {

    return (
      <Wrapper>
        <InvitationsCard>
          <InvitationsCardTitle>
            Email
          </InvitationsCardTitle>
          <InvitationsCardContent>
            <Query query={getPendingInvitations} fetchPolicy="cache-and-network">
              {({data: {getInvitations}, loading}) => {
                if (loading) {
                  return (
                    <>
                      {times(3, index => (
                          <Invitation key={index}>
                            <LoadingBlock height="20px" width="60%"/>
                          </Invitation>
                        )
                      )}
                    </>
                  )
                }

                if (getInvitations.length === 0) {
                  return <Invitation>No pending invitations</Invitation>
                }

                return map(getInvitations, invitation => (
                  <Invitation key={invitation.email}>
                    <Email>{invitation.email}</Email>
                    <Buttons>
                      <StyledButton color="primary" onClick={() => this.props.toggleInviteUserModal(invitation.email)}>
                        Resend Invitation
                      </StyledButton>
                      <StyledButton color="alert" onClick={() => this.openRevokeModal(invitation.email)}>
                        Revoke Invitation
                      </StyledButton>
                    </Buttons>
                  </Invitation>
                ))
              }}
            </Query>
          </InvitationsCardContent>
        </InvitationsCard>
        <DeleteInvitationModal
          email={this.state.deleteEmail}
          toggle={this.toggleDeleteModal}
          isOpen={this.state.isDeleteDialogVisible}
          onDone={this.toggleDeleteModal}
        />
      </Wrapper>
    )
  }
}
