import * as React from 'react'
import { Show } from '../../../../../components'

import {
  EmployeeRow,
  PersonPhoto,
  PersonIcon,
  Description,
  Name,
  Title,
  Source,
  ButtonWrapper,
  OpenSource,
} from '../other-employees.styles'

const openLink = url => () => {
  window.open(url, '_blank')
}

export const Employee = ({ employee }) => (
  <EmployeeRow>
    <Show if={employee.avatar} >
      <PersonPhoto image={employee.avatar} />
    </Show>
    <Show if={!employee.avatar} >
      <PersonIcon icon="person" size={56} />
    </Show>
    <Description>
      <Name>{employee.fullName}</Name>
      <Show if={employee.title}>
        <Title>{employee.title}</Title>
      </Show>
      <Show if={employee.source}>
        <Source>Source: {employee.source}</Source>
      </Show>
    </Description>
    <Show if={employee.linkedin}>
      <ButtonWrapper>
        <OpenSource onClick={openLink(employee.linkedin)}>Go to source</OpenSource>
      </ButtonWrapper>
    </Show>
  </EmployeeRow>
)
