import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { getGoogleAnalyticsData, googleStatus } from '../../../../gql/google/google.query'

const disconnectGoogleAnalytics = gql`
  mutation {
    disconnectGoogleAnalytics {
      tenantId
    }
  }
`

const getGoogleAnalyticsUser = gql`
  query getGoogleAnalyticsUser{
    getGoogleAnalyticsUser {
      name,
      photo,
      email
    }
  }
`

export default [
  graphql(getGoogleAnalyticsData, {
    name: 'data',
  }),
  graphql(googleStatus, {
    name: 'status',
  }),
  graphql(disconnectGoogleAnalytics, {
    name: 'disconnectGoogleAnalytics',
    // @ts-ignore
    options: ({ tenantId }) => ({
      variables: { tenantId },
      update: (proxy) => {
        proxy.writeQuery({ query: getGoogleAnalyticsUser, data: { getGoogleAnalyticsUser: null } })
      },
    }),
  }),
]

