import * as React from 'react'
import styled from 'styled-components'

import { LayoutWrapper } from '../verify.styles'
import { BaseLayout } from 'components'

const Wrapper = styled.div`
  background: white;
  min-height: calc(100vh - 140px);
  padding: 100px 24px 40px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const Title = styled.div`
  font-size: 48px;
  font-weight: 200;
  text-align: center;
`
const SleepImage = styled.img`
  margin: 20px 0 20px 0;
  width: 260px;
  height: 260px;
`
const ValidityText = styled.div`
  font-size: 20px;
  text-align: center;
`
const ActionText = styled.div`
  margin-top: 5px;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
`
const Spacer = styled.div`
  flex: 0.3;
`

export const ExpiredInvitation = () => (
  <LayoutWrapper>
    <BaseLayout/>
    <Wrapper>
      <Spacer />
      <Title>Your invitation has expired!</Title>
      <SleepImage src="/images/sleep1.png"/>
      <ValidityText>Invitations is only valid for 10 days</ValidityText>
      <ActionText>Request a new one from a Leadexplorer admin</ActionText>
    </Wrapper>
  </LayoutWrapper>
)
