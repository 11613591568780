import * as React from 'react'
import { Dropdown } from 'components'

import { SortIcon, Wrapper } from './sort-button.styles'

interface SortButtonProps {
  items: any[]
}

export class SortButton extends React.PureComponent<SortButtonProps> {
  public render() {
    return (
      <Wrapper>
        <Dropdown items={this.props.items}>
          <SortIcon />
        </Dropdown>
      </Wrapper>
    )
  }
}
