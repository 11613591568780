import gql from 'graphql-tag'
import { CONFIG } from '../../config'

export const getSuperOfficeAuthUrl = gql`
  query {
    getSuperOfficeAuthUrl(serviceId: "leadexplorer", redirectUrl: "${CONFIG.SUPER_OFFICE.redirectUrl}") {
      url
    }
  }
`

export const getSuperOfficeIntegrationStatus = gql`
  query {
    getSuperOfficeIntegrationStatus(serviceId: "leadexplorer") {
      connected
      tenantName
    }
  }
`

export const getCompaniesFromCrm = gql`
  query getCompaniesFromCrm($domain: String!, $name: String!){
    getCompaniesFromCrm(serviceId: "leadexplorer", domain: $domain, name: $name) {
      companyId
      name
      country
      updatedBy
      url
    }
  }
`

export const getLinkedCrmCompany = gql`
  query getLinkedCrmCompany($companyId: String!) {
    getLinkedCrmCompany(companyId: $companyId) {
      companyId
      tenantId
      crmCompanyId
      crmSummary {
        name
        owner
        startDate
        currency
        totalAmount
        latestSale {
          currentStageId
          saleName
          saleTypeName
          sold
          stages {
            id
            name
            probability
          }
        }
      }
    }
  }
`

export const getCreateCompanyMetadata = gql`
  query getCreateCompanyMetadata {
    getCreateCompanyMetadata(serviceId: "leadexplorer") {
      categories {
        id
        value
      }
      business {
        id
        value
      }
      users {
        id
        value
      }
    }
  }
`
