import { SET_ACTIVE_VISIT } from '../actions'

const defaultState = {
}

export const webVisitSidePanel = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_VISIT:
      return {
        ...state,
        visit: action.visit,
      }

    default:
      return state
  }
}
