import styled from 'styled-components'
import { upperCase } from 'lodash'

import { Icon } from '../'
import { OutsideClickHandler } from '../utils'

interface PanelProps {
  show: boolean
}

export const Panel = styled(OutsideClickHandler)<PanelProps>`
  position: fixed;
  top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  background: white;
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: right .5s ease-in-out;
  right: ${({ show }) => show ? 0 : '-100vw'};
  @media (min-width: 801px) and (max-width: 1200px) {
    width: 50vw;
  }
  @media (min-width: 1201px) {
    max-width: 584px;
  }
`
export const Header = styled.div`
  font-weight: 500;
  color: #FFFFFF;
  background: ${({ theme }) => theme.PANEL_TOP_COLOR};
  padding: 18px 10px 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`
export const CloseIcon = styled(Icon)`
  cursor: pointer;
`
