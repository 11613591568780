import * as React from 'react'
import { NavLink } from 'react-router-dom'

import { Wrapper, HeaderLogo, MenuItem } from './header-menu.styles'
import { LogoText } from '../../page-header.styles'
import { Show } from 'components'
import { User } from '../../../../../models/user.model'
import { LiveViewCounter } from './components/live-view-counter'

const getItemClassName = isSmallScreen => isSmallScreen ? 'mobile' : ''

interface HeaderMenuProps {
  user: User
  isSmallScreen: boolean
  pathname: string
}

export const HeaderMenu: React.SFC<HeaderMenuProps> = ({ isSmallScreen, user, pathname }) => (
  <Wrapper isMobile={isSmallScreen}>
    <Show if={isSmallScreen}>
      <HeaderLogo>
        <img src="/images/logo.svg" alt="home" width="30" height="34" />
        <LogoText>Leadexplorer</LogoText>
      </HeaderLogo>
    </Show>
    <MenuItem to="/live-view" className={getItemClassName(isSmallScreen)}>
      Live View
      {pathname !== '/live-view' ? <LiveViewCounter /> : null}
    </MenuItem>
    <MenuItem to="/webleads" className={getItemClassName(isSmallScreen)}>
      Web Leads
    </MenuItem>
    <Show if={user && (user.isAdmin() || user.isOwner())}>
      <MenuItem to="/settings" className={getItemClassName(isSmallScreen)}>
        Settings
      </MenuItem>
    </Show>
  </Wrapper>
)
