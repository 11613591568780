import * as React from 'react'
import { History } from 'history'

import { SidebarLayout } from '../../components/layouts/sidebar-layout'
import { Button } from '../../components/controls/button/button.component'
import { Wrapper } from './subscription-expired.styles'

interface SubscriptionExpiredProps {
  history: History
}

export class SubscriptionExpired extends React.PureComponent<SubscriptionExpiredProps> {
  public onSubscribeClick = () => this.props.history.push('/purchase-plan')

  public render() {
    return (
      <SidebarLayout>
        <Wrapper>
          <h1>Your subscription has been expired</h1>
          <h3>To continue using Leadexplorer please subscribe to a plan</h3>
          <Button
            color="primary"
            onClick={this.onSubscribeClick}
          >
            Subscribe
          </Button>
        </Wrapper>
      </SidebarLayout>
    )
  }
}
