import { SET_RESOLVER_VALUE, SET_RESOLVER_RESULT, SET_RESOLVER_ERROR, SET_RESOLVER_LOADING, CLEAR_RESOLVER } from 'actions'
import { trim } from 'lodash'

const defaultState = {
  value: '',
  result: [],
  loading: false,
  error: false,
  isEmpty: false
}

export const resolver = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_RESOLVER_VALUE:
      return {
        ...state,
        value: trim(payload)
      }

    case SET_RESOLVER_RESULT:
      const { name } = payload
      return {
        ...state,
        loading: false,
        result: payload,
        isEmpty: !name
      }

    case SET_RESOLVER_LOADING:
      return {
        ...state,
        result: [],
        isEmpty: false,
        error: false,
        loading: true
      }

    case SET_RESOLVER_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case CLEAR_RESOLVER:
      return { ...defaultState }

    default:
      return state
  }
}

