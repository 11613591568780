import * as React from 'react'
import styled from 'styled-components'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Icon } from 'components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  padding: 20px;
`
const Header = styled.div`
  font-weight: 300;
  font-size: 18px;
  margin: 20px 0 0 0;
  text-align: center;
  line-height: 1.4;
`
const DescriptionText = styled.div`
  text-align: center;
  font-weight: 300;
  margin: 10px 0;
  font-size: 12px;
  line-height: 1.4;
`
const Tools = styled.div`
  margin: 10px 0 30px 0;
`
const EmarketeerButton = styled(Icon)`
  width: 130px !important;
  height: 25px !important;
  cursor: pointer;
`
export const MarketingIntegrationPlaceholderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface MarketingIntegrationPlaceholderProps {
  componentName: string
  description: string
  className?: string
  push?: (url: string) => void
}

@connect(null, { push })
export class MarketingIntegrationPlaceholder extends React.Component<MarketingIntegrationPlaceholderProps> {
  public render() {
    const { componentName, description, className } = this.props

    return (
      <Wrapper className={className}>
        <Icon icon="lock" size={45} />
        <Header>
          Unlock&nbsp;{componentName}
        </Header>
        <DescriptionText>
          {description}
        </DescriptionText>
        <DescriptionText>
          Pick your tool using the buttons below:
        </DescriptionText>
        <Tools>
          <EmarketeerButton
            onClick={() => this.props.push('/settings/emarketeer-integration')}
            icon="emarketeerOnlight"
          />
        </Tools>
      </Wrapper>
    )
  }
}
