import styled from 'styled-components'
import settingsIcon from './images/settings.svg'
import { TextWrapper } from '../../crm-insight.styles'
import { Dropdown } from '../../../../../../components'

export const Wrapper = styled(TextWrapper)`
  justify-content: space-between;
  padding: 16px 0 20px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
`

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 300;
  font-size: 18px;
  color: #314550;
`

export const OwnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const TotalWrapper = styled(Row)`
  height: 52px;
`

export const Total = styled.div`
  font-weight: 300;
  font-size: 38px;
`

export const TotalText = styled.div`
  font-size: 18px;
`

export const Date = styled.div`
  margin: 5px 0;
  font-weight: 300;
  font-size: 12px;
`

export const StageDescription = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  
  &:first-child {
    margin-top: 16px;
  }
`

export const Label = styled.div`
  font-size: 12px;
`

export const Value = styled.div`
  font-size: 16px;
  font-weight: 300;
`

export const BottomRow = styled(Row)`
  align-items: flex-end;
  overflow: hidden;
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`

export const SettingsButton = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
  background: url(${settingsIcon}) 50% no-repeat;
`
export const StyledDropdown = styled(Dropdown)`
  ${Dropdown.Menu} {
    right: auto;
    min-width: 200px;
  }

  ${Dropdown.MenuItem} {
    height: 14px;
    font-size: 12px;
    padding: 12px 14px;

    &:hover {
      background-color: #fbfbfb;
    }
  }
`
