import * as React from 'react'
import { connect } from 'react-redux'
import { WebAuth } from 'auth0-js'

import { userLogout, saveRedirectUri } from 'actions'
import { BaseLayout } from 'components'
import { CONFIG } from '../../config'
import { randomString } from 'utils'
import { localStorageStateKey } from '../../services/auth'

interface SignInProps {
  authData: any
  dispatch?: any
  location: any
}

@connect(x => ({ authData: x.auth }))
export class SignIn extends React.Component<SignInProps> {
  public componentDidMount() {
    if (this.props.authData.profileData) {
      this.props.dispatch(userLogout())
    }
    this.props.dispatch(saveRedirectUri(this.props.location.pathname))
    const newState = randomString(32)
    localStorage.setItem(localStorageStateKey, newState)
    new WebAuth({
      domain: CONFIG.AUTH.domain,
      clientID: CONFIG.AUTH.clientId,
      responseType: 'token id_token',
      audience: `https://${CONFIG.AUTH.domain}/userinfo`,
      scope: 'openid profile',
      state: newState,
      redirectUri: CONFIG.AUTH.callbackUrl,
    }).authorize()
  }

  public render() {
    return <BaseLayout />
  }
}
