import { auth as authService } from '../services/auth/index'
import { CONFIG } from '../config'

import {
  RECEIVE_USER_INFO,
  RECEIVE_TOKEN_DATA,
  REMOVE_TOKEN_DATA,
  UPDATE_TOKEN_DATA,
  SAVE_REDIRECT_URI,
  CLEAR_REDIRECT_URI
} from 'actions'

const defaultState = {
  isAuthenticated: false,
  profileData: null,
  tokenData: {},
  redirectUri: null,
}

export const auth = (state = defaultState, { type, payload }) => {
  switch (type) {
    case RECEIVE_USER_INFO:
      return {
        ...state,
        profileData: payload,
      }

    case RECEIVE_TOKEN_DATA:
      return {
        ...state,
        isAuthenticated: authService.isAuthenticated(payload.expires_at),
        tokenData: payload,
      }

    case REMOVE_TOKEN_DATA:
      return {
        ...state,
        tokenData: {},
        isAuthenticated: false,
        profileData: null,
      }

    case UPDATE_TOKEN_DATA: {
      const key = CONFIG.REGISTRATION.tenantKey
      const { accessToken, idToken, idTokenPayload } = payload
      return {
        ...state,
        tokenData: {
          id_token: idToken,
          access_token: accessToken,
          expires_at: idTokenPayload.exp * 1000,
        },
        profileData: {
          ...state.profileData,
          [key]: idTokenPayload[key],
        },
      }
    }

    case SAVE_REDIRECT_URI: {
      return {
        ...state,
        redirectUri: payload
      }
    }

    case CLEAR_REDIRECT_URI: {
      return {
        ...state,
        redirectUri: null
      }
    }

    default:
      return state
  }
}
