import * as React from 'react'
import { connect } from 'react-redux'
import { Mutation, Query } from 'react-apollo'
import { find, filter, map } from 'lodash'

import { SidePanel, Show, MaxLengthText } from 'components'
import { closeSidePanel, showSidePanel, switchFilterPanel, setProspectsDateRange } from 'actions'
import { getFilters, createFilter, deleteFilter, updateFilter } from '../../../../gql'
import { FilterList, EditFilter } from './components'
import { filterSwitcherSelector } from './filter-switcher.selector'
import { DatePicker, ActiveFilter, ClickableArea, FilterIcon } from './filters.styles'

interface FilterProps {
  sidePanelVisible?: boolean
  companiesDateRange?: any
  activeTab?: any
  dispatch?: any
  filterId?: any
  filterIdToEdit?: any
}

@connect(filterSwitcherSelector)
export class Filter extends React.PureComponent<FilterProps> {
  public openSidePanel = () => {
    this.props.dispatch(switchFilterPanel('list'))
    this.props.dispatch(showSidePanel('filterList'))
  }

  public closeSidePanel = () => {
    this.props.dispatch(closeSidePanel('filterList'))
  }

  public onFilterCreated = () => {
    this.props.dispatch(switchFilterPanel('list'))
  }

  public render() {
    const { sidePanelVisible, activeTab, dispatch, companiesDateRange, filterId, filterIdToEdit } = this.props

    return (
      <Query query={getFilters}>
        {({ data, loading }) => {
          if (!loading && data.getFilters) {
            return (
              <ActiveFilter>
                <DatePicker
                  dateRange={companiesDateRange}
                  onDateChange={range => dispatch(setProspectsDateRange(range))}
                />
                <ClickableArea onClick={this.openSidePanel}>
                  <MaxLengthText>{find(data.getFilters, x => x.id === filterId).name}</MaxLengthText>
                  <FilterIcon size={8} icon="filterArrow" />
                </ClickableArea>
                <SidePanel
                  isVisible={sidePanelVisible}
                  handleClose={this.closeSidePanel}
                  title="Filters"
                >
                  <Show if={activeTab === 'list'}>
                    <Mutation
                      mutation={deleteFilter}
                    >
                      {(mutation) => (
                        <FilterList
                          data={data.getFilters}
                          mutation={mutation}
                        />
                      )}
                    </Mutation>
                  </Show>
                  <Show if={activeTab === 'create'}>
                    <Mutation
                      mutation={createFilter}
                      update={(cache, result) => {
                        const cachedData: any = cache.readQuery({ query: getFilters });
                        cache.writeQuery({
                          query: getFilters,
                          data: { getFilters: [...cachedData.getFilters, result.data.createFilter] }
                        });
                      }}
                    >
                      {(mutation) => (
                        <EditFilter
                          initialValues={{
                            favorite: true,
                            orClauses: [{ andClauses: [{}] }],
                          }}
                          mutation={mutation}
                          onFilterCreated={this.onFilterCreated}
                        />
                      )}
                    </Mutation>
                  </Show>
                  <Show if={activeTab === 'edit'}>
                    <Mutation
                      mutation={updateFilter}
                      refetchQueries={() => ['getCompanies']}
                    >
                      {(mutation) => {
                        const filterToEdit = find(data.getFilters, x => x.id === filterIdToEdit)
                        return (
                          <EditFilter
                            initialValues={{
                              name: filterToEdit.name,
                              favorite: filterToEdit.favorite,
                            }}
                            mutation={mutation}
                            onFilterCreated={this.onFilterCreated}
                            filterToEdit={filterToEdit}
                          />
                        )
                      }}
                    </Mutation>
                  </Show>
                </SidePanel>
              </ActiveFilter>
            )
          }
          return null
        }}
      </Query>
    )
  }
}
