import * as moment from 'moment'
import { SET_WEB_VISITS_DATE_RANGE } from '../actions'

const defaultState = {
  dateRange: { startDate: moment().subtract(90, 'days'), endDate: moment() },
}

export const webVisits = (state = defaultState, action) => {
  switch (action.type) {
    case SET_WEB_VISITS_DATE_RANGE:
      return {
        ...state,
        domain: action.domain,
        name: action.name,
        dateRange: action.dateRange,
      }

    default:
      return state
  }
}
