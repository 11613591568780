import * as React from 'react'
import { Wrapper, Arrow } from './back-button.styles'

interface BackButtonProps {
  onClick: () => any
}

export const BackButton: React.SFC<BackButtonProps> = ({ onClick }) => (
  <Wrapper role="button" tabIndex={0} onClick={onClick}>
    <Arrow icon="backArrow" size={14} color="white" /> Back to list
  </Wrapper>
)
