export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'
export const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'

export const setSearchValue = (payload: string) => ({
  type: SET_SEARCH_VALUE,
  payload
})

export const setSearchResult = (payload: any[]) => {
  return ({
    type: SET_SEARCH_RESULT,
    payload
  })
}

export const setSearchError = () => ({
  type: SET_SEARCH_ERROR
})

export const setSearchLoading = () => ({
  type: SET_SEARCH_LOADING
})

export const clearSearch = () => ({
  type: CLEAR_SEARCH
})
