import gql from 'graphql-tag'

export const googleStatus = gql`{
  getOnboardingStatus {
    onboardingStatus
    etlDataRange {
      startDate
      latestDate
    }
  }
  getPaymentSubscriptionStatus {
    active
    trial
    trialStatus {
      daysLeft
    }
  }
  getTenantStats {
    currentLimit
    noOfCompanies
  }
  getGaIntegrationStatus {
    inError
  }
}`

export const getGoogleAnalyticsData = gql`query getGoogleAnalyticsData{
    getGoogleAnalyticsSummary {
        accountId,
        accountName,
        viewId,
        viewName,
        websiteUrl,
        enabled,
        visits,
    },
    getGoogleAnalyticsUser {
        name,
        photo,
        email
    }
}`
