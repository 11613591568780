import gql from 'graphql-tag'

export const createFilter = gql`
  mutation createFilter($input: CreateFilterInput!) {
    createFilter(input: $input) {
      id
      name
      editable
      favorite
      filterDefinition {
        orClauses {
          andClauses {
            field
            operator
            value
          }
        }
      }
    }
  }
`

export const updateFilter = gql`
  mutation updateFilter($input: UpdateFilterInput!) {
    updateFilter(input: $input) {
      id
      name
      editable
      favorite
      filterDefinition {
        orClauses {
          andClauses {
            field
            operator
            value
          }
        }
      }
    }
  }
`

export const deleteFilter = gql`
  mutation deleteFilter($id: String!) {
    deleteFilter(id: $id)
  }
`
