import { graphql } from 'react-apollo'

import {
  getSuperOfficeAuthUrl,
  getSuperOfficeIntegrationStatus,
  setupSuperOfficeIntegration,
  deleteSuperOfficeIntegration,
} from '../../../../gql/superoffice'

export const superOfficeIntegrationQueries = [
  graphql(getSuperOfficeAuthUrl, {
    name: 'auth',
  }),
  graphql(getSuperOfficeIntegrationStatus, {
    name: 'data',
  }),
  graphql(setupSuperOfficeIntegration, {
    name: 'setupSuperOfficeIntegration',
  }),
  graphql(deleteSuperOfficeIntegration, {
    name: 'disconnect',
  }),
]
