import { SET_SEARCH_VALUE, SET_SEARCH_RESULT, SET_SEARCH_ERROR, SET_SEARCH_LOADING, CLEAR_SEARCH } from 'actions'
import { trim } from 'lodash'

const defaultState = {
  value: '',
  result: [],
  loading: false,
  error: false,
  isEmpty: false
}

export const search = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        value: trim(payload)
      }

    case SET_SEARCH_RESULT:
      return {
        ...state,
        loading: false,
        result: payload,
        isEmpty: !payload.length
      }

    case SET_SEARCH_LOADING:
      return {
        ...state,
        result: [],
        isEmpty: false,
        error: false,
        loading: true
      }

    case SET_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case CLEAR_SEARCH:
      return { ...defaultState }

    default:
      return state
  }
}

