import * as React from 'react'
import { SmallCard } from '../../../settings-container.styles'

export interface HeaderBlockItemProps {
  value: string
  title: string
}

export const HeaderBlockItem: React.SFC<HeaderBlockItemProps> = ({ value, title }) => (
  <SmallCard>
    <h3>{value}</h3>
    <div>{title}</div>
  </SmallCard>
)

