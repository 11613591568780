import { uniq } from 'lodash'
import { SET_SELECTED_COMPANY_ID, ADD_VIEWED_COMPANY_IDS } from 'actions'

const defaultState = {
  selectedCompanyId: null,
  viewedIds: []
}

export const liveView = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SELECTED_COMPANY_ID:
      return {
        ...state,
        selectedCompanyId: action.payload
      }

    case ADD_VIEWED_COMPANY_IDS:
      return {
        ...state,
        viewedIds: uniq([...state.viewedIds, ...action.payload]),
      }

    default:
      return state
  }
}
