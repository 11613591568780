import styled from 'styled-components'

import { Dropdown } from '../../../../controls/index'

const menuGap = '18px'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${menuGap};
`
export const StyledDropdown = styled(Dropdown)`
  margin-left: ${menuGap};
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #F2F2F2;
`
