import * as React from 'react'
import { toastr } from 'react-redux-toastr'
import styled from 'styled-components'
import { Icon } from '../components'

import Linkify from 'react-linkify'

const colors = {
  success: '#7AAB59',
  error: '#D85251',
}

interface WrapperProps {
  type: string
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  background: ${({type}) => colors[type]};
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 300;
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  
  a { 
    color: white;
    text-decoration: underline;
  }
`
const CloseIcon = styled(Icon)`
  margin: 0 0 0 20px;
  cursor: pointer;
`

export const success = (message) => {
  const toastrMessageOptions = {
    timeOut: 5000,
    removeOnHover: false,
    component: ({remove}) => (
      <Wrapper type="success">
        <Content>
          <Icon icon="successCheckmark" size={40}/>
          {message}
        </Content>
        <CloseIcon icon="closeToast" size={10} onClick={() => remove()}/>
      </Wrapper>
    ),
  }
  toastr.message('', toastrMessageOptions)
}

export const error = (message) => {
  const toastrMessageOptions = {
    timeOut: 5000,
    removeOnHover: false,
    component: ({remove}) => (
      <Wrapper type="error">
        <Content>
          <Icon icon="fail" size={40}/>
          <Linkify>
            {message}
          </Linkify>
        </Content>
        <CloseIcon icon="closeToast" size={10} onClick={() => remove()}/>
      </Wrapper>
    ),
  }
  toastr.message('', toastrMessageOptions)
}
