import * as React from 'react'
import { compose } from 'react-apollo'
import * as queryString from 'query-string'
import { integrateGoogleAnalyticsQueries } from './google-analytics-integration.query'
import { error, Events, getRedirectUri, registerAnalyticEvent } from 'utils'
import { AlreadyInUseModal, AnalyticsModal } from '../analytics-modal'

interface GoogleAnalyticsIntegrationProps {
  googleStatus?: {
    refetch: () => void
  }
  auth?: any
  data?: any
  submitGoogleToken?: any
  shouldOpenDialog: boolean
  dispatch?: any
  children: (openGaPopup: () => void, loading: boolean) => React.ReactNode
  onGoogleAccountConnected?: () => void
}

@compose(...integrateGoogleAnalyticsQueries)
export class GoogleAnalyticsIntegration extends React.Component<GoogleAnalyticsIntegrationProps> {
  public static instance
  public static timer
  public state = {
    loading: false,
    isAnalyticsModalOpen: false,
    isAlreadyInUseModalOpen: false
  }

  public openGoogleAnalyticsModal = () => {
    this.setState({isAnalyticsModalOpen: true})
  }

  public closeGoogleAnalyticsModal = () => {
    this.setState({isAnalyticsModalOpen: false})
  }

  public closeAlreadyInUseModal = () => {
    this.setState({isAlreadyInUseModalOpen: false})
  }

  public onGoogleAnalyticsConnected = () => {
    this.closeGoogleAnalyticsModal()
    this.props.googleStatus.refetch()
    if (this.props.onGoogleAccountConnected) {
      this.props.onGoogleAccountConnected()
    }
  }


  public openGAPopUp = () => {
    const {auth: {getGoogleAuthUrl}, submitGoogleToken, shouldOpenDialog} = this.props
    if (GoogleAnalyticsIntegration.instance) {
      GoogleAnalyticsIntegration.instance.close()
      clearInterval(GoogleAnalyticsIntegration.timer)
    }

    const current = window.location.origin
    const google = encodeURIComponent(getGoogleAuthUrl)
    const url = getRedirectUri(`/callback?current=${current}&next=${google}`)

    GoogleAnalyticsIntegration.instance = window.open(url, '', 'top=100,left=100,width=500,height=500')
    GoogleAnalyticsIntegration.timer = setInterval(() => {
      const {instance, timer} = GoogleAnalyticsIntegration
      if (!instance || instance.closed) {
        clearInterval(timer)
        return
      }
      try {
        if (instance.location.pathname.includes('/callback/done')) {
          const oAuthCode = queryString.parse(new URL(instance.document.URL).search).code
          this.setState({loading: true})
          submitGoogleToken({variables: {oAuthCode}})
            .then(async ({data}) => {
              if (data.setupGoogleAnalytics.success) {
                registerAnalyticEvent(Events.GA_CONNECTED)
                if (shouldOpenDialog) {
                  this.openGoogleAnalyticsModal()
                }
                this.setState({loading: false})
              } else {
                this.setState({
                  loading: false,
                  isAlreadyInUseModalOpen: true,
                })
              }
            })
            .catch(e => {
              this.setState({loading: false})
              registerAnalyticEvent(Events.GA_CONNECT_FAILED, { error: this.constructErrorForEvent(e) })
              error(this.constructError(e))
            })

          clearInterval(timer)
          instance.close()
        }
      } catch (e) {
        if (!instance || instance.closed) {
          clearInterval(timer)
        }
      }
    }, 150)
  }

  public render() {
    const {auth} = this.props

    return (
      <React.Fragment>
        <AnalyticsModal
          isOpen={this.state.isAnalyticsModalOpen}
          toggle={this.closeGoogleAnalyticsModal}
          onDone={this.onGoogleAnalyticsConnected}
        />
        <AlreadyInUseModal
          isOpen={this.state.isAlreadyInUseModalOpen}
          toggle={this.closeAlreadyInUseModal}
        />
        {this.props.children(this.openGAPopUp, auth.loading || this.state.loading)}
      </React.Fragment>
    )
  }

private constructError(e: any) {
    const ne = e.message.replace('GraphQL error:', '').trim()
    try {
      const err = JSON.parse(ne)
      return (<div>
        <div>{err.userMessage}</div>
        <br/>Need more help? Click <a target="_blank"
                                      href={'https://help.leadexplorer.com/integrations#google-analytics'}>here</a> for
        more information on how to connect to Google Analytics.</div>)
    } catch (e) {
      return `Something unexpected happened, contact support if needed.\n Error: ${e}`
    }
  }


  private constructErrorForEvent(e: any) {
    const ne = e.message.replace('GraphQL error:', '').trim()
    try {
      const err = JSON.parse(ne)
      return err.userMessage
    } catch (e) {
      return e
    }
  }

}
