import * as React from 'react'
import { compose, withApollo } from 'react-apollo'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { get } from 'lodash'

import {
  Card,
  Show,
  LoadingBlock,
  GoogleAnalyticsSummary,
  LoadingOverlay,
  GoogleAnalyticsIntegration,
} from '../../../../components'
import {
  WrappingContent, InfoCard, DisconnectButton, ConnectedAccount, ConnectButton, CardContent,
  Wrapper, Title, AccountDetails, AccountEmail, AccountName, AccountPhoto, LoadingPhoto
} from './google-analytics.styles'
import googleAnalyticsSummaryQueries from './google-analytics-settings.query'
import { googleAnalyticsSummarySelector } from '../../../../components/google-analytics/accounts/google-analytics-summary.selector'
import { GoogleAnalyticsError } from './google-analytics-error.component'

interface GoogleAnalyticsSettingsProps {
  className: string
  disconnectGoogleAnalytics: any
  data: any
  status: any
}

// @ts-ignore
@connect(googleAnalyticsSummarySelector, { push })
// @ts-ignore
@compose(...googleAnalyticsSummaryQueries)
// @ts-ignore
@withApollo
export class GoogleAnalyticsSettings extends React.PureComponent<GoogleAnalyticsSettingsProps> {
  public state = {
    disconnecting: false,
  }

  public disconnect = async () => {
    const { disconnectGoogleAnalytics } = this.props

    this.setState({ disconnecting: true })

    try {
      await disconnectGoogleAnalytics()
    } finally {
      this.setState({ disconnecting: false })
    }
  }

  public render() {
    const { data: { loading, getGoogleAnalyticsUser }, status } = this.props

    const connected = !!getGoogleAnalyticsUser
    const isInError = get(status, 'getGaIntegrationStatus.inError')

    console.log(status)

    return (
      <Wrapper>
        <Title>Google Analytics</Title>
        <GoogleAnalyticsIntegration shouldOpenDialog={false}>
          {(openGaPopup, gaLoading) => (
            <React.Fragment>
              <Show if={this.state.disconnecting || gaLoading}>
                <LoadingOverlay
                  active={true}
                  spinner={true}
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: '100vw',
                    zIndex: 20,
                  }}
                />
              </Show>
              <InfoCard>
                <Card.Title>
                  Connected Google Account
                </Card.Title>

                <WrappingContent>
                  <ConnectedAccount>
                    <Show if={!loading && !gaLoading && connected}>
                      <AccountPhoto src={get(getGoogleAnalyticsUser, 'photo')} round={true} />
                      <AccountDetails>
                        <AccountName>
                          {get(getGoogleAnalyticsUser, 'name')}
                        </AccountName>
                        <AccountEmail>
                          {get(getGoogleAnalyticsUser, 'email')}
                        </AccountEmail>
                      </AccountDetails>
                    </Show>
                    <Show if={!loading && !gaLoading && !connected}>
                      <AccountDetails>
                        <AccountName>
                          No account connected
                        </AccountName>
                      </AccountDetails>
                    </Show>
                    <Show if={loading || gaLoading}>
                      <LoadingPhoto />
                      <AccountDetails>
                        <AccountName>
                          <LoadingBlock width="100px" height="18px" />
                        </AccountName>
                        <AccountEmail>
                          <LoadingBlock width="100px" height="16px" />
                        </AccountEmail>
                      </AccountDetails>
                    </Show>
                  </ConnectedAccount>

                  <Show if={!loading && isInError}>
                    <GoogleAnalyticsError />
                  </Show>

                  <Show if={!loading && !gaLoading && connected}>
                    <DisconnectButton onClick={() => this.disconnect()}>
                      Disconnect
                    </DisconnectButton>
                  </Show>
                  <Show if={!loading && !gaLoading && !connected}>
                    <ConnectButton onClick={openGaPopup}>
                      Connect an account
                    </ConnectButton>
                  </Show>
                </WrappingContent>
              </InfoCard>
              <Show if={!loading && !gaLoading && connected}>
                <InfoCard>
                  <Card.Title>
                    Enabled Views
                  </Card.Title>
                  <CardContent>
                    <GoogleAnalyticsSummary />
                  </CardContent>
                </InfoCard>
              </Show>
            </React.Fragment>
          )}
        </GoogleAnalyticsIntegration>
      </Wrapper>
    )
  }
}
