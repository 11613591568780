import * as React from 'react'
import { map } from 'lodash'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Mutation, Query } from 'react-apollo'

import { Spinner, Input } from '../../../../components'
import { MainWrapper, Title, InfoCard } from '../../settings-container.styles'
import { usersCountQuery, updateUsersCount } from '../../../../gql'
import { AddUsersForm } from './components/add-users-form.component'

export class AddUsers extends React.PureComponent {
  public render() {
    return (
      <MainWrapper>
        <Title>Add users</Title>
        <Query
          query={usersCountQuery}
        >
          {({ data = { getUsers: [] } , loading, error, refetch }) => {
            if (loading) {
              return <Spinner />
            }
            if (error) {
              throw error
            }
            return (
              <InfoCard>
                <div>
                  You have {data.getUsers.length} users
                </div>
                <div>
                  Current user limit is {data.getPaymentSubscriptionStatus.users}
                </div>
                <Mutation mutation={updateUsersCount}>
                  {mutation => (
                    <AddUsersForm
                      onSubmit={(formData) => {
                        mutation({ variables: { addon_quantity: (formData as any).count }})
                          .then(refetch)
                      }}
                      initialValues={{
                        count: data.getPaymentSubscriptionStatus.users
                      }}
                    />
                  )}
                </Mutation>
              </InfoCard>
            )
          }}
        </Query>
      </MainWrapper>
    )
  }
}
