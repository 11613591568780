import styled from 'styled-components'
import src from './images/sort-icon.svg'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 4px;
`

export const SortIcon = styled.img.attrs({ src })`
  width: 18px;
  height: 20px;
  padding: 4px;
`
