import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

const updateCompanyInformation = gql`
    mutation updateTenantCompanyInformation(
      $name: String
      $address1: String
      $address2: String
      $zipCode: String
      $city: String
      $country: String
      $industry: String
      $logo: String
    ) {
        updateTenantCompanyInformation(
            name: $name, 
            address1: $address1,
            address2: $address2,
            zipCode: $zipCode,
            city: $city,
            countryCode: $country,
            industry: $industry,
            logo: $logo
        ) {
            name
            address1
            address2
            zipCode
            city
            countryCode
            industry
            logo
        }
    }
`

const getCompanyInformation = gql`query getCompanyInfo{
    getTenantCompanyInformation {
        name
        address1
        address2
        zipCode
        city
        countryCode
        industry
        logo
    }
}`

export const companySettingsQueries = [
  graphql(getCompanyInformation, {
    name: 'data',
  }),
  graphql(updateCompanyInformation, {
    name: 'updateCompanyInformation',
    options: () => ({
      update: (proxy, { data: { updateTenantCompanyInformation } }) => {
        // Read the data from our cache for this query.
        const data = proxy.readQuery({ query: getCompanyInformation })

        const newData = {
          ...data,
          getTenantCompanyInformation: updateTenantCompanyInformation,
        }

        // Write our data back to the cache.
        proxy.writeQuery({ query: getCompanyInformation, data: newData })
      },
    }),
  }),
]
