import * as React from 'react'
import { reduce, pick } from 'lodash'
import { Mutation, Query } from 'react-apollo'
import { connect } from 'react-redux'

import { Spinner } from 'components'
import { Plan } from './plan.model'
import { MainWrapper, Title } from '../../settings-container.styles'
import { getPaymentPlans, createPaymentSubscription, registerVatID, getSubscriptionStatus } from '../../../../gql/subscriptions/subscriptions.query'
import { PurchasePlanForm } from './component/purchase-plan-form.component'
import {
  addCountryCodeToInitialValues,
  addCountryCodeToResultValues,
  handleBillingFormRejection,
  countriesList,
  registerAnalyticEvent,
  Events,
  isInEU,
  getVATIdCode
} from 'utils'
import { purchasePlanSelector } from './purchase-plan.selector'

const MONTHLY_INTERVAL = 'months'
const YEARLY_INTERVAL = 'years'
const BILLING_FIELDS = ['address1', 'address2', 'city', 'countryCode', 'emailAddress', 'name', 'zipCode', 'vatID']

interface PurchasePlanSelectorProps {
  email?: any
}

@connect(purchasePlanSelector)
export class PurchasePlan extends React.PureComponent<PurchasePlanSelectorProps> {
  public state = {
    redirecting: false
  }

  public sortPlans = data => reduce(data, (result, item) => {
    switch (item.interval_unit) {
      case MONTHLY_INTERVAL: {
        result.monthlyPlans.push(new Plan(item))
        return result
      }
      case YEARLY_INTERVAL: {
        result.yearlyPlans.push(new Plan(item))
        return result
      }
    }
  }, { monthlyPlans: [], yearlyPlans: [] })

  public getInitialValues = (data, users) => {
    return {
      ...pick(addCountryCodeToInitialValues(data.getTenantBillingDetails), BILLING_FIELDS),
    }
  }

  public componentDidMount() {
    registerAnalyticEvent(Events.SUBSCRIPTION_PLAN_PAGE_VISIT, { 'email': this.props.email })
  }

  public render() {
    return (
      <MainWrapper>
        <Title>Purchase plan</Title>
        <Query query={getSubscriptionStatus}>
          {(subscription) => {
            if (subscription.loading) {
              return <Spinner />
            }
            if (subscription.error) {
              throw subscription.error
            }
            const { users } = subscription.data.getPaymentSubscriptionStatus
            const planCode = subscription.data.getPaymentSubscriptionStatus.subscription.plan.plan_code
            return (
              <Query
                query={getPaymentPlans}
                variables={{
                  plan_code: planCode,
                }}
              >
                {({ data, loading, error }) => {
                  if (loading) {
                    return <Spinner />
                  }
                  if (error) {
                    throw error
                  }
                  const { monthlyPlans, yearlyPlans } = this.sortPlans(data.getPaymentPlans)
                  return (
                    <Mutation mutation={registerVatID}>
                      {(registerVatIDMutation, registerVatIDMutationOptions) => (
                        <Mutation mutation={createPaymentSubscription}>
                          {(createPaymentSubscriptionMutation, createPaymentSubscriptionMutationOptions) => (
                            <PurchasePlanForm
                              onSubmit={(values) => {
                                const variables: any = addCountryCodeToResultValues(values)
                                if (isInEU(variables.countryCode)) {
                                  variables.vatID = `${getVATIdCode(variables.countryCode)}${variables.vatID}`
                                } else {
                                  variables.vatID = null
                                }
                                variables.redirect_uri = `https://${window.location.host}/settings/subscription`
                                return registerVatIDMutation({ variables })
                                  .then(() => createPaymentSubscriptionMutation({ variables }))
                                  .catch(handleBillingFormRejection)
                                  .then((result) => {
                                    this.setState({
                                      redirecting: true
                                    })
                                    window.location.href = (result as any).data.createPaymentSubscription.url
                                  })
                              }}
                              initialValues={this.getInitialValues(data, users)}
                              users={users}
                              planCode={planCode}
                              monthlyPlans={monthlyPlans}
                              yearlyPlans={yearlyPlans}
                              countriesList={countriesList}
                              updating={
                                registerVatIDMutationOptions.loading ||
                                createPaymentSubscriptionMutationOptions.loading ||
                                this.state.redirecting
                              }
                            />
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </MainWrapper>
    )
  }
}
