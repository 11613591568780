import * as React from 'react'
import { IntegrationProps } from '../../models'

import { Wrapper, Header, Inner } from './list-item-integration.styles'

interface ListItemIntegrationProps extends IntegrationProps {
  onClick: (url: string) => void
}

export class ListItemIntegration extends React.PureComponent<ListItemIntegrationProps> {
  public render(): React.ReactNode {
    const { status, logo } = this.props
    return (
      <Wrapper
        active={status === 'active'}
        onClick={this.onClick}
      >
        <Header active={status === 'active'}>{status || 'updating'}</Header>
        <Inner
          logo={logo}
        />
      </Wrapper>
    )
  }

  private onClick = () => {
    this.props.onClick(this.props.url)
  }
}
