import * as React from 'react'

import { Wrapper, Name, Address, Owner } from './search-result-item.styles'

interface SearchResultItemProps {
  company: any
  selected: boolean
  onSelect: (selected: string) => void
}

export class SearchResultItem extends React.PureComponent<SearchResultItemProps> {
  public render() {
    const { company: { name, country, updatedBy, companyId }, selected, onSelect } = this.props
    return (
      <Wrapper
        selected={selected}
        onClick={() => onSelect(companyId)}
      >
        <Name>{name}</Name>
        <Address>{country}</Address>
        <Owner>{updatedBy}</Owner>
      </Wrapper>
    )
  }
}
