import * as React from 'react'
import { Field, reduxForm } from 'redux-form'

import { switchFilterPanel } from 'actions'
import { SimpleOrClause } from '../simple-or-clause'
import { Events, registerAnalyticEvent, validation } from 'utils'
import {
  Wrapper,
  SaveButton,
  CancelButton,
  Buttons,
  FilterTitleInput,
} from './edit-filter.styles'
import { Checkbox } from '../../../../../settings/components/users/components/form-checkbox'

const required = validation.required()

interface EditFilterProps {
  dispatch?: any
  handleSubmit?: any
  valid?: boolean
  mutation: any
  onFilterCreated: any
  initialValues?: any
  filterToEdit?: any
  submitting?: boolean
}

// @ts-ignore
@reduxForm({
  form: 'editFilter',
  onSubmit: ({ name, favorite, orClauses }: any, _, { mutation, onFilterCreated, filterToEdit }: any) => {
    const input: any = {
      name,
      favorite,
      filterDefinition: {
        orClauses
      }
    }
    if (filterToEdit) {
      input.id = filterToEdit.id
    }

    return mutation({
      variables: { input }
    })
    .then(registerAnalyticEvent(Events.FILTER_CREATED, { 'filterName': name }))
    .then(onFilterCreated)
  }
})
export class EditFilter extends React.PureComponent<EditFilterProps> {
  public switchTo = tab => () => {
    this.props.dispatch(switchFilterPanel(tab))
  }

  public render() {
    const { handleSubmit, valid, submitting } = this.props
    return (
      <Wrapper onSubmit={handleSubmit}>
        <Field
          name="name"
          component={FilterTitleInput as any}
          type="text"
          placeholder="Filter Name"
          validate={[required]}
        />
        <Field
          name="orClauses"
          component={SimpleOrClause as any}
          filterToEdit={this.props.filterToEdit}
        />
        <Field
          name="favorite"
          type="checkbox"
          label="Show as custom tab"
          component={Checkbox as any}
        />
        <Buttons>
          <CancelButton
            onClick={this.switchTo('list')}
          >
            CANCEL
          </CancelButton>
          <SaveButton
            disabled={!valid || submitting}
            updating={submitting}
            color="primary"
          >
            Save filter
          </SaveButton>
        </Buttons>
      </Wrapper>
    )
  }
}

