import styled, { css } from 'styled-components'
import { PageTitle as BasePageTitle, Card } from './../../components'

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 258px;
  padding: 21px 0;
`
export const FormWrapper = styled.div`
  flex: 1;
  padding: 0 0 24px 40px;
  max-width: 550px;

  h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
  }
`
export const PageTitle = styled(BasePageTitle)`
  margin-bottom: 32px;
`
export const NoPictureText = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
  color: white;
  text-transform: uppercase;
`

interface PictureWrapperProps {
  noPicture: boolean
}
export const PictureWrapper = styled.div<PictureWrapperProps>`
  position: relative;
  
  ${props => props.noPicture ? css`
    ${NoPictureText} {
      display: block;
    }
` : ''}
`
export const Picture = styled.img`
  display: block;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin-bottom: 8px;
  ${props => props.src === null ? 'background: #d5d5d5' : ''}
`
export const ContentRow = styled((Card as any).ContentRow)`
  align-items: flex-start;
`
export const PageWrapper = styled.div`
  padding-bottom: 24px;
`
