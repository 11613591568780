import * as React from 'react'

import { Text, Title, Wrapper } from './default-error.styles'
import { Show } from 'components'
import { CONFIG } from '../../../config'

export const DefaultErrorContainer = ({ error }) => (
  <Wrapper>
    <Title>Error</Title>
    <Text>Something went wrong. Please refresh the page and try again</Text>
    <Show if={CONFIG.ENV === 'dev'}>
      <Text>{error.message}</Text>
    </Show>
  </Wrapper>
)
