import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

interface MobileProps {
  isMobile: boolean
}

export const Wrapper = styled.div<MobileProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
  height: ${({ isMobile }) => isMobile ? '100vh' : '100%'};;
  z-index: 20;
  align-items: center;
  padding:  ${({ isMobile }) => isMobile ? '54px 0 0 0' : '0 0 0 54px'};
`
export const MenuItem = styled(NavLink)<MobileProps>`
  box-sizing: border-box;
  display: flex;
  padding: 0 23px;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.TOP_MENU_FONT_COLOR};
  border-top:  5px solid transparent;
  border-bottom:  5px solid transparent;
  text-decoration: none;
  transition: background-color .3s;
  &:hover {
    background: ${({ theme }) => theme.TOP_MENU_ITEM_HOVER};
  }
  &.active {
    border-bottom-color: ${({ isMobile, theme }) => isMobile ? 'transparent' : theme.TOP_MENU_ACTIVE_COLOR};
    border-left-color: ${({ isMobile, theme }) => isMobile ? theme.TOP_MENU_ACTIVE_COLOR : 'transparent'};
    font-weight: 500;
    background: ${({ theme }) => theme.TOP_MENU_ITEM_ACTIVE};
  }
  &.mobile {
    padding: 23px;
    height: auto;
    width: 100%;
    border-left: 5px solid transparent;
    border-top: none;
    border-bottom: none;
  }
  &.mobile.active {
    border-bottom-color: transparent;
    border-left-color: ${({ theme }) => theme.TOP_MENU_ACTIVE_COLOR};
  }
`
export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 30px;
`
export const DisabledMenuItem = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 0 23px;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.TOP_MENU_DISABLED_FONT_COLOR}
  border-top:  5px solid transparent;
  border-bottom:  5px solid transparent;
  text-decoration: none;
  cursor: not-allowed;

  &.mobile {
    padding: 23px;
    height: auto;
    width: 100%;
    border-left: 5px solid transparent;
    border-top: none;
    border-bottom: none;
  }
`
