import * as React from 'react'
import { Icon } from 'components'
import { Wrapper } from './relevant-icon.styles'

interface RelevantIconProps {
  relevance: number
}

export class RelevantIcon extends React.PureComponent<RelevantIconProps> {
  public render() {
    return (
      <Wrapper opacity={(this.props.relevance + 5) / 100}>
        <Icon size={24} icon="eye" color="#61869E"/>
      </Wrapper>
    )
  }
}
