import * as React from 'react'
import { BaseLayout } from '../base-layout'

import { Wrapper, Content } from './layout.styles'

export const Layout = ({ children, ...props }) => (
  <BaseLayout {...props}>
    <Wrapper>
      <Content>
        {children}
      </Content>
    </Wrapper>
  </BaseLayout>
)
