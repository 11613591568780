import gql from 'graphql-tag'

export const setAssignedUser = gql`
  mutation setAssignedUser($email: String!, $companyId: String!) {
    setAssignedUser(email: $email, companyId: $companyId) {
      email
      companyId
    }
  }
`
