import * as React from 'react'
import { connect } from 'react-redux'
import { ApolloConsumer } from 'react-apollo'

import { Show, Spinner, PageSubHeader, BackButton } from 'components'
import { setResolverValue, setResolverError, setResolverLoading, setResolverResult, clearResolver } from 'actions'
import { Title, Error, CenterBox } from './email-resolver.styles'
import { ResolverForm } from './components/resolver-form'
import { resolverSelector } from '../../selectors/resolver.selector'
import { CompanyInfo } from './components/company-info'
import { Layout } from './components/layout/layout.component';

interface ResolverProps {
  history: any
  value?: string
  error?: string
  loading?: boolean
  result?: any[]
  isEmpty?: boolean
  setResolverValue?: (value: string) => void
  setResolverError?: () => void
  setResolverLoading?: () => void
  setResolverResult?: () => void
  clearResolver?: () => void
}

@connect(resolverSelector, { setResolverValue, setResolverError, setResolverLoading, setResolverResult, clearResolver })
export class EmailResolver extends React.PureComponent<ResolverProps> {
  public componentWillUnmount(): void {
    this.props.clearResolver()
  }

  public onResolveEmailClick = (id: string) => {
    this.props.history.push(`webleads/company/${id}/dashboard`)
  }

  public render() {
    const { value, error, loading, result, isEmpty } = this.props
    return (
      <Layout>
        <PageSubHeader>
          <BackButton onClick={() => this.props.history.push('/webleads')} />
        </PageSubHeader>
        <Title>Resolve Company Information From Email</Title>
        <ApolloConsumer>
          {(client) => (
            <ResolverForm
              value={value}
              client={client}
              onResult={this.props.setResolverResult}
              onLoading={this.props.setResolverLoading}
              onError={this.props.setResolverError}
              onValueChange={this.onValueChange}
            />
          )}
        </ApolloConsumer>
        <Show if={result}>
          <CompanyInfo
            company={result}
            loading={this.props.loading}
          />
        </Show>
        <Show if={error}>
          <CenterBox>
            <Error>Something went wrong, please try again later</Error>
          </CenterBox>
        </Show>
        <Show if={isEmpty}>
          <CenterBox>
            <Error>Nothing was found</Error>
          </CenterBox>
        </Show>
        <Show if={loading}>
          <CenterBox>
            <Spinner />
          </CenterBox>
        </Show>
      </Layout>
    )
  }

  private onValueChange = (event) => {
    this.props.setResolverValue(event.target.value)
  }

}
