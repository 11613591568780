import styled from 'styled-components'
import { map } from 'lodash'

import { Icon } from '../../../'
import { OutsideClickHandler } from '../../../utils'
import { Button } from '../../../controls/button'

export const Wrapper = styled.div`
  max-width: 150px;
  padding: 6px 10px;
  cursor: pointer;
  position: relative;
`
export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #314550;
  user-select: none;
`
export const Value = styled.div`
  font-size: 14px;
`
export const CalendarIcon = styled(Icon)`
  background-size: contain;
`

export const Ranges = styled(OutsideClickHandler)`
  position: absolute;
  top: 120%;
  right: ${({ position: { right } }) => right};
  left: ${({ position: { left } }) => left};
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  max-width: calc(100vw - 40px);
  padding: 20px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0,0,0, 0.05);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
  @media (max-width: 800px) {
    padding: 10px;
    max-width: calc(100vw - 20px);
  }
`
export const RangeButton = styled(Button)`
  width: 140px;
  margin: 5px;
  text-transform: none;
`
