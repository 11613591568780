import * as React from 'react'
import { spring, TransitionMotion } from 'react-motion'
import {
  getCurrentSubscription,
  isWebPushSupported,
  saveSubscription,
  subscribeToNotifications
} from '../../utils/notification'
import {
  ActionButton,
  Actions,
  CloseIcon,
  Content,
  Header,
  Logo,
  Row,
  TopBlock,
  Wrapper
} from './request-notification.styles'
import { Mutation } from 'react-apollo'
import { savePushSubscription } from '../../gql/notification'

export class RequestNotification extends React.PureComponent {
  public state = {
    subscription: null,
    loading: false,
    hidden: true,
  }

  public async componentDidMount() {
    const subscription = await getCurrentSubscription()
    this.setState({ subscription, hidden: false })
  }

  public handleClick = async (mutation) => {
    this.setState({ loading: true })
    const subscription = await subscribeToNotifications()
    if (subscription) {
      await saveSubscription(subscription, mutation)
    }
    this.setState({ loading: false, hidden: true })
  }

  public dismiss = async () => {
    localStorage.setItem('isBrowserNotificationDismissed', 'true')
    this.setState({ hidden: true })
  }

  public render(): React.ReactNode {
    const isDismissed = localStorage.getItem('isBrowserNotificationDismissed') === 'true'

    const hidden = !isWebPushSupported() || isDismissed || !!this.state.subscription || this.state.hidden

    return (
      <TransitionMotion
        key="slide-in"
        willLeave={() => ({
          transform: spring(-405),
        })}
        willEnter={() => ({
          transform: -405,
        })}
        styles={!hidden ? [{
          key: 'someId',
          style: {
            transform: spring(0),
          },
        }] : []}
      >
        {(interpolatedStyles) => {
          if (interpolatedStyles.length) {
            return (
              <Wrapper
                style={{
                  transform: `translateX(${interpolatedStyles[0].style.transform}px)`,
                }}
              >
                <Mutation mutation={savePushSubscription}>
                  {mutation => (
                    <>
                      <TopBlock>
                        <Content>
                          <Logo src="/favicon.png"/>
                          <div>
                            <Header>
                              Leadexplorer Browser Notifications
                            </Header>
                            <div>
                              <Row>We can let you know right away when something</Row>
                              <Row>important happens in Leadexplorer.</Row>
                            </div>
                          </div>
                        </Content>
                        <CloseIcon icon="closeToast" size={13} onClick={this.dismiss}/>
                      </TopBlock>
                      <Actions>
                        <ActionButton
                          updating={this.state.loading}
                          color="primary"
                          onClick={() => this.handleClick(mutation)}
                        >
                          Activate
                        </ActionButton>
                      </Actions>
                    </>
                  )}
                </Mutation>
              </Wrapper>
            )
          }

          return null
        }}
      </TransitionMotion>
    )
  }
}
