import * as React from 'react'
import { reduxForm } from 'redux-form'

import { Button, Spinner } from './../../../../../components'
import { Wrapper, Footer } from '../billing.styles'
import { BillingFields } from './billing-fields.component'

interface BillingFormProps {
  valid: any
  handleSubmit: any
  loading: any
  countriesList: any
}

// @ts-ignore
@reduxForm({
  form: 'BillingForm',
})
export class BillingForm extends React.PureComponent<BillingFormProps> {
  public render() {
    const { valid, handleSubmit, loading, countriesList } = this.props
    if (loading) {
      return <Spinner />
    }
    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <BillingFields
            countriesList={countriesList}
            columns={1}
          />
          <Footer>
            <Button
              disabled={!valid}
              color="primary"
            >
              Save
            </Button>
          </Footer>
        </form>
      </Wrapper>
    )
  }
}
