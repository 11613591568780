import gql from 'graphql-tag'
import { getRedirectUri } from 'utils'

const redirectUri = getRedirectUri('/callback')

export const submitEmarketeerCode = gql`
    mutation submitEmarketeerCode($code: String!)   {
        setupEmarketeerIntegration(code: $code, redirectUri: "${redirectUri}") {
            accountId
        }
    }
`

export const disconnectEmarketeer = gql`
mutation {
    disconnectEmarketeer
}
`
