import * as React from 'react'
import { compose, graphql } from 'react-apollo'
import { find } from 'lodash'

import { Modal, Show, LoadingOverlay } from '../../../../../../components'
import { InviteUserForm } from '../invite-user-form'
import { success, error } from 'utils'
import { userQuery, tenantCompanyInformation } from '../../../../../../gql'
import { Title } from './invite-user-modal.styles'
import { inviteUser as inviteUserMutation } from '../../../../../../gql/user/user.mutation'
import { Events, registerAnalyticEvent } from 'utils';

interface InviteUserModalProps {
  inviteUser?: any
  toggle?: any
  isAdmin?: boolean
  data?: any
  company?: any
  isOpen?: boolean
  onDone?: any
  email?: string
}

// @ts-ignore
@compose(
  graphql(
    inviteUserMutation,
    {
      name: 'inviteUser',
      options: {
        awaitRefetchQueries: true,
        refetchQueries: () => ['getPendingInvitations'],
      },
    }
  ),
  graphql(userQuery),
  graphql(tenantCompanyInformation, { name: 'company' }),
)
export class InviteUserModal extends React.Component<InviteUserModalProps> {
  public state = {
    loading: false,
  }

  public inviteUser = async (data) => {
    const { inviteUser, toggle } = this.props

    const { firstName, lastName } = this.props.data.getUser

    const inviter = `${firstName} ${lastName}`

    this.setState({ loading: true })
    try {
      await inviteUser({
        variables: {
          ...data,
          inviter,
          companyName: this.props.company.getTenantCompanyInformation.name,
        },
      })
      toggle()
      registerAnalyticEvent(Events.USER_INVITE, { invitedEmail:	data.email , inviterEmail:	this.props.data.getUser.email })
      success('Invitation email was successfully sent')
    } catch (e) {
      if (e.message.indexOf('409') !== -1) {
        error('User with this email already exists')
      } else {
        error('Internal server error. Please contact support.')
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  public render() {
    const { isOpen, toggle, email } = this.props

    return (
      <Modal isOpen={isOpen} onRequestClose={toggle}>
        <Show if={this.state.loading}>
          <LoadingOverlay
            active={true}
            spinner={true}
            background="rgba(0,0,0,0.4)"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 20,
            }}
          />
        </Show>
        <Title>Invite new user</Title>
        <InviteUserForm initialValues={{ isAdmin: false, email }} onSubmit={this.inviteUser} cancel={toggle} />
      </Modal>
    )
  }
}
