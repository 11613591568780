import styled from 'styled-components'

export const Table = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const TableWrapper = styled.div`
  padding: 8px;
`

export const TableRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`

export enum Alignment {
  left = 'flex-start',
  right = 'flex-end',
  center = 'center'
}

interface TableRowItemProps {
  size?: number
  maxWidth?: number
  alignment?: Alignment
}
export const TableRowItem = styled.div<TableRowItemProps>`
  display: flex;
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : 'auto'}
  flex-flow: row nowrap;
  flex-grow: ${({ size = 1}) => size};
  flex-basis: 0;
  justify-content: ${({ alignment = Alignment.left }) => alignment}
  padding: 4px;
  word-break: break-word;
`
export const TableHeaderItem = styled(TableRowItem)`
  font-size: 14px;
  color: #516060;
`
