import * as React from 'react'
import styled from 'styled-components'
import { clamp } from 'lodash'

import { colorScale } from 'utils'
import { Thermometer } from './thermometer.component'

const ThermometerRatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface ValueWrapperProps {
  big: boolean
}

const ValueWrapper = styled.div<ValueWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ big }) => big ? 72 : 36}px;
  height: ${({ big }) => big ? 72 : 36}px;
  margin-right: 8px;
  border-radius: 50%;
  background: ${({ color }) => color};
  color: white;
  font-size: ${({ big }) => big ? 28 : 16}px;
  @media (max-width: 800px) {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
`

export const ThermometerRating = ({ value = 0, big = false, ...other }) => {
  const cappedValue = clamp(value, 0, 100)
  const color = colorScale(cappedValue)

  return (
    <ThermometerRatingWrapper {...other}>
      <ValueWrapper color={color} big={big}>
        {value}
      </ValueWrapper>
      <Thermometer
        color={color}
        value={cappedValue}
        big={big}
      />
    </ThermometerRatingWrapper>
  )
}
