import * as React from 'react'
import { map } from 'lodash'
import { Count } from '../../../../../../components'
import { Employee } from '../employee.component'
import { EmployeeRows } from '../../other-employees.styles'
import { Header } from './other-employees-selection.styles'

export const OtherEmployeesSelection = ({ employees = [] }) => (
  <EmployeeRows>
    <Header>
      Others We Found
      <Count>{employees.length}</Count>
    </Header>
    {map(employees, (employee, i) => <Employee key={i} employee={employee} />)}
  </EmployeeRows>
)
