import * as React from 'react'
import { map, slice } from 'lodash'
import { connect } from 'react-redux'
import { ResponsiveCardTable, Content } from 'react-responsive-cards-table'

import { closeSidePanel, showSidePanel, setActiveVisit } from 'actions'
import { Show, SidePanel } from 'components'
import { WebVisit, WebVisitsTable, ListView, VisitDetails } from './components'
import { webVisitSlider } from './web-visits.selector'
import { Footer, Triangle } from './web-visits.styles'

interface WebVisitsProps {
  sidePanelVisible?: any
  company?: any
  loading?: any
  activeVisit?: any
  sessions?: any
  dispatch?: any
  totalSessions: number
  onFetchMore: () => void
}

@connect(webVisitSlider)
export class WebVisits extends React.Component<WebVisitsProps> {
  public getTableContent = (isCard, sessions) => (
    <Content styles={{ flexDirection: 'column' }}>
      <Show if={isCard}>
        {map(sessions, (x, i) => (
          <WebVisit
            key={i}
            session={x}
            showVisit={this.showVisit}
          />
        ))}
      </Show>
      <Show if={!isCard}>
        <WebVisitsTable sessions={sessions} onFetchMore={this.props.onFetchMore} />
      </Show>
    </Content>
  )

  public closeSidePanel = () => {
    this.props.dispatch(closeSidePanel('webVisit'))
  }

  public showVisit = (visit) => {
    this.props.dispatch(showSidePanel('webVisit'))
    this.props.dispatch(setActiveVisit(visit))
  }

  public render() {
    const { sidePanelVisible, company, loading, activeVisit, sessions = [], totalSessions, onFetchMore } = this.props

    const visibleSessions = sessions

    return (
      <ListView loading={loading}>
        <Show if={!loading}>
          <ResponsiveCardTable>
            {({ isCard }) => this.getTableContent(isCard, visibleSessions)}
          </ResponsiveCardTable>

          <SidePanel isVisible={sidePanelVisible} handleClose={this.closeSidePanel} title="Visit">
            <Show if={activeVisit}>
              <VisitDetails visit={activeVisit} company={company} />
            </Show>
          </SidePanel>
        </Show>
        <Show if={totalSessions > visibleSessions.length}>
          <Footer onClick={() => onFetchMore()}>
            Show more visits <Triangle icon="triangleDown" size={10} />
          </Footer>
        </Show>
      </ListView>
    )
  }
}
