import * as React from 'react'
import { map } from 'lodash'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Mutation, Query } from 'react-apollo'
import { History } from 'history'

import { Spinner, Row } from '../../../../components'
import { MainWrapper, Title } from '../../settings-container.styles'
import { CurrentSubscription } from './components/current-subscription.component'
import { BillingAddressCard } from './components/billing-address-card.component'
import { CreditCard } from './components/credit-card.component'
import { HeaderBlock } from './components/header-block.component'
import { getPaymentSubscriptionStatus, cancelPaymentSubscription } from '../../../../gql/subscriptions/subscriptions.query'
import { HeaderBlocksModel } from './models/header-blocks.model'
import { CancellationModal } from './components/cancellation-modal/cancellation-modal.component'
import { success as successToast, error as errorToast } from '../../../../utils/toast'
import { Events, registerAnalyticEvent } from 'utils';
import { purchasePlanSelector } from '../purchase-plan/purchase-plan.selector'

interface SubscriptionAndBillingProps {
  history: History,
  push: push,
  email?: any
}

@connect(purchasePlanSelector, { push } )
export class Subscription extends React.PureComponent<SubscriptionAndBillingProps> {
  public state = {
    cancellationModalIsOpen: false
  }

  public onUpgradeClick = () => {
    this.props.push('/settings/purchase-plan')
  }

  public onCancelClick = () => {
    this.setState({
      cancellationModalIsOpen: true,
    })
  }

  public onRequestClose = () => {
    this.setState({
      cancellationModalIsOpen: false,
    })
  }

  public componentDidMount() {
    registerAnalyticEvent(Events.SUBSCRIPTION_PAGE_VISIT, { 'email': this.props.email })
  }

  public render() {
    return (
      <MainWrapper>
        <Title>Subscription & Billing</Title>
        <Query
          query={getPaymentSubscriptionStatus}
        >
          {({ data = { getPaymentSubscriptionStatus: {} }, loading, error }) => {
            if (loading) {
              return <Spinner />
            }
            if (error) {
              throw error
            }
            const headerBlockModel = new HeaderBlocksModel(data.getPaymentSubscriptionStatus.subscription)
            const { card } = data.getPaymentSubscriptionStatus.subscription
            return (
              <div>
                <HeaderBlock data={headerBlockModel}/>
                <CurrentSubscription
                  data={data.getPaymentSubscriptionStatus.subscription}
                  onUpgradeClick={this.onUpgradeClick}
                  onCancelClick={this.onCancelClick}
                />
                <Row>
                  <CreditCard cardData={card}/>
                  <BillingAddressCard history={this.props.history}/>
                </Row>
                <Mutation mutation={cancelPaymentSubscription}>
                  {(mutation) => (
                    <CancellationModal
                      currentTerm={data.getPaymentSubscriptionStatus.subscription.current_term_ends_at}
                      isOpen={this.state.cancellationModalIsOpen}
                      onRequestClose={this.onRequestClose}
                      onYesClick={() => {
                        mutation().then(response => {
                          if (response) {
                            this.onRequestClose()
                            successToast('Subscription is cancelled')
                          } else {
                            errorToast('Something went wrong, please try again later')
                          }
                        }).catch(() => errorToast('Something went wrong, please try again later'))
                      }}
                      onNoClick={this.onRequestClose}
                    />
                  )}
                </Mutation>
              </div>
            )
          }}
        </Query>
      </MainWrapper>
    )
  }
}
