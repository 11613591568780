import styled from 'styled-components'
import { Row, Button } from '../../../../components'

export const Wrapper = styled.div`
  padding: 8px 32px 32px;

  form {
    max-width: 700px;
  }
`

export const Footer = styled.div`
  padding-top: 24px;

  ${Button} {
    min-width: 120px;
  }
`

export const Column = styled.div`
  flex: 1;
`

export const ColumnWrapper = styled.div<{ column: number }>`
  display: flex;
  flex-direction: ${({ column }) => column > 1 ? 'row' : 'column'};
  justify-content: space-between;
  
  & .simple-select.react-selectize.default.root-node.big-select .react-selectize-control {
    background: #ffffff;
    border: 1px solid #bfbfbf;
  }
  
  ${Row} {
    justify-content: space-between;

    div:last-child {
      flex: 1;
      margin-left: 16px;
    }
  }
  
  ${Column}:first-child {
    margin-right: ${({ column }) => column > 1 ? '16px' : '0'};
  }
`

export const DropdownWrapper = styled.div`

`
