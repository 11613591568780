import * as React from 'react'
import { clone, filter, find, get, includes, keys, map, omit, debounce } from 'lodash'
import { ControlButton } from '../or-clauses/or-clauses.styles'
import {
  AndContainer,
  CloseIcon,
  EntryContainer,
  FilterExpressionContainer,
  FilterExpressionInput,
  FilterFieldContainer,
  FilterOperatorContainer
} from '../and-clauses/and-clauses.styles'
import { countriesList } from 'utils'
import { Wrapper } from './simple-or-clause.styles'
import { Select } from 'components'
import { Query } from 'react-apollo'
import { getPageSuggestions, getDomainSuggestions } from '../../../../../../gql/filters'

interface SimpleOrClauseProps {
  input: any
  filterToEdit: any
}

const DataMap = {
  fields: {
    latest_page_view_country: {
      value: 'latest_page_view_country',
      label: 'Country'
    },
    company_score: {
      value: 'company_score',
      label: 'Score'
    },
    domain: {
      value: 'domain',
      label: 'Domain'
    },
    page_url: {
      value: 'page_url',
      label: 'Page Url'
    }
  },
  operator: {
    page_url: [{
      value: 'contains',
      label: 'contains'
    }, {
      value: 'does_not_contain',
      label: 'does not contain'
    }, {
      value: 'is',
      label: 'is'
    }, {
      value: 'is_not',
      label: 'is not'
    }, {
      value: 'begins_with',
      label: 'begins with'
    }, {
      value: 'ends_with',
      label: 'ends with'
    }],
    domain: [{
      value: 'contains',
      label: 'contains'
    }, {
      value: 'does_not_contain',
      label: 'does not contain'
    }, {
      value: 'is',
      label: 'is'
    }, {
      value: 'is_not',
      label: 'is not'
    }, {
      value: 'begins_with',
      label: 'begins with'
    }, {
      value: 'ends_with',
      label: 'ends with'
    }],
    latest_page_view_country: [{
      value: 'is',
      label: 'is'
    }],
    company_score: [{
      value: 'larger_than',
      label: 'higher than'
    }],
  }
}

function renderInput(item: any, onDropdownValueChange: any, onValueChange: any) {
  if (!item.field || !item.operator) {
    return <FilterExpressionInput
      disabled={true}
    />
  }

  if (item.field === 'latest_page_view_country') {
    return <Select
      placeholder="Country"
      showSearch={true}
      fullWidth={true}
      onChange={onDropdownValueChange}
      defaultValue={item.value || undefined}
    >
      {map(countriesList, ({ value, label }) => (
        <Select.Option
          key={value}
          value={value}
        >
          {label}
        </Select.Option>
      ))}
    </Select>
  }

  if (item.field === 'page_url') {
    return (
      <Query query={getPageSuggestions} variables={{ search: item.value || '' }}>
        {
          ({ loading, error, data }) => (
            <Select
              placeholder="Page Url"
              showSearch={true}
              fullWidth={true}
              onChange={onDropdownValueChange}
              combobox={true}
              loading={loading}
              notFoundContent={null}
              defaultValue={item.value || undefined}
            >
              {
                map(get(data, 'getPageSuggestions'), (page) =>
                  <Select.Option
                    key={page.url}
                    value={page.url}
                  >
                    {page.url}
                  </Select.Option>
                )
              }
            </Select>
          )
        }
      </Query>
    )
  }

  if (item.field === 'domain') {
    return (
      <Query query={getDomainSuggestions} variables={{ search: item.value || '' }}>
        {
          ({ loading, error, data }) => (
            <Select
              placeholder="Domain"
              showSearch={true}
              fullWidth={true}
              onChange={onDropdownValueChange}
              combobox={true}
              loading={loading}
              notFoundContent={null}
              defaultValue={item.value || undefined}
            >
              {
                map(get(data, 'getDomainSuggestions'), (domain) =>
                  <Select.Option
                    key={domain.domain}
                    value={domain.domain}
                  >
                    {domain.domain}
                  </Select.Option>
                )
              }
            </Select>
          )
        }
      </Query>
    )
  }

  return <FilterExpressionInput
    type="number"
    defaultValue={item.value}
    onChange={onValueChange}
  />
}

const AndClause = ({ item, onFieldChange, onOperatorChange, onDropdownValueChange, onValueChange, onClose, used }) => {
  const fieldOptions = filter(DataMap.fields, f => !includes(used, f.value)) || []

  return (
    <EntryContainer>
      <FilterFieldContainer>
        <Select
          onChange={onFieldChange}
          placeholder="Field"
          value={get(DataMap.fields[item.field], 'label')}
          disabled={fieldOptions.length === 0}
        >
          {map(fieldOptions, x => (
            <Select.Option
              key={x.value}
              value={x.value}
            >
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </FilterFieldContainer>
      <FilterOperatorContainer>
        <Select
          onChange={onOperatorChange}
          placeholder="Operator"
          disabled={!item.field}
          value={get(find(DataMap.operator[item.field], x => x.value === item.operator), 'label')}
        >
          {map(item.field ? DataMap.operator[item.field] : [], x => (
            <Select.Option
              key={x.value}
              value={x.value}
            >
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </FilterOperatorContainer>
      <FilterExpressionContainer>
        {
          renderInput(item, onDropdownValueChange, onValueChange)
        }
      </FilterExpressionContainer>
      {
        onClose ? (
          <CloseIcon
            icon="close"
            size={12}
            onClick={onClose}
          />
        ) : null
      }
    </EntryContainer>
  )
}

interface SimpleOrClauseState {
  or: any[][]
}

export class SimpleOrClause extends React.PureComponent<SimpleOrClauseProps> {
  public state: SimpleOrClauseState = {
    or: [
      [{
        field: null,
        operator: null,
        value: null
      }],
    ]
  }

  constructor(props) {
    super(props)
    if (props.filterToEdit) {
      this.state = {
        or: map(props.filterToEdit.filterDefinition.orClauses, ({ andClauses }) => {
          return map(andClauses, x => omit(x, '__typename'))
        }),
      }
      this.props.input.onChange(this.getValue())
    }
  }

  public addMoreOr = () => {
    this.setState({
      or: [
        ...this.state.or,
        [{
          field: null,
          operator: null,
          value: null
        }]
      ]
    })
  }

  public addMoreAnd = (orIndex: number) => {
    const or = clone(this.state.or)
    or[orIndex] = [
      ...this.state.or[orIndex],
      {
        field: null,
        operator: null,
        value: null
      }
    ]
    this.setState({ or })
  }

  public getValue = () => {
    return map(this.state.or, (and) => {
      return {
        andClauses: and
      }
    })
  }

  public removeOr = (orIndex: number) => {
    const or = filter(this.state.or, (_, i) => i !== orIndex)
    this.setState({ or }, () => {
      this.props.input.onChange(this.getValue())
    })
  }

  public render(): React.ReactNode {
    return (
      <Wrapper>
        {
          map(this.state.or, (and, orIndex) => {
            return (
              <AndContainer key={`or-${orIndex}`}>
                {orIndex === 0 ?
                  <div>List companies matching...</div> :
                  <div>
                    OR matching...
                    <CloseIcon
                      icon="close"
                      size={12}
                      onClick={() => this.removeOr(orIndex)}
                    />
                  </div>
                }
                {map(and, (item, index) => {
                  const onDropdownValueChange = (dropdownValue) => {
                    const or = clone(this.state.or)
                    or[orIndex][index] = {
                      ...or[orIndex][index],
                      value: dropdownValue,
                    }
                    this.setState({ or }, () => {
                      this.props.input.onChange(this.getValue())
                    })
                  }

                  return (
                    <AndClause
                      key={`and-${orIndex}-${index}`}
                      onFieldChange={(dropdownValue) => {
                        const or = clone(this.state.or)
                        or[orIndex][index] = {
                          field: dropdownValue,
                          operator: null,
                          value: null
                        }
                        this.setState({ or })
                      }}
                      onOperatorChange={(dropdownValue) => {
                        const or = clone(this.state.or)
                        or[orIndex][index] = {
                          ...or[orIndex][index],
                          operator: dropdownValue,
                          value: null
                        }
                        this.setState({ or })
                      }}
                      onDropdownValueChange={item.field === 'page_url' ? debounce(onDropdownValueChange, 500) : onDropdownValueChange}
                      onValueChange={(event) => {
                        const or = clone(this.state.or)
                        or[orIndex][index] = {
                          ...or[orIndex][index],
                          value: event.target.value,
                        }
                        this.setState({ or }, () => {
                          this.props.input.onChange(this.getValue())
                        })
                      }}
                      onClose={index > 0 ? () => {
                        const or = clone(this.state.or)
                        or[orIndex] = filter(this.state.or[orIndex], (_, i) => i !== index)
                        this.setState({ or }, () => {
                          this.props.input.onChange(this.getValue())
                        })
                      } : null}
                      used={map(this.state.or[orIndex], x => x.field)}
                      item={item}
                    />
                  )
                })}
                {
                  this.state.or[orIndex].length < keys(DataMap.fields).length ? (
                    <ControlButton
                      onClick={this.addMoreAnd.bind(null, orIndex)}
                    >
                      + AND
                    </ControlButton>
                  ) : null
                }
                {
                  this.state.or.length - 1 === orIndex ? (
                    <ControlButton
                      onClick={this.addMoreOr}
                    >
                      + OR
                    </ControlButton>
                  ) : null
                }
              </AndContainer>
            )
          })
        }
      </Wrapper>
    )
  }
}
