import * as React from 'react'
import { Mutation } from 'react-apollo'
import { find, filter } from 'lodash'

import { Modal, Show, LoadingOverlay } from '../../../../../../components'
import { deleteUser, getUsers } from '../../../../../../gql'
import { Title } from './delete-user-modal.styles'
import { AddButton, CancelButton, Controls } from '../invite-user-form/invite-user-form.styles'

interface DeleteUserModalProps {
  toggle?: any
  isAdmin?: boolean
  data?: any
  isOpen?: boolean
  onDone?: any
  email?: string
  firstName?: string
  lastName?: string
  onRequestClose?: () => void
}

export class DeleteUserModal extends React.Component<DeleteUserModalProps> {
  public state = {
    loading: false,
  }

  public deleteUser = (email, mutation) => {
    mutation({
      variables: { email },
      update: (cache) => {
        const data: any = cache.readQuery({ query: getUsers })
        cache.writeQuery({
          query: getUsers,
          data: {
            getUsers: filter(data.getUsers, (x: any) => x.email !== email),
          }
        })
      }
    })
    this.props.onRequestClose()
  }

  public render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal isOpen={isOpen} onRequestClose={toggle}>
        <Show if={this.state.loading}>
          <LoadingOverlay
            active={true}
            spinner={true}
            background="rgba(0,0,0,0.4)"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 20,
            }}
          />
        </Show>
        <Title>Confirm delete of '{this.props.firstName} {this.props.lastName}'</Title>
        <Controls>
          <CancelButton onClick={this.props.onRequestClose}>Cancel</CancelButton>
          <Mutation
            mutation={deleteUser}
          >
            {(mutation) => (
              <AddButton onClick={() => this.deleteUser(this.props.email, mutation)}>Delete</AddButton>
            )}
          </Mutation>
        </Controls>
      </Modal>
    )
  }
}
