export class Invoice {
  public id: string
  public date: string
  public status: string
  public amount: string
  public url: string

  constructor(data) {
    this.id = data.number
    this.date = data.invoice_date
    this.status = data.status
    this.amount = `${data.currency_code} ${data.total}`
    this.url = data.url
  }

  public getColor = () => {
    switch (this.status) {
      case 'paid': {
        return '#7AAB59'
      }
      default: {
        return 'red'
      }
    }
  }
}
