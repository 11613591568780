import * as React from 'react'
import * as moment from 'moment'
import { find, defaultTo, times } from 'lodash'
import { graphql } from 'react-apollo'

import { SuperOfficeLogo } from '../../crm-insight.styles'
import {
  Row,
  Name,
  OwnerWrapper,
  Wrapper,
  Total,
  TotalText,
  Date,
  StageDescription,
  InfoWrapper,
  Label,
  Value,
  BottomRow,
  DateWrapper,
  TotalWrapper,
  SettingsButton,
  StyledDropdown,
} from './linked-company-card.styles'
import { Stages } from './components/stages.component'
import { unlinkCrmCompany as mutation } from '../../../../../../gql/superoffice/superoffice.mutation'

interface LinkedCompanyCardProps {
  company: any
  id: string
  onUnlink: () => void
  toggleSearchModal: () => void
  unlinkCrmCompany?: any
}

// @ts-ignore
@graphql(mutation, {
  name: 'unlinkCrmCompany',
})
export class LinkedCompanyCard extends React.PureComponent<LinkedCompanyCardProps> {
  public timeout
  public state = {
    showTotal: true
  }

  public componentDidMount() {
    this.timeout = setTimeout(this.swapCards, 5000)
  }

  public componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  public swapCards = () => {
    this.setState({
      showTotal: !this.state.showTotal
    })
    this.timeout = setTimeout(this.swapCards, 5000)
  }

  public showTotal = () => {
    const { company: { totalAmount, currency, startDate } } = this.props
    return (
      <TotalWrapper>
        <DateWrapper>
          <TotalText>Total Sales</TotalText>
          <Date>{moment(startDate).format('MMM YYYY')} - Today</Date>
        </DateWrapper>
        <Total>{totalAmount} {currency}</Total>
      </TotalWrapper>
    )
  }

  public showOpportunity = () => {
    const emptySale = {
      stages: times(5, (x => ({ id: x, probability: (x + 1) * 20 }))),
      saleTypeName: 'No sales opportunity created',
    }
    const { stages, saleName, currentStageId, saleTypeName } = defaultTo(this.props.company.latestSale, emptySale)
    const currentStage = find(stages, { id: currentStageId })
    return (
      <BottomRow>
        <StageDescription>
          <InfoWrapper>
            <Label>
              Latest Opportunity:
            </Label>
            <Value>
              {this.truncateSaleName(saleName) || saleTypeName}
            </Value>
          </InfoWrapper>
          <InfoWrapper>
            <Label>
              Sales Stage:
            </Label>
            <Value>
              {this.getSalesStage(currentStage)}
            </Value>
          </InfoWrapper>
        </StageDescription>
        <Stages
          currentStageId={currentStageId}
          stages={stages}
        />
      </BottomRow>
    )
  }

  public getSalesStage(currentStage) {
    if (currentStage) {
      return `${currentStage.name} (${currentStage.probability}%)`
    }
    return 'N/A'
  }

  public showSwapper = () => {
    const { company: { totalAmount } } = this.props
    const { showTotal } = this.state
    if (totalAmount) {
      return showTotal ? this.showTotal() : this.showOpportunity()
    }
    return this.showOpportunity()
  }

  public render() {
    const { company: { name, owner }, id, unlinkCrmCompany, onUnlink, toggleSearchModal } = this.props
    const items = [
      {
        title: 'Unmatch company',
        onClick: () => unlinkCrmCompany({ variables: { companyId: id } }).then(onUnlink)
      },
      {
        title: 'Match with different company',
        onClick: toggleSearchModal,
      },
    ]

    return (
      <Wrapper>
        <SuperOfficeLogo />
        <Row>
          <Name>
            {name}
            <StyledDropdown itemHeight={38} items={items}>
              <SettingsButton />
            </StyledDropdown>
          </Name>
          <OwnerWrapper>
            <Label>Owner:</Label>
            <Value>{owner}</Value>
          </OwnerWrapper>
        </Row>
        {this.showSwapper()}
      </Wrapper>
    )
  }

  private truncateSaleName = (saleName = '') => {
    if (saleName.length > 50) {
      return saleName.substring(0, 50) + '...'
    }

    return saleName
  }
}
