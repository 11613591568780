import styled from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 240px;
  margin: 0 4px 8px;
  background: white;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.9;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0;
  transition: all 0.3s;
  transform: scale(1);

  &:hover {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px;
    transform: scale(1.02);
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 214px;
  border-bottom: 1px solid #EAEAEA;
  width: 240px;
`

export const Logo = styled.img`
  max-height: 140px;
  max-width: 140px;
`

export const Footer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  word-break: break-word;
`

export const Name = styled.div`
  font-size: 18px;
  color: #314550;
  text-align: center;
`

export const Location = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #314550;
  text-align: center;
`

interface ScoreProps {
  color: string
}

export const Score = styled.div<ScoreProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -13px;
  right: 16px;
  width: 26px;
  height: 26px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 13px;
  background-color: ${({ color }) => color};
`
