import * as React from 'react'
import { map } from 'lodash'
import { integrationCategories } from '../../integrations.data'
import { Wrapper, CategoryTabsItem } from './category-tabs.styles'

interface CategoryTabsProps {
  activeCategory: string
  onTabClick: (selectedCategory: string) => void
}

export class CategoryTabs extends React.PureComponent<CategoryTabsProps> {
  public render(): React.ReactNode {
    const { activeCategory } = this.props
    return (
      <Wrapper>
        <CategoryTabsItem
          active={activeCategory === 'all'}
          onClick={this.props.onTabClick.bind(null, 'all')}
        >
          All Tools
        </CategoryTabsItem>
        {map(integrationCategories, (category) => (
          <CategoryTabsItem
            key={category}
            active={activeCategory === category}
            onClick={this.props.onTabClick.bind(null, category)}
          >
            {category}
          </CategoryTabsItem>
        ))}
      </Wrapper>
    )
  }
}
