import { replace } from 'react-router-redux'
import { get } from 'lodash'

import {
  USER_LOGIN, HANDLE_AUTHENTICATION, USER_LOGOUT, HANDLE_API_AUTHENTICATION,
  receiveUserInfo, receiveUserToken, removeUserToken, clearRedirectUri
} from 'actions'
import { auth } from '../services/auth'
import { Events, registerAnalyticEvent } from 'utils';

export const authMiddleware = store => next => (action) => {
  switch (action.type) {
    case USER_LOGIN: {
      auth.login()
      break
    }

    case USER_LOGOUT: {
      store.dispatch(removeUserToken())
      store.dispatch(replace('/'))
      break
    }

    case HANDLE_AUTHENTICATION: {
      return auth.handleAuthentication()
        .then((tokenData: any) => {
          store.dispatch(receiveUserToken(tokenData))
          auth.fetchProfile(tokenData.access_token).then((profileData: any) => {
            const uri = get(store.getState(), 'auth.redirectUri', '/')
            registerAnalyticEvent(Events.USER_LOGIN, { userEmail: profileData.name });
            store.dispatch(receiveUserInfo(profileData))
            store.dispatch(clearRedirectUri())
            store.dispatch(replace(uri))
          })
        })
    }

    case HANDLE_API_AUTHENTICATION: {
      store.dispatch(receiveUserToken(action.payload))
      auth.fetchProfile(action.payload.access_token).then((profileData) => {
        store.dispatch(receiveUserInfo(profileData))
        store.dispatch(replace('/'))
      })
      break
    }

    default:
  }

  return next(action)
}
