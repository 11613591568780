import * as React from 'react'
import { filter, toLower, join, debounce } from 'lodash'
import { Show, Link, CheckboxInput, CheckboxLabel, Icon } from 'components'
import { Wrapper, Hr, Button, Input, Step } from '../../registration.styles'
import { InputWrapper, Label, Small, IconWrapper, BottomTextWrapper, AgreementWrapper } from './domain-input.styles'
import { TenantApi } from '../../../../api/tenant'
import { AgreementModal } from '../../../../components/modals/agreement-modal'
import { slugify } from 'utils'

interface DomainInputProps {
  onDone: (domain: string, companyName: string) => void
  loading: boolean
}

export class DomainInput extends React.PureComponent<DomainInputProps> {
  public state = {
    domain: '',
    domainIsFree: null,
    showAgreementModal: false,
    agreementAccepted: false,
    companyName: '',
  }
  private readonly domainIsFree: (domain: any) => void
  constructor(props) {
    super(props)
    this.domainIsFree = debounce((domain) => {
      TenantApi
        .domainIsFree(domain)
        .then((res) => {
          const payload: { loading: boolean, domainIsFree?: boolean } = { loading: false }
          if (this.state.domain === domain) {
            payload.domainIsFree = res.message === 'true'
          }
          this.setState(payload)
        })
    }, 500)
  }
  public onChange = (value) => {
    const domain = this.filterDomain(value)
    if (domain) {
      this.setState({
        loading: true,
        domain,
      }, () => this.domainIsFree(domain))
    } else {
      this.setState({ domain, domainIsFree: null })
    }
  }
  public showAgreementModal = () => {
    this.setState({ showAgreementModal: true })
  }
  public closeAgreementModal = () => {
    this.setState({ showAgreementModal: false })
  }
  public onAgree = ({ target }) => {
    this.setState({
      agreementAccepted: target.checked,
    })
  }
  public onNameChange = (value) => {
    const domain = this.filterDomain(slugify(value))
    if (domain) {
      this.setState({
        loading: true,
        companyName: value,
        domain,
      }, () => this.domainIsFree(domain))
    } else {
      this.setState({
        companyName: value,
        domain,
        domainIsFree: null
      })
    }
  }
  public render(): React.ReactNode {
    const { loading, onDone } = this.props
    const { domain, domainIsFree, agreementAccepted, companyName } = this.state
    return (
      <Wrapper>
        <Step>Step 3 of 3</Step>
        <h1 style={{marginBottom: 64}}>Company Name and URL</h1>
        <Label>Company or group name</Label>
        <Input
          style={{marginBottom: 64}}
          type="text"
          placeholder="Company or group name"
          onChange={({ target }) => this.onNameChange(target.value)}
        />
        <Hr style={{marginBottom: 64}}/>
        <Label>Your account URL <span>(letters, numbers, and dashes only)</span></Label>
        <InputWrapper>
          <input
            type="text"
            placeholder="companyname"
            onChange={({ target }) => this.onChange(target.value)}
            value={domain}
          />
          <span>.leadexplorer.com</span>
          <IconWrapper
            show={domainIsFree !== null}
            isFree={domainIsFree}
          >
            <Icon
              icon={domainIsFree ? 'successCheckmark' : 'fail'}
              color="#7AAB59"
              size={25}
            />
          </IconWrapper>
        </InputWrapper>
        <BottomTextWrapper>
          <Show if={domainIsFree}>
            <Small>Good news! Your account name is available as your URL on Leadexplorer. We’ve pre-filled it for you, but feel free to change it.</Small>
          </Show>
        </BottomTextWrapper>
        <AgreementWrapper>
          <CheckboxInput
            id="agree"
            type="checkbox"
            onChange={this.onAgree}
          />
          <CheckboxLabel htmlFor="agree" />
          <span>
            I accept the Leadexplorer <Link onClick={this.showAgreementModal}>Terms of Service</Link>
          </span>
          <AgreementModal
            show={this.state.showAgreementModal}
            onRequestClose={this.closeAgreementModal}
          />
        </AgreementWrapper>
        <Button
          color="primary"
          onClick={() => onDone(domain, companyName)}
          disabled={!(domain && domainIsFree) || loading || !agreementAccepted}
          updating={loading}
        >
          CONTINUE TO ACCOUNT
        </Button>
      </Wrapper>
    )
  }

  private filterDomain(domain: string = '') {
    return join(filter(toLower(domain), x => /[a-z0-9\-]/.test(x)), '')
  }
}
