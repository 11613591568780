import styled from 'styled-components'
import { Button, Card } from 'components'

export const Wrapper = styled.div`
  margin: 20px 0;
`

export const InvitationsCard = styled(Card)`
`

export const InvitationsCardTitle = styled(Card.Title)`
  font-weight: normal;
  text-transform: none;
  font-size: 14px;
`

export const InvitationsCardContent = styled(Card.Content)`
  padding: 10px 25px;
`

export const Invitation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 52px;
`

export const Email = styled.div`
  margin: 5px;
`

export const Buttons = styled.div`
  
`

export const StyledButton = styled(Button)`
  font-weight: normal;
  font-size: 12px;
  margin: 5px;
`
