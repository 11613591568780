import styled from 'styled-components'
import { Link as BaseLink } from '../../../../../../components'

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  
  div {
    font-size: 18px;
  }
`
export const Search = styled.input`
  padding: 8px 16px;
  border: none;
  font-size: 14px;
  color: #314550;
  background: #fafafa;
`

export const Link = styled(BaseLink)`
  margin-left: 16px;
  margin-bottom: 16px;
  color: #61869E;
  font-size: 14px;
`

