import { reduce, find, map, includes } from 'lodash'
import { getData } from 'country-list'
import { SubmissionError } from 'redux-form'

const EU_COUNTRIES = ['AT', 'BE', 'HR', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB']

export const countriesList = map(getData(), country => ({ value: country.code, label: country.name }))

export const addCountryCodeToInitialValues = (data) => {
  return reduce(data, (result, value, key) => {
    if (key === 'countryCode') {
      if (value) {
        result[key] = {
          label: find<any>(countriesList, { value }).label,
          value,
        }
      }
    } else {
      result[key] = value
    }
    return result
  }, {})
}

export const addCountryCodeToResultValues = (values) => {
  return reduce(values, (result, value, key) => {
    if (key === 'countryCode') {
      result[key] = value.value
    } else {
      result[key] = value
    }
    return result
  }, {})
}

export const handleBillingFormRejection = (error) => {
  let errors
  try {
    errors = reduce(JSON.parse(error.graphQLErrors[0].message), (result, { field, message }) => {
      result[field] = message
      return result
    }, {})
  } catch (e) {
    throw error
  }
  throw new SubmissionError(errors)
}

export const isInEU = code => includes(EU_COUNTRIES, code)

export const getVATIdCode = code => code === 'GR' ? 'EL' : code
