import * as React from 'react'
import styled from 'styled-components'

import { PageHeader } from '../page-header/index'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #E5E5E5;
`

interface BaseLayout {
  style?: any
}

export const BaseLayout: React.SFC<BaseLayout> = ({ children, ...props }) => (
  <Wrapper {...props}>
    <PageHeader />
    {children}
  </Wrapper>
)
