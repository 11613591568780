import gql from 'graphql-tag'

export const emarketeerAuthUrl = gql`{
    getEmarketeerAuthUrl {
        url
        clientId
    }
}`

export const emarketeerIntegrationStatus = gql`{
    getEmarketeerIntegrationStatus {
        accountId
        companyName
        connected
    }
}`

export const marketingEventDetails = gql`
    query($domain: String!, $offset: Int, $type: String!, $name: String!, $date: String){
        getMarketingEventDetails(domain: $domain, type: $type, name: $name, date: $date, offset: $offset) {
            eventDate
            person {
                title
                name
                email
                linkedin
                photo
            }
        }
    }`

export const marketingEventsCount = gql`
  query($domain: String!, $type: String!, $name: String!) {
      getMarketingEventsCount(domain: $domain, type: $type, name: $name) {
          count
      }
  }
`

export const getActiveEmployees = gql`
  query($domain: String!) {
    getActiveEmployees(domain: $domain) {
      fullName
      title
      source
      score
      avatar
      linkedin
    }
  }
`
