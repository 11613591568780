import * as React from 'react'
import { connect } from 'react-redux'
import { BaseLayout } from 'components'
import { DomainInput, NameInput } from './components'
import { RegistrationApi, TenantApi } from '../../api'
import { Events, registerAnalyticEvent, setAnalyticTenantId } from 'utils'

interface SignupProps {
  history: any
  match: {
    params: { verificationId: string }
  }
  dispatch?: any
}

// @ts-ignore
@connect()
export class Registration extends React.PureComponent<SignupProps> {
  public state = {
    affiliateId: '',
    email: '',
    verificationId: '',
    firstName: '',
    lastName: '',
    password: '',
    companyName: '',
    loading: false
  }

  public componentDidMount() {
    const { verificationId } = this.props.match.params
    RegistrationApi
      .getDataByVerificationId(verificationId)
      .then((data) => {
        this.setState({
          loading: false,
          ...data
        })
      })
    registerAnalyticEvent(Events.SIGNUP_EMAIL_CONFIRMED)
  }

  public onName = (firstName: string, lastName: string, password: string) => {
    const data = {
      firstName,
      lastName,
      password,
    }
    this.setState(data)
    RegistrationApi.updateDataByVerificationId(this.state.email, data)
    registerAnalyticEvent(Events.SIGNUP_NAME_PASS)
  }

  public onDomain = (domain: string, companyName: string) => {
    const { affiliateId, email, password, firstName, lastName, verificationId } = this.state
    registerAnalyticEvent(Events.SIGNUP_COMPANY_DOMAIN)
    this.setState({
      loading: true,
    }, () => {
      TenantApi
        .registerTenant({
          tenantId: domain,
          affiliateId,
          email,
          password,
          firstName,
          lastName,
          companyName,
          verificationId,
        })
        .then((res) => {
          registerAnalyticEvent(Events.SIGNUP_TENANT_CREATED, { 'tenantId': domain })
          let url = res.url
          if (!/^http/.test(url)) {
            url = `https://${url}`
          }
          window.location = url
        })
    })
    setAnalyticTenantId(domain)
  }

  public getStep = () => {
    const { firstName, lastName, password, loading } = this.state
    if (!(firstName && lastName && password)) {
      return <NameInput onDone={this.onName}/>
    }
    return <DomainInput onDone={this.onDomain} loading={loading}/>
  }

  public render(): React.ReactNode {
    return (
      <BaseLayout style={{background: 'white'}}>
        {this.getStep()}
      </BaseLayout>
    )
  }
}
