import * as React from 'react'
import { map } from 'lodash'

import { SearchResultWrapper, Center } from './search-result.styles'
import { SearchResultItem } from './components/search-result-item.component'
import { Spinner } from '../../../../../../components'

interface SearchResultProps {
  loading: boolean
  companies: any[]
  selected: string
  onSelect: (selected: string) => void
}

export class SearchResult extends React.PureComponent<SearchResultProps> {
  public render() {
    const { companies, selected, onSelect, loading } = this.props
    if (loading) {
      return (
        <SearchResultWrapper>
          <Spinner />
        </SearchResultWrapper>
      )
    }
    if (companies.length) {
      return (
        <SearchResultWrapper>
          {map(companies, company => (
            <SearchResultItem
              selected={company.companyId === selected}
              company={company}
              onSelect={onSelect}
            />
          ))}
        </SearchResultWrapper>
      )
    }
    return (
      <SearchResultWrapper>
        <Center>
          Nothing found
        </Center>
      </SearchResultWrapper>
    )
  }
}
