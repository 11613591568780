import { CONFIG } from '../../config'

export const TenantApi = {
  // Check if domain is free
  domainIsFree: domain => fetch(
    `${CONFIG.TENANT.tenantService}/domain/${domain}/free`,
    {
      headers: {
        'x-api-key': CONFIG.TENANT.xApiKey,
      },
    },
  ).then(response => response.json()),
  registerTenant: (data: {}) => fetch(
    `${CONFIG.TENANT.tenantService}/register`,
    {
      method: 'POST',
      headers: {
        'x-api-key': CONFIG.TENANT.xApiKey,
      },
      body: JSON.stringify(data),
    },
  ).then((res) => {
    if (res.ok) {
      return res.json()
    }
    throw res
  }),
}
