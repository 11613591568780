import * as React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button } from 'components'
import { validation } from 'utils'
import { Footer, CustomInput } from './my-profile-form.styles'

const required = validation.required('Field is required')

interface MyProfileFormProps {
  valid?: boolean
  handleSubmit?: any
  initialValues: any
  onSubmit: any
}

// @ts-ignore
@reduxForm({
  form: 'myProfile',
})
export class MyProfileForm extends React.PureComponent<MyProfileFormProps> {
  public render() {
    const { valid, handleSubmit } = this.props
    return (
      <form
        onSubmit={handleSubmit}
      >
        <Field
          name="firstName"
          label="First name"
          component={CustomInput as any}
          validate={[required]}
          type="text"
        />
        <Field
          name="lastName"
          label="Last name"
          component={CustomInput as any}
          validate={[required]}
          type="text"
        />
        <Field
          name="email"
          label="Email Address"
          component={CustomInput as any}
          disabled={true}
          type="text"
        />
        <Footer>
          <Button
            disabled={!valid}
            color="primary"
          >
            SAVE
          </Button>
        </Footer>
      </form>
    )
  }
}
