import { CONFIG } from '../../config'

export const RegistrationApi = {
  // Check if given email is already registered
  emailIsRegistered: email => fetch(
    `${CONFIG.REGISTRATION.userService}/email/${email}/free`,
  ).then(response => response.json()),

  // send validation email
  sendEmail: (email, affiliateId) => {
    let url = `${CONFIG.REGISTRATION.userService}/verify-email/${email}`
    if (affiliateId) {
      url += `?affiliateId=${affiliateId}`
    }
    return fetch(url, {
      method: 'POST',
    })
  },

  // get data based on verificationId
  getDataByVerificationId: (verificationId: string) => fetch(
    `${CONFIG.REGISTRATION.userService}/registration-data/${verificationId}`,
  )
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw response
    }),

  // update data based on verificationId
  updateDataByVerificationId: (email: string, data) => fetch(
    `${CONFIG.REGISTRATION.userService}/update-registration-data/${email}`,
    {
      method: 'PATCH',
      body: JSON.stringify(data)
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw response
    }),

  loginByVerification: (args) => fetch(
    `${CONFIG.REGISTRATION.userService}/verify-email/authenticate`,
    {
      method: 'POST',
      body: JSON.stringify(args)
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      throw res
    }),

  // send user update with ticket
  register: ({ firstName, ticketId, lastName, password }) => fetch(
    `${CONFIG.REGISTRATION.userService}/users`,
    {
      method: 'POST',
      headers: {
        'x-api-key': CONFIG.REGISTRATION.xApiKey,
      },
      body: JSON.stringify({ firstName, lastName, password, ticketId }),
    },
  ),
}
