import gql from 'graphql-tag'

export const updateBlacklist = gql`mutation updateBlacklist($records: [BlacklistRecordInput]!){
    updateBlacklist(records: $records) {
        serviceProvider
        networkDomain
    }
}`

export const deleteBlacklist = gql`mutation deleteBlacklist($records: [BlacklistRecordInput]!){
    deleteBlacklist(records: $records)
}`
