import * as React from 'react'
import { map } from 'lodash'

import { colorScale } from 'utils'
import { SearchResultWrapper, SearchResultItem, Score } from './search-result.styles'

export const SearchResult = ({ isOpen, result, navigateToCompany, navigateToSearch }) => {
  return (
    <SearchResultWrapper isOpen={isOpen}>
      {map(result, (item) => (
        <SearchResultItem
          key={item.id}
          onClick={navigateToCompany.bind(null, item.id)}
        >
          {item.name} <Score color={colorScale(item.score)}>{item.score}</Score>
        </SearchResultItem>
      ))}
      <SearchResultItem
        onClick={navigateToSearch}
      >
        Show full search result
      </SearchResultItem>
    </SearchResultWrapper>
  )
}
