import * as React from 'react'
import { IntlProvider } from 'react-intl'
import { get } from 'lodash'
import { Provider } from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import { ApolloProvider } from 'react-apollo'
import { ConnectedRouter } from 'react-router-redux'
import AWSAppSyncClient from 'aws-appsync'
import { Rehydrated as BaseRehydrated } from 'aws-appsync-react'
import * as Raven from 'raven-js'
import { defaultDataIdFromObject } from 'apollo-cache-inmemory'

import { removeUserToken, receiveUserToken } from 'actions'
import messages from '../../i18n/messages'
import { getStore, load } from '../../store'
import { CONFIG } from '../../config'
import { RootContainer } from '../root'
import { auth as authService } from '../../services/auth'
import { DefaultErrorContainer } from '../errors'

const Rehydrated = BaseRehydrated as any

const history = createHistory()
const store = getStore(history)
load(store)

const locale = get(navigator, 'languages[0]') ||
  navigator.language ||
  (navigator as any).userLanguage ||
  'en-US'

export class Application extends React.Component {
  public state = {
    hasError: false,
    error: null,
  }

  public componentDidCatch(error) {
    this.setState({ hasError: true, error })
    Raven.captureException(error)
  }

  public getApolloClient = () => {
    const getJwtToken = () => {
      const { id_token, expires_at } = store.getState().auth.tokenData
      const isAuthenticated = authService.isAuthenticated(expires_at)

      if (!isAuthenticated) {
        return authService
          .checkSession()
          .then(({ accessToken, idToken, expiresIn }) => {
            store.dispatch(receiveUserToken({
              access_token: accessToken,
              expires_at: Date.now() + expiresIn * 1000,
              id_token: idToken
            }))
            return idToken
          })
          .catch((err) => {
            console.log(err)
            store.dispatch(removeUserToken())
          })
      }

      return id_token || null
    }

    return new AWSAppSyncClient({
      url: CONFIG.APPSYNC.graphqlEndpoint,
      region: CONFIG.APPSYNC.region,
      auth: {
        type: CONFIG.APPSYNC.authenticationType,
        jwtToken: getJwtToken,
      },
      disableOffline: true,
      cacheOptions: {
        dataIdFromObject: (object) => {
          switch (object.__typename) {
            case 'AssignedUserCompany': {
              return (object as any).companyId
            }
            case 'User': {
              return (object as any).email
            }
            default: return defaultDataIdFromObject(object)
          }
        },
      },
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <DefaultErrorContainer error={this.state.error} />
      )
    }

    return (
      <ApolloProvider client={this.getApolloClient() as any}>
        <Rehydrated>
          <Provider store={store}>
            <IntlProvider locale={locale} messages={messages[locale]}>
              <ConnectedRouter history={history}>
                <RootContainer history={history} />
              </ConnectedRouter>
            </IntlProvider>
          </Provider>
        </Rehydrated>
      </ApolloProvider>
    )
  }
}
