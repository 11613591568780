import * as React from 'react'
import { first, capitalize, each, reduce } from 'lodash'

import { Wrapper } from './no-avatar.styles'

interface NoAvatarProps {
  firstName: string
  lastName: string
  size?: number
}

export class NoAvatar extends React.PureComponent<NoAvatarProps> {
  public render() {
    const { size = 40, firstName, lastName } = this.props
    const firstInitial = capitalize(first(firstName))
    const secondInitial = capitalize(first(lastName))
    return (
      <Wrapper
        size={size}
      >
        {firstInitial}{secondInitial}
      </Wrapper>
    )
  }
}
