import * as React from 'react'
import * as moment from 'moment'
import { get } from 'lodash'

import { Flag, Show } from 'components'
import { getDeviceIcon, getDeviceText, isVisitLive } from 'utils'
import {
  ItemRow,
  FirstColumn,
  SecondColumn,
  DateColumn,
  Date,
  OnSiteNow,
  VisitButton,
  Name,
  RightAlignedRow,
  ShowVisitColumn,
  Row,
  CityCountryRow,
  DeviceRowIcon,
  NameRow,
  Description,
  VerticalSpacer,
  PageViewsValue,
  DurationRow,
} from './web-visit.styles'

export const WebVisit = ({ session, showVisit }) => {
  const sessionLocalDateTime = moment.utc(session.dateTime).local();

  const isLive = isVisitLive(sessionLocalDateTime)

  return (
    <ItemRow key={session.dateTime}>
      <FirstColumn>
        <DateColumn>
          <Date>
            <Show if={isLive}>
              <OnSiteNow>
                  ON SITE NOW
              </OnSiteNow>
            </Show>
            <Show if={!isLive}>
              <div>
                {moment(sessionLocalDateTime)
                  .calendar(null, {
                    sameDay: '[Today] h:mmA',
                    lastDay: '[Yesterday] h:mmA',
                    nextWeek: 'dddd h:mmA',
                    lastWeek: '[Last] dddd h:mmA',
                    sameElse: 'DD/MM/YYYY h:mmA',
                  })}
              </div>
            </Show>
          </Date>
        </DateColumn>
        <NameRow>
          <Name>{get(session, 'visitor.fullName') || session.userType}</Name>
        </NameRow>
        <Row>
          <DeviceRowIcon size={16} icon={getDeviceIcon(session.deviceCategory, session.operatingSystem)} />
          <div>
            {getDeviceText(session.deviceCategory, session.operatingSystem)}
          </div>
        </Row>
        <CityCountryRow>
          <Show if={session.countryCode}>
            <Flag code={session.countryCode} />
          </Show>
          <Show if={session.countryCode !== 'ZZ'}>
            <Show if={session.city}>
              {session.city}
            </Show>
            <Show if={session.city && session.country}>
              {', '}
            </Show>
            <Show if={session.country}>
              {session.country}
            </Show>
          </Show>
          <Show if={session.countryCode === 'ZZ'}>
            Unknown Location
          </Show>
        </CityCountryRow>
      </FirstColumn>
      <SecondColumn>
        <RightAlignedRow>
          <Description>Page views </Description>
          <PageViewsValue>{session.sessionPages.length}</PageViewsValue>
        </RightAlignedRow>
        <DurationRow>
          <Description>Duration:</Description>
          {moment.utc(session.duration * 1000)
            .format('m [min] s [sec]')}
        </DurationRow>
        <VerticalSpacer />
        <ShowVisitColumn>
          <VisitButton onClick={() => showVisit(session)}>Show visit</VisitButton>
        </ShowVisitColumn>
      </SecondColumn>
    </ItemRow>
  )
}
