import { UPDATE_FEED_STATE } from '../actions'

const defaultState = {
}

export const marketingEventDetailsFeed = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_FEED_STATE:
      return {
        ...state,
        [action.payload.key]: action.payload.state,
      }

    default:
      return state
  }
}
