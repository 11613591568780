import * as React from 'react'
import { connect } from 'react-redux'
import { FieldArray, arrayPush } from 'redux-form'

import { Button, Show } from 'components'
import { renderAndClauses } from '../and-clauses'
import { Wrapper, ControlButton, OrClauseWrapper } from './or-clauses.styles'

export const renderOrClauses = connect()(({ fields, dispatch }) => {
  return (
    <Wrapper>
      {fields.map((orClause, index) => (
        <OrClauseWrapper key={orClause}>
          <Show if={index === 0}>
            List companies matching...
          </Show>
          <FieldArray
            name={`${orClause}.andClauses`}
            component={renderAndClauses}
          />
          <ControlButton
            onClick={() => dispatch(arrayPush('editFilter', `orClauses.${index}.andClauses`, {}))}
          >
            + AND
          </ControlButton>
        </OrClauseWrapper>
      ))}
    </Wrapper>
  )
});
