import styled from 'styled-components'
import { PageTitle as BasePageTitle } from 'components'

export const Spacer = styled.div`
  flex: 1;
`

export const PageTitle = styled(BasePageTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
