import { get } from 'lodash'

export interface IntegrationProps {
  id: string
  name: string
  status: string
  category: string
  logo: string
  url: string
}

export class IntegrationModel {
  public readonly id: string
  public readonly name: string
  public readonly category: string
  public readonly logo: string
  public readonly url: string
  public status: string

  constructor(props: IntegrationProps) {
    this.id = props.id
    this.name = props.name
    this.status = props.status
    this.category = props.category
    this.logo = props.logo
    this.url = props.url
  }

  public updateStatus(data: any): boolean {
    switch (this.id) {
      case 'ga': {
        const summary = get(data, 'getGoogleAnalyticsSummary')
        this.status = summary && summary.length ? 'active' : 'inactive'
        break;
      }
      case 'superoffice': {
        const connected = get(data, 'getSuperOfficeIntegrationStatus.connected')
        this.status = connected ? 'active' : 'inactive'
        break;
      }
      case 'emarketeer': {
        const connected = get(data, 'getEmarketeerIntegrationStatus.connected')
        this.status = connected ? 'active' : 'inactive'
        break;
      }
      case 'slack': {
        const webhook = get(data, 'getSlackChannelConfigurations[0].incomingWebhook.channel')
        this.status = webhook ? 'active' : 'inactive'
        break;
      }
    }
    return this.isActive()
  }

  public isActive(): boolean {
    return this.status === 'active'
  }
}
