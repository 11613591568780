import styled from 'styled-components'

export const Pre = styled.pre`
  margin: 0 auto 16px;
  padding: 16px 24px;
  background: #eaeaea;
  border-radius: 5px;
`
export const ButtonWrapper = styled.div`
  margin-bottom: 12px;
`

