import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  height: 60px;
  background: ${({ theme }) => theme.TOP_MENU_COLOR};
  z-index: 15;
  position: fixed;
  width: 100%;
  top: 0;
`

export const MobileWrapper = styled(Wrapper)`
  justify-content: space-between;
  align-items: center;
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;
`
export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`
export const LogoText = styled.span`
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  color: ${({ theme }) => theme.TOP_MENU_FONT_COLOR};
  margin-left: 12px;
  text-transform: uppercase;
`
export const RightSide = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`

export const Help = styled.div`
  height: 36px;
  width: 36px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  transition: background-color .3s;
  cursor: pointer;

  &::after {
    content: '?';
    font-size: 22px;
    color: ${({ theme }) => theme.TOP_MENU_FONT_COLOR};
  }

  &:hover  {
    background: ${({ theme }) => theme.TOP_MENU_ITEM_HOVER}};
  }
`

export const BurgerWrapper = styled.div`
  margin-right: auto;
  padding: 0 18px;
`

export const BurgerPart = styled.div`
  height: 3px;
  width: 20px;
  margin: 4px 20px 4px 0;
  border-radius: 2px;
  background-color: white;
`
