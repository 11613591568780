import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { graphql } from 'react-apollo'

import { SidebarLayout } from 'components'
import { Navigation, NavigationItem, Wrapper } from './settings-container.styles'
import {
  CompanySettings,
  UsersSettings,
  GoogleAnalyticsSettings,
  EmarketeerIntegrationSettings,
  Subscription,
  Invoices,
  Billing,
  AddUsers,
  PurchasePlan,
  SuperOfficeIntegration,
  Integrations,
  SlackIntegration,
  TrackingScriptSetup,
} from './components'
import { userQuery } from '../../gql/user'
import { User } from '../../models/user.model'

interface SettingsProps {
  data: any
}

// @ts-ignore
@graphql(userQuery)
export class Settings extends React.PureComponent<SettingsProps> {
  public render() {
    const user = new User(this.props.data.getUser)
    if (!user.isAdmin() && !user.isOwner()) {
      return <Redirect to="/" />
    }
    return (
      <SidebarLayout
        noPadding={true}
      >
        <Wrapper>
          <Navigation>
            <NavigationItem to="/settings/company">
              Company Information
            </NavigationItem>
            <NavigationItem to="/settings/integrations">
              Integrations
            </NavigationItem>
            <NavigationItem to="/settings/users">
              Users
            </NavigationItem>
            <NavigationItem to="/settings/subscription">
              Subscription & Billing
            </NavigationItem>
            <NavigationItem to="/settings/invoices">
              Invoices
            </NavigationItem>
          </Navigation>
          <Route
            exact={true}
            path="/settings"
            render={() => <Redirect to="/settings/company" />}
          />
          <Route
            exact={true}
            path="/settings/company"
            component={CompanySettings}
          />
          <Route
            exact={true}
            path="/settings/users"
            component={UsersSettings}
          />
          <Route
            exact={true}
            path="/settings/integrations"
            component={Integrations}
          />
          <Route
            exact={true}
            path="/settings/invitations"
            component={UsersSettings}
          />
          <Route
            exact={true}
            path="/settings/google-analytics"
            component={GoogleAnalyticsSettings}
          />
          <Route
            exact={true}
            path="/settings/emarketeer-integration"
            component={EmarketeerIntegrationSettings}
          />
          <Route
            exact={true}
            path="/settings/superoffice-integration"
            component={SuperOfficeIntegration}
          />
          <Route
            exact={true}
            path="/settings/slack-integration"
            component={SlackIntegration}
          />
          <Route
            exact={true}
            path="/settings/subscription"
            component={Subscription}
          />
          <Route
            exact={true}
            path="/settings/invoices"
            component={Invoices}
          />
          <Route
            path="/settings/billing"
            component={Billing}
          />
          <Route
            path="/settings/add-users"
            component={AddUsers}
          />
          <Route
            path="/settings/purchase-plan"
            component={PurchasePlan}
          />
          <Route
            path="/settings/tracking-script"
            component={TrackingScriptSetup}
          />
        </Wrapper>
      </SidebarLayout>
    )
  }
}
