import * as React from 'react'
import { IntegrationProps } from '../../models'
import { Wrapper, Title, Logo } from './active-integration.styles'

interface ActiveIntegrationProps extends IntegrationProps {
  onClick: (url: string) => void
}

export class ActiveIntegration extends React.PureComponent<ActiveIntegrationProps> {
  public render(): React.ReactNode {
    const { name, category, logo } = this.props
    return (
      <Wrapper onClick={this.onClick}>
        <Logo
          logo={logo}
        />
        <Title>
          <h3>{name}</h3>
          <p>{category}</p>
        </Title>
      </Wrapper>
    )
  }

  private onClick = () => {
    this.props.onClick(this.props.url)
  }
}
