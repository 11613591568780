import * as React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { filter, find, get, map, times } from 'lodash'

import { toggleInviteUserModal } from 'actions'
import { Title, Wrapper } from './users-settings.styles'
import { UsersList } from './users-list'
import { UserSettingsSwitcher } from './user-settings-switcher.component'
import { PendingInvitationsList } from './pending-invitations-list'
import { InviteUserModal } from './components/invite-user-modal'
import { usersSettingsSelector } from './users-settings.selector'

interface UserSettingsProps {
  toggleInviteUserModal?: any
  inviteUserModal?: any
}

// @ts-ignore
@connect(usersSettingsSelector, { toggleInviteUserModal })
export class UsersSettings extends React.Component<UserSettingsProps> {
  public render() {

    return (
      <Wrapper>
        <Title>
          Users
        </Title>
        <UserSettingsSwitcher/>
        <Route
          path="/settings/users"
          component={UsersList}
        />
        <Route
          path="/settings/invitations"
          component={PendingInvitationsList}
        />
        <InviteUserModal
          email={this.props.inviteUserModal.email}
          toggle={() => this.props.toggleInviteUserModal(null)}
          isOpen={this.props.inviteUserModal.visible}
          onDone={() => this.props.toggleInviteUserModal(null)}
        />
      </Wrapper>
    )
  }
}
