import styled from 'styled-components'

interface ItemWrapperProps {
  isHighlighted: boolean
}

export const ItemWrapper = styled.div<ItemWrapperProps>`
  background: ${props => props.isHighlighted ? 'lightgray' : 'white'}
  padding: 4px 8px;
`

export const Input = styled.input`
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    box-sizing: border-box;
    border: 1px solid #BFBFBF;
    border-radius: 5px;
`
export const menuStyle = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  zIndex: 1,
  maxHeight: 300,
}

export const wrapperStyle = {
  display: 'block',
}
