import * as React from 'react'
import { Wrapper, Hr, Input, Label, Button, Step } from '../../registration.styles'
import { Small, PasswordStatus } from './name-input.styles'
import { passwordWithResponse } from 'utils'

interface NameInputProps {
  onDone: (firstName: string, lastName: string, password: string) => void
}

export class NameInput extends React.PureComponent<NameInputProps> {
  public state = {
    firstName: '',
    lastName: '',
    passwordIsValid: false,
    passwordStatus: {} as any,
    password: ''
  }
  public onChange = (name, value) => {
     this.setState({
       [name]: value
     })
  }
  public onPasswordChange = (password) => {
    const { valid, ...passwordStatus } = passwordWithResponse(password)
    this.setState({
      passwordIsValid: valid,
      passwordStatus,
      password,
    })
  }
  public render(): React.ReactNode {
    const { firstName, lastName, passwordStatus, password, passwordIsValid } = this.state
    return (
      <Wrapper>
        <Step>Step 2 of 3</Step>
        <h1>Name and Password</h1>
        <p>Please enter your name that will be displayed for you and your colleagues in Leadexplorer</p>
        <Hr />
        <Label>First name</Label>
        <Input
          placeholder="First name"
          onChange={({ target }) => this.onChange('firstName', target.value)}
        />
        <Label>Last name</Label>
        <Input
          placeholder="Last name"
          onChange={({ target }) => this.onChange('lastName', target.value)}
        />
        <Label>Password</Label>
        <Input
          type="password"
          placeholder="Password"
          onChange={({ target }) => this.onPasswordChange(target.value)}
        />
        <Small>
          Passwords must be at least
          <PasswordStatus valid={passwordStatus.length}> 8 characters long </PasswordStatus>
          including a
          <PasswordStatus valid={passwordStatus.hasLowerCase}> lower-case letter</PasswordStatus>
          , an
          <PasswordStatus valid={passwordStatus.hasUpperCase}> upper-case letter </PasswordStatus>
          and a
          <PasswordStatus valid={passwordStatus.hasNumber}> number </PasswordStatus>
          .
        </Small>
        <Button
          color="primary"
          onClick={() => this.props.onDone(firstName, lastName, password)}
          disabled={!(firstName && lastName && passwordIsValid)}
        >
          CONTINUE TO COMPANY AND URL
        </Button>
      </Wrapper>
    )
  }
}
