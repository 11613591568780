import * as React from 'react'
import { get } from 'lodash'

import { Form, Input, SearchIcon, SubmitButton } from './search-form.styles'
import { searchByName } from '../../../../gql/company'

interface SearchFormProps {
  value: string
  client: any
  onResult: any
  onError: any
  onLoading: any
  onValueChange: any
}

export class SearchForm extends React.PureComponent<SearchFormProps> {
  public componentDidMount(): void {
    const { value } = this.props
    if (value) {
      this.searchForCompanies(value)
    }
  }

  public searchForCompanies = (value) => {
    this.props.onLoading()
    return this.props.client.query({
      query: searchByName,
      variables: { name: value }
    })
    .then((data) => {
      this.props.onResult(get(data, 'data.getCompaniesByName', []))
    })
    .catch(() => {
      this.props.onError()
    })
  }

  public render(): React.ReactNode {
    const { onValueChange, value } = this.props
    return (
      <Form onSubmit={(event) => {
        event.preventDefault()
        this.searchForCompanies(value)
      }}>
        <Input
          name="search"
          value={value}
          autoComplete="off"
          onChange={onValueChange}
        />
        <SubmitButton>
          <SearchIcon />
        </SubmitButton>
      </Form>
    )
  }
}
