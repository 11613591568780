import styled from 'styled-components'

interface ActiveProps {
  active: boolean
}

export const Wrapper = styled.div<ActiveProps>`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid ${({ active }) => active ? '#7aab59' : '#e0e1df'};
  box-sizing: border-box;
  border-radius: 5px;
  width: 137px;
  height: 95px;
  margin-right: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: border-color 300ms;
`

interface InnerProps {
  logo: string
}

export const Inner = styled.div<InnerProps>`
  margin: 12px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-image: url(${({ logo }) => logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

export const Header = styled.div<ActiveProps>`
  padding: 6px 8px;
  font-weight: 500;
  line-height: normal;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background-color: ${({ active }) => active ? '#7aab59' : '#e0e1df'};
  transition: background-color 300ms;
`
