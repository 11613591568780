import styled from 'styled-components'

import { Button, Icon, LoadingCircle } from '../../../../components'

export const EmployeeRows = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 30px;
`
export const EmployeeRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  border-bottom: 1px #ECF1F5 solid;
  :last-child {
    border-bottom: none;
  }
`

interface PersonPhotoProps {
  image: string
}

export const PersonPhoto = styled.div<PersonPhotoProps>`
  min-width: 56px;
  min-height: 56px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: ${({ image }) => `url("${image}") center no-repeat`};
  background-size: cover;
  margin: 0 10px 0 0;
`
export const PersonIcon = styled(Icon)`
  margin: 0;
  padding: 0 10px 0 0;
`
export const Description = styled.div`
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const Name = styled.div`
  margin-bottom: 5px;
`
export const Title = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`
export const Source = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
`
export const OpenSource = styled(Button)`
  font-size: 12px;
  padding: 6px 12px;
  height: auto;
  min-height: 24px;
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const Footer = styled.div`
  background: #FAFAFA;
  font-weight: 300;
  font-size: 14px;
  padding: 14px 0;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
  cursor: pointer;
`
export const Triangle = styled(Icon)`
  margin: 10px;
`
export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 50px;
  flex: 1;
`
export const LoadingAvatar = styled(LoadingCircle)`
  margin: 0 10px 0 0;
`
