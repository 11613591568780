import * as React from 'react'
import { chain, map } from 'lodash'
import { Tooltip } from 'react-tippy'
import { Icon, LoadingBlock, LoadingCircle, Show } from '../../../../components'

import { Wrapper, PageTitle, Row, Link, LoadingIcon, PageTooltip, RowInfo, RowTitle, RowValue, TooltipText } from './interests.styles'

export const Interests = ({ company, analytics, loading }) => {
  const topPages = analytics.topPagesByTime || []

  const topKeywords = chain(analytics.topKeywords)
    .map('name')
    .join(', ')
    .value()

  return (
    <Wrapper>
      <Show if={loading}>
        <Row>
          <LoadingIcon size={25} />
          <RowInfo>
            <RowTitle>
              <LoadingBlock width="60%" />
            </RowTitle>
            <RowValue>
              <LoadingBlock width="60%" />
            </RowValue>
          </RowInfo>
        </Row>
      </Show>
      <Show if={topKeywords}>
        <Row>
          <Icon icon="search" size={25} color="#61869E" />
          <RowInfo>
            <RowTitle>
              {company.name} is searching for:
            </RowTitle>
            <RowValue>
              {topKeywords}
            </RowValue>
          </RowInfo>
        </Row>
      </Show>
      <Show if={topPages.length}>
        <Row>
          <Icon icon="eye" size={25} color="#EFB17C" />
          <RowInfo>
            <RowTitle>
              {company.name} spent most time on web pages:
            </RowTitle>
            <RowValue>
              {map(topPages, (page, i) => (
                <PageTitle key={i}>
                  <Link href={page.fullUrl} target="_blank">
                    <PageTooltip
                      html={(<TooltipText>{page.name}</TooltipText>)}
                      position="top"
                    >
                      {page.name}
                    </PageTooltip>
                  </Link>
                </PageTitle>
              ))}
            </RowValue>
          </RowInfo>
        </Row>
      </Show>
    </Wrapper>
  )
}
