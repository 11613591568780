import styled from 'styled-components'

import { Dropdown } from '../../../../components'

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-right: 32px;
  
  ${Dropdown.MenuItem} {
    height: 40px; 
  }
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  
  &:before {
    content: '';
    width: 0; 
    height: 0; 
    margin-right: 8px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    
    border-top: 4px solid #314550;
  }
`

export const Contact = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  
  span {
    margin-right: auto;
  }
`

export const AvatarWrapper = styled.div`
  margin-left: 8px;
`
