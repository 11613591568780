import styled from 'styled-components'
import { times, map, find } from 'lodash'

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 36px;
  margin: 25px 0 0 0;
  padding: 20px;
  border-bottom: 1px solid #CBCBCB;
  @media (max-width: 500px) {
    padding: 20px 0;
  };
`
export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
`
