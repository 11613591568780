import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../../'
import { getSourceDescription } from 'utils'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`
const Text = styled.div`
  margin-top: 2px;
  font-size: 12px;
`


export const Source = ({ sourceMedium }) => {
  if (!sourceMedium) {
    return null
  }

  const sourceDescription = getSourceDescription(sourceMedium)

  return (
    <Wrapper>
      <Icon size={14} icon={sourceDescription.icon} />
      <Text>{sourceDescription.text}</Text>
    </Wrapper>
  )
}
