import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2px;
  background: #fafafa;
`

export const NameWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 14px;
`

export const MatchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 12px;
  margin-right: 20px;
  padding: 0 20px;
  cursor: pointer;
  background: ${({ theme }) => theme.STANDARD_BUTTON_COLOR}
  transition: background-color 0.3s;
  color: white;
  font-weight: 400;
  font-size: 12px;
  
  &:hover {
    background: #7ea7c0;
  }
`
