import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button } from 'components'

export const OrClauseWrapper = styled.div`
  border-top: 1px solid #D4D4D4;
  padding: 40px 0;

  &:nth-child(1) {
    border-top: none;
  }
`
export const ControlButton = styled(Button).attrs({
  type: 'button',
})`
  padding: 10px 10px;
  width: 68px;
  margin-right: 9px;
  font-weight: 500;
  font-size: 14px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
