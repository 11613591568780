import styled from 'styled-components'
import { RoundedInput, Button } from 'components'

export const Wrapper = styled.form`
  color: #314550;
  background: white;
  padding: 37px 37px 0 37px;

  .ant-select {
    min-width: 110px;
  }

  .ant-select-selection--single {
    height: 36px;
  }

  .ant-select-selection__rendered {
    line-height: 34px;
  }

  .ant-select-selection {
    border-color: #314550;
  }

  .ant-select-disabled  {
    .ant-select-selection:hover,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: #314550;
    }
  }

  .ant-select-selection-selected-value {
    color: #314550;
  }
`
export const FilterTitleInput = styled(RoundedInput)`
  width: 100%;
  font-size: 18px;
  padding: 13px 26px;
  display: flex;
`
export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 34px;
  min-height: 42px;
`
export const CancelButton = styled(Button).attrs({
  type: 'button'
})`
  background: none;
  padding: .78571429em 32px;
  font-size: 14px;
  font-weight: 500;
`
export const SaveButton = styled(Button)`
  width: 140px;
  font-size: 14px;
  font-weight: 500;
`
