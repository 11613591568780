import styled from 'styled-components'

import { Button } from 'components'

export const Title = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  margin-bottom: 5px;
`


export const Wrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`
export const Label = styled.div`
  margin: 15px 0 10px 0;
  font-size: 14px;
  font-weight: 300;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`
export const Controls = styled.div`
  margin-top: 10px;
  border-top: 1px #E3E3E3 solid;
  padding-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
export const AddButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  background: #61869E;
  color: white;
  margin: 0;
`
export const CancelButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: #A5A6A6;
  background: #EAEAEA;
  margin: 0 15px 0 0;
`
