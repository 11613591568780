const required = (errorText = 'Required') => value => (value ? null : errorText)

const email = (errorText = 'Invalid email address') => value =>
  !value || (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    ? errorText
    : null

const password = (errorText = 'Password is too weak') => value =>
  !value || (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/.test(value))
    ? errorText
    : null


const domain = (errorText = 'Domain name can contain only letters, digits or hyphen') => value =>
  !value || (value && !/^[a-zA-Z\d-]{1,100}$/.test(value))
    ? errorText
    : null

const isEmail = value => !email(value)

export interface PasswordState {
  length: boolean
  hasUpperCase: boolean
  hasLowerCase: boolean
  hasNumber: boolean
  // hasSpecialCharacter: boolean
  valid: boolean
}

export const passwordWithResponse = (value: string = ''): PasswordState => {
  const length = value.length >= 8
  const hasUpperCase = /[A-Z]+/.test(value)
  const hasLowerCase = /[a-z]+/.test(value)
  const hasNumber = /\d/.test(value)
  // const hasSpecialCharacter = /[!@#$%^&*]+/.test(value)

  return {
    length,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    // hasSpecialCharacter,
    valid: length && hasLowerCase && hasUpperCase && hasNumber // && hasSpecialCharacter
  }
}

export const validation = {
  required,
  email,
  password,
  isEmail,
  domain,
}
