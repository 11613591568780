import styled from 'styled-components'
import { times, find } from 'lodash'

import { Card, ProgressiveImage, Button } from '../../../../components'

export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  margin: 45px 0 26px 0;
`

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
`

export const SettingsFormCard = styled(Card)`
  padding: 30px;
  height: 100%;
  flex: 1 1 auto;
  margin-bottom: 60px;
`

export const CardTitle = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
  };
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 800px;
`

export const RightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0 0 150px;
  align-items: flex-start;
  @media (max-width: 1200px) {
    padding: 20px 0;
  };
`

export const CompanyLogo = styled(ProgressiveImage)`
  width: 140px;
  height: 140px;
  margin-bottom: 15px;
`

export const ChangeLogoButton = styled(Button)`
  width: 140px;
  font-size: 12px;
  color: #314550;
  font-weight: 300;
  padding: 5px 18px;
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
