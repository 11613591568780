import gql from 'graphql-tag'

export const tenantCompanyInformation = gql`{
    getTenantCompanyInformation {
        name
        address1
        address2
        zipCode
        city
        countryCode
        industry
        logo
    }
}`

export const searchByName = gql`
  query search($name: String!) {
    getCompaniesByName(name: $name) {
      id
      name
      logo
      score
      locations {
        country
        city
      }
    }
  }
`

export const resolveEmail = gql`
  query resolverEmail($email: String!) {
      getCompanyByEmail(email: $email) {
        company {
            id
            domain
            name
            logo
            website
            founded
            bio
            employeesCount
            profiles {
                twitter {
                    url
                    service
                }
                facebook {
                    url
                    service
                }
                linkedincompany {
                    url
                    service
                }
                youtube {
                    url
                    service
                }
            }
            industries {
                name
            }
            locations {
                addressLine1
                addressLine2
                city
                country
                postalCode
            }
            phone
        }
      }
  }
`
