import styled from 'styled-components'
import { Button, Icon } from '../../../../../../components'

export const VisitButton = styled(Button)`
  font-size: 12px;
  padding: 6px 20px;
  height: 24px;
  margin: 0;
  font-weight: 400;
`
export const Description = styled.div`
  display: block;
  margin-right: 3px;
`
export const VerticalSpacer = styled.div`
  display: flex;
  flex: 1;
`
export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 0;
  box-sizing: border-box;
  transition: background-color .3s;
  outline: none;
  background-color: rgba(49, 69, 80, .03);
  padding: 20px;
  font-size: 12px;
`
export const TableRoot = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`
export const TableItemRow = styled.div`
  margin: 2px 0;
  box-sizing: border-box;
  transition: background-color .3s;
  outline: none;
  background-color: rgba(49, 69, 80, .03);
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`
export const CenteredTableRow = styled(TableItemRow)`
  justify-content: center;
  padding: 0 20px;
`
export const LeftAlignedTableRow = styled(TableItemRow)`
  justify-content: flex-start;
  padding: 0 20px;
`
export const FirstColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const FirstTableColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`
export const SecondTableColumn = styled.div`
  display: flex;
  flex-direction: row;
`
export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const ItemCol = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding: 5px 8px;
  font-size: 12px;
`
export const TableColumn = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  font-size: 14px;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
`
export const TableDateColumn = styled(TableColumn)`
  line-height: 1.4;
  flex: 0 0 auto;
  white-space: nowrap;
`
export const ShrinkedTableColumn = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  flex: 0.1 0 auto;
  flex-direction: column;
  min-width: 120px;
`
export const CenteredTableColumn = styled(ShrinkedTableColumn)`
  align-items: center;
  min-width: 120px;
  flex: 0.1 0 auto;
`
export const LeftAlignedTableColumn = styled(ShrinkedTableColumn)`
  min-width: 156px;
  flex: 0.1 0 auto;
  align-items: flex-start;
`
export const WideTableColumn = styled(TableColumn)`
  min-width: 180px;
`
export const DurationTableColumn = styled(LeftAlignedTableColumn)`
  min-width: 90px;
`
export const ShowVisitTableColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const TableHeader = styled.div`
  display: flex;
  position: absolute;
  top: -25px;
  font-size: 14px;
  pointer-events: none;
  padding: 0 20px;
  width: 100%;
  left: 0;
`
export const CenteredTableHader = styled.div`
  display: flex;
  position: absolute;
  top: -25px;
  font-size: 14px;
  pointer-events: none;
  padding: 0 20px;
`
export const DateColumn = styled(ItemCol)`
  line-height: 1.4;
  flex: 0;
  white-space: nowrap;
  width: 100%;
  padding: 2px 0;
  min-height: 20px;
`
export const Row = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  flex: 0.2;
  padding: 2px 0;
  min-height: 20px;
  font-size: 12px;
`
export const NameRow = styled(Row)`
  font-size: 18px;
  font-weight: 500;
  padding: 2px 0 6px 0;
`
export const CityCountryRow = styled(Row)`
  order: 4;
`
export const RightAlignedRow = styled(Row)`
  min-width: 80px;
  flex: 0.1;
  justify-content: flex-end;
`
export const DurationRow = styled(RightAlignedRow)`
  min-width: 90px;
`
export const ShowVisitColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px 0;
  min-height: 20px;
`
export const Date = styled.div`
  display: flex;
  flex-direction: row;
`
export const Header = styled.div`
  position: absolute;
  top: -25px;
  font-size: 14px;
  pointer-events: none;
`
export const OnSiteNow = styled.div`
  color: #e14c54;
  font-weight: 500;
  font-size: 12px;
`
export const Name = styled.div`
  font-weight: 500;
`
export const DeviceIcon = styled(Icon)`
  margin: 0 10px 0 0;
`
export const DeviceRowIcon = styled(Icon)`
  margin: 0 8px 0 0;
`
export const PageViewsValue = styled.div`
 font-size: 14px;
 font-weight: 500;
`
