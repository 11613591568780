import styled from 'styled-components'

import { Icon, LoadingCircle, Button } from '../../../../../../components'

export const Events = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 35px 20px 20px 50px;
  position: relative;
  @media (max-width: 800px) {
    padding: 35px 20px 20px 20px;
  }
`
export const Line = styled.div`
  width: 5px;
  background: #ECF1F5;
  left: 69px;
  top: 0;
  position:absolute;
  height: calc(100% - 20px);
  @media (max-width: 800px) {
    left: 39px;
  }
`
export const Event = styled.div`
  z-index: 5;
  padding: 0 0 35px 0;
  display: flex;
 
  flex-direction: column;
  :last-child {
    padding: 0;
  }
`
export const Details = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0 0 69px;
`
export const TopText = styled.div`
  padding: 0 0 5px 22px;
  flex: 1;
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  background: url('/images/components/marketing-timeline/line.svg') left 42px bottom 0 no-repeat;
  align-items: flex-end;
`
export const Date = styled.div`
  font-size: 10px;
  padding: 2px 0;
`
export const Users = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
`

interface SinglePersonPhotoProps {
  image: string
}

export const SinglePersonPhoto = styled.div<SinglePersonPhotoProps>`
  min-width: 54px;
  min-height: 54px;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background: ${({ image }) => `url("${image}") center no-repeat`};
  background-size: cover;
  margin: 0 10px 0 0;
`

interface FirstPerosnPhotoProps {
  image: string
}

export const FirstPersonPhoto = styled.div<FirstPerosnPhotoProps>`
  min-width: 38px;
  min-height: 38px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  border: 2px solid white;
  background: ${({ image }) => `url("${image}") center no-repeat`};
  background-size: cover;
  margin: 0 10px 0 0;
  z-index: 1;
`
export const SecondPersonPhoto = styled(FirstPersonPhoto)`
  margin: 0 10px 0 -40px;
  z-index: 0;
`
export const FirstPersonIcon = styled(Icon)`
  border-radius: 50%;
  z-index: 1;
`
export const SeconPersonIcon = styled(FirstPersonIcon)`
  z-index: 0;
  margin: 0 10px 0 -40px;
`

export const LoadingAvatar = styled(LoadingCircle)`
  margin: 0 10px 0 0;
`
export const LoadingIcon = styled(LoadingCircle)`
  margin: 0 13px 0 0;
`
export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  font-size: 12px;
  flex: 1;
`
export const PersonName = styled.span`
  font-weight: 500;
`
export const EventDescription = styled.div`

`
export const LowerSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: space-between;
`
export const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
`
export const EventEntityName = styled.div`
  font-weight: 500;
`
export const EventSource = styled.div`
  padding-top: 2px;
`
export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
`
export const ShowMoreButton = styled(Button)`
  white-space: nowrap;
  font-weight: 300;
  font-size: 9px;
  padding: 4px 10px;
  height: 20px;
  width: 80px;
  margin: 0;
  border-radius: 7px;
`
export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 50px;
  flex: 1;
`
