import styled from 'styled-components'

export const HeaderBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 7px 14px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin: 0 7px;
  }
`
