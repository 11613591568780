import styled from 'styled-components'
import { StickyContainer } from 'react-sticky'
import { Card, LoadingBlock, ThermometerRating, Row } from '../../components'

const InfoWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  max-width: 100vw;
  flex: 1;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`
const InfoContent = styled(StickyContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  width: 0;
  max-width: 100%;
  @media (min-width: 801px) {
    max-width: calc(100% - 320px);
  }
`
const CardsHolder = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`
const InfoCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: auto;
  min-width: 300px;
  margin: 7px;
  @media (min-width: 800px) {
    min-width: 330px;
  }
`
export const TwitterInfoCard = styled(InfoCard)`
  flex: 1;
`
export const TwitterInner = styled.div`
  padding: 0 20px;
`
const EmployeesCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 300px;
  flex: 1 1 auto;
  @media (min-width: 800px) {
    min-width: 400px;
  }
  margin: 7px;
`
const WebVisitsCard = styled(InfoCard)`
  width: 100%;
  flex: auto;
`
const Total = styled.div`
  margin-left: 10px;
  display: inline-block;
`
const WithinDateRange = styled.div`
  margin-left: 10px;
  display: inline-block;
  font-weight: 400;
  text-transform: none;
`
const DoubleCards = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 1200px) {
    width:100%;
  }
`
const CardContent = styled((Card as any).Content)`
  min-height: 184px;
`
const PlaceholderCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 50px;
`
const MapLoading = styled(LoadingBlock)`
  margin-bottom: 0;
  border-radius: 0;
`
const CompanyScore = styled(ThermometerRating)`
  position: absolute;
  right: 50px; 
  top: 175px;
  @media (max-width: 800px) {
    right: 10px; 
    top: 210px;
    svg {
      width: 25px;
      height: 53px;
    }
  }
`
const WebVisitsCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`
const DatePickerWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
`

export const MenuRow = styled(Row)`
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export {
  InfoWrapper,
  InfoContent,
  CardsHolder,
  InfoCard,
  WebVisitsCard,
  CardContent,
  DoubleCards,
  EmployeesCard,
  PlaceholderCardContent,
  MapLoading,
  CompanyScore,
  WebVisitsCardTitle,
  DatePickerWrapper,
  Total,
  WithinDateRange,
}
