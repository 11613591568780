import * as React from 'react'
import styled, { keyframes } from 'styled-components'

interface OverlayProps {
  background: string
  speed: number
  zIndex: number
}

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${props => props.background};
  color: ${props => props.color};
  transition: opacity ${props => props.speed}ms ease-out;
  display: flex;
  text-align: center;
  font-size: 1.2em;
  z-index: ${props => props.zIndex};
  &._loading-overlay-transition-appear,
  &._loading-overlay-transition-enter {
    opacity: 0.01;
  }
  &._loading-overlay-transition-appear._loading-overlay-transition-appear-active,
  &._loading-overlay-transition-enter._loading-overlay-transition-enter-active {
    opacity: 1;
    transition: opacity .5s ease-in;
  }
  &._loading-overlay-transition-leave {
    opacity: 1;
  }
  &._loading-overlay-transition-leave._loading-overlay-transition-leave-active {
    opacity: 0;
    transition: opacity .5s ease-in;
  }
`

interface SpinnerProps {
  spinnerSize: string
}

const Spinner = styled.div<SpinnerProps>`
  position: relative;
  margin: 0 auto 10px auto;
  width: ${props => props.spinnerSize};
  max-height: 100%;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Content = styled.div`
  margin: auto;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const spinnerDash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
`

const Svg = styled.svg`
  animation: ${rotate360} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  margin: auto;
`

const Circle = styled.circle`
  animation: ${spinnerDash} 1.5s ease-in-out infinite;
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: ${props => props.color};
`

interface LoadingOverlayProps {
  zIndex?: number
  onClick?: () => void
  background?: string
  color?: string
  spinnerSize?: string
  spinner?: boolean
  speed?: number
  text?: string
  active?: boolean
  style?: any
}

export default class LoadingOverlay extends React.Component<LoadingOverlayProps> {
  public static defaultProps = {
    spinner: false,
  }
  public render() {
    let spinnerNode = null
    if (this.props.spinner) {
      spinnerNode = (
        <Spinner spinnerSize={this.props.spinnerSize}>
          <Svg viewBox="25 25 50 50">
            <Circle color={this.props.color} cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
          </Svg>
        </Spinner>
      )
    }

    let textNode = null
    if (this.props.text) {
      textNode = <div>{this.props.text}</div>
    }

    let contentNode = null
    if (this.props.text || this.props.spinner) {
      contentNode = (
        <Content>
          {spinnerNode}
          {textNode}
        </Content>
      )
    }

    return (
      <Overlay
        background={this.props.background}
        color={this.props.color}
        speed={this.props.speed}
        zIndex={this.props.zIndex}
        key="dimmer"
        onClick={this.props.onClick}
      >
        {contentNode}
      </Overlay>
    )
  }
}
