import styled from 'styled-components'

interface WrapperProp {
  opacity: number
}

export const Wrapper = styled.div<WrapperProp>`
  display: flex;
  align-items: center;
  opacity: ${({ opacity }) => opacity};
`
