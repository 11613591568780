import * as React from 'react'

interface OutsideClickHandlerProps {
  position?: any
  onOutsideClick?: () => void
  className?: string
  onClick?: () => void
}

export class OutsideClickHandler extends React.PureComponent<OutsideClickHandlerProps> {
  public wrapperRef
  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  public setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  public handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onOutsideClick()
    }
  }

  public render() {
    const { className, onClick } = this.props

    return (
      <div ref={this.setWrapperRef} className={className} onClick={onClick}>
        {this.props.children}
      </div>
    )
  }
}
