import * as React from 'react'
import { connect } from 'react-redux'
import { Card, Row } from 'components'
import { Wrapper, Title, InfoCard, Inner, TextWrapper, Block } from './tracking-script-setup.styles'

import { tenantIdSelector } from '../../../../selectors'
import { TrackingScriptButton, TrackingScriptHelpLink, TrackingScriptText } from '../../../../components/tracking-script'

interface SlackIntegrationProps {
  tenantId?: string
}

@connect((state) => ({ tenantId: tenantIdSelector(state) }))
export class TrackingScriptSetup extends React.PureComponent<SlackIntegrationProps> {
  public render(): React.ReactNode {
    return (
      <Wrapper>
        <Title>Leadexplorer web script</Title>
        <Row>
          <div style={{ flex: 1 }}>
            <InfoCard>
              <Card.Title>
                SCRIPT CODE
              </Card.Title>
              <Inner>
                <p>Leadexplorer web script is required to identify leads from your website.
                  Copy the code below and paste it into your website header.</p>
                <TrackingScriptText tenantId={this.props.tenantId}/>
                <TrackingScriptButton tenantId={this.props.tenantId}/>
              </Inner>
            </InfoCard>
          </div>
          <TextWrapper>
            <h3>How to install</h3>
            <Block>
              It only takes a couple of minutes to install the web script. <TrackingScriptHelpLink>Visit this guide</TrackingScriptHelpLink> on how to add the script to your site.
            </Block>
            <Block>
              If you need further help, contact us at <a target="_blank" href="mailto:support@leadexplorer.com">support@leadexplorer.com</a>
            </Block>
          </TextWrapper>
        </Row>
      </Wrapper>
    )
  }
}
