import styled from 'styled-components'

import { Icon } from '../../../../components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;  
`

export const Footer = styled.div`
  background: #FAFAFA;
  font-weight: 300;
  font-size: 14px;
  padding: 14px 0;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
  cursor: pointer;
  z-index: 1;
`

export const Triangle = styled(Icon)`
  margin: 10px;
`
