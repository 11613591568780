import * as React from 'react'
import { get } from 'lodash'

import { Form, Input, SearchIcon, SubmitButton } from './resolver-form.styles'
import { resolveEmail } from '../../../../gql/company'

interface ResolverFormProps {
  value: string
  client: any
  onResult: any
  onError: any
  onLoading: any
  onValueChange: any
}

export class ResolverForm extends React.PureComponent<ResolverFormProps> {

  public resolveEmail = (value) => {
    console.log('Resolver Value 2: ' + value)
    this.props.onLoading()
    return this.props.client.query({
      query: resolveEmail,
      variables: { email: value }
    })
    .then((data) => {
      console.log('Data: ' + JSON.stringify(data))
      this.props.onResult(get(data, 'data.getCompanyByEmail.company', []))
    })
    .catch(() => {
      this.props.onError()
    })
  }

  public render(): React.ReactNode {
    const { onValueChange, value } = this.props
    return (
      <Form onSubmit={(event) => {
        event.preventDefault()
        this.resolveEmail(value)
      }}>
        <Input
          name="email"
          value={value}
          autoComplete="off"
          onChange={onValueChange}
        />
        <SubmitButton>
          <SearchIcon />
        </SubmitButton>
      </Form>
    )
  }
}
