import * as React from 'react'
import { Field, formValues } from 'redux-form'
import { get } from 'lodash'

import { Input, Row, FormDropdown, Label } from 'components'
import { isInEU, validation } from 'utils'
import { DropdownWrapper, ColumnWrapper, Column } from '../billing.styles'
import { VatInput } from './vat-input'

const required = validation.required('Field is required')

interface BillingFieldsProps {
  countriesList: any
  columns: number
  countryCode?: any
}

// @ts-ignore
@formValues('countryCode')
export class BillingFields extends React.PureComponent<BillingFieldsProps> {
  public render() {
    const countryCode = get(this.props, 'countryCode.value')
    return (
      <ColumnWrapper column={this.props.columns}>
        <Column>
          <Field
            name="name"
            label="Company name"
            component={Input as any}
            validate={[required]}
            type="text"
          />
          <Field
            name="address1"
            label="Street Name"
            component={Input as any}
            validate={[required]}
            type="text"
          />
          <Field
            name="address2"
            label="State/Province/Region"
            component={Input as any}
            type="text"
          />
          <Row>
            <Field
              name="zipCode"
              label="Postal Code"
              component={Input as any}
              type="text"
            />
            <Field
              name="city"
              label="City"
              component={Input as any}
              validate={[required]}
              type="text"
            />
          </Row>
        </Column>
        <Column>
          <DropdownWrapper>
            <Label>
              Country
            </Label>
            <Field
              name="countryCode"
              component={FormDropdown(this.props.countriesList, 'Choose Country', true)}
              validate={[required]}
            />
          </DropdownWrapper>
          {
            isInEU(get(this.props, 'countryCode.value')) ? (
              <Field
                name="vatID"
                code={countryCode}
                label="VAT ID (Only EU)"
                component={VatInput as any}
                type="text"
              />
            ) : null
          }
          <Field
            name="emailAddress"
            label="Billing email address"
            component={Input as any}
            type="email"
          />
        </Column>
      </ColumnWrapper>
    )
  }
}
