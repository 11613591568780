import gql from 'graphql-tag'

export const getSlackChannelConfigurations = gql`
  query getSlackChannelConfigurations {
    getSlackChannelConfigurations {
      incomingWebhook {
        channel
        configurationUrl
      }
    }
  }
`

export const getSlackActivateAppLink = gql`
  query getSlackActivateAppLink {
    getSlackActivateAppLink {
      url
    }
  }
`
