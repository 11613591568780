import styled from 'styled-components'
import { CheckboxInput, CheckboxLabel } from 'components'

export const Label = styled(CheckboxLabel)`
  font-size: 14px;
  
  &:before {
    position: absolute;
    top: 1px;
    left: 0;
    width: 17px;
    height: 17px;
    content: '';
    background: #EAEAEA;
    border-radius: 4px;
    border: none;
  }
`
export const Input = styled(CheckboxInput)`
  &:checked ~ label:after {
    top: 3px;
    left: 5px;
  }
`
