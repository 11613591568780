import * as React from 'react'
import { Card, Spinner, Button } from '../../../../components'
import { Query } from 'react-apollo'
import { History } from 'history'

import { InfoCard } from '../../company-container.styles'
import { getSuperOfficeIntegrationStatus } from '../../../../gql/superoffice/superoffice.query'
import { Wrapper, TextWrapper, MainText, Bold, Text } from './crm-insight.styles'
import { ConnectedCard } from './components/connected-card/connected-card.component'

const Title = (Card as any).Title

interface CRMInsightProps {
  history: History
  company: any
  companyId: string
}

export class CRMInsight extends React.PureComponent<CRMInsightProps> {
  public onConnectClick = () => {
    this.props.history.push('/settings/superoffice-integration')
  }
  public render() {
    const { companyId } = this.props
    let { company } = this.props
    return (
      <InfoCard>
        <Title>
          Crm Insight
        </Title>
        <Wrapper>
          <Query query={getSuperOfficeIntegrationStatus}
                 notifyOnNetworkStatusChange={true}>
            {({ loading, error, data }) => {
              if (loading) {
                return <Spinner />
              }

              if (error) {
                return <TextWrapper>Can't connect to CRM due to unknown error</TextWrapper>
              }
              if (data.getSuperOfficeIntegrationStatus.connected && company.name) {
                if (company.domain === null) {
                  company = { ...company, domain: '' }
                }
                return (
                  <ConnectedCard
                    company={company}
                    companyId={companyId}
                  />
                )
              }
              if (!data.getSuperOfficeIntegrationStatus.connected) {
                return (
                  <TextWrapper>
                    <MainText>You are <Bold>not</Bold> connected to a CRM System.</MainText>
                    <Text>You are missing out of many fantastic <Bold>benefits</Bold> that comes with the integration to a CRM system.</Text>
                    <Button
                      color="primary"
                      onClick={this.onConnectClick}
                    >
                      CONNECT TO A CRM SYSTEM
                    </Button>
                  </TextWrapper>
                )
              }
              return (
                <Spinner />
              )
            }}
          </Query>
        </Wrapper>
      </InfoCard>
    )
  }
}
