import * as React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { graphql, compose } from 'react-apollo'
import { Route, Redirect } from 'react-router-dom'

import { isAuthenticatedSelector } from '../../selectors'
import { googleStatus } from '../../gql/google'
import { tenantCompanyInformation } from '../../gql/company'
import { Spinner } from '../common'

interface CheckSetupRouteProps {
  data?: any
  companyInformation?: any
  exact?: boolean
  path?: string
  component?: any
  render?: any
}

@compose(
  graphql(tenantCompanyInformation, {
    name: 'companyInformation',
  }),
  graphql(googleStatus),
)
@connect(createStructuredSelector({
  store: store => store,
  isAuthenticated: isAuthenticatedSelector,
}))
export class CheckSetupRoute extends React.Component<CheckSetupRouteProps> {

  public render() {
    const {
      data: {
        getPaymentSubscriptionStatus,
        loading,
        error,
      },
      companyInformation,
      ...other
    } = this.props

    if (error) {
      throw error
    }

    if (loading) {
      return <Spinner />
    }

    if (!loading && !getPaymentSubscriptionStatus.active) {
      return <Redirect to="/subscription-expired" />
    }

    return <Route {...other} />
  }
}
