import * as React from 'react'
import { Mutation, Query } from 'react-apollo'
import { get } from 'lodash'
import { sendOutQuery, updateSendOutConfiguration  } from '../../../../gql/user'
import { Switch } from 'components'

import { Wrapper, Label } from './send-out-setting.styles'

export class SendOutSetting extends React.PureComponent {
  public state = {
    weeklyTopLeads: null
  }

  public render(): React.ReactNode {
    return (
      <>
        <h3>Send out settings</h3>
        <Query query={sendOutQuery}>
          {({ data, loading }) => {
            return (
              <Wrapper>
                <Mutation
                  mutation={updateSendOutConfiguration}
                  update={(proxy, mutationResult) => {
                    const options = { query: sendOutQuery }
                    const cache: any = proxy.readQuery(options)
                    cache.getSendOutConfiguration = mutationResult.data.updateSendOutConfiguration
                    proxy.writeQuery({ ...options, data: cache })
                  }}
                >
                {(mutation) => (
                  <Switch
                    disabled={loading}
                    checked={get(data, 'getSendOutConfiguration.isActivated', false)}
                    onChange={(value) => mutation({ variables: { isActivated: value }})}
                  />
                )}
                </Mutation>
                <Label>Weekly Top Leads</Label>
              </Wrapper>
            )
          }}
        </Query>
      </>
    )
  }
}
