import * as React from 'react'
import { Icon } from '../../icons'

export const RoundCheckbox = ({ checked, onClick, ...other }) => (
  <Icon
    icon={checked ? 'checkedCheckbox' : 'uncheckedCheckbox'}
    onClick={onClick}
    {...other}
  />
)
