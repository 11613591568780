import styled from 'styled-components'
import { Button as BaseButton } from 'components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 960px;
  padding-top: 60px;
  padding-bottom: 24px;
  margin: auto;
  text-align: center;
  flex: 1;
  align-items: center;

  @media (max-width: 959px) {
    width: auto;
    padding: 30px 4px 48px;
  }
  
  h1 {
    font-weight: 200;
    font-size: 36px;
    color: #314550;
    margin-top: 96px;
  }
  
  p {
    max-width: 600px;
    font-weight: normal;
    font-size: 20px;
    color: #314550;
    
    span {
      font-weight: bold;
    }
  }
`

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 560px;
  font-size: 18px;
  color: #314550;
  text-align: left;
  margin-bottom: 12px;

  @media (max-width: 959px) {
    width: auto;
  }
`

interface ErrorProps {
  show: boolean
}

export const Error = styled.div<ErrorProps>`
  display: ${({ show }) => show ? 'block' : 'none'};
  color: #D85251;
`

export const Input = styled.input`
  border: 1px solid #BFBFBF;
  padding: 16px 20px;
  margin-bottom: 32px;
  width: 560px;
  font-size: 20px;
  box-sizing: border-box;

  @media (max-width: 959px) {
    width: auto;
  }
  
  &::placeholder {
    color: #C4C4C4;
  }
`

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #E3E3E3;
  margin-bottom: 45px;
`

export const Button = styled(BaseButton)`
  font-size: 14px;
`

export const Small = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: center;
  color: #314550;
`

export const MobileMessage = styled.p`
  @media (min-width: 800px) {
    display: none;
  }
`
