import * as React from 'react'
import { map } from 'lodash'
import { Row } from '../../../../../components'
import { PlanCard } from './plan-card/plan-card.component'
import { Plan } from '../plan.model'

interface PlanCardsListProps {
  plans: Plan[]
  selected: string
  current: string
  onClick: (selectedPlan: string) => void
}

export class PlanCardsList extends React.PureComponent<PlanCardsListProps> {
  public render() {
    return (
      <Row>
        {map(this.props.plans, plan => (
          <PlanCard
            key={plan.code}
            plan={plan}
            selected={plan.isSelected(this.props.selected)}
            current={plan.isSelected(this.props.current)}
            onClick={this.props.onClick}
          />
        ))}
      </Row>
    )
  }
}
