import { appsync } from './appsync.config'

let url = 'http://localhost:3000/sign-in-callback'

if (window) {
  url = `${window.location.origin}/sign-in-callback`
}

const env = process.env.REACT_APP_ENV || 'dev'

export const rootUrl = `https://${env}-apigw.emarketeer.com`

const X_API_KEY = {
  REGISTRATION: {
    dev: 'Kbc6hlCkHZ3AcCLij6XsKa31ZQWWeTanZfO0OYY4',
    prod: 'lgzjYJmAanaB8TqQMSUzRFNaq0VMAsD8yI7CPr8j',
  },
  TENANT: {
    dev: 'tzPdfB00YP3imkhQ7a51m8Mu6KrkXEHX8ZesGp6U',
    prod: 'lgzjYJmAanaB8TqQMSUzRFNaq0VMAsD8yI7CPr8j',
  },
}

const AUTH = {
  dev: {
    domain: 'emarketeer-dev.eu.auth0.com',
    clientId: 's25lSaumktqlIv3216uruC0c1wg33dGj',
  },
  prod: {
    domain: 'emarketeteer.eu.auth0.com',
    clientId: 'X7bqOiMD6pkbwTdqov6lOKGliwg34Jf9',
  },
}

const SUPER_OFFICE = {
  dev: {
    redirectUrl: 'https://dev-apigw.emarketeer.com/redirector/leadexplorer',
  },
  prod: {
    redirectUrl: 'https://prod-apigw.emarketeer.com/redirector/leadexplorer',
  },
}

const SLACK_INTEGRATION = {
  dev: 'https://slack.com/oauth/authorize?client_id=235710618723.565583740407&scope=incoming-webhook&redirect_uri=https://dev-apigw.emarketeer.com/slack/api-callback&state=',
  prod: 'https://slack.com/oauth/authorize?client_id=235710618723.546654348816&scope=incoming-webhook&redirect_uri=https://prod-apigw.emarketeer.com/slack/api-callback&state=',
}

export const CONFIG = {
  ENV: env,
  AUTH: {
    domain: AUTH[env].domain,
    clientId: AUTH[env].clientId,
    callbackUrl: url,
  },
  APOLLO: {
    url: `${rootUrl}/graphql`,
  },
  REGISTRATION: {
    userService: `${rootUrl}/user`,
    xApiKey: X_API_KEY.REGISTRATION[env],
    tenantKey: 'https://emarketeer.io/tenant',
  },
  TENANT: {
    xApiKey: X_API_KEY.TENANT[env],
    tenantService: `${rootUrl}/tenant-service`,
  },
  APPSYNC: appsync[env],
  SENTRY: {
    DSN: 'https://1953370252a94358a0645afb6adfdaa4@sentry.io/1259288',
  },
  SUPER_OFFICE: SUPER_OFFICE[env],
  ENVIRONMENT: env,
  MAP_URI: 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyB-KQNBAeUsTI3iBKsjLXF7P4Ndxd1NqPw',
  SLACK_INTEGRATION: SLACK_INTEGRATION[env],
  BROWSER_PUSH_APPLICATION_SERVER_KEY: 'BEgc4ngVew63WbZqhU8Q5xGinNlxhqjgI5d1WLAKSMnB4KlJ4JvHUv3IXjo0A-uKjjS8sYeL-agC6_IxqiN50TA'
}
