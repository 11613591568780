import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 96px;
  padding: 8px 32px;
  margin: 0 -60px;
  background: #61869E;
  box-sizing: border-box;

  @media (max-width: 1399px) {
    min-height: 80px;
  };

  @media (max-width: 800px) {
    flex-direction: column;
    min-height: 80px;
    margin: 0 -10px;
    padding: 16px;
  };
`

export const Title = styled.div`
  padding: 4px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;

  @media (max-width: 800px) {
    margin-bottom: 16px;
  }
`

export const LeadsLeftText = styled.div`
  padding: 4px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;

  @media (max-width: 1399px) {
    font-size: 20px;
  };

  @media (max-width: 800px) {
    margin-bottom: 16px;
  }

  span {
    font-weight: bold;
  }
`
