import styled from 'styled-components'

import { TableHeaderItem, TableRowItem, TableRow } from '../../../../components/index'
import { InfoCard, Table } from '../../settings-container.styles'

export const InvoicesCard = styled(InfoCard)`
  ${Table} {
    padding: 32px 40px;
  }

  ${TableRow} {
    padding: 4px 0;
  }

  ${TableRow}:nth-child(odd) {
    background: #f7f7f7;
  }

  ${TableRow}:first-child {
    border-bottom: 1px solid #f7f7f7;
    background: none;
  }

  ${TableRowItem} {
    font-size: 18px;
    font-weight: 300;
  }

  ${TableHeaderItem} {
    font-size: 12px;
    font-weight: normal;
  }
`

export const PaymentStatus = styled.div<{ color: string }>`
  text-transform: uppercase;
  color: ${({ color }) => color};
`

export const DownloadButton = styled.div`
  height: 20px;
  padding: 0 8px;
  margin-right: 8px;
  cursor: pointer;
`
