import gql from 'graphql-tag'

export const updateCompanyInformation = gql`
  mutation updateTenantCompanyInformation(
  $name: String
  $address1: String
  $address2: String
  $zipCode: String
  $city: String
  $country: String
  $industry: String
  $logo: String
  ) {
    updateTenantCompanyInformation(
      name: $name,
      address1: $address1,
      address2: $address2,
      zipCode: $zipCode,
      city: $city,
      countryCode: $country,
      industry: $industry,
      logo: $logo
    ) {
      name
      address1
      address2
      zipCode
      city
      countryCode
      industry
      logo
    }
  }
`
