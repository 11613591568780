import * as React from 'react'
import { Mutation, Query } from 'react-apollo'
import { filter, find, get, map } from 'lodash'

import { getAssignedUser } from '../../../../gql/assign/assign.query'
import { setAssignedUser } from '../../../../gql/assign/assign.mutation'
import { Avatar, Dropdown } from 'components'
import { AvatarWrapper, Contact, Text, Wrapper } from './assign-dropdown.styles'
import { Events, registerAnalyticEvent } from 'utils'


interface AssignDropdownProps {
  companyId: string,
    companyName?: any
}

export class AssignDropdown extends React.PureComponent<AssignDropdownProps> {
  public state = {
    registeredEvent: false,
  }

  public getDropdownContent = (data, user: any = {}) => {
    if (data.getAssignedUser && user.email) {
      return (
        <Text>
          Assigned to {user.firstName} {user.lastName}
          <AvatarWrapper><Avatar user={user}/></AvatarWrapper>
        </Text>
      )
    }
    return <Text>Not Assigned</Text>
  }
  public render() {
    const { companyId, companyName } = this.props
    return (
      <Wrapper>
        <Query query={getAssignedUser} variables={{ companyId }}>
          {({ loading, error, data }) => {
            if (!loading && !error && data.getUsers) {
              return (
                <Mutation
                  mutation={setAssignedUser}
                >
                  {(mutation) => {
                    const onClick = (email: string) => {
                      return mutation({
                        variables: {
                          email,
                          companyId,
                        },
                        optimisticResponse: {
                          setAssignedUser: {
                            email,
                            companyId,
                            __typename: 'AssignedUserCompany'
                          },
                        },
                        update: (dataProxy, updatedData) => {
                          const query = getAssignedUser
                          const result: any = dataProxy.readQuery({ query, variables: { companyId } })
                          result.getAssignedUser = updatedData.data.setAssignedUser
                          dataProxy.writeQuery({ query, variables: { companyId }, data: result })
                            if (email !== '' && !this.state.registeredEvent) {
                                this.setState({ registeredEvent: true })
                                registerAnalyticEvent(Events.COMPANY_ASSIGN, { assignedEmail: email , 'companyName': companyName })
                            }
                        }
                      })
                    }
                    const unassign = {
                      title: <Contact><span>Not assigned</span></Contact>,
                      onClick: () => onClick('')
                    }
                    const users = filter<any>(data.getUsers, x => x.firstName && x.lastName)
                    const items = map(users, (x) => {
                      const { firstName, lastName, email } = x
                      return {
                        title: <Contact><span>{firstName} {lastName}</span><AvatarWrapper><Avatar user={x}/></AvatarWrapper></Contact>,
                        onClick: () => onClick(email),
                      }
                    })
                    const user = find(data.getUsers, { email: get(data, 'getAssignedUser.email') })
                    return (
                      <Dropdown items={[unassign, ...items]} itemHeight={56}>
                        {this.getDropdownContent(data, user)}
                      </Dropdown>
                    )
                  }}
                </Mutation>
              )
            }
            return null
          }}
        </Query>
      </Wrapper>
    )
  }
}
