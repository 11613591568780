import * as React from 'react'
import { map, reduce, find, trimStart, get } from 'lodash'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Mutation, Query } from 'react-apollo'

import { Spinner } from 'components'
import { BillingForm as BillingFormBasic } from './components/billing-form.component'
import { MainWrapper, Title, InfoCard } from '../../settings-container.styles'
import { getTenantBillingDetails, registerVatID } from '../../../../gql/subscriptions'
import {
  addCountryCodeToInitialValues,
  addCountryCodeToResultValues,
  handleBillingFormRejection,
  countriesList,
  getVATIdCode,
  isInEU,
} from 'utils'

const BillingForm = BillingFormBasic as any

export class Billing extends React.PureComponent {
  public handelSubmit = (mutation: (options: any) => Promise<any>, values): Promise<any> => {
    const variables: any = addCountryCodeToResultValues(values)
    if (isInEU(variables.countryCode)) {
      variables.vatID = `${getVATIdCode(variables.countryCode)}${variables.vatID}`
    } else {
      variables.vatID = null
    }
    return mutation({variables})
  }


  public render() {
    return (
      <MainWrapper>
        <Title>Billing Address</Title>
        <Query
          query={getTenantBillingDetails}
        >
          {({data = {getTenantBillingDetails: {}}, loading, error, refetch}) => {
            if (loading) {
              return <Spinner/>
            }
            if (error) {
              throw error
            }
            return (
              <InfoCard>
                <Mutation
                  mutation={registerVatID}
                >
                  {(mutation, mutationStatus) => (
                    <BillingForm
                      loading={mutationStatus.loading}
                      countriesList={countriesList}
                      initialValues={this.removeCountryCodeFromVATId(addCountryCodeToInitialValues(data.getTenantBillingDetails))}
                      onSubmit={variables => this.handelSubmit(mutation, variables)
                        .then(refetch)
                        .catch(handleBillingFormRejection)
                      }
                      onDelete={refetch}
                    />
                  )}
                </Mutation>
              </InfoCard>
            )
          }}
        </Query>
      </MainWrapper>
    )
  }

  private removeCountryCodeFromVATId(values: any) {
    return {...values, vatID: trimStart(values.vatID, getVATIdCode(get(values, 'countryCode.value', '')))}

  }
}
