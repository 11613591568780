import * as React from 'react'
import { Query } from 'react-apollo'

import { CommonModal } from '../common-modal/common-modal.component'
import { SearchWrapper, Search, Link } from './search-company-modal.styles'
import { SearchResult } from '../search-result/search-result.component'
import { getCompaniesFromCrm } from '../../../../../../gql/superoffice/superoffice.query'

interface SearchCompanyModalProps {
  company: any
  isOpen: boolean
  onRequestClose: () => void
  onAction: (id) => void
  onSwitchDialog: () => void
}

export class SearchCompanyModal extends React.PureComponent<SearchCompanyModalProps> {
  public state = {
    name: '',
    selected: ''
  }
  constructor(props) {
    super(props)
    this.state.name = props.company.name
  }
  public onSearch = (refetch, event) => {
    const value = event.target.value
    this.setState({ name: value }, () => {
      refetch({
        name: value,
        domain: value,
      })
    })
  }
  public onMatch = () => {
    this.props.onAction(this.state.selected)
  }
  public onSelect = (selected) => {
    this.setState({ selected })
  }
  public render() {
    const {
      isOpen,
      onRequestClose,
      company,
      onSwitchDialog,
    } = this.props
    const { selected, name } = this.state
    return (
      <CommonModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        company={company}
        onCancel={onRequestClose}
        onAction={this.onMatch}
        actionButtonDisabled={!Boolean(selected)}
      >
        <Query
          query={getCompaniesFromCrm}
          variables={{ domain: name, name }}
        >
          {({ loading, error, data, refetch }) => {
            return (
              <div>
                <SearchWrapper>
                  <div>Search for an existing company in SuperOffice</div>
                  <Search
                    value={this.state.name}
                    onChange={this.onSearch.bind(null, refetch)}
                    placeholder="Search..."
                  />
                </SearchWrapper>
                <SearchResult
                  loading={loading}
                  selected={selected}
                  companies={data.getCompaniesFromCrm}
                  onSelect={this.onSelect}
                />
                <Link
                  onClick={onSwitchDialog}
                >
                  Or, create new company
                </Link>
              </div>
            )
          }}
        </Query>
      </CommonModal>
    )
  }
}
