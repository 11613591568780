import * as React from 'react'
import { connect } from 'react-redux'
import { Field, arrayRemove } from 'redux-form'

import { FormDropdown } from 'components'
import {
  AndContainer,
  CloseIcon,
  EntryContainer,
  FilterExpressionContainer,
  FilterExpressionInput,
  FilterFieldContainer,
  FilterOperatorContainer
} from './and-clauses.styles'

const DataMap = {
  fields: [{
    value: 'domain',
    label: 'Domain'
  }, {
    value: 'page_url',
    label: 'Page Url'
  }, {
    value: 'country',
    label: 'Country'
  }, {
    value: 'page_visits',
    label: 'Page visits'
  }, {
    value: 'score',
    label: 'Score'
  }],
  operator: {
    value: '',
  },
  value: {}
}

export const renderAndClauses = connect()(({ fields, dispatch }) => (
  <AndContainer>
    {fields.map((andClause, andClauseIndex) => (
      <EntryContainer key={andClause}>
        <FilterFieldContainer>
          <Field
            name={`${andClause}.field`}
            component={FormDropdown(DataMap.fields, 'Field name') }
          />
        </FilterFieldContainer>
        <FilterOperatorContainer>
          <Field
            name={`${andClause}.operator`}
            component={FormDropdown([{ value: 'contains', label: 'Contains' }], 'Operator')}
          />
        </FilterOperatorContainer>
        <FilterExpressionContainer>
          <Field
            name={`${andClause}.expression`}
            component={FilterExpressionInput as any}
            type="text"
            placeholder="Expression"
          />
        </FilterExpressionContainer>
        <CloseIcon
          icon="close"
          size={12}
          onClick={() => {
            const orClauseIndex = Number(andClause.match('[0-9]+')[0])

            if (fields.getAll().length === 1 && orClauseIndex !== 0) {
              dispatch(arrayRemove('editFilter', 'orClauses', orClauseIndex))
            } else {
              fields.remove(andClauseIndex)
            }
          }}
        />
      </EntryContainer>
    ))}
  </AndContainer>
))
