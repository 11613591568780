import styled from 'styled-components'

interface AvatarPictureProps {
  size: number
}

export const AvatarPicture = styled.img<AvatarPictureProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => Math.round(size / 2.6)}px;
  border-radius: 50%;
`
