import * as React from 'react'
import { get, map } from 'lodash'
import { Query, ApolloConsumer } from 'react-apollo'
import { connect } from 'react-redux'
import { Button, Card, LoadingBlock, Row } from 'components'
import { Wrapper, Title, InfoCard, Inner, StatusText, Channel, Footer, TextWrapper } from './slack-integration.styles'

import { getSlackChannelConfigurations, getSlackActivateAppLink } from '../../../../gql'
import { tenantIdSelector } from '../../../../selectors'
import { ApolloClient } from 'apollo-client'

interface SlackIntegrationProps {
  tenantId?: string
}

@connect((state) => ({ tenantId: tenantIdSelector(state) }))
export class SlackIntegration extends React.PureComponent<SlackIntegrationProps> {
  public goToSlack = async (client: ApolloClient<any>) => {
    const { data } = await client.query({
      query: getSlackActivateAppLink
    });
    if (data && data.getSlackActivateAppLink) {
      window.location.href = data.getSlackActivateAppLink.url
    }
  }

  public goToSlackSetting = (url: string) => {
    window.open(url, '_blank')
  }

  public render(): React.ReactNode {
    return (
      <Wrapper>
        <Title>Slack</Title>
          <Row>
            <ApolloConsumer>
              {client => (
                <Query query={getSlackChannelConfigurations}>
                  {({ loading, data }) => {
                    const configurations = get(data, 'getSlackChannelConfigurations') || []
                    const integrationIsEnabled = configurations.length
                    const settingsUrl = get(configurations, '[0].incomingWebhook.configurationUrl', '')
                    return (
                      <div style={{ flex: 1 }}>
                        <InfoCard>
                          <Card.Title>
                            INTEGRATION STATUS
                          </Card.Title>
                          <Inner>
                            {loading ? (
                              <Row>
                                <LoadingBlock style={{ width: 200 }}/>
                                <LoadingBlock style={{ width: 200, height: 42 }}/>
                              </Row>
                            ) : (
                              integrationIsEnabled ? (
                                <Row>
                                  <StatusText>Slack is activated</StatusText>
                                  <Button onClick={() => this.goToSlackSetting(settingsUrl)} color="alert">REMOVE APP OR CHANNELS</Button>
                                </Row>
                              ) : (
                                <Row>
                                  <StatusText>Slack is not activated</StatusText>
                                  <Button onClick={this.goToSlack.bind(null, client)} color="primary">ACTIVATE SLACK APP</Button>
                                </Row>
                              )
                            )}
                          </Inner>
                        </InfoCard>
                        {integrationIsEnabled ? (
                          <InfoCard>
                            <Card.Title>
                              ADDED SLACK CHANNELS
                            </Card.Title>
                            <Inner style={{ paddingTop: 0 }}>
                              {map(configurations, ({ incomingWebhook }) => (
                                <Channel>{incomingWebhook.channel}</Channel>
                              ))}
                              <Footer>
                                <Button onClick={this.goToSlack.bind(null, client)} color="primary">ADD CHANNEL</Button>
                              </Footer>
                            </Inner>
                          </InfoCard>
                        ) : null}
                      </div>
                    )
                }}
              </Query>
            )}
          </ApolloConsumer>
          <TextWrapper>
            <h3>Why should you connect?</h3>
            <p>With the Slack integration activated, Leadexplorer automatically posts stage updates to your selected Slack channels, i.e. when someone assigns a lead, when a company becomes a marketing qualified lead etc.</p>
          </TextWrapper>
        </Row>
      </Wrapper>
    )
  }
}
