import * as React from 'react'
import { CheckboxInput, CheckboxLabel, Wrapper } from './checkbox.styles'
import { WrappedFieldInputProps } from 'redux-form'

interface CheckboxProps {
  input: WrappedFieldInputProps,
  label: string,
  placeholder: string
}

export const Checkbox: React.SFC<CheckboxProps> = ({input: { name, ...inputProps }, label, placeholder }) => (
  <Wrapper>
    <CheckboxInput
      id={name}
      name={name}
      placeholder={placeholder}
      {...inputProps}
      type="checkbox"
    />
    <CheckboxLabel
      htmlFor={name}
    >
      {label}
    </CheckboxLabel>
  </Wrapper>
)
