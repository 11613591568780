import * as React from 'react'
import { TableRow, TableRowItem, Alignment } from '../../../../../components/index'

interface CurrentSubscriptionTableRowProps {
  plan: string
  qty: string
  rate: string
  amount: string
}

export const CurrentSubscriptionTableRow: React.SFC<CurrentSubscriptionTableRowProps> = ({plan, qty, rate, amount}) => (
  <TableRow>
    <TableRowItem size={2}>
      {plan}
    </TableRowItem>
    <TableRowItem alignment={Alignment.right}>
      {qty}
    </TableRowItem>
    <TableRowItem alignment={Alignment.right}>
      {rate}
    </TableRowItem>
    <TableRowItem alignment={Alignment.right}>
      {amount}
    </TableRowItem>
  </TableRow>
)
