export default {
  Weekday: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  Day: {
    color: '#314550',
    fontSize: '14px',
    transition: 'transform .1s ease, box-shadow .1s ease, background .1s ease',
    border: '1px solid white',
    background: '#D2D9DE',
    borderRadius: '4px',
  },
  DaySelected: {
    color: '#314550',
    background: '#8DBCD1',
    fontWeight: 500,
  },
  DayPassive: {
    color: '#314550',
    opacity: 1,
    background: '#FFFFFF',
  },
  DayInRange: {
    color: '#314550',
    background: '#AECFDD',
    fontWeight: 500,
  },
  DayHover: {
    background: '#FF9070',
  },
}
