import * as React from 'react'
import { map } from 'lodash'
import styled from 'styled-components'
import { Icon } from '../../../components'

const Wrapper = styled.div`
  margin: 0 0 20px 0;
`
const Th = styled.th`
  text-align: left;
  padding: 10px;
  font-weight: normal;
`
const Td = styled.td`
  text-align: left;
  padding: 10px;
`
const HeaderRow = styled.tr`
  border-bottom: 1px solid #CBCBCB;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`
const CloseIcon = styled(Icon)`
  margin: 0 0 0 12px;
  cursor: pointer;
`

export const BlacklistTable = ({ records, deleteRecord }) => (
  <Wrapper>
    <Table>
      <thead>
        <HeaderRow>
          <Th>Network Domain</Th>
          <Th>Service Provider</Th>
          <Th />
        </HeaderRow>
      </thead>
      <tbody>
        {map(records, (record, i) => (
          <tr key={i}>
            <Td>{record.networkDomain}</Td>
            <Td>{record.serviceProvider}</Td>
            <Td>
              <CloseIcon
                icon="close"
                size={12}
                onClick={() => deleteRecord({
                  serviceProvider: record.serviceProvider,
                  networkDomain: record.networkDomain,
                })}
              />
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Wrapper>
)
