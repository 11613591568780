import * as React from 'react'
import styled from 'styled-components'
import okIcon from './images/ok.svg'
import errorIcon from './images/error.svg'

const Wrapper = styled.span`
  display: inline-flex;
  margin-left: 8px;
  
  img {
    width: 12px;
    height: 12px;
    line-height: 14px;
  }
`

const TextWrapper = styled.span`
  margin-left: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #D85251
`

export const InputWrapper = styled.div`
  & input {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    box-sizing: border-box;
    border: 1px solid #BFBFBF;
    border-radius: 5px;
  }

  & input::placeholder {
    color: #BFBFBF;
  }
  
  & input:disabled {
    background: #DDD;
  }
`

export const Label = styled.label`
  display: flex;
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
`

export const OKIcon = () => (
  <Wrapper>
    <img src={okIcon} alt="success" />
  </Wrapper>
)

export const ErrorMessage = ({ error }) => (
  <Wrapper>
    <img src={errorIcon} alt="error" />
    <TextWrapper>
      {error}
    </TextWrapper>
  </Wrapper>
)
