import * as React from 'react'
import * as moment from 'moment'
import { noop, get, compact } from 'lodash'

import { isVisitLive } from 'utils'
import { Flag, Show, ThermometerRating } from '../'
import {
  ItemRow,
  ItemCol,
  Header,
  NameRow,
  Name,
  CrmIcon,
  Location,
  DateColumn,
  Info,
  Engagement,
  Logo,
  LogoContainer,
  DateView,
  OnSiteNow,
  WhatHappened,
  Time,
  EngagementContainer,
  EngagementHeader,
  User,
  UserIcon,
  Assigned,
  AssignedContainer,
} from './list-item.styles'
import { Attribution, UserType, Source, AssignedAvatar } from './components'

interface CompanyListItemProps {
  index: number
  company: any
  onClick: any
  users: any
}

export class CompanyListItem extends React.Component<CompanyListItemProps> {
  public shouldComponentUpdate(nextProps: Readonly<CompanyListItemProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    return JSON.stringify(nextProps.company) !== JSON.stringify(this.props.company)
  }

  public render() {
    const { index, company, onClick = noop, users } = this.props
    const dateTime = moment.utc(company.dateTime, 'YYYYMMDDHHmm').local()

    const isLive = isVisitLive(dateTime)

    return (
      <ItemRow key={company.code} onClick={() => onClick(company)} role="button" tabIndex={-1}>
        <DateColumn>
          <Show if={index === 0}>
            <Header>When</Header>
          </Show>
          <DateView>
            <Show if={isLive}>
              <OnSiteNow>
                ON SITE NOW
              </OnSiteNow>
            </Show>
            <Show if={!isLive}>
              <div>
                {moment(dateTime).calendar(null, {
                  sameDay: '[Today]',
                  lastDay: '[Yesterday]',
                  nextWeek: 'dddd',
                  lastWeek: '[Last] dddd',
                  sameElse: 'DD/MM/YYYY',
                })}
              </div>
            </Show>
            <Show if={!isLive}>
              <Time>
                {moment(dateTime).format('h:mmA')}
              </Time>
            </Show>
          </DateView>
        </DateColumn>
        <ItemCol>
          <Show if={index === 0}>
            <Header>Who</Header>
          </Show>
          <LogoContainer>
            <Logo src={company.logo} />
          </LogoContainer>
        </ItemCol>
        <Info>
          <NameRow>
            <Name>
              {company.name || company.serviceProvider}
            </Name>
            <Show if={company.crm}>
              <CrmIcon icon={company.crm} size={16} />
            </Show>
          </NameRow>
          <User>
            <UserIcon icon="person" size={16} />
            Anonymous
          </User>
          <Location>
            <Show if={get(company, 'address.countryCode')}>
              <Flag code={get(company, 'address.countryCode')} />
            </Show>
            <Show if={get(company, 'address.countryCode') !== 'ZZ'}>
              {compact([get(company, 'address.city'), get(company, 'address.country')]).join(', ')}
            </Show>
            <Show if={get(company, 'address.countryCode') === 'ZZ'}>
              Unknown Location
            </Show>
          </Location>
        </Info>
        <WhatHappened>
          <Show if={index === 0}>
            <Header>What happened</Header>
          </Show>
          <Source sourceMedium={company.sourceMedium} />
          <Attribution company={company} isLive={isLive} />
          <UserType revisit={company.revisit} />
        </WhatHappened>
        <Assigned>
          <Show if={index === 0}>
            <EngagementHeader>Assigned</EngagementHeader>
          </Show>
          <AssignedContainer>
            <AssignedAvatar
              assignedTo={company.assignedTo}
              users={users}
            />
          </AssignedContainer>
        </Assigned>
        <Engagement>
          <Show if={index === 0}>
            <EngagementHeader>Engagement</EngagementHeader>
          </Show>
          <EngagementContainer>
            <ThermometerRating value={company.score} />
          </EngagementContainer>
        </Engagement>
      </ItemRow>
    )
  }
}
