import filterArrow from './filter-arrow.component.svg'
import backArrow from './back-arrow.component.svg'
import filterSettings from './filter-settings.component.svg'
import close from './close.component.svg'
import calendar from './calendar.component.svg'
import newUser from './new-user.component.svg'
import returningUser from './returning-user.component.svg'
import email from './email.component.svg'
import emarketeer from './emarketeer.component.svg'
import google from './google.component.svg'
import bing from './bing.component.svg'
import twitter from './twitter.component.svg'
import facebook from './facebook.component.svg'
import web from './web.component.svg'
import yahoo from './yahoo.component.svg'
import youtube from './youtube.component.svg'
import search from './search.component.svg'
import watching from './watching.component.svg'
import desktopWindows from './desktop-windows.component.svg'
import desktopMac from './desktop-mac.component.svg'
import iphone from './iphone.component.svg'
import android from './android.component.svg'
import tablet from './tablet.component.svg'
import heart from './heart.component.svg'
import emptyHeart from './empty-heart.component.svg'
import star from './star.component.svg'
import emptyStar from './empty-star.component.svg'
import person from './person.component.svg'
import clock from './clock.component.svg'
import eye from './eye.component.svg'
import plus from './plus.component.svg'
import linkedIn from './linkedin.component.svg'
import twitterAlt from './twitter-alt.component.svg'
import facebookAlt from './facebook-alt.component.svg'
import youtubeAlt from './youtube-alt.component.svg'
import webEvent from './web-event.component.svg'
import emailLinkClickedEvent from './email-open-event.component.svg'
import formAnsweredEvent from './form-answered-event.component.svg'
import emailUnsubscribedEvent from './email-unsubscribed-event.component.svg'
import landingPageVisitedEvent from './landing-page-visited.component.svg'
import personOutline from './person-outline.component.svg'
import tree1 from './tree1.component.svg'
import tree2 from './tree2.component.svg'
import tree3 from './tree3.component.svg'
import tree4 from './tree4.component.svg'
import tree5 from './tree5.component.svg'
import exitPage from './exit-page.component.svg'
import questionMark from './question-mark.component.svg'
import checkedCheckbox from './checkbox-checked.component.svg'
import uncheckedCheckbox from './checkbox-unchecked.component.svg'
import triangleRight from './triangle-right.component.svg'
import successCheckmark from './success-checkmark.component.svg'
import closeToast from './close-toast.component.svg'
import fail from './fail.component.svg'
import triangleDown from './triangle-down.component.svg'
import dragDrop from './drag-drop.component.svg'
import lock from './lock.component.svg'
import emarketeerOnlight from './emarketeer-onlight.component.svg'
import threeDots from './three-dots.component.svg'
import superoffice from './superoffice.component.svg'
import closeRound from './close-round.component.svg'

export default {
  filterArrow,
  backArrow,
  filterSettings,
  close,
  calendar,
  newUser,
  returningUser,
  email,
  twitter,
  emarketeer,
  google,
  bing,
  yahoo,
  facebook,
  youtube,
  search,
  web,
  watching,
  desktopWindows,
  desktopMac,
  iphone,
  android,
  tablet,
  heart,
  emptyHeart,
  star,
  emptyStar,
  person,
  clock,
  eye,
  plus,
  linkedIn,
  twitterAlt,
  facebookAlt,
  youtubeAlt,
  webEvent,
  emailLinkClickedEvent,
  emailUnsubscribedEvent,
  formAnsweredEvent,
  landingPageVisitedEvent,
  personOutline,
  tree1,
  tree2,
  tree3,
  tree4,
  tree5,
  exitPage,
  questionMark,
  checkedCheckbox,
  uncheckedCheckbox,
  triangleRight,
  successCheckmark,
  closeToast,
  fail,
  triangleDown,
  dragDrop,
  lock,
  emarketeerOnlight,
  superoffice,
  threeDots,
  closeRound,
}
