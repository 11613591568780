export class Plan {
  public code: string
  public leads: string
  public price: number

  constructor(data) {
    this.code = data.plan_code
    this.leads = data.name.split(' ')[0]
    if (data.interval_unit === 'years') {
      this.price = Math.round(data.recurring_price / 12)
    } else {
      this.price = data.recurring_price
    }
  }

  public isSelected = (currentPlanCode) => this.code === currentPlanCode
}
