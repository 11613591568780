import * as React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

interface WrapperProps {
  timeout: number
}

const Wrapper = styled.div<WrapperProps>`
  &.fade-in-appear {
    opacity: 0.01;
  }

  &.fade-in-appear.fade-in-appear-active {
    opacity: 1;
    transition: opacity ${({ timeout }) => timeout}ms ease-in;
  }
`

interface FadeInProps {
  children: React.ReactNode
  timeout?: number
}

export const FadeIn: React.SFC<FadeInProps> = ({ children, timeout = 500 }) => (
  <CSSTransitionGroup
    transitionName="fade-in"
    transitionAppear={true}
    transitionAppearTimeout={timeout}
    transitionEnter={false}
    transitionLeave={false}
  >
    <Wrapper
      timeout={timeout}
    >
      {children}
    </Wrapper>
  </CSSTransitionGroup>
)
