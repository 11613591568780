import * as React from 'react'
import styled from 'styled-components'
import { RoundCheckbox } from '../../index'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 10px 0;
  border-top: 1px solid #ECF1F5;
  &:nth-child(1) {
    border-top: none;
  }
`
const CardRow = styled.div`
  margin: 5px 0;
  font-size: 14px;
`
const CheckboxRow = styled(CardRow)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const CardRowTitle = styled.div`
  font-weight: bold;
`
const CardCheckbox = styled(RoundCheckbox)`
  margin-right: 0;
`

export const TableCard = ({ row, onItemToggle }) => (
  <Wrapper>
    <CheckboxRow>
      <CardRowTitle>Account</CardRowTitle>
      <CardCheckbox
        checked={row.enabled}
        onClick={onItemToggle}
      />
    </CheckboxRow>
    <CardRow>{row.accountName}</CardRow>
    <CardRow><CardRowTitle>View</CardRowTitle></CardRow>
    <CardRow>{row.viewName}</CardRow>
  </Wrapper>
)
