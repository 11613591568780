import styled from 'styled-components'

import { Alignment } from '../table/table.component';

interface RowProps {
  alignment?: Alignment
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ alignment = Alignment.left}) => alignment};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
