import styled from 'styled-components'
import * as Dropzone from 'react-dropzone'
import { debounce } from 'lodash'

export const RatioWrapper = styled.div`
  width: 100%;
`
export const StyledDropzone = styled(Dropzone.default || Dropzone)`
  background: #EAEAEA;
  padding-top: 100%;
  width: 100%;
  border-radius: 6px;
  position: relative; 
`
export const DropzoneContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`
export const DropzoneText = styled.div`
  color: #A5A6A6;
  font-size: 24px;
  line-height: 28px;
  margin-top: 34px;
  text-align: center;
  @media (max-width: 400px) {
    font-size: 18px;
    line-height: 22px;
  }
`
export const SmallerDropzoneText = styled.div`
  font-size: 20px;
  @media (max-width: 400px) {
    font-size: 16px;
    line-height: 18px;
  }
`
export const CropperWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`

