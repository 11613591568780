import styled from 'styled-components'

import { Icon } from 'components'

export const Form = styled.form`
  position: relative;
  width: 700px;
  margin: 0 auto;
  text-align: center;
`

export const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
`
export const SearchIcon = styled(Icon).attrs({
  icon: 'search',
})`
  margin: 0;
`
export const SubmitButton = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`
