import styled from 'styled-components'
import { Link as BaseLink } from '../../../../../../components'

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  div {
    font-size: 18px;
  }
`

export const Link = styled(BaseLink)`
  color: #61869E;
  font-size: 14px;
`

export const FormWrapper = styled.div`
  min-height: 200px;
`
