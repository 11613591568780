import { addLocaleData } from 'react-intl'
import enLocaleData from 'react-intl/locale-data/en'
import svLocaleData from 'react-intl/locale-data/sv'

import enJson from './en.json'
import svJson from './sv.json'

import { flattenMessages } from '../utils/flatten-messages'

addLocaleData([...enLocaleData, ...svLocaleData])

const en = flattenMessages(enJson)
const sv = flattenMessages(svJson)

export default {
  'en-US': en,
  'en-GB': en,
  'sv-SE': sv,
  en,
  sv,
}
