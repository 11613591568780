import * as React from 'react'
import { map, times, chain, get } from 'lodash'
import * as moment from 'moment'

import { Show, Icon, LoadingBlock } from '../../../../../../components'
import {
  Events,
  Line,
  Event,
  Details,
  TopText,
  Header,
  Date,
  Users,
  SinglePersonPhoto,
  FirstPersonIcon,
  FirstPersonPhoto,
  SeconPersonIcon,
  SecondPersonPhoto,
  LoadingAvatar,
  LoadingIcon,
  TextBlock,
  PersonName,
  EventDescription,
  LowerSection,
  EventDetails,
  EventEntityName,
  EventSource,
  Controls,
  ShowMoreButton,
  NoData,
} from './marketing-events.styles'

const actionNames = {
  emailLinkClicked: 'interacted',
  landingPageVisited: 'visited your landing page',
  formAnswered: 'answered a form',
  emailUnsubscribed: 'unsubscribed',
}

export const MarketingEvents = ({ events, loading, showEventDetails }) => {
  return (
    <Events>
      <Show if={loading || events.length !== 0}>
        <Line />
        <Show if={!loading || events.length !== 0}>
          {map(events, (event, i) => {
            const photo1 = get(event, 'persons[0].photo')
            const photo2 = get(event, 'persons[1].photo')
            const { persons = [] } = event
            if (!event) {
              return null
            }
            return (
              <Event key={i}>
                <Header>
                  <Icon size={44} icon={`${event.type}Event`} />
                  <TopText>
                    <Date>
                      {moment(event.date, 'YYYY-MM-DD')
                          .calendar(null, {
                            sameDay: '[Today]',
                            lastDay: 'MMM Do, YYYY',
                            nextWeek: 'MMM Do, YYYY',
                            lastWeek: 'MMM Do, YYYY',
                            sameElse: 'MMM Do, YYYY',
                          })}
                    </Date>
                  </TopText>
                </Header>
                <Details>
                  <Users>
                    <Show if={persons.length === 1}>
                      <Show if={photo1}>
                        <SinglePersonPhoto image={photo1} />
                      </Show>
                      <Show if={!photo1}>
                        <Icon size={54} icon="person" />
                      </Show>
                    </Show>
                    <Show if={persons.length > 1}>
                      <Show if={photo1}>
                        <FirstPersonPhoto image={photo1} />
                      </Show>
                      <Show if={!photo1}>
                        <FirstPersonIcon size={40} icon="personOutline" />
                      </Show>
                      <Show if={photo2}>
                        <SecondPersonPhoto image={photo2} />
                      </Show>
                      <Show if={!photo2}>
                        <SeconPersonIcon size={40} icon="personOutline" />
                      </Show>
                    </Show>
                  </Users>
                  <TextBlock>
                    <EventDescription>
                      <PersonName>{(chain(persons) as any)
                          .slice(0, 2)
                          .map(person => person.name || person.email)
                          .join(', ')
                          .value()}
                      </PersonName>
                      <Show if={persons.length > 2}>
                          &nbsp;and {persons.length - 2} others
                      </Show>
                      &nbsp;{actionNames[event.type]}
                    </EventDescription>
                    <LowerSection>
                      <EventDetails>
                        <EventEntityName>
                          {event.name}
                        </EventEntityName>
                        <EventSource>
                            Source: eMarketeer
                        </EventSource>
                      </EventDetails>
                      <Controls>
                        <ShowMoreButton onClick={() => showEventDetails(event)}>Show More</ShowMoreButton>
                      </Controls>
                    </LowerSection>
                  </TextBlock>
                </Details>
              </Event>
            )
          })}
        </Show>

        <Show if={loading}>
          {times(3, i => (
            <Event key={i}>
              <Header>
                <LoadingIcon size={44} />
                <TopText>
                  <Date>
                    <LoadingBlock width="60%" />
                  </Date>
                </TopText>
              </Header>
              <Details>
                <Users>
                  <LoadingAvatar size={54} />
                </Users>
                <TextBlock>
                  <EventDescription>
                    <LoadingBlock width="60%" />
                    <LoadingBlock width="60%" />
                  </EventDescription>
                </TextBlock>
              </Details>
            </Event>
            ))}
        </Show>
      </Show>

      <Show if={!loading && events.length === 0}>
        <NoData>No Marketing Events Found</NoData>
      </Show>
    </Events>
  )
}
