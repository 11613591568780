import styled from 'styled-components'
import { LoadingBlock, Row as BaseRow } from 'components'

export const SubTitle = styled.h3`
  display: flex;
  margin-top: 36px;
  margin-bottom: 4px;
  font-weight: 300;
  line-height: normal;
  font-size: 24px;
  color: #314550;
`

export const List = styled.div`
  display: flex;
  padding: 24px;
  background: #ffffff;
  border-radius: 10px;
`

export const LoadingCard = styled(LoadingBlock)`
  width: 250px;
  height: 300px;
  margin: 0;
  border-radius: 0;
`

export const Row = styled(BaseRow)`
  min-height: 300px;
`

export const NoActiveIntegrations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 300px;
  color: white;
  font-size: 16px;
  background: #cdcdcd;
  text-transform: uppercase;
`
