import * as moment from 'moment'
import { Moment } from 'moment'
import { SET_PROSPECTS_DATE_RANGE, SET_PROSPECTS_SORTING, SET_TRIAL_PROSPECTS_DATE_RANGE } from 'actions'

interface State {
  updated: boolean
  dateRange: {
    title: string
    startDate: Moment
    endDate: Moment
  }
  sortBy: 'score' | 'dateTime'
}

const defaultState: State = {
  updated: false,
  dateRange: {
    title: 'Since yesterday',
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  },
  sortBy: 'score'
}

export const prospectsList = (state = defaultState, action): State => {
  switch (action.type) {
    case SET_TRIAL_PROSPECTS_DATE_RANGE:
      if (!state.updated) {
        return {
          ...state,
          updated: true,
          dateRange: {
            title: 'Last 7 days',
            startDate: moment().add(-7, 'days'),
            endDate: moment()
          }
        }
      }
      return state
    case SET_PROSPECTS_DATE_RANGE:
      return {
        ...state,
        updated: true,
        dateRange: action.payload,
      }
    case SET_PROSPECTS_SORTING:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
