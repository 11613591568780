import { includes, startCase } from 'lodash'

const knownSources = ['google', 'bing', 'facebook', 'twitter', 'yahoo', 'youtube']

export const getSourceDescription = (sourceMedium) => {
  const [source] = sourceMedium.split('/')
    .map(s => s.trim())

  let text = `Came through ${source}`
  let icon = 'web'

  if (source === '(direct)') {
    text = 'Direct - by typing your web URL in browser'
  }

  if (source === 'emarketeer.com') {
    text = 'Came through an eMarketeer Campaign'
    icon = 'emarketeer'
  }

  if (includes(knownSources, source)) {
    text = `Came through ${startCase(source)}`
    icon = source
  }

  return {
    text,
    icon,
  }
}
