import * as React from 'react'
import styled from 'styled-components'
import { times } from 'lodash'
import { Sticky } from 'react-sticky'
import { withRouter } from 'react-router-dom'

import { LoadingBlock , PageTabs } from '../../../components'
import { Events, registerAnalyticEvent } from 'utils'

const CompanyPageTabs = styled(PageTabs)`
  max-width: calc(100vw - 20px);
  padding-left: 7px;
  background-color: ${({ theme }) => theme.CANVAS_COLOR};
  @media (max-width: 800px) {
    padding: 20px 20px 0 20px;
  } 
`
const TabLoading = styled(LoadingBlock)`
  width: 70px;
  height: 10px;
  margin: 0 4px 14px 0;
  padding: 8px 16px;
`
const WideTabLoading = styled(TabLoading)`
  width: 120px;
`

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

interface CompanyTabsProps {
  domain?: any
  serviceProvider?: any
  location?: any
  match?: any
}

// @ts-ignore
@withRouter
export class CompanyTabs extends React.PureComponent<CompanyTabsProps> {
  public state = {
    isSmallScreen: false,
  }

  private mql: any

  public componentDidMount() {
    registerAnalyticEvent(Events.COMPANY_PAGE_VISIT, { companyId: this.props.match.params.id })
    this.mql = window.matchMedia('(min-width: 801px)')
    this.mql.addListener(this.handleMediaQueryChanged)
    this.handleMediaQueryChanged()
  }

  public componentWillUnmount() {
    if (this.mql) {
      this.mql.removeListener(this.handleMediaQueryChanged)
    }
  }

  public getTabsContent = (domain, serviceProvider, location, stickyStyles = {}, isSticky = false) => {
    const style = stickyStyles ? {
      ...stickyStyles,
      marginTop: isSticky ? 60 : 0,
      zIndex: 10,
    } : {}

    if (domain) {
      return (
        <MenuWrapper>
          <CompanyPageTabs style={style}>
            <PageTabs.Item
              to={`/webleads/company/${domain}/dashboard?serviceProvider=${encodeURIComponent(serviceProvider)}`}
              location={location}
            >
              Company Dashboard
            </PageTabs.Item>
            <PageTabs.Item
              to={`/webleads/company/${domain}/financial-report?serviceProvider=${encodeURIComponent(serviceProvider)}`}
              location={location}
            >
              Financial Report
            </PageTabs.Item>
            <PageTabs.Item
              to={`/webleads/company/${domain}/social-media?serviceProvider=${encodeURIComponent(serviceProvider)}`}
              location={location}
            >
              Social Media
            </PageTabs.Item>
            <PageTabs.Item
              to={`/webleads/company/${domain}/in-media?serviceProvider=${encodeURIComponent(serviceProvider)}`}
              location={location}
            >
              In Media
            </PageTabs.Item>
            <PageTabs.Item
              to={`/webleads/company/${domain}/technology?serviceProvider=${encodeURIComponent(serviceProvider)}`}
              location={location}
            >
              Technology
            </PageTabs.Item>
          </CompanyPageTabs>
        </MenuWrapper>
      )
    }

    return (
      <CompanyPageTabs style={style}>
        {times(2, i => <WideTabLoading key={i} />)}
        {times(3, i => <TabLoading key={i} />)}
      </CompanyPageTabs>
    )
  }

  public handleMediaQueryChanged = () => {
    const { matches } = this.mql
    this.setState({ isSmallScreen: !matches })
  }

  public render() {
    const { domain, serviceProvider, location } = this.props
    const { isSmallScreen } = this.state

    return this.getTabsContent(domain, serviceProvider, location)
  }
}
