import * as React from 'react'
import { scaleLinear } from 'd3-scale'

const getY = scaleLinear()
  .domain([0, 100])
  .range([33, 1.654623])

export const Thermometer = ({ color, value, big }) => (
  <svg
    width={big ? 50 : 25}
    height={big ? 106 : 53}
    viewBox="0 0 25 53"
  >
    <g transform="translate(1101 -1027)">
      <path
        transform="translate(-1083.45 1037.1)"
        fill="#C4C4C4"
        d="M 0 0.977692C 0 0.437727 0.437727 0 0.977692 0L 6.17056 0C 6.71052 0 7.14825 0.437727 7.14825 0.977692C 7.14825 1.51766 6.71052 1.95538 6.17056 1.95538L 0.977691 1.95538C 0.437726 1.95538 0 1.51766 0 0.977692Z"
      />
      <path
        transform="translate(-1083.45 1044.92)"
        fill="#C4C4C4"
        d="M 0 0.977692C 0 0.437727 0.437727 0 0.977692 0L 6.17056 0C 6.71052 0 7.14825 0.437727 7.14825 0.977692C 7.14825 1.51766 6.71052 1.95538 6.17056 1.95538L 0.977691 1.95538C 0.437726 1.95538 0 1.51766 0 0.977692Z"
      />
      <path
        transform="translate(-1083.45 1053.07)"
        fill="#C4C4C4"
        d="M 0 0.977692C 0 0.437727 0.437727 0 0.977692 0L 6.17056 0C 6.71052 0 7.14825 0.437727 7.14825 0.977692C 7.14825 1.51766 6.71052 1.95538 6.17056 1.95538L 0.977691 1.95538C 0.437726 1.95538 0 1.51766 0 0.977692Z"
      />
      <path
        transform="translate(-1083.45 1041.01)"
        fill="#C4C4C4"
        d="M 0 0.977692C 0 0.437727 0.437727 0 0.977691 0L 3.89611 0C 4.43608 0 4.87381 0.437727 4.87381 0.977692C 4.87381 1.51766 4.43608 1.95538 3.89611 1.95538L 0.977691 1.95538C 0.437727 1.95538 0 1.51766 0 0.977692Z"
      />
      <path
        transform="translate(-1083.45 1049.16)"
        fill="#C4C4C4"
        d="M 0 0.977692C 0 0.437727 0.437727 0 0.977691 0L 3.89611 0C 4.43608 0 4.87381 0.437727 4.87381 0.977692C 4.87381 1.51766 4.43608 1.95538 3.89611 1.95538L 0.977691 1.95538C 0.437727 1.95538 0 1.51766 0 0.977692Z"
      />
      <path
        transform="translate(-1101 1058.61)"
        d="M 21.1198 10.5917C 21.1198 16.4413 16.392 21.1833 10.5599 21.1833C 4.72783 21.1833 0 16.4413 0 10.5917C 0 4.74205 4.72783 0 10.5599 0C 16.392 0 21.1198 4.74205 21.1198 10.5917Z"
        fill="#C4C4C4"
      />
      <path
        transform="translate(-1095.8 1027)"
        fill="#C4C4C4"
        d="M 0 5.3612C 0 2.40029 2.40028 0 5.36119 0C 8.32209 0 10.7224 2.40029 10.7224 5.36119L 10.7224 33.0947C 10.7224 36.0556 8.32209 38.4559 5.36119 38.4559C 2.40028 38.4559 0 36.0556 0 33.0947L 0 5.3612Z"
      />
      <path
        transform="translate(-1099.38 1060.57)"
        fill="#FFFFFF"
        d="M 17.8706 8.79922C 17.8706 13.6589 13.8701 17.5984 8.93531 17.5984C 4.00047 17.5984 0 13.6589 0 8.79922C 0 3.93955 4.00047 0 8.93531 0C 13.8701 0 17.8706 3.93955 17.8706 8.79922Z"
      />
      <path
        transform="translate(-1094.18 1028.63)"
        fill="#FFFFFF"
        d="M 0 3.73658C 0 1.67292 1.67292 0 3.73658 0C 5.80024 0 7.47317 1.67293 7.47317 3.73659L 7.47317 35.3711C 7.47317 37.4347 5.80024 39.1077 3.73658 39.1077C 1.67292 39.1077 0 37.4347 0 35.3711L 0 3.73658Z"
      />
      <path
        transform="translate(-1097.1 1062.85)"
        fill={color}
        d="M 13.3217 6.51794C 13.3217 10.1177 10.3396 13.0359 6.66087 13.0359C 2.98217 13.0359 0 10.1177 0 6.51794C 0 2.91818 2.98217 0 6.66087 0C 10.3396 0 13.3217 2.91818 13.3217 6.51794Z"
      />
      <line
        strokeLinecap="round"
        transform="translate(-1091.9 1030.58)"
        x1="1.454623"
        y1={getY(value)}
        x2="1.454623"
        y2="39.1077"
        stroke={color}
        strokeWidth={3}
        fill="none"
      />
    </g>
  </svg>
)
