import styled from 'styled-components'

interface SearchResultWrapperProps {
  isOpen: boolean
}

export const SearchResultWrapper = styled.div<SearchResultWrapperProps>`
  width: ${({ isOpen }) => isOpen ? '256px' : 0};
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #ECF1F5;
  border-top: none;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
`

export const SearchResultItem = styled.div`
  position: relative;
  padding: 12px 46px 12px 12px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 1px solid #ECF1F5;
  cursor: pointer;
  
  &:hover {
    background: rgba(49,69,80,.03);
  }
`

interface ScoreProps {
  color: string
}

export const Score = styled.div<ScoreProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 12px;
  width: 26px;
  height: 26px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 13px;
  background-color: ${({ color }) => color};
`
