import { LOCATION_CHANGE } from 'react-router-redux'

const state = {
  scrollPositions: {},
  currentRoute: null,
}

export const customRouterMiddleware = () => next => (action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { documentElement } = document
      const { pathname } = action.payload

      const scrollPosition = documentElement.scrollTop

      if (state.currentRoute) {
        state.scrollPositions[state.currentRoute] = scrollPosition
      }

      if (documentElement && documentElement.scrollTo) {
        const existingState = state.scrollPositions[pathname]

        if (existingState) {
          window.requestAnimationFrame(() => {
            documentElement.scrollTo(0, existingState)
          })
        } else {
          documentElement.scrollTo(0, 0)
        }
      }

      state.currentRoute = pathname

      break
    }

    default:
  }

  return next(action)
}
