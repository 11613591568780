import * as React from 'react'
import { map } from 'lodash'

import { SearchResultCard } from './components'
import { Wrapper } from './search-result.styles'

export const SearchResult = ({ result, onCompanyClick }) => {
  return (
    <Wrapper>
      {map(result, (company) => (
        <SearchResultCard
          onClick={onCompanyClick.bind(null, company.id)}
          company={company}
        />
      ))}
    </Wrapper>
  )
}
