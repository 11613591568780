import * as React from 'react'

import { Button } from 'components'
import { Wrapper, Title, LeadsLeftText } from './lead-limit-header.styles'

interface LeadLimitHeaderProps {
  tenantStats: {
    currentLimit: number
    noOfCompanies: number
  }
  onClick: () => void
}

export class LeadLimitHeader extends React.PureComponent<LeadLimitHeaderProps> {
  public render(): React.ReactNode {
    const { tenantStats, onClick } = this.props

    if (!tenantStats) {
      return null
    }

    const limit = tenantStats.noOfCompanies - tenantStats.currentLimit

    if (limit <= 0) {
      return null
    }

    return (
      <Wrapper>
        <Title>
          Lead Limit Reached
        </Title>
        <LeadsLeftText>
          We found an additional <span>{limit} Leads</span> that we would like to show you.
        </LeadsLeftText>
        <Button
          color="success"
          onClick={onClick}
        >
          Upgrade your account to see them
        </Button>
      </Wrapper>
    )
  }
}
