import * as React from 'react'
import { compose } from 'react-apollo'

import { SidebarLayout, Show, LoadingOverlay } from './../../components'
import { Wrapper } from './blacklist.styles'
import { BlacklistCard } from './components'
import queries from './blacklist.query'
import { success } from '../../utils/toast'

interface BlacklistProps {
  deleteBlacklist: any
  updateBlacklist: any
  data: any
}

@compose(...queries)
export class Blacklist extends React.Component<BlacklistProps> {
  public state = {
    loading: false,
  }

  public addRecord = async (record) => {
    this.setState({ loading: true })
    const { updateBlacklist, data } = this.props

    await updateBlacklist({ variables: { records: [record] } })
    await data.refetch()
    this.setState({ loading: false })
    success('Added blacklist record')
  }

  public deleteRecord = async (record) => {
    this.setState({ loading: true })
    const { deleteBlacklist, data } = this.props

    await deleteBlacklist({ variables: { records: [record] } })
    await data.refetch()
    this.setState({ loading: false })
    success('Removed blacklist record')
  }

  public render() {
    const { data: { loading }, data } = this.props

    return (
      <SidebarLayout>
        <Show if={this.state.loading}>
          <LoadingOverlay
            active={true}
            spinner={true}
            background="rgba(0,0,0,0.4)"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 20,
            }}
          />
        </Show>
        <Wrapper>
          <BlacklistCard
            records={data.getBlacklist}
            loading={loading}
            addRecord={this.addRecord}
            deleteRecord={this.deleteRecord}
          />
        </Wrapper>
      </SidebarLayout>
    )
  }
}
