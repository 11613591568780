import * as React from 'react'
import { map, chain, get } from 'lodash'
import * as moment from 'moment'

import { Show, ProgressiveImage } from '../../../../../../components'
import { getSourceDescription, getScreenshotUrl } from 'utils'
import { PageView } from '../page-view'
import {
  Wrapper,
  Description,
  Header,
  Name,
  Logo,
  Arrow,
  Content,
  CountryFlag,
  DurationBlock,
  InterestingPageBlock,
  PagePreview,
  PagesSection,
  PageViews,
  Source,
  SourceIcon,
  Time,
  Visitor,
  VisitorDetails
} from './visit-details.styles'

export const VisitDetails = ({ visit = { visitor: {} as any} as any, company = {} as any }) => {
  const { visitor = {} } = visit

  const sourceDescription = getSourceDescription(visit.sourceMedium)
  const mostInterestingPage = chain(visit.sessionPages)
    .orderBy('duration', 'desc')
    .first()
    .value()

  return (
    <Wrapper>
      <Header>
        <DurationBlock>
          <Time>
            {moment.utc((chain(visit.sessionPages) as any)
              .map('duration')
              .sum() * 1000)
              .format('mm:ss')}
          </Time>
          <Description>
            Total Visit Duration
          </Description>
        </DurationBlock>
        <InterestingPageBlock>
          <PagePreview>
            <ProgressiveImage
              src={getScreenshotUrl(get(mostInterestingPage, 'fullUrl'))}
              styles={{ backgroundSize: 'cover', position: 'top center' }}
              loadingAnimation={true}
            />
          </PagePreview>
          <Description>
            Most Interesting Page
          </Description>
        </InterestingPageBlock>
      </Header>
      <Content>
        <Visitor>
          <Logo image={company.logo} />
          <div>
            <Name>
              {`${get(visitor, 'fullName') || 'Anonymous'} from ${company.name}`}
            </Name>
            <VisitorDetails>
              <Show if={get(visitor, 'gender')}>
                {get(visitor, 'gender')}
              </Show>
              <Show if={get(visitor, 'gender') && get(visitor, 'ageRange')}>
                {', '}
              </Show>
              <Show if={get(visitor, 'ageRange')}>
                {`${get(visitor, 'ageRange')} y`}
              </Show>
              <Show if={visit.city || visit.country || visit.countryCode}>
                {'from '}
                <Show if={visit.city}>
                  {visit.city}
                </Show>
                <Show if={visit.city && visit.country}>
                  {', '}
                </Show>
                <Show if={visit.country}>
                  {visit.country}
                </Show>
                <Show if={visit.countryCode}>
                  <CountryFlag code={visit.countryCode} />
                </Show>
              </Show>
            </VisitorDetails>
          </div>
        </Visitor>
        <Source>
          <SourceIcon icon={sourceDescription.icon} size={38} />
          {sourceDescription.text}
        </Source>
        <PagesSection>
          <Arrow />
          <PageViews>
            {map(visit.sessionPages, (page, i) => (
              <PageView
                key={i}
                page={page}
                i={i}
                visit={visit}
              />
            ))}
          </PageViews>
        </PagesSection>
      </Content>
    </Wrapper>
  )
}
