import * as React from 'react'
import styled from 'styled-components'
import { toLower } from 'lodash'

const FlagImg = styled.img`
  margin-right: 8px;
`

export const Flag = ({ code = 'se', ...props }) => (
  <FlagImg
    src={`/images/flags/${toLower(code)}.svg`}
    width={16}
    height={code === 'ZZ' ? 16 : 12}
    alt={code}
    {...props}
  />
)
