import gql from 'graphql-tag'

export const getSubscriptionStatus = gql`
  query {
    getPaymentSubscriptionStatus {
      active
      trial
      users
      subscription {
        plan {
          name
          interval
          interval_unit
          plan_code
          recurring_price
        }
      }
    }
  }
`

export const getPaymentPlans = gql`
  query getPaymentPlans($plan_code: String) {
    getPaymentPlans(plan_code: $plan_code) {
      name
      interval
      interval_unit
      plan_code
      recurring_price
    }
    getTenantBillingDetails {
      address1
      address2
      city
      countryCode
      emailAddress
      name
      zipCode
      vatID
    }
  }
`

export const getPaymentSubscriptionStatus = gql`
  query {
    getPaymentSubscriptionStatus {
      subscription {
        name
        current_term_ends_at
        next_billing_at
        amount
        currency_code
        plan {
          name
          quantity
          price
          total
        }
        addons {
          addon_code
          name
          addon_description
          quantity
          price
          discount
          total
          tax_id
        }
        status
        last_billing_at
        interval
        interval_unit
        card {
          card_id
          expiry_month
          expiry_year
          last_four_digits
        }
      }
    }
  }
`

export const getPaymentInvoices = gql`
  query {
    getPaymentInvoices {
      invoice_date
      number
      status
      total
      currency_code
      url
    }
  }
`

export const createPaymentSubscription = gql`
  mutation createPaymentSubscription($plan_code: String!, $redirect_uri: String!) {
    createPaymentSubscription(
      plan_code: $plan_code,
      redirect_uri: $redirect_uri
    ) {
      url
    }
  }
`

export const getTenantBillingDetails = gql`
  query {
    getTenantBillingDetails {
      address1
      address2
      city
      countryCode
      emailAddress
      name
      vatID
      zipCode
    }
  }
`

export const registerVatID = gql`
  mutation registerVatID(
    $name: String!,
    $address1: String!,
    $address2: String,
    $countryCode: String!,
    $city: String!,
    $zipCode: String,
    $vatID: String,
    $emailAddress: String
  ) {
    updateTenantBillingDetails(
      name: $name,
      address1: $address1,
      address2: $address2,
      countryCode: $countryCode,
      city: $city,
      zipCode: $zipCode
      vatID: $vatID,
      emailAddress: $emailAddress,
    ) {
      name
      address1
      address2
      countryCode
      city
      zipCode
      vatID
      emailAddress
    }
  }
`

export const updateCard = gql`
  mutation updateCard($redirect_uri: String!) {
    updatePaymentCard(redirect_uri: $redirect_uri) {
      url
    }
  }
`

export const deleteCard = gql`
  mutation deleteCard($card_id: String!) {
    deletePaymentCard(card_id: $card_id)
  }
`

export const cancelPaymentSubscription = gql`
  mutation {
    cancelPaymentSubscription
  }
`
