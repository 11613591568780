import * as React from 'react'
import { map } from 'lodash'
import { Show, Count } from '../../../../../components'
import {
  EmployeeRows,
  EmployeeRow,
  PersonPhoto,
  PersonIcon,
  Description,
  Name,
  Title,
  Source,
} from '../active-employees.styles'
import { Header } from './active-employees-selection.styles'

export const ActiveEmployeesSelection = ({ employees }) => (
  <EmployeeRows>
    <Header>
      Active Employees
      <Count>{employees.length}</Count>
    </Header>
    {map(employees, (employee, i) => (
      <EmployeeRow key={i}>
        <Show if={employee.avatar}>
          <PersonPhoto image={employee.avatar} />
        </Show>
        <Show if={!employee.avatar}>
          <PersonIcon icon="person" size={56} />
        </Show>
        <Description>
          <Name>{employee.fullName}</Name>
          <Show if={employee.title}>
            <Title>{employee.title}</Title>
          </Show>
          <Source>Source: {employee.source}</Source>
        </Description>
      </EmployeeRow>
    ))}
  </EmployeeRows>
)
