import * as React from 'react'
import styled from 'styled-components'
import { Icon } from 'components'

const BigText = styled.div`
  font-size: 18px;
  color: #D85251;
`

const SmallText = styled.div`
  font-size: 14px;
  color: #D85251;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
`

export const GoogleAnalyticsError = () => (
  <Wrapper>
    <Icon icon="closeRound" color="#D85251" size={40} />
    <div>
      <BigText>
        Your Google Analytics connection is broken
      </BigText>
      <SmallText>
        Try to disconnect and then re-connect. Need help? Contact support (bottom right corner).
      </SmallText>
    </div>
  </Wrapper>
)
