import { keys, each, map } from 'lodash'
import { d20140228, d20160901 } from './definitions'

const definitions = {
  '20140228': d20140228,
  '20160901': d20160901,
}

export class GICS {
  private readonly definition: any
  private sectors: string[] = []
  private industryGroups: string[] = []
  private industries: string[] = []
  private subIndustries: string[] = []

  constructor(version = '20160901') {
    this.definition = definitions[version]
    each(keys(this.definition), (key) => {
      switch (key.length) {
        case 2: {
          this.sectors.push(key)
          break
        }
        case 4: {
          this.industryGroups.push(key)
          break
        }
        case 6: {
          this.industries.push(key)
          break
        }
        case 8: {
          this.subIndustries.push(key)
          break
        }
      }
    })
  }

  public getIndustryGroupsOptionsList = () => {
    return map(this.industryGroups, (key) => {
      return {
        value: key,
        label: this.definition[key].name
      }
    })
  }
}

export const gicsDefault = new GICS()
