import styled from 'styled-components'

export const Input = styled.input`
  font-size: 16px;
  margin-bottom: 8px;
  padding: 4px 8px;
  border: 1px solid white;
  border-radius: 5px;
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-size: 14px;
    color: #314550;
  }
`
