import * as React from 'react'
import { compose, withApollo } from 'react-apollo'
import queryString from 'query-string'
import { get } from 'lodash'

import { Card, Show, LoadingBlock, LoadingOverlay } from 'components'
import { Events, getRedirectUri, registerAnalyticEvent } from 'utils'
import { emarketeerIntegrationQueries } from './emarketeer-integration.query'
import {
  Title, Wrapper, Content, ConnectButton, ConnectedAccount, DisconnectButton,
  InfoCard, Motivation, MotivationContent, MotivationHeader, WrappingContent
} from './emarketeer-integeration.style'

interface EmarketeerIntegrationSettingsProps {
  emarketeerAuthUrl: any
  submitEmarketeerCode: any
  disconnect: any
  data: any
}

@compose(...emarketeerIntegrationQueries)
// @ts-ignore
@withApollo
export class EmarketeerIntegrationSettings extends React.PureComponent<EmarketeerIntegrationSettingsProps> {
  public static defaultProps = {
    data: {},
  }

  private static instance: Window
  private static timer: any

  public state = {
    loading: false,
  }

  public connect() {
    const { emarketeerAuthUrl: { getEmarketeerAuthUrl: { url, clientId } }, submitEmarketeerCode, data } = this.props

    const redirectUri = getRedirectUri('/callback/done')

    const current = window.location.origin
    const authUrl = encodeURIComponent(`${url}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`)
    const popupUrl = getRedirectUri(`/callback?current=${current}&next=${authUrl}`)

    if (EmarketeerIntegrationSettings.instance) {
      EmarketeerIntegrationSettings.instance.close()
      clearInterval(EmarketeerIntegrationSettings.timer)
    }

    EmarketeerIntegrationSettings.instance = window.open(popupUrl, '', 'top=100,left=100,width=500,height=500')
    registerAnalyticEvent(Events.EMARKETEER_CONNECT_STARTED)
    EmarketeerIntegrationSettings.timer = setInterval(() => {
      const { instance, timer } = EmarketeerIntegrationSettings
      if (!instance || instance.closed) {
        registerAnalyticEvent(Events.EMARKETEER_CONNECT_STOPPED)
        clearInterval(timer)
        return
      }
      try {
        if (instance.document.URL.includes('/callback/done')) {
          const code = queryString.parse(new URL(instance.document.URL).search).code
          this.setState({ loading: true })

          submitEmarketeerCode({ variables: { code } })
            .then((result) => {
              if (result.data.setupEmarketeerIntegration.accountId) {
                registerAnalyticEvent(Events.EMARKETEER_CONNECTED)
              } else {
                registerAnalyticEvent(Events.EMARKETEER_CONNECT_FAILURE)
              }
              data.refetch()
            })
            .finally(() => this.setState({ loading: false }))

          clearInterval(timer)
          instance.close()
        }
      } catch (e) {
        if (!instance || instance.closed) {
          clearInterval(timer)
        }
      }
    }, 150)
  }

  public disconnect = async () => {
    const { disconnect, data } = this.props

    this.setState({ loading: true })
    await disconnect()
    await data.refetch()
    this.setState({ loading: false })
  }

  public render() {
    const { data, data: { loading } } = this.props

    const connected = get(data, 'getEmarketeerIntegrationStatus.connected')

    return (
      <Wrapper>
        <Title>eMarketeer</Title>
          <Show if={this.state.loading}>
            <LoadingOverlay
              active={true}
              spinner={true}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                zIndex: 20,
              }}
            />
          </Show>
        <Content>
          <InfoCard>
            <Card.Title>
              Connected Emarketeer Account
            </Card.Title>

            <WrappingContent>
              <ConnectedAccount>
                <Show if={!loading && connected}>
                  {get(data, 'getEmarketeerIntegrationStatus.companyName') || ''}
                </Show>
                <Show if={!loading && !connected}>
                  No account connected
                </Show>
                <Show if={loading}>
                  <LoadingBlock width="200px" height="40px" />
                </Show>
              </ConnectedAccount>

              <Show if={!loading && connected}>
                <DisconnectButton onClick={() => this.disconnect()}>
                Disconnect
                </DisconnectButton>
              </Show>
              <Show if={!loading && !connected}>
                <ConnectButton onClick={() => this.connect()}>
                Connect an account
                </ConnectButton>
              </Show>
            </WrappingContent>
          </InfoCard>
          <Motivation>
            <MotivationHeader>
              Why should you connect?
            </MotivationHeader>
            <MotivationContent>
              The eMarketeer integration will extend your leadsources with multiple
              marketing channels, Email, Forms, SMS, Landingpages and more. This
              integration will also give you a marketing timeline on the contact
              and company view. It also contributes to the active
              contacts information on the company view.
            </MotivationContent>
          </Motivation>
        </Content>
      </Wrapper>
    )
  }
}
