import styled from 'styled-components'
import { Tooltip } from 'react-tippy'

import { Flag, Icon } from '../../../../../../components'

export const PageViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const PageViewContent = styled.div`
  display: flex;
  flex-direction: row;
`
export const LeftColumn = styled.div`
  width: 106px;
  min-height: 96px;
  padding-right: 18px;
`
export const Thumbnail = styled.div`
  width: 108px;
  height: 96px;
`
export const ArrowDown = styled.div`
  padding: 4px 0;
  background: url('/images/components/web-visits-panel/arrow-down.svg') left 54px center no-repeat;
  height: 15px;
`
export const RightColumn = styled.div`
  font-size: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

interface LengthIndicatorProps {
  width?: number
  color?: string
}

export const LengthIndicator = styled.div<LengthIndicatorProps>`
  padding: 6px;
  width: calc(${({ width }) => width}% - 12px);
  background: ${({ color }) => color};
  height: 100%;
  min-width: 30px;
`
export const ExitPageWrapper = styled.div`
  padding: 4px 8px 8px 8px;
  width: calc(100% - 12px);
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #ECF1F5;
`
export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 10px;
  font-size: 12px;
`
export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  flex: 1 1 auto;
`
export const ExitPage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
`
export const TooltipHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
  text-transform: uppercase;
`
export const TooltipParagraph = styled.div`
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 300;
   &:last-child {
     margin-bottom: 0;
   }
`
export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: .8rem .4rem;
`
export const InnerText = styled.span`
  color: white;
`
export const PageTitle = styled.div`
  padding-bottom: 8px;
`
export const TextBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 21px;
  align-items: center;
`
export const ExitPageTime = styled.div`
  display: flex;
  flex-direction: row;
  height: 21px;
  align-items: center;
`
export const HidableText = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
  @media (max-width: 1200px) and (min-width: 800px) {
    display: none;
  }
`
export const QuestionMarkIcon = styled(Icon)`
  
`
export const TooltipContainer = styled(Tooltip)`
  width: 18px;
  display: flex !important;
`
