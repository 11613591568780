import * as React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { push } from 'react-router-redux'
import { compose } from 'react-apollo'
import queryString from 'query-string'
import { Timeline } from 'react-twitter-widgets'

import { SidebarLayout, PageSubHeader, BackButton, Card, Show, PredefinedDatePicker } from 'components'
import {
  InfoWrapper,
  InfoContent,
  CardsHolder,
  InfoCard,
  WebVisitsCard,
  CardContent,
  EmployeesCard,
  DoubleCards,
  PlaceholderCardContent,
  MapLoading,
  CompanyScore,
  WebVisitsCardTitle,
  DatePickerWrapper,
  Total,
  WithinDateRange,
  TwitterInfoCard,
  TwitterInner,
  MenuRow,
} from './company-container.styles'
import {
  Engagement,
  Interests,
  WebVisits,
  BasicInfo,
  ActiveEmployees,
  OtherEmployees,
  MarketingTimeline,
  CompanyTabs,
  MapView,
} from './components'
import query from './company.query'
import { setWebVisitsDateRange } from 'actions'
import { companySelector } from './company.selector'
import { CRMInsight } from './components/crm-insight/crm-insight.component'
import { AssignDropdown } from './components/assign-dropdown/assign-dropdown.component'

interface CompanyProps {
  data?: any
  webVisitsDateRange?: any
  webVisits?: any
  location?: any
  match?: any
  emarketeerIntegrationStatus?: any
  marketingEvents?: any
  history?: any
  push?: any
  setWebVisitsDateRange?: any
}

@connect(companySelector, { push, setWebVisitsDateRange })
@compose(...query)
export class Company extends React.PureComponent<CompanyProps> {
  public getTwitterData = (data) => {
    const url = get(data, 'profiles.twitter.url', null)
    return url ? url.replace('https://twitter.com/', '') : null
  }

  public render() {
    const {
      data,
      webVisitsDateRange,
      webVisits,
      location,
      match,
      emarketeerIntegrationStatus,
      history,
    } = this.props

    const company = get(data, 'getCompanyDetails.company', {})
    const analytics = get(data, 'getCompanyDetails.analytics', {})
    const loading = get(data, 'loading')
    const queryParams = queryString.parse(location.search)

    const sessions = get(webVisits, 'getWebVisits.items', [])
    const totalSessions = get(webVisits, 'getWebVisits.totalCount', 0)
    const webVisitsLoading = get(webVisits, 'loading')

    const fetchMoreWebVisits = async () => {
      webVisits.fetchMore({
        variables: { offset: sessions.length.toString() },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          console.log(previousResult, fetchMoreResult)
          const newSessions = fetchMoreResult.getWebVisits.items

          if (!newSessions) {
            return previousResult
          }

          return {
            getWebVisits: {
              ...previousResult.getWebVisits,
              items: [
                ...previousResult.getWebVisits.items, ...fetchMoreResult.getWebVisits.items,
              ],
            },
          }
        },
      })
    }

    const { name, domain } = company

    const emarketeerConnected = get(emarketeerIntegrationStatus, 'getEmarketeerIntegrationStatus.connected')

    const hasCoordinates = get(company, 'locations.[0].lat') && get(company, 'locations.[0].lng')

    const twitter = this.getTwitterData(company)
    return (
      <SidebarLayout noPadding={true}>
        <PageSubHeader>
          <BackButton onClick={() => this.props.push('/webleads')} />
        </PageSubHeader>
        {
          !loading && !webVisits.loading ? (
            <>
              <MapView
                company={company}
                locations={get(company, 'locations')}
                sessions={sessions}
                hasCoordinates={hasCoordinates}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyB-KQNBAeUsTI3iBKsjLXF7P4Ndxd1NqPw"
                loadingElement={<MapLoading height="260px" />}
                containerElement={<div style={{ height: '260px' }} />}
                mapElement={<div style={{ height: '260px' }} />}
              />
              <CompanyScore value={company.score} big={true} />
            </>
          ) : <MapLoading height="260px" />
        }
        <InfoWrapper>
          <BasicInfo company={company} loading={loading} />
          <InfoContent>
            <MenuRow>
              <CompanyTabs
                domain={match.params.id}
                serviceProvider={queryParams.serviceProvider}
              />
              <AssignDropdown
                companyId={match.params.id}
                companyName={company.name}
              />
            </MenuRow>
            <Route
              exact={true}
              path="/webleads/company/:id/dashboard"
              render={() => (
                <CardsHolder>
                  <InfoCard>
                    <Card.Title>
                      Engagement
                    </Card.Title>
                    <CardContent>
                      <Engagement
                        analytics={analytics}
                        activeEmployees={company.employees}
                        events={[]}
                        loading={loading}
                      />
                    </CardContent>
                  </InfoCard>
                  <InfoCard>
                    <Card.Title>
                      Interests
                    </Card.Title>
                    <CardContent>
                      <Interests analytics={analytics} company={company} loading={loading} />
                    </CardContent>
                  </InfoCard>
                  <CRMInsight
                    companyId={match.params.id}
                    company={company}
                    history={history}
                  />
                  <WebVisitsCard>
                    <Card.Title>
                      <WebVisitsCardTitle>
                        <div>
                          Web Visits <Total>{totalSessions || ''}</Total>
                          <WithinDateRange>
                            <Show if={totalSessions}>
                              (within date range)
                            </Show>
                          </WithinDateRange>
                        </div>
                        <DatePickerWrapper>
                          <PredefinedDatePicker
                            dateRange={webVisitsDateRange}
                            onDateChange={range =>
                              this.props.setWebVisitsDateRange(
                                queryParams.serviceProvider,
                                match.params.id,
                                range,
                              )
                            }
                          />
                        </DatePickerWrapper>
                      </WebVisitsCardTitle>
                    </Card.Title>
                    <CardContent>
                      <WebVisits
                        sessions={sessions}
                        company={company}
                        loading={webVisitsLoading}
                        totalSessions={totalSessions}
                        onFetchMore={fetchMoreWebVisits}
                      />
                    </CardContent>
                  </WebVisitsCard>
                  <DoubleCards>
                    <EmployeesCard>
                      <Card.Title>
                        Active Employees <Total>{get(company, 'employees.length', '')}</Total>
                      </Card.Title>
                      <CardContent>
                        <ActiveEmployees
                          domain={company.domain}
                          loading={loading || emarketeerIntegrationStatus.loading}
                          emarketeerConnected={emarketeerConnected}
                        />
                      </CardContent>
                    </EmployeesCard>
                    <EmployeesCard>
                      <Card.Title>
                        Other we found <Total>{get(company, 'keyPeople.length', '')}</Total>
                      </Card.Title>
                      <CardContent>
                        <OtherEmployees employees={company.keyPeople} loading={loading} />
                      </CardContent>
                    </EmployeesCard>
                  </DoubleCards>
                  <TwitterInfoCard>
                    <Card.Title>
                      {`Latest news from ${name || ''}`}
                    </Card.Title>
                    <TwitterInner>
                      <Show if={twitter}>
                        <Timeline
                          dataSource={{
                            sourceType: 'profile',
                            screenName: twitter,
                          }}
                          options={{
                            username: twitter,
                            height: '600',
                            chrome: 'noheader,nofooter,noborders',
                          }}
                        />
                      </Show>
                      <Show if={!twitter}>
                        <PlaceholderCardContent>
                          No news are available
                        </PlaceholderCardContent>
                      </Show>
                    </TwitterInner>
                  </TwitterInfoCard>
                </CardsHolder>
              )}
            />
            <Route
              exact={true}
              path="/webleads/company/:id/financial-report"
              render={() => (
                <InfoCard>
                  <PlaceholderCardContent>
                    Not available in Alpha
                  </PlaceholderCardContent>
                </InfoCard>
              )}
            />
            <Route
              exact={true}
              path="/webleads/company/:id/social-media"
              render={() => (
                <InfoCard>
                  <PlaceholderCardContent>
                    Not available in Alpha
                  </PlaceholderCardContent>
                </InfoCard>
              )}
            />
            <Route
              exact={true}
              path="/webleads/company/:id/in-media"
              render={() => (
                <InfoCard>
                  <PlaceholderCardContent>
                    Not available in Alpha
                  </PlaceholderCardContent>
                </InfoCard>
              )}
            />
            <Route
              exact={true}
              path="/webleads/company/:id/technology"
              render={() => (
                <InfoCard>
                  <PlaceholderCardContent>
                    Not available in Alpha
                  </PlaceholderCardContent>
                </InfoCard>
              )}
            />
          </InfoContent>
        </InfoWrapper>
      </SidebarLayout>
    )
  }
}
