import * as React from 'react'
import { Button } from 'components'
import * as copy from 'copy-to-clipboard'
import { Pre, ButtonWrapper } from './tracking-script.styles'

const COPY_TEXT = 'COPY TO CLIPBOARD'
const AFTER_CLICK_TEXT = 'COPIED!'

const getScriptString = (tenantId: string) => `<script>var EMTENANTID = "${btoa(tenantId)}";</script>
<script src="${window.location.origin}/script.js"></script>`

export const TrackingScriptHelpLink = ({ children }) => (
  <a
    target="_blank"
    href="https://help.leadexplorer.com/en/articles/3704598-how-to-install-leadexplorer-web-script-to-your-website"
  >
    {children}
  </a>
)

export const TrackingScriptText = ({ tenantId }: { tenantId: string}) => (
  <>
    <Pre>
      {getScriptString(tenantId)}
    </Pre>
  </>
)

export class TrackingScriptButton extends React.Component<{tenantId: string}> {
  public state = {
    text: COPY_TEXT
  }
  public render() {
    const copyScript = () => {
      copy(getScriptString(this.props.tenantId))
      this.setState( {
        text: AFTER_CLICK_TEXT,
      })
      setTimeout(() => this.setState({ text: COPY_TEXT }), 2000)
    }

    return (
      <ButtonWrapper>
        <Button
          color="primary"
          onClick={copyScript}
        >
          {this.state.text}
        </Button>
      </ButtonWrapper>
    )
  }
}
