import { WebAuth } from 'auth0-js'
import { randomString } from 'utils'

import { CONFIG } from '../../config'

export const localStorageStateKey = '_le_auth0_state'

class Auth {
  private auth0 = new WebAuth({
    domain: CONFIG.AUTH.domain,
    clientID: CONFIG.AUTH.clientId,
    redirectUri: CONFIG.AUTH.callbackUrl,
    audience: `https://${CONFIG.AUTH.domain}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid profile',
  })

  public login = () => {
    const newState = randomString(32)
    localStorage.setItem(localStorageStateKey, newState)
    this.auth0.authorize({ state: newState })
  }

  public handleAuthentication = () => new Promise((resolve, reject) => {
    const state = localStorage.getItem(localStorageStateKey)
    this.auth0.parseHash({ state }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
        resolve({
          access_token: authResult.accessToken,
          id_token: authResult.idToken,
          expires_at: expiresAt,
        })
      } else if (err) {
        console.log(err)
        reject(err)
      }
    })
  })

  public checkSession = () => new Promise((resolve, reject) => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        return reject(err)
      }
      return resolve(authResult)
    })
  })

  public fetchProfile = accessToken => new Promise((resolve) => {
    this.auth0.client.userInfo(accessToken, (_, profile) => {
      if (profile) {
        resolve(profile)
      }
    })
  })

  public logout = (history) => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    // navigate to the home route
    history.replace('/')
  }

  public logoutWithoutRedirect = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    // navigate to the home route
  }

  // Check whether the current time is past the
  // access token's expiry time
  public isAuthenticated = expiresAt => new Date().getTime() < expiresAt
}

export const auth = new Auth()
