import { createStore, combineReducers, compose, applyMiddleware, Store } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as form } from 'redux-form'
import * as storage from 'redux-storage'
import createEngine from 'redux-storage-engine-localstorage'
import filter from 'redux-storage-decorator-filter'

import * as reducers from '../reducers/index'
import { authMiddleware, customRouterMiddleware } from '../middleware'

const engine = filter(createEngine('Leadexplorer'), [
  'auth',
  ['prospectsList', 'sortBy'],
  ['liveView', 'viewedIds']
])
export const load = storage.createLoader(engine)

export const getStore = (history): Store => createStore(
  storage.reducer(combineReducers({
    ...reducers,
    toastr: toastrReducer,
    router: routerReducer,
    form,
  })),
  composeWithDevTools(compose(
    applyMiddleware(
      thunk,
      authMiddleware,
      customRouterMiddleware,
      routerMiddleware(history),
      storage.createMiddleware(engine)
    ),
  )),
)
