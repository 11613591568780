import * as React from 'react'
import { map, filter, compact } from 'lodash'
import { Query } from 'react-apollo'

import { MainWrapper, Title } from '../../settings-container.styles'
import { SubTitle, List, LoadingCard, Row, NoActiveIntegrations } from './integrations.styles'
import { IntegrationModel } from './models'
import { integrationsData } from './integrations.data'
import { ActiveIntegration, ListItemIntegration } from './components'
import { IntegrationsQuery } from './integrations.query'
import { CategoryTabs } from './components/category-tabs/category-tabs.component'

const IntegrationsCollection = map(integrationsData, x => new IntegrationModel(x))

interface IntegrationsProps {
  history?: {
    push: (url: string) => void
  }
}

export class Integrations extends React.PureComponent<IntegrationsProps> {
  public state = {
    activeCategory: 'all'
  }

  public navigateToIntegration = (url: string) => {
    this.props.history.push(url)
  }

  public changeActiveCategory = (activeCategory: string) => {
    this.setState({ activeCategory })
  }

  public render() {
    const { activeCategory } = this.state
    return (
      <MainWrapper>
        <Title>
          Integrations
        </Title>
        <Query
          query={IntegrationsQuery}
          errorPolicy="all"
        >
          {({ data, loading }) => (
            <>
              <Row>
                {loading ? <LoadingCard /> : this.getActiveIntegrations(data)}
              </Row>
              <SubTitle>
                Integrations you may want to add
                <CategoryTabs
                  activeCategory={activeCategory}
                  onTabClick={this.changeActiveCategory}
                />
              </SubTitle>
              <List>
                {map(IntegrationsCollection, (integration: IntegrationModel) => {
                  if (activeCategory === 'all' || activeCategory === integration.category) {
                    return (
                      <ListItemIntegration
                        key={integration.id}
                        onClick={this.navigateToIntegration}
                        {...integration}
                      />
                    )
                  }
                  return null
                })}
              </List>
            </>
          )}
        </Query>
      </MainWrapper>
    )
  }

  private getActiveIntegrations = (data: any) => {
    const integrations = map(IntegrationsCollection, (integration: IntegrationModel) => {
      return integration.updateStatus(data) ? (
        <ActiveIntegration
          key={integration.id}
          onClick={this.navigateToIntegration}
          {...integration}
        />
      ) : null
    })
    if (!compact(integrations).length) {
      return (
        <NoActiveIntegrations>No active integrations</NoActiveIntegrations>
      )
    }
    return integrations
  }
}
