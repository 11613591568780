import * as React from 'react'
import { Route } from 'react-router-dom'
import { compose, Query } from 'react-apollo'
import { connect } from 'react-redux'
import { get, map, filter } from 'lodash'
import 'rc-select/assets/index.css';

import { setProspectsSorting, setFilterId } from 'actions'
import { SidebarLayout, PageTabs, MaxLengthText } from 'components'
import { TrialInfo, LeadLimitHeader } from './components'
import { CompaniesList } from './components/companies-list.component'
import { CompaniesControls } from './components/companies-controls.component'
import { Spacer, PageTitle } from './webleads.style'
import { webleadsQuery } from './webleads.query'
import selector from './webleads.selector'
import { getFilters } from '../../gql/filters'
import { IntegrationFailHeader } from './components/integration-fail-header'
import { User } from '../../models/user.model'
import { Events, registerAnalyticEvent } from 'utils'

const PlainItem = (PageTabs as any).PlainItem

interface WebleadsProps {
  history?: any
  data: any
  getUsers: any
  dispatch: any
  googleStatus: any
  filterId?: string
}

@connect(selector)
@compose(...webleadsQuery)
export class Webleads extends React.PureComponent<WebleadsProps> {
  private timeout: any

  public componentDidMount() {
    this.checkProspects(this.props)
  }

  public componentWillReceiveProps(newProps) {
    this.checkProspects(newProps)
  }

  public componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  public checkProspects({ data }) {
    if (
      !data.loading
      && data.getCompanies
      && data.getCompanies.domains
      && data.getCompanies.domains.length === 0
      && !this.timeout
    ) {
      this.timeout = setTimeout(() => {
        this.timeout = null
        data.refetch()
      }, 60000)
    }
  }

  public navigateToPurchasePlan = () => {
    this.props.history.push('/purchase-plan')
    const profileData = JSON.parse(localStorage.getItem('Leadexplorer'));
    registerAnalyticEvent(Events.LEADLIMITER_CLICKED, { 'email': profileData.auth.profileData.name })
  }

  public navigateToGaSettings = () => {
    this.props.history.push('/settings/google-analytics')
  }

  public render() {
    const { data, getUsers, googleStatus, dispatch } = this.props
    const items = [{
      title: 'Sort by engagement',
      onClick: () => dispatch(setProspectsSorting({ sortBy: 'score' })),
    }, {
      title: 'Sort by last visits',
      onClick: () => dispatch(setProspectsSorting({ sortBy: 'dateTime' })),
    }]

    return (
      <SidebarLayout>
        <LeadLimitHeader
          tenantStats={get(googleStatus, 'getTenantStats')}
          onClick={this.navigateToPurchasePlan}
        />
        <PageTitle>
          Web leads
          <TrialInfo status={googleStatus}/>
        </PageTitle>
        <Query query={getFilters}>
          {(getFiltersResult) => {
            if (getFiltersResult.data || !getFiltersResult.loading) {
              const filters = filter(getFiltersResult.data.getFilters, x => x.favorite || x.id === 'allLeads')
              return (
                <PageTabs>
                  {map(filters, (item: any) => {
                    const isActive = this.props.filterId === item.id
                    return (
                      <PlainItem
                        key={item.id}
                        onClick={() => this.props.dispatch(setFilterId(item.id))}
                        isActive={isActive}
                      >
                        <MaxLengthText length={16}>{item.name}</MaxLengthText> {isActive && !data.loading ? get(data, 'getCompanies.total', '') : ''}
                      </PlainItem>
                    )
                  })}
                  <Spacer />
                  <Route
                    exact={true}
                    path="/webleads"
                    render={() => (
                      <CompaniesControls sortItems={items} />
                    )}
                  />
                </PageTabs>
              )
            }
            return null
          }}
        </Query>
        <Route
          exact={true}
          path="/webleads"
          render={() => (
            <CompaniesList
              users={getUsers.getUsers}
              status={googleStatus}
              data={data}
            />
          )}
        />
      </SidebarLayout>
    )
  }
}
