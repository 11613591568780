import * as React from 'react'
import { Query } from 'react-apollo'
import * as Raven from 'raven-js'
import { Redirect } from 'react-router-dom'

import { Spinner } from '../../../components'
import { userQuery } from '../../../gql/user'
import { removeUserToken } from 'actions'
import { startSession } from 'utils'

function updateUserData(name, email, tenantId) {
  Raven.setUserContext({ id: email, email })
  startSession(email, tenantId)
}

export const UserProvider = ({ children, userId, dispatch }) => (
  <Query
    query={userQuery}
    variables={{ userId }}
  >
    {({ loading, error, data = { getUser: {}} }) => {
      if (loading) {
        return <Spinner />
      }
      if (error) {
        return <p>Error </p>
      }
      if (data.getUser === null) {
        dispatch(removeUserToken())
        return <Redirect to="/"/>
      }
      const { firstName, lastName, email, tenantId} = data.getUser
      updateUserData(`${firstName} ${lastName}`, email, tenantId)

      return children
    }}
  </Query>
)
