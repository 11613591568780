import * as React from 'react'
import { Wrapper } from '../../../../../../components/controls/checkbox/checkbox.styles'
import { Label, Input } from './form-checkbox.styles'

interface CheckboxProps {
  input: any
  label: any
  placeholder: any
}

export class Checkbox extends React.Component<CheckboxProps> {
  public render() {
    const { input: { name, ...inputProps }, label, placeholder } = this.props
    return (
      <Wrapper>
        <Input
          id={name}
          name={name}
          placeholder={placeholder}
          {...inputProps}
          type="checkbox"
        />
        <Label
          htmlFor={name}
        >
          {label}
        </Label>
      </Wrapper>
    )
  }
}
