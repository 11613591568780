import styled from 'styled-components'

export const PageTitle = styled.h1`
  margin: 0;
  padding: 35px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.HR_COLOR};
  font-size: 28px;
  line-height: 28px;
  font-weight: 300;
`
