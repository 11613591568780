import styled, { keyframes } from 'styled-components'
import { MEDIA_LARGE_DESKTOP } from 'styles'
import { ProgressiveImage } from '../../../../components'

const defaultIcon = '/images/company-logo.png'

const Wrapper = styled.div`
  position: relative;
  width: 280px;
  margin: -55px auto 0 auto;
  padding: 0 20px;
  @media (max-width: ${MEDIA_LARGE_DESKTOP}) {
    width: 200px;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

interface LogoProps {
  src: string
}

const Logo = styled.div<LogoProps>`
  width: 100%;
  height: 100%;
  background: ${({ src }) => src ? `white url("${src}") no-repeat center center` : `white url("${defaultIcon}") no-repeat center center`};
  background-size: contain;
`
const LogoContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  height: 200px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: auto;
    width: 80%;
  }
`
const Name = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`
const Description = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 800px) {
    margin-top: 10px;
  } 
`
const Line = styled.div`
  margin: 25px 0;
  display: inline-block;
  border: 1px solid #C5C5C5;
  width:100%;
  @media (max-width: 800px) {
    margin: 15px 0;
  } 
`
const CompanyInformation = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  line-height: normal;
  font-style: normal;
`
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`
const Detail = styled.div`
  display: flex;
  margin: 5px 0;
`
export const PhoneDetail = styled(Detail)`
  margin: 0 0 5px 0;
`
const DetailTitle = styled.div`
  min-width: 65px;
`
const Title = styled.div`
  display: flex;
  font-weight: 700;
  text-transform: uppercase;
`
const CompanySizeTitle = styled(Title)`
  margin-top: 30px;
`
const Address = styled.div`
  margin: 5px 0 0 0;
`
const Phone = styled.div`
`
const Age = styled.div`
  margin-bottom: 30px;
`
const SocialIconList = styled.div`
  display: flex;
  margin-top: 40px;
`
const Industry = styled.div`
  margin-top: 10px;
`
const bgColor = 'rgba(0, 0, 0, 0.12)'
const progress = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 35px 35px;
  }
`
export const Animated = styled.div`
  background: ${bgColor};
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
  animation: ${progress} 2s linear infinite;
`
const LogoLoading = styled(Animated)`
  height: 100%;
  width: 100%;
`
const NameLoading = styled(Animated)`
  height: 28px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
`

interface LoadingTextProps {
  half?: boolean
}

const LoadingText = styled(Animated)<LoadingTextProps>`
  width: ${({ half }) => half ? '40%' : '100%'};
  height: 14px;
  margin-bottom: 8px;
  border-radius: 8px;
`
const WebsiteLink = styled.a`
  color: inherit;
`
const WebsiteScreenshot = styled(ProgressiveImage)`
  height: 200px;
`

export {
  Wrapper,
  Logo,
  LogoContainer,
  Name,
  Description,
  Line,
  Title,
  CompanyInformation,
  DetailsContainer,
  Detail,
  DetailTitle,
  Address,
  Age,
  SocialIconList,
  Industry,
  LogoLoading,
  NameLoading,
  LoadingText,
  WebsiteLink,
  Phone,
  WebsiteScreenshot,
  CompanySizeTitle,
}
