import * as React from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { filter, includes, uniqBy } from 'lodash'
import { withRouter } from 'react-router-dom'

import { Wrapper } from './live-view-counter.styles'
import { getLiveViewStateQuery } from '../../../../../../../gql/live-view'

interface LiveViewCounterProps {
  viewedIds?: any[]
}

@connect((state) => ({
  viewedIds: state.liveView.viewedIds
}))
export class LiveViewCounter extends React.PureComponent<LiveViewCounterProps> {
  public render(): React.ReactNode {
    return (
      <Query
        query={getLiveViewStateQuery}
        pollInterval={30000}
      >
        {({ data }) => {
          if (data.getLiveViewState) {
            const newCompanies = uniqBy(filter(data.getLiveViewState, this.filterByViewedIds), 'companyId')
            if (newCompanies.length) {
              return <Wrapper>{newCompanies.length}</Wrapper>
            }
          }
          return null
        }}
      </Query>
    )
  }

  private filterByViewedIds = (company) => !includes(this.props.viewedIds, company.companyId)
}
