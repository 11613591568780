import { graphql } from 'react-apollo'

import {
  emarketeerAuthUrl,
  submitEmarketeerCode,
  emarketeerIntegrationStatus,
  disconnectEmarketeer
} from '../../../../gql/emarketeer/'

export const emarketeerIntegrationQueries = [
  graphql(emarketeerAuthUrl, {
    name: 'emarketeerAuthUrl',
  }),
  graphql(emarketeerIntegrationStatus, {
    name: 'data',
  }),
  graphql(submitEmarketeerCode, {
    name: 'submitEmarketeerCode',
  }),
  graphql(disconnectEmarketeer, {
    name: 'disconnect',
  }),
]
