import * as React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Checkbox } from '../form-checkbox'
import { validation } from 'utils'
import { Label, Wrapper, EmailInput, AddButton, CancelButton, Controls, MessageInput, Row } from './invite-user-form.styles'

const email = validation.email()
const required = validation.required()

interface InviteUserFormProps {
  cancel?: any
  handleSubmit?: any
  invalid?: any
  submitting?: boolean
  onSubmit?: any
  initialValues?: any
}

// @ts-ignore
@reduxForm({
  form: 'inviteUserForm',
})
export class InviteUserForm extends React.Component<InviteUserFormProps> {
  public render() {
    const { cancel, handleSubmit, invalid, submitting } = this.props

    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <Label>Email</Label>
          <Field
            name="email"
            component={EmailInput as any}
            type="text"
            validate={[email, required]}
          />
          <Label>Add a message (optional)</Label>
          <Field
            name="message"
            component={MessageInput as any}
            type="text"
            rows={10}
          />
          <Row>
            <Field
              label="Admin?"
              name="isAdmin"
              component={Checkbox as any}
            />
          </Row>
          <Controls>
            <CancelButton onClick={() => cancel()}>Cancel</CancelButton>
            <AddButton
              type="submit"
              disabled={invalid || submitting}
            >
              Add User
            </AddButton>
          </Controls>
        </form>
      </Wrapper>
    )
  }
}
