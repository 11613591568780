import styled from 'styled-components'
import { Input } from '../../../../components'

export const CustomInput = styled(Input)`
  flex: 1;
  margin-right: 24px;
`

export const Footer = styled.div`
  margin: 24px 0 48px;
  
  button {
    min-width: 120px;
  }
`
