import * as React from 'react'
import { map, slice, times } from 'lodash'
import { connect } from 'react-redux'
import { Show, LoadingCircle, LoadingBlock, SidePanel, Icon } from '../../../../components'
import { OtherEmployeesSelection } from './components'

import { closeSidePanel, showSidePanel } from 'actions'
import { otherEmployeesSelector } from './other-employees.selector'
import { Employee } from './components/employee.component'

import { EmployeeRow, Description, EmployeeRows, Wrapper, LoadingAvatar, NoData, Footer, Triangle } from './other-employees.styles'

interface OtherEmployeesProps {
  employees?: any
  loading?: any
  sidePanelVisible?: any
  dispatch?: any
}

@connect(otherEmployeesSelector)
export class OtherEmployees extends React.Component<OtherEmployeesProps> {
  public showSidePanel = () => {
    this.props.dispatch(showSidePanel('otherEmployeesSelection'))
  }

  public closeSidePanel = () => {
    this.props.dispatch(closeSidePanel('otherEmployeesSelection'))
  }

  public render() {
    const { employees = [], loading, sidePanelVisible } = this.props

    return (
      <Wrapper>
        <EmployeeRows>
          <Show if={!loading}>
            {map(slice(employees, 0, 5), (employee, i) => (
              <Employee key={i} employee={employee} />
            ))}
          </Show>

          <Show if={loading}>
            {times(3, i => (
              <EmployeeRow key={i}>
                <LoadingAvatar size={56} />
                <Description>
                  {times(3, index => <LoadingBlock key={index} width="40%" />)}
                </Description>
              </EmployeeRow>
            ))}
          </Show>

          <Show if={!loading && !employees.length}>
            <NoData>
              No other employees found
            </NoData>
          </Show>
        </EmployeeRows>
        <Show if={employees.length > 5}>
          <Footer onClick={() => this.showSidePanel()}>
            Show all {employees.length} contacts <Triangle icon="triangleRight" size={10} />
          </Footer>
        </Show>
        <SidePanel isVisible={sidePanelVisible} handleClose={this.closeSidePanel} title="Selection">
          <OtherEmployeesSelection employees={employees} />
        </SidePanel>
      </Wrapper>
    )
  }
}
