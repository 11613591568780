export const DefaultTheme = {
  THEME_ID: 'DEFAULT',
  TOP_MENU_COLOR: '#314550',
  TOP_MENU_ITEM_ACTIVE: '#2a3b44',
  TOP_MENU_ITEM_HOVER: '#2a3b44',
  TOP_MENU_FONT_COLOR: '#ffffff',
  TOP_MENU_ACTIVE_COLOR: '#d85251',
  TOP_MENU_DISABLED_FONT_COLOR: '#96a2a8',
  SUB_MENU_BACKGROUND_COLOR: '#202d34',
  SUB_MENU_FONT_COLOR: '#ffffff',
  HEADER_SEARCH_BACKGROUND: '#ffffff',
  CANVAS_COLOR: '#e4e4e4',
  PANEL_TOP_COLOR: '#26353e',
  STANDARD_BUTTON_COLOR: '#61869e',
  WEB_VISIT_BACKGROUND_COLOR: '#314550',
  HR_COLOR: '#cbcbcb',
  BUTTONS: {
    default: {
      background: '#e0e1e2',
      backgroundHover: '#d7d9da',
      color: 'rgba(0, 0, 0, .6)',
      border: '1px solid #e0e1e2',
      backgroundDisabled: '#D2D9DE',
      borderDisabled: '#D2D9DE',
    },
    primary: {
      background: '#61869e',
      backgroundHover: '#5b7d93',
      color: 'white',
      border: '1px solid rgb(97, 134, 158)',
      backgroundDisabled: '#D2D9DE',
      borderDisabled: '#D2D9DE',
    },
    success: {
      background: '#7AAB59',
      backgroundHover: '#72a152',
      color: 'white',
      border: '1px solid #7AAB59',
      backgroundDisabled: '#e3ecd6',
      borderDisabled: '#e3ecd6',
    },
    white: {
      background: '#ffffff',
      backgroundHover: '#f7f7f7',
      color: '#61869E',
      border: '1px solid #61869E',
      backgroundDisabled: '#8B929E',
      borderDisabled: '#8B929E',
    },
    alert: {
      background: '#d9534f',
      backgroundHover: '#dc3732',
      color: 'white',
      border: '1px solid #d9534f',
      backgroundDisabled: '#8B929E',
      borderDisabled: '#8B929E',
    },
  },
  SELECT_HOVER: '#31455088',
  SELECT_HOVER_SHADOW: '#31455020',
  SELECT_ITEM_HOVER: 'rgba(49,69,80,.05)',
}
