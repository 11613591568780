import styled from 'styled-components'
import { Icon } from '../../../../components'

const Footer = styled.div`
  font-weight: 300;
  font-size: 14px;
  padding: 20px 0;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  margin-bottom: -30px;
  cursor: pointer;
  user-select: none;
`
const Triangle = styled(Icon)`
  margin: 10px;
`

export {
  Triangle,
  Footer,
}
