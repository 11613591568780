import styled from 'styled-components'
import { Row as BaseRow, Link } from '../../../../../../components'
import { TextWrapper } from '../../crm-insight.styles'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
`

export const NoConnectionText = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 300;
`

export const UpperText = styled.div`
  font-weight: 300;
  line-height: normal;
  font-size: 16px;
  margin-bottom: 8px;
`

export const BottomText = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
`
export const FooterText = styled.div`
  font-size: 14px;
  margin-top: 26px;
`

export const SearchTextWrapper = styled(TextWrapper)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 24px;
  
  ${BaseRow} {
    padding-left: 115px;
  }
`

export const CenteredLink = styled(Link)`
  position: relative;
  margin: 0 auto;

  &:after {
    position: absolute;
    top: 6px;
    right: -16px; 
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #314550;
  }
`

export const Row = styled(BaseRow)`
  width: 100%;
  margin-top: auto;
  font-size: 14px;
  padding-top: 16px;
  padding-right: 15px;
  box-sizing: border-box;

  ${Link} {
    margin-left: 16px;
    text-decoration: none;
    color: #314550;
  }

  ${CenteredLink} {
    margin-left: auto;
  }
`
