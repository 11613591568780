import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 100px 24px 24px;
  background: white;
  
  h1, h3 {
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    color: #333333;
    text-align: center;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 48px;
    font-weight: 100;

    @media (max-width: 700px) {
      font-size: 36px;
      margin-bottom: 16px;
    }
  }

  h3 {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 400;
  }
`
export const LayoutWrapper = styled.div`
  background: white;
`
export const AgreementWrapper = styled.div`
  margin-top: 48px;
`
