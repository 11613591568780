import * as React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Input, Checkbox, Link } from 'components'
import { Form, AgreementWrapper, SubmitButton } from './verify-form.styles'

import { validation } from 'utils'
import { AgreementModal } from '../../../../components/modals/agreement-modal'

const required = validation.required()
const passwordValidation = validation.password('Minimum 8 characters. Must contain upper case, lower case, number, and a special character.')
const validate = ({ password, verifyPassword }) => {
  if (password !== verifyPassword) {
    return {
      verifyPassword: 'Password does not match',
    }
  }
  return {}
}

interface VerifyFormProps {
  valid?: any
  submitting?: any
  handleSubmit?: any
  onSubmit?: any
}

// @ts-ignore
@reduxForm({
  form: 'verifyAccount',
  validate,
})
export class VerifyForm extends React.PureComponent<VerifyFormProps> {
  public state = {
    showModal: false,
  }

  public onTermsClick = () => {
    this.setState({ showModal: true })
  }

  public closeModal = () => {
    this.setState({ showModal: false })
  }

  public render() {
    const { valid, handleSubmit } = this.props
    return (
      <Form
        onSubmit={handleSubmit}
      >
        <Field
          name="firstName"
          component={Input as any}
          label="First name"
          type="text"
          placeholder="First name"
          validate={[required]}
        />
        <Field
          name="lastName"
          component={Input as any}
          label="Last name"
          type="text"
          placeholder="Last name"
          validate={[required]}
        />
        <Field
          name="password"
          component={Input as any}
          label="Password"
          type="password"
          placeholder="Password"
          validate={[required, passwordValidation]}
        />
        <Field
          name="verifyPassword"
          component={Input as any}
          label="Confirm password"
          type="password"
          placeholder="Confirm password"
          validate={[required]}
        />
        <AgreementWrapper>
          <Field
            name="agree"
            // @ts-ignore
            label={<span>I accept the <Link onClick={this.onTermsClick}>terms of service</Link></span>}
            component={Checkbox as any}
            validate={[required]}
          />
          <AgreementModal
            show={this.state.showModal}
            onRequestClose={this.closeModal}
          />
        </AgreementWrapper>
        <SubmitButton disabled={!valid} color="primary">
          Create account
        </SubmitButton>
      </Form>
    )
  }
}
