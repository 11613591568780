import * as React from 'react'
import { defaultTo } from 'lodash'

import { Modal, Button } from '../../../../../../components'
import { Row, CompanyWrapper, Logo, Info, Name, Address, SuperOfficeLogo } from './common-modal.styles'

interface CommonModalProps {
  isOpen: boolean
  onRequestClose: () => void
  actionButtonText?: string
  company: any
  onCancel: () => void
  onAction: () => void
  actionButtonDisabled: boolean
}

export class CommonModal extends React.PureComponent<CommonModalProps> {
  public render() {
    const {
      isOpen,
      onRequestClose,
      children,
      actionButtonText = 'Match',
      company,
      onAction,
      onCancel,
      actionButtonDisabled,
    } = this.props
    const { name, logo, locations = [] } = company
    let addressLine = ''
    let cityLine = ''
    if (locations.length) {
      const { addressLine1, city, country } = locations[0]
      addressLine = `${defaultTo(addressLine1, '')}`
      cityLine = `${city}, ${country}`
    }
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <CompanyWrapper>
          <Logo src={logo || '/images/company-logo.png'} />
          <Info>
            <Name>{name}</Name>
            <Address>{addressLine}</Address>
            <Address>{cityLine}</Address>
          </Info>
          <SuperOfficeLogo />
        </CompanyWrapper>
        {children}
        <Row>
          <Button
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={actionButtonDisabled}
            onClick={onAction}
          >
            {actionButtonText}
          </Button>
        </Row>
      </Modal>
    )
  }
}
