import * as React from 'react'
import { map, get } from 'lodash'
import { connect } from 'react-redux'
import * as moment from 'moment'

import { getDeviceIcon, getDeviceText, isVisitLive } from 'utils'
import { closeSidePanel, showSidePanel, setActiveVisit } from 'actions'
import { Flag, Show } from 'components'
import { webVisitSlider } from '../../web-visits.selector'
import {
  Date,
  OnSiteNow,
  VisitButton,
  Name,
  DeviceIcon,
  PageViewsValue,
  FirstTableColumn,
  TableDateColumn,
  CenteredTableColumn,
  ShrinkedTableColumn,
  DurationTableColumn,
  ShowVisitTableColumn,
  TableHeader,
  TableRoot,
  TableItemRow,
  CenteredTableRow,
  CenteredTableHader,
  SecondTableColumn,
  LeftAlignedTableColumn,
  WideTableColumn,
  LeftAlignedTableRow,
} from '../web-visit/web-visit.styles'

interface WebVisitsTableProps {
  dispatch?: any
  sessions: any[]
  onFetchMore: () => void
}

@connect(webVisitSlider)
export class WebVisitsTable extends React.Component<WebVisitsTableProps> {
  public closeSidePanel = () => {
    this.props.dispatch(closeSidePanel('webVisit'))
  }

  public showVisit = (visit) => {
    this.props.dispatch(showSidePanel('webVisit'))
    this.props.dispatch(setActiveVisit(visit))
  }

  public render() {
    const { sessions } = this.props

    return (
      <TableRoot>
        <FirstTableColumn>
          <TableDateColumn>
            <TableHeader>When</TableHeader>
            {map(sessions, (session, i) => {
              const sessionLocalDateTime = moment.utc(session.dateTime).local();

              const isLive = isVisitLive(sessionLocalDateTime)

              return (
                <TableItemRow key={i}>
                  <Date>
                    <Show if={isLive}>
                      <OnSiteNow>
                      ON SITE NOW
                      </OnSiteNow>
                    </Show>
                    <Show if={!isLive}>
                      <div>
                        {moment(sessionLocalDateTime)
                          .calendar(null, {
                            sameDay: '[Today] h:mmA',
                            lastDay: '[Yesterday] h:mmA',
                            nextWeek: 'dddd h:mmA',
                            lastWeek: '[Last] dddd h:mmA',
                            sameElse: 'DD/MM/YYYY h:mmA',
                          })}
                      </div>
                    </Show>
                  </Date>
                </TableItemRow>
              )
            })}
          </TableDateColumn>
          <ShrinkedTableColumn>
            <TableHeader>Visitor</TableHeader>
            {map(sessions, (session, i) => (
              <TableItemRow key={i}>
                <Name>{get(session, 'visitor.fullName') || session.userType}</Name>
              </TableItemRow>
            ))}
          </ShrinkedTableColumn>
          <WideTableColumn>
            <TableHeader>Where</TableHeader>
            {map(sessions, (session, i) => (
              <TableItemRow key={i}>
                <Show if={session.countryCode}>
                  <Flag code={session.countryCode} />
                </Show>
                <Show if={get(session, 'countryCode') !== 'ZZ'}>
                  <Show if={session.city}>
                    {session.city}
                  </Show>
                  <Show if={session.city && session.country}>
                    {', '}
                  </Show>
                  <Show if={session.country}>
                    {session.country}
                  </Show>
                </Show>
                <Show if={get(session, 'countryCode') === 'ZZ'}>
                  Unknown
                </Show>
              </TableItemRow>
            ))}
          </WideTableColumn>
        </FirstTableColumn>
        <SecondTableColumn>
          <CenteredTableColumn>
            <CenteredTableHader>Page views</CenteredTableHader>
            {map(sessions, (session, i) => (
              <CenteredTableRow key={i}>
                <PageViewsValue>{session.sessionPages.length}</PageViewsValue>
              </CenteredTableRow>
            ))}
          </CenteredTableColumn>
          <DurationTableColumn>
            <CenteredTableHader>Duration</CenteredTableHader>
            {map(sessions, (session, i) => (
              <LeftAlignedTableRow key={i}>
                {moment.utc(session.duration * 1000)
                  .format('m [min] s [sec]')}
              </LeftAlignedTableRow>
            ))}
          </DurationTableColumn>
          <LeftAlignedTableColumn>
            <CenteredTableHader>Browser Language</CenteredTableHader>
            {map(sessions, (session, i) => (
              <LeftAlignedTableRow key={i}>
                {session.userLanguage || ''}
              </LeftAlignedTableRow>
            ))}
          </LeftAlignedTableColumn>
          <LeftAlignedTableColumn>
            <CenteredTableHader>Device</CenteredTableHader>
            {map(sessions, (session, i) => (
              <LeftAlignedTableRow key={i}>
                <DeviceIcon icon={getDeviceIcon(session.deviceCategory, session.operatingSystem)} />
                <div>
                  {getDeviceText(session.deviceCategory, session.operatingSystem)}
                </div>
              </LeftAlignedTableRow>
            ))}
          </LeftAlignedTableColumn>
          <ShowVisitTableColumn>
            {map(sessions, (session, i) => (
              <CenteredTableRow key={i}>
                <VisitButton onClick={() => this.showVisit(session)}>Show visit</VisitButton>
              </CenteredTableRow>
            ))}
          </ShowVisitTableColumn>
        </SecondTableColumn>
      </TableRoot>
    )
  }
}
