import gql from 'graphql-tag'

export const getLiveViewStateQuery = gql`
  query {
    getLiveViewState {
      bio
      city
      companyId
      companyName
      country
      dateHourMinute
      employeesCount
      pageUrl
      pageTitle
      score
      latitude
      longitude
      logo
    }
  }
`
