import * as React from 'react'
import { connect } from 'react-redux'
import { History } from 'history'
import { graphql } from 'react-apollo'

import { handleAuthentication } from 'actions'
import { Layout, Show, Button } from 'components'
import { Input, Wrapper } from './sign-in-callback.styles'
import { savePushSubscription } from '../../gql/notification'
import { getCurrentSubscription, isSubscribed, saveSubscription } from '../../utils/notification'

interface SignInCallbackProps {
  history?: History
  dispatch?: any
  mutate: any
}

@connect()
// @ts-ignore
@graphql(savePushSubscription)
export class SignInCallbackContainer extends React.PureComponent<SignInCallbackProps> {
  public state = {
    error: null,
    email: '',
  }

  public async componentDidMount() {
    if (await isSubscribed()) {
      const permission = (window as any).Notification.permission
      if (permission === 'granted') {
        const subscription = await getCurrentSubscription()
        await saveSubscription(subscription, this.props.mutate)
      }
    }

    const { history } = this.props

    if (/access_token|id_token|error/.test(history.location.hash)) {
      this.props.dispatch(handleAuthentication()).catch((error) => {
        if (error.error === 'unauthorized') {
          this.setState({ error })
        }
      })
    }
  }

  public render() {
    const { error, email } = this.state
    return (
      <Layout
        noPadding={false}
      >
        <Show if={error}>
          <Wrapper>
            <p>{error && error.errorDescription}</p>
            <Input type="text" onChange={this.onEmailChange} value={email}/>
            <Button
              updating={false}
            >
              Resend email
            </Button>
            <p><a href="mailto:sales@leadexplorer.com?subject=Email%20verification">contact support</a></p>
          </Wrapper>
        </Show>
      </Layout>
    )
  }

  private onEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }
}
