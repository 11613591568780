import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../../'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  height: 16px;
`
const Text = styled.div`
  font-size: 12px;
`

export const CompanyEvent = ({ icon, text }) => (
  <Wrapper>
    <Icon size={14} icon={icon} />
    <Text>{text}</Text>
  </Wrapper>
)
