import * as Raven from 'raven-js'

const ga = (window as any).ga

export const registerGoogleAnalyticsEvent = (eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) => {
  try {
    if (ga && ga.getAll && ga.getAll()[0]) {
      const tracker = ga.getAll()[0]

      if (tracker) {
        tracker.send('event', eventCategory, eventAction, eventLabel, eventValue)
      }
    }
  } catch (e) {
    Raven.captureException(e)
  }
}
