import * as React from 'react'
import Cropper from 'react-cropper'
import { debounce } from 'lodash'

import { Show, Icon } from '../index'
import {
  CropperWrapper,
  DropzoneContent,
  DropzoneText,
  RatioWrapper,
  SmallerDropzoneText,
  StyledDropzone,
} from './image-picker.styles'

const PICTURE_SIZE = 512

interface ImagePickerProps {
  onImage: (image: any) => void
}

export class ImagePicker extends React.Component<ImagePickerProps> {
  public cropper
  public state = {
    image: null,
  }

  public onDrop = (files) => {
    const reader = new FileReader()
    reader.onload = () => {
      this.setState({
        image: reader.result,
      }, () => this.props.onImage(reader.result))
    }
    reader.readAsDataURL(files[0])
  }

  public crop = () => {
    const dataUrl = this.cropper.getCroppedCanvas({
      width: PICTURE_SIZE,
      height: PICTURE_SIZE,
    }).toDataURL()
    this.props.onImage(dataUrl)
  }

  public render() {
    const { image } = this.state

    return (
      <RatioWrapper>
        <Show if={!image}>
          <StyledDropzone
            multiple={false}
            onDrop={this.onDrop}
          >
            <DropzoneContent>
              <Icon icon="dragDrop" size={150} color="#A5A6A6" />
              <DropzoneText>
                Drag logo image here
                <SmallerDropzoneText>
                  or click to select a file
                </SmallerDropzoneText>
              </DropzoneText>
            </DropzoneContent>
          </StyledDropzone>
        </Show>
        <Show if={image}>
          <CropperWrapper>
            <Cropper
              ref={(cropper) => { this.cropper = cropper }}
              src={image}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              aspectRatio={1}
              crop={debounce(this.crop, 100)}
            />
          </CropperWrapper>
        </Show>
      </RatioWrapper>
    )
  }
}
