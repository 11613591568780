import * as React from 'react'
import { map, chain } from 'lodash'
import * as moment from 'moment'

import { Flag, Icon, Show, ProgressiveImage } from '../../../../../../components'
import { getScreenshotUrl } from 'utils'
import {
  PageTitle,
  ArrowDown,
  ExitPage,
  ExitPageTime,
  ExitPageWrapper,
  FirstColumn,
  HidableText,
  InnerText,
  LeftColumn,
  LengthIndicator,
  PageViewContent,
  PageViewWrapper,
  QuestionMarkIcon,
  RightColumn,
  SecondColumn,
  TextBlock,
  Thumbnail,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  TooltipParagraph
} from './page-view.styles'

export const PageView = ({ visit, page, i }) => {
  const maxLength = chain(visit.sessionPages)
    .map('duration')
    .max()
    .value() || 1

  let color = '#8DBCD1'

  if (page.length >= 30) {
    color = '#D85251'
  } else if (page.length >= 10) {
    color = '#EFB17C'
  }

  const isExitPage = i === visit.sessionPages.length - 1

  return (
    <PageViewWrapper>
      <PageViewContent>
        <LeftColumn>
          <Thumbnail>
            <ProgressiveImage
              src={getScreenshotUrl(page.fullUrl)}
              styles={{ backgroundSize: 'cover', position: 'top center' }}
              loadingAnimation={true}
            />
          </Thumbnail>
        </LeftColumn>
        <RightColumn>
          <PageTitle>
            {page.name}
          </PageTitle>
          <Show if={!isExitPage}>
            <LengthIndicator color={color} width={(page.duration / maxLength) * 100}>
              <InnerText>
                {moment.utc(page.duration * 1000)
                  .format('mm:ss')}
              </InnerText>
            </LengthIndicator>
          </Show>
          <Show if={isExitPage}>
            <ExitPageWrapper>
              <FirstColumn>
                <ExitPageTime>00:00</ExitPageTime>
                <Icon icon="exitPage" />
              </FirstColumn>
              <SecondColumn>
                <TextBlock>
                  <HidableText>Duration can&apos;t be calculated on exit page</HidableText>
                  <TooltipContainer
                    position="top"
                    arrow={true}
                    html={(
                      <TooltipContent>
                        <TooltipHeader>
                          The Exit Page
                        </TooltipHeader>
                        <TooltipParagraph>
                          Since no interaction was registered on this page,
                          we don’t know how long time the visitor spent here.
                        </TooltipParagraph>
                        <TooltipParagraph>
                          One could speculate that the last page represents the
                          page the visitor was looking for, making it the most
                          interesting page. However, it can also be interpreted
                          as sign of uninterest. Unfortunately, there is no way of knowing this.
                        </TooltipParagraph>
                      </TooltipContent>
                    )}
                  >
                    <QuestionMarkIcon icon="questionMark" />
                  </TooltipContainer>
                </TextBlock>
                <ExitPage>
                  Exit page
                </ExitPage>
              </SecondColumn>
            </ExitPageWrapper>
          </Show>
        </RightColumn>
      </PageViewContent>
      <Show if={!isExitPage}>
        <ArrowDown />
      </Show>
    </PageViewWrapper>
  )
}
