import * as React from 'react'

import { Input, Button, Wrapper } from './counter.styles'

interface CounterProps {
  value: number
  lowLimit: number
  highLimit: number
  onChange: (value: number) => void
}

export class Counter extends React.PureComponent<CounterProps> {
  public state: { value: number } = {
    value: null
  }
  constructor(props) {
    super(props)
    this.state.value = props.value
  }
  public onChange = (e) => {
    const { value } = e.target
    this.change(value)
  }
  public onMinusClick = () => {
    this.change(this.state.value - 1)
  }
  public onPlusClick = () => {
    this.change(this.state.value + 1)
  }
  public change = (value) => {
    const { lowLimit, highLimit } = this.props
    if (value >= lowLimit && value <= highLimit) {
      this.setState({ value }, () => this.props.onChange(value))
    }
  }
  public render() {
    const { lowLimit, highLimit } = this.props
    const { value } = this.state
    return (
      <Wrapper>
        <Button
          type="button"
          onClick={this.onMinusClick}
          disabled={lowLimit === value}
        >
          -
        </Button>
        <Input
          value={value}
          onChange={this.onChange}
        />
        <Button
          type="button"
          onClick={this.onPlusClick}
          disabled={highLimit === value}
        >
          +
        </Button>
      </Wrapper>
    )
  }
}
