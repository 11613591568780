import styled from 'styled-components'
import { Button, Icon } from 'components'

export const Wrapper = styled.div`
  background: #314550;
  padding: 15px;
  bottom: 20px;
  @media (max-width: 500px) {
    bottom: 100px;
  }
  position: fixed;
  left: 0;
  min-height: 0 !important;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
`

export const CloseIcon = styled(Icon)`
  margin-right: 0;
  margin-left: 15px;
  cursor: pointer;
`

export const TopBlock = styled.div`
  display: flex;
`

export const Content = styled.div`
  display: flex;
`

export const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`

export const Row = styled.div`
  @media (min-width: 500px) {
    white-space: nowrap;
  }
`

export const ActionButton = styled(Button)`
  padding: 5px 18px;
  font-weight: 400;
`

export const Logo = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`

export const Header = styled.div`
  font-weight: 500;
  white-space: nowrap;
  margin: 10px 0 10px 0;
`
