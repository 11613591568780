import * as React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import LoadingOverlay from './loading-overlay.component'

const FirstChild = props => React.Children.toArray(props.children)[0] || null

interface LoadingOverlayWrapperProps {
  active?: boolean
  text?: string
  spinner?: boolean
  spinnerSize?: string
  className?: string
  background?: string
  color?: string
  zIndex?: number
  animate?: boolean
  onClick?: () => void
  style?: any
}

export default class LoadingOverlayWrapper extends React.Component<LoadingOverlayWrapperProps> {
  public static defaultProps = {
    active: false,
    className: '_loading-overlay',
    background: 'rgba(0, 0, 0, 0.7)',
    spinnerSize: '50px',
    color: '#FFF',
    zIndex: 800,
    animate: false,
    style: {},
  }

  public wrapper: HTMLDivElement

  public componentWillReceiveProps(nextProps) {
    const s = nextProps.style
    if (nextProps.active && (s.overflow || s.overflowY || s.overflowX)) {
      this.wrapper.scrollTop = 0
    }
  }

  public render() {
    const {
      active,
      animate,
      spinner,
    } = this.props

    let loadNode = active && <LoadingOverlay key="the_dimmer" {...this.props} />
    if (animate || spinner) {
      loadNode = (
        <CSSTransitionGroup
          transitionName="_loading-overlay-transition"
          transitionAppear={true}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          transitionAppearTimeout={500}
          component={FirstChild}
        >
          {loadNode}
        </CSSTransitionGroup>
      )
    }

    const styles = {
      position: 'relative',
      ...this.props.style,
    }
    if (active) {
      if (styles.overflow) {
        styles.overflow = 'hidden'
      }
      if (styles.overflowY) {
        styles.overflowY = 'hidden'
      }
      if (styles.overflowX) {
        styles.overflowX = 'hidden'
      }
    }
    return (
      <div
        ref={(n) => { this.wrapper = n }}
        className={this.props.className}
        style={styles}
      >
        {loadNode}
        {this.props.children}
      </div>
    )
  }
}
