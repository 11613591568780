import { SHOW_SIDE_PANEL, CLOSE_SIDE_PANEL, SET_FILTER_ID } from 'actions'

const defaultState = {
}

export const sidePanel = (state = defaultState, action) => {
  switch (action.type) {
    case SET_FILTER_ID:
      return {
        ...state,
        filterList: {
          visible: false,
        }
      }

    case SHOW_SIDE_PANEL:
      return {
        ...state,
        [action.panelName]: {
          visible: true,
        },
      }

    case CLOSE_SIDE_PANEL:
      return {
        ...state,
        [action.panelName]: {
          visible: false,
        },
      }

    default:
      return state
  }
}
