import * as React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #314550;
  ::placeholder {
    color: #aaa;
    opacity: 1; /* Firefox */
  }
`

export const RoundedInput = ({ input, placeholder, ...other }) => (
  <Input
    placeholder={placeholder}
    type="text"
    {...input}
    {...other}
  />
)
