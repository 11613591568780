import * as React from 'react'
import styled, { css } from 'styled-components'

const spinnerCss = css`
  position: relative;
  color: transparent;

  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 4px solid rgba(0,0,0,.15);
  }

  &:after {
    border: 4px solid;
    border-color: #fff transparent transparent;
    animation: button-spin .6s linear;
    animation-iteration-count: infinite;
  }

  @keyframes button-spin {
    from {
      transform:rotate(0);
    }
    to {
      transform:rotate(360deg);
    }
  }
`

interface ButtonProps {
  color?: 'success' | 'primary' | 'white' | 'alert' | 'default'
  flat?: boolean
  updating?: boolean
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  height: 42px;
  outline: 0;
  border: ${({ color = 'default', theme }) => theme.BUTTONS[color].border};
  vertical-align: baseline;
  background: ${({ color = 'default', theme }) => theme.BUTTONS[color].background};
  color: ${({ color = 'default', theme }) => theme.BUTTONS[color].color};
  font-family: Roboto, sans-serif;
  margin: 0 .25em 0 0;
  padding: .78571429em 1.5em .78571429em;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: ${({ flat }) => flat ? 0 : '10px'};
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
  text-transform: uppercase;
  transition: background-color 0.3s;
  ${props => props.updating ? spinnerCss : ''}

  &:hover {
    background-color: ${({ color = 'default', theme }) => theme.BUTTONS[color].backgroundHover};
    transition: background-color 300ms;
  }

  &[disabled] {
    border-color: ${({ color = 'default', theme }) => theme.BUTTONS[color].borderDisabled};
    background: ${({ color = 'default', theme }) => theme.BUTTONS[color].backgroundDisabled};
    cursor: auto;
  }
`
