import * as React from 'react'
import { map, toLower, truncate, get, includes, forEach, filter } from 'lodash'
import { spring, TransitionMotion } from 'react-motion'
import * as moment from 'moment'
import { connect } from 'react-redux'

import {
  Wrapper,
  Title,
  Header,
  Counter,
  CompanyWrapper,
  CompanyLogoWrapper,
  CompaniesWrapper,
  CompanyInfo,
  CompanyInfoInner,
  LiveText,
  LeftColumn,
  RightColumn,
  NewIndicator,
} from './side-bar.styles'
import { Button, Row, ThermometerRating } from 'components'
import { RelevantIcon } from './components'

const BASIC_HEIGHT = 164

interface SideBarProps {
  autoplay: boolean
  companies: any
  viewedIds: any[]
  onOpenView: (company: any) => void
  onCompanyClick: (company: any) => void
  selectedCompanyId: string
  addViewedCompanyIds: (ids: any[]) => void
}

export class SideBar extends React.PureComponent<SideBarProps> {
  private static calculateRelevance(diff: number) {
    const percent = 18000 // ms
    return Math.max(Math.floor(100 - (diff / percent)), 0)
  }

  public companyRefs = {}

  public state = {
    newCompanies: []
  }

  public componentDidMount(): void {
    this.markCompaniesAsVisited(this.props);
  }

  public componentWillReceiveProps(nextProps: Readonly<SideBarProps>, nextContext: any): void {
    if (JSON.stringify(nextProps.companies) !== JSON.stringify(this.props.companies)) {
      this.markCompaniesAsVisited(nextProps)
    }
    if (nextProps.selectedCompanyId !== this.props.selectedCompanyId) {
      const element = this.companyRefs[nextProps.selectedCompanyId] as Element
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }

  public onCompanyClick = (company) => {
    this.setState({
      newCompanies: filter(this.state.newCompanies, id => id !== company.companyId)
    })
    this.props.onCompanyClick(company)
  }

  public render() {
    const { companies, autoplay } = this.props
    return (
      <Wrapper>
        <Title>ON YOUR SITE</Title>
        <Header>
          <Counter>{companies.length}</Counter>
          IDENTIFIED VISITORS ON WEBSITE
          <LiveText>LIVE</LiveText>
        </Header>
        <CompaniesWrapper>
          {map(companies, (company) => {
            const diff = moment().utc().diff(moment.utc(company.dateHourMinute, 'YYYYMMDDHHmm'))
            return (
              <div
                ref={(ref) => this.companyRefs[company.companyId] = ref}
                key={company.companyId}
              >
                <CompanyWrapper onClick={this.onCompanyClick.bind(null, company)}>
                  {includes(this.state.newCompanies, company.companyId) ? <NewIndicator /> : null}
                  <CompanyLogoWrapper>
                    <img src={company.logo || '/images/company-logo.png'} alt="logo" />
                  </CompanyLogoWrapper>
                  <p>{company.companyName}</p>
                  <RelevantIcon relevance={SideBar.calculateRelevance(diff)} />
                </CompanyWrapper>
                <TransitionMotion
                  willLeave={() => ({
                    height: spring(0),
                  })}
                  willEnter={() => ({
                    height: 0,
                  })}
                  styles={this.props.selectedCompanyId === company.companyId ? [{
                    style: {
                      height: spring(BASIC_HEIGHT - (autoplay ? 40 : 0)),
                    },
                  }] : [{
                    style: {
                      height: spring(0),
                    },
                  }]}
                >
                  {(interpolatedStyles) => (
                    <CompanyInfo
                      {...interpolatedStyles[0]}
                    >
                      <CompanyInfoInner>
                        <Row>
                          <LeftColumn>
                            <p>{company.city}, {company.country} <img src={`/images/flags/${toLower(company.country)}.svg`}/></p>
                            <p>{truncate(company.bio, { length: 116})}</p>
                          </LeftColumn>
                          <RightColumn>
                            <ThermometerRating value={company.score} />
                          </RightColumn>
                        </Row>
                        <p>
                          Entered <b>{truncate(company.pageTitle, { length: 36 })}</b> {moment.duration(-diff).humanize(true)}
                        </p>
                        {!autoplay ? (
                          <Button
                            color="primary"
                            onClick={this.props.onOpenView.bind(null, company)}
                          >
                            OPEN COMPANY VIEW
                          </Button>
                        ) : null}
                      </CompanyInfoInner>
                    </CompanyInfo>
                  )}
                </TransitionMotion>
              </div>
            )
          })}
        </CompaniesWrapper>
      </Wrapper>
    )
  }

  private markCompaniesAsVisited = (props) => {
    const visited = []
    const newCompanies = [...this.state.newCompanies]
    forEach(props.companies, ({ companyId }) => {
      visited.push(companyId)
      if (!includes(props.viewedIds, companyId)) {
        newCompanies.push(companyId)
      }
    })
    this.setState( { newCompanies }, () => {
      props.addViewedCompanyIds(visited)
    })
  }
}
