import * as React from 'react'
import { compose } from 'react-apollo'
import { connect } from 'react-redux'
import { find, map, times, omit } from 'lodash'
import styled from 'styled-components'
import { Content, ResponsiveCardTable } from 'react-responsive-cards-table'

import { TableCard } from './google-analytics-table-card.component'
import { googleAnalyticsSummaryQueries } from './google-analytics-summary.query'
import { googleAnalyticsSummarySelector } from './google-analytics-summary.selector'
import { RoundCheckbox, Show, Spinner, LoadingBlock } from 'components'
import { Events, registerAnalyticEvent } from 'utils'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ViewNameColumn = styled(Column)`
  flex: 5;
`
const CheckboxColumn = styled(Column)`
  flex: 0;
`
const LoadingRow = styled(LoadingBlock)`
  margin-bottom: 0;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ECF1F5;
  padding: 15px;
  min-height: 26px;
  align-items: center;
  cursor: pointer;
`
const HeaderRow = styled(Row)`
  white-space: nowrap;
  border-bottom: 1px solid #95989A;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

interface OverlayProps {
  loading: boolean
}

const Overlay = styled.div<OverlayProps>`
  display: flex;
  flex: 1;
  position: relative;
  margin-bottom: 16px;
  opacity: ${({loading}) => loading ? '0.5' : '1'};
  transition: opacity 0.3s;
`

interface GoogleAnalyticsSummaryProps {
  enableGaViews?: any
  data?: any
  className?: string
}

@connect(googleAnalyticsSummarySelector)
@compose(...googleAnalyticsSummaryQueries)
export class GoogleAnalyticsSummary extends React.Component<GoogleAnalyticsSummaryProps> {
  public state = {
    loading: false,
    initialViewSelected: false,
  }

  public componentWillReceiveProps(nextProps) {
    const {enableGaViews, data: {getGoogleAnalyticsSummary, loading}} = nextProps
    const enabledView = find(getGoogleAnalyticsSummary, {enabled: true})
    if (!this.state.initialViewSelected &&
      !loading &&
      !this.state.loading &&
      getGoogleAnalyticsSummary &&
      getGoogleAnalyticsSummary.length &&
      !enabledView) {
      this.setState({loading: true})
      enableGaViews({variables: {viewId: getGoogleAnalyticsSummary[0].viewId}})
        .then(() => this.setState({loading: false, initialViewSelected: true}))
    }
  }

  public onItemToggle = (view) => {
    const {enableGaViews} = this.props

    this.setState({loading: true})
    registerAnalyticEvent(Events.GA_CONNECTED, omit(view, '__typename'))
    enableGaViews({variables: {viewId: view.viewId}})
      .then(() => this.setState({loading: false}))
  }

  public getTableContent = (isCard) => {
    const {data: {getGoogleAnalyticsSummary}} = this.props

    if (isCard) {
      return (
        <Content isCard={isCard}>
          {map(getGoogleAnalyticsSummary, (row, i) => (
            <TableCard onItemToggle={() => this.onItemToggle(row)} row={row} key={i}/>
          ))}
        </Content>
      )
    }

    return (
      <Content>
        <Overlay
          loading={this.state.loading}
        >
          <Column>
            <HeaderRow>
              GA Account
            </HeaderRow>
            {map(getGoogleAnalyticsSummary, (row, i) => (
              <Row
                key={i}
                onClick={() => this.onItemToggle(row)}
              >
                {row.accountName}
              </Row>))}
          </Column>
          <ViewNameColumn>
            <HeaderRow>
              View Name
            </HeaderRow>
            {map(getGoogleAnalyticsSummary, (row, i) => (
              <Row
                key={i}
                onClick={() => this.onItemToggle(row)}
              >
                {row.viewName}
              </Row>))}
          </ViewNameColumn>
          <ViewNameColumn>
            <HeaderRow>
              Visits 30 days
            </HeaderRow>
            {map(getGoogleAnalyticsSummary, (row, i) => (
              <Row
                key={i}
                onClick={() => this.onItemToggle(row)}
              >
                {row.visits}
              </Row>))}
          </ViewNameColumn>
          <CheckboxColumn>
            <HeaderRow/>
            {map(getGoogleAnalyticsSummary, (row, i) => (
              <Row key={i}>
                <RoundCheckbox
                  checked={row.enabled}
                  onClick={() => this.onItemToggle(row)}
                />
              </Row>))}
          </CheckboxColumn>
          <Show if={this.state.loading}>
            <Spinner/>
          </Show>
        </Overlay>
      </Content>
    )
  }


  public render() {
    const {className, data: {loading}} = this.props
    return (
      <Wrapper className={className}>
        <Show if={!loading}>
          <ResponsiveCardTable>
            {({isCard}) => this.getTableContent(isCard)}
          </ResponsiveCardTable>
        </Show>

        <Show if={loading}>
          <Column>
            <Row>
              <LoadingRow width="60%"/>
            </Row>
            {times(5, i => (
              <Row key={i}>
                <LoadingRow width="60%"/>
              </Row>
            ))}
          </Column>
        </Show>
      </Wrapper>
    )
  }
}
