import * as React from 'react'
import { SimpleSelect } from 'react-selectize'

export const FormDropdown = (options, placeholder, isBig = false) => ({ input }) => (
  <SimpleSelect
    options={options}
    placeholder={placeholder}
    onValueChange={input.onChange}
    hideResetButton={true}
    value={input.value}
    className={isBig ? 'big-select' : ''}
  />
)
