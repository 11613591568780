import * as React from 'react'
import { Switch } from 'components'
import { Label, Wrapper, Description } from './browser-notification-setting.styles'
import {
  getCurrentSubscription,
  isWebPushSupported,
  saveSubscription,
  subscribeToNotifications
} from '../../../../utils/notification'
import { Mutation } from 'react-apollo'
import { savePushSubscription } from '../../../../gql/notification'

export class BrowserNotificationSetting extends React.PureComponent {
  public state = {
    permission: null,
    subscription: null,
  }

  public async componentDidMount() {
    const subscription = await getCurrentSubscription()
    this.setState({ subscription })
  }

  public handleChange = async (value, mutation) => {
    if (value) {
      const subscription = await subscribeToNotifications()
      if (subscription) {
        await saveSubscription(subscription, mutation)
      }
      this.setState({ subscription })
    } else {
      const subscription = await getCurrentSubscription()
      await subscription.unsubscribe()
      this.setState({ subscription: null })
    }
  }

  public render(): React.ReactNode {
    if (isWebPushSupported()) {
      const subscribed = (window as any).Notification.permission === 'granted' && this.state.subscription

      return (
        <>
          <h3>Browser Notification</h3>
          <Description>
            We can let you know right away when something
            important happens in Leadexplorer.
          </Description>
          <Wrapper>
            <Mutation mutation={savePushSubscription}>
              {mutation => (
                <Switch
                  checked={subscribed}
                  onChange={(value) => this.handleChange(value, mutation)}
                />
              )}
            </Mutation>
            <Label>Browser Notification</Label>
          </Wrapper>
        </>
      )
    }
    return null
  }
}
