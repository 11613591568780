import * as React from 'react'
import { map, reduce } from 'lodash'
import { WrappedFieldProps } from 'redux-form'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import { Card, Row, Show, Link } from 'components'
import { PlanCardInner, PlanLink } from '../purchase-plan.styles'
import { InfoCard } from '../../../settings-container.styles'
import { PlanCardsList } from './plan-cards-list.component'
import { Plan } from '../plan.model'

const CardTitle = (Card as any).Title

const query = gql`
  query {
    getTenantStats {
      noOfCompanies
    }
  }
`

type ChoosePlanProps = {
  planCode: string
  monthlyPlans: Plan[]
  yearlyPlans: Plan[]
} & WrappedFieldProps

export class ChoosePlan extends React.PureComponent<ChoosePlanProps> {
  public state = {
    plansRow: 0,
    selectedPlan: null
  }

  public onLinkClick = index => this.setState({ plansRow: index })

  public onPlanClick = (selectedPlan) => {
    this.props.input.onChange(selectedPlan)
    this.setState({ selectedPlan })
  }

  public onContactUs = () => {
    const subject = decodeURIComponent('More leads or a downgrade')
    const { location } = (window as any)
    location.href = `mailto:sales@leadexplorer.com?subject=${subject}`
  }

  public render() {
    const { planCode, monthlyPlans, yearlyPlans } = this.props
    return (
      <InfoCard>
        <CardTitle>
          1. Choose plan
        </CardTitle>
        <PlanCardInner>
          <Query query={query}>
            {({ data }) => (
              <p>
                Choose the plan which matches your site. Your site generated {data.getTenantStats ? <b>{data.getTenantStats.noOfCompanies}</b> : '...'} leads the last 30 days.
              </p>
            )}
          </Query>
          <Row>
            <PlanLink
              active={this.state.plansRow === 0}
              onClick={this.onLinkClick.bind(null, 0)}
            >
              MONTHLY PLANS
            </PlanLink>
            <PlanLink
              active={this.state.plansRow === 1}
              onClick={this.onLinkClick.bind(null, 1)}
            >
              YEARLY PLANS (Save 20%)
            </PlanLink>
          </Row>
          <Show if={this.state.plansRow === 0}>
            <PlanCardsList
              plans={monthlyPlans}
              current={planCode}
              selected={this.state.selectedPlan}
              onClick={this.onPlanClick}
            />
            <footer>
              <p>The plans are paid for in advance on a monthly basis.</p>
              <p>Need more leads or a downgrade? <Link onClick={this.onContactUs}>Contact us.</Link></p>
            </footer>
          </Show>
          <Show if={this.state.plansRow === 1}>
            <PlanCardsList
              plans={yearlyPlans}
              current={planCode}
              selected={this.state.selectedPlan}
              onClick={this.onPlanClick}
            />
            <footer>
              <p>The plans are paid for in advance on a yearly basis.</p>
              <p>Need more leads or a downgrade? <Link onClick={this.onContactUs}>Contact us.</Link></p>
            </footer>
          </Show>
        </PlanCardInner>
      </InfoCard>
    )
  }
}
