import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { Show } from './show.component'

const bgColor = 'rgba(0, 0, 0, 0.12)'
const progress = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 35px 35px;
  }
`
const ImageLoading = styled.div`
  background: ${bgColor};
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
  animation: ${progress} 2s linear infinite;
  width: 100%;
  height: 100%;
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const fadeInKeyFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

interface AnimatedImageProps {
  src: any
  position?: string
  backgroundSize?: string
  round?: boolean
}

export const AnimatedImage = styled.div<AnimatedImageProps>`
  background: ${({ src, position = 'center center' }) => `url(${src}) no-repeat ${position}`};
  background-size: ${({ backgroundSize = 'cover' }) => backgroundSize};
  animation: ${fadeInKeyFrames} 1s ease-in;
  width: 100%;
  height: 100%;
  border-radius: ${({ round = false }) => round ? '50%' : 0};
`

interface ProgressiveImageProps {
  src: string
  loadingAnimation?: any
  className?: string
  styles?: any
  round?: boolean
  onError?: (message) => void
  loading?: boolean
}

export class ProgressiveImage extends React.Component<ProgressiveImageProps> {
  public image: any
  public state = {
    loading: true,
  }

  public componentDidMount() {
    const { src } = this.props
    this.loadImage(src)
  }

  public componentWillReceiveProps(nextProps) {
    const { src } = nextProps

    if (src !== this.props.src) {
      this.setState({ loading: true }, () => {
        this.loadImage(src)
      })
    }
  }

  public componentWillUnmount() {
    if (this.image) {
      this.image.onload = null
      this.image.onerror = null
    }
  }

  public onLoad = () => {
    this.setState({
      loading: false,
    })
  }

  public onError = (errorEvent) => {
    const { onError } = this.props
    if (onError) {
      onError(errorEvent)
    }
  }

  public loadImage = (src) => {
    const image = new Image()
    this.image = image
    image.onload = this.onLoad
    image.onerror = this.onError
    image.src = src
  }

  public render() {
    const { src, loadingAnimation, className, styles = {}, round, ...other } = this.props

    return (
      <Wrapper className={className} >
        <Show if={loadingAnimation && this.state.loading}>
          <ImageLoading />
        </Show>
        <Show if={!this.state.loading}>
          <AnimatedImage
            src={src}
            round={round}
            backgroundSize={styles.backgroundSize}
            position={styles.position}
            {...other}
          />
        </Show>
      </Wrapper>
    )
  }
}
