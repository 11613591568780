/* tslint:disable */
import * as React from 'react'
import { withTheme } from 'styled-components'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import { map as mapFunc, forEach, get, find, filter, includes, reduce, first, last } from 'lodash'

import mapStyles from '../../../../styles/google-maps'
import { getScreenshotUrl } from 'utils'
import { LiveViewMapNavigator } from './live-view-map-navigator'

interface LiveViewMapProps {
  theme?: any
  googleMapURL?: any
  loadingElement?: any
  containerElement?: any
  mapElement?: any
  companies: any
  onCompanyClick?: any
  selectedCompanyId: any
  autoplay: boolean
  changeAutoplay: () => void
  setSelectedCompanyId: (id: string) => void
}

const LAT = 48.815979
const LNG = 16.397186

// @ts-ignore
@withScriptjs
// @ts-ignore
@withGoogleMap
// @ts-ignore
@withTheme
export class LiveViewMap extends React.Component<LiveViewMapProps> {
  public map: any
  public markers: any
  public HTMLMapMarker: any
  public currentSelectedCompany: any

  private navigator: LiveViewMapNavigator
  private autoplayTimeout: NodeJS.Timer

  public componentDidMount(): void {
    const self = this
    this.HTMLMapMarker = class HTMLMapMarker extends google.maps.OverlayView {
      public id: string
      public readonly otherCompanies: any
      private div: HTMLElement
      private selected: boolean
      private readonly html: any
      private readonly latlng: any
      private readonly size: number

      constructor(args) {
        super()
        this.id = args.id
        this.latlng = args.latlng
        this.html = args.html
        this.size = args.size
        this.selected = args.selected
        this.otherCompanies = args.otherCompanies
        this.setMap(args.map)
      }

      public createDiv() {
        this.div = document.createElement('div')
        this.div.style.position = 'absolute'
        if (this.html) {
          this.div.innerHTML = this.html
        }
        google.maps.event.addDomListener(this.div.querySelector('.company-circle'), 'click', () => {
          google.maps.event.trigger(this, 'click:circle')
        })
        google.maps.event.addDomListener(this.div.querySelector('.company-arrow-left'), 'click', () => {
          google.maps.event.trigger(this, 'click:arrow:left')
        })
        google.maps.event.addDomListener(this.div.querySelector('.company-arrow-right'), 'click', () => {
          google.maps.event.trigger(this, 'click:arrow:right')
        })
      }

      public appendDivToOverlay() {
        const panes = this.getPanes()
        panes.overlayMouseTarget.appendChild(this.div)
      }

      public positionDiv() {
        if (!this.div) {
          return
        }
        const map = this.getMap() as google.maps.Map
        const zoom = map.getZoom()
        const currentId = get(self, 'currentSelectedCompany.companyId')
        const isActive = this.id === currentId
        const isInBounds = map.getBounds().contains(this.latlng)
        const isOverlapped = self.currentSelectedCompany && this.otherCompanies.length > 1 && !isActive && includes(this.otherCompanies, currentId)
        if (!isInBounds || isOverlapped) {
          this.div.style.display = 'none'
          return
        }
        if (this.otherCompanies.length > 1) {
          this.div.classList.add('has-other-companies')
        }
        this.div.style.display = 'block'
        const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
        const showInfo = isActive && zoom > 8
        if (point) {
          this.div.style.left = `${point.x - 28}px`
          this.div.style.top = `${point.y - 28}px`
          this.div.style.zIndex = isActive ? '100' : '1'
          this.div.querySelector<HTMLElement>('.company-circle').style.transform = `scale(${(0.1 * this.size) + Math.min(zoom, 8) / 10})`
          if (showInfo) {
            this.div.classList.add('show-info')
          } else {
            this.div.classList.remove('show-info')
          }
        }
      }

      public draw() {
        if (!this.div) {
          this.createDiv()
          this.appendDivToOverlay()
        }
        this.positionDiv()
      }

      public remove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div)
          this.div = null
        }
      }

      public getPosition() {
        return this.latlng
      }

      public getDraggable() {
        return false
      }
    }

    const playButton = document.createElement('div')
    playButton.innerHTML = "<button class='gm-live-view-play'><span class='play'>▶</span><span class='pause'>❚❚</span></button>"
    playButton.onclick = this.props.changeAutoplay

    const map = this.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED

    this.navigator = new LiveViewMapNavigator(map)

    map.controls[google.maps.ControlPosition.RIGHT_TOP]
      .push(playButton)

    this.markers = this.createMarkers(this.props.companies)
  }

  public componentWillUnmount(): void {
    clearTimeout(this.autoplayTimeout)
  }

  public componentWillReceiveProps(nextProps: Readonly<LiveViewMapProps>, nextContext: any): void {
    const newCompanies = nextProps.companies
    if (JSON.stringify(this.props.companies) !== JSON.stringify(nextProps.companies)) {
      const markersToKeep = []
      const markersToKeepIds = []
      forEach(this.markers, (marker) => {
        const company: any = find(newCompanies, { companyId: marker.id })
        if (company) {
          marker.otherCompanies = company.sortedByCity[company.country + company.city] || []
          marker.positionDiv()
          markersToKeep.push(marker)
          markersToKeepIds.push(marker.id)
        } else {
          marker.setMap(null)
        }
      })

      this.markers = [
        ...markersToKeep,
        ...this.createMarkers(
          filter(nextProps.companies, company => !includes(markersToKeepIds, company.companyId))
        )
      ]
    }
    if (nextProps.selectedCompanyId !== this.props.selectedCompanyId) {
      const company = find(nextProps.companies, x => x.companyId === nextProps.selectedCompanyId)
      if (company) {
        this.currentSelectedCompany = company
        this.centerCompany(company)
      } else {
        this.centerDefault()
      }
      forEach(this.markers, (marker) => marker.positionDiv())
    }
    if (nextProps.autoplay && !this.props.autoplay) {
      this.startAutoplay()
      if (nextProps.selectedCompanyId === null && this.props.selectedCompanyId === null) {
        this.centerDefault()
      }
    } else if (!nextProps.autoplay && this.props.autoplay) {
      this.stopAutoplay()
    }
  }

  public centerCompany(company) {
    const { latitude, longitude } = company
    this.navigator.setPointToShow('company', new google.maps.LatLng(latitude, longitude))
  }

  public centerDefault() {
    this.navigator.setPointToShow('default', new google.maps.LatLng(LAT, LNG))
  }

  public render() {
    return (
      <GoogleMap
        ref={(x) => this.map = x}
        clickableIcons={true}
        defaultZoom={4}
        defaultCenter={{ lat: LAT, lng: LNG }}
        options={{
          styles: mapStyles[this.props.theme.THEME_ID],
          draggable: true,
          minZoom: 3,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true
        }}
      />
    )
  }

  private createMarkers = (companies) => {
    return mapFunc(companies, (company) => {
      const { logo, latitude, longitude, companyId, employeesCount, sortedByCity, country, city } = company
      const marker = new this.HTMLMapMarker({
        id: companyId,
        otherCompanies: sortedByCity[country + city] || [],
        size: this.getSizeByEmployeesCount(employeesCount),
        latlng: new google.maps.LatLng(latitude, longitude),
        map: this.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
        html: `
          <div class="company-wrapper">
            <div class="company-arrow-left"></div>
            <div class="company-circle">
              <img class="company-wrapper-logo" src="${logo || '/images/company-logo.png'}" />
            </div>
            <div class="company-arrow-right"></div>
            <div class="company-screenshot">
              <img src="${getScreenshotUrl(company.pageUrl)}" />
            </div>
            <div class="company-name">
                <span>${company.companyName}</span>
            </div>
          </div>
        `
      })
      marker.addListener('click:circle', () => {
        this.centerCompany(company)
        this.props.onCompanyClick(company.companyId)
      })
      marker.addListener('click:arrow:left', () => {
        const newIndex = marker.otherCompanies.indexOf(marker.id) - 1
        if (newIndex < 0) {
          this.props.onCompanyClick(last(marker.otherCompanies))
        } else {
          this.props.onCompanyClick(marker.otherCompanies[newIndex])
        }
      })
      marker.addListener('click:arrow:right', () => {
        const newIndex = marker.otherCompanies.indexOf(marker.id) + 1
        if (newIndex < marker.otherCompanies.length) {
          this.props.onCompanyClick(marker.otherCompanies[newIndex])
        } else {
          this.props.onCompanyClick(first(marker.otherCompanies))
        }
      })
      return marker
    })
  }

  private getSizeByEmployeesCount = (count) => {
    const sizeBounds = [
      [1, 10],
      [11, 50],
      [51, 100],
      [101, 500],
      [500, 1000],
      [1000, Infinity]
    ]
    return reduce(sizeBounds, (result, bounds, index) => {
      if (count >= bounds[0] && count <= bounds[1]) {
        return index + 1
      }
      return result
    }, 3)
  }

  private startAutoplay = () => {
    this.props.setSelectedCompanyId(null)
    this.autoplayTimeout = setTimeout(this.performAutoplayStep, 7000)
  }

  private stopAutoplay = () => {
    clearTimeout(this.autoplayTimeout)
  }

  private performAutoplayStep = () => {
    if (!this.props.autoplay) {
      return
    }
    const { companies, selectedCompanyId } = this.props
    if (selectedCompanyId) {
      const lastIndex = companies.length - 1
      const nextCompany = reduce(companies, (result: any, company: any, index: number) => {
        if (company.companyId === selectedCompanyId) {
          if (index < lastIndex) {
            return companies[index + 1]
          }
        }
        return result
      }, null)
      if (nextCompany) {
        this.props.setSelectedCompanyId(nextCompany.companyId)
      } else {
        this.props.setSelectedCompanyId(null)
      }
    } else {
      this.props.setSelectedCompanyId(get(first(companies), 'companyId', null))
    }
    this.autoplayTimeout = setTimeout(this.performAutoplayStep, 7000)
  }
}
