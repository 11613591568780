import * as React from 'react'

import { Modal, ImagePicker } from '../../../../../../components'
import { Title } from './change-logo-modal.styles'

interface ChangeLogoModalProps {
  onDone: any
  toggle: any
  isOpen: boolean
}

export class ChangeLogoModal extends React.Component<ChangeLogoModalProps> {
  public state = {
    image: null,
  }

  public onImage = (image) => {
    this.setState({ image })
  }

  public upload = () => {
    const { image } = this.state
    this.props.onDone(image)
    this.setState({ image: null })
  }

  public closeModal = () => {
    this.setState({ image: null })
    this.props.toggle()
  }

  public render() {
    const { isOpen, toggle } = this.props
    const { image } = this.state

    return (
      <Modal style={{ content: { maxWidth: '500px' } }} isOpen={isOpen} onRequestClose={toggle}>
        <Title>Company Logo</Title>
        <ImagePicker
          onImage={this.onImage}
        />
        <Modal.Controls>
          <Modal.CancelButton
            onClick={this.closeModal}
          >
            Cancel
          </Modal.CancelButton>
          <Modal.ApproveButton
            onClick={this.upload}
            disabled={!image}
          >
            Upload
          </Modal.ApproveButton>
        </Modal.Controls>
      </Modal>
    )
  }
}
