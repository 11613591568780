import { graphql } from 'react-apollo'
import { blacklist, deleteBlacklist, updateBlacklist } from '../../gql/blacklist'

export default [
  graphql(blacklist, {
    name: 'data',
  }),
  graphql(updateBlacklist, {
    name: 'updateBlacklist',
  }),
  graphql(deleteBlacklist, {
    name: 'deleteBlacklist',
  }),
]

