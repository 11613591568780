import styled from 'styled-components'

export const Label = styled.div`
  width: 560px;
  font-size: 18px;
  color: #314550;
  text-align: left;
  margin-bottom: 12px;
  
  @media (max-width: 959px) {
    width: auto;
    text-align: center;
  }

  span {
    font-size: 14px;

    @media (max-width: 959px) {
      display: block;
    }
  }
`

interface IconWrapperProps {
  show: boolean
  isFree: boolean
}

export const IconWrapper = styled.div<IconWrapperProps>`
  display: ${({ show }) => show ? 'block' : 'none' }
  width: 25px;
  height: 25px;
  
  g {
    stroke: ${({ isFree }) => isFree ? '#7AAB59' : '' }
  }

  path {
    fill: ${({ isFree }) => isFree ? '' : '#D85251' }
  }
`
export const InputWrapper = styled.div`
  position: relative;
  
  input {
    border: 1px solid #BFBFBF;
    padding: 16px 20px;
    margin-right: 8px;
    margin-bottom: 32px;
    width: 345px;
    font-size: 24px;
    box-sizing: border-box;
    text-align: right;

    @media (max-width: 959px) {
      margin-bottom: 16px;
      width: auto;
    }
    
    &::placeholder {
      color: #C4C4C4;
    }
  }
  
  span {
    font-size: 24px;
    color: #314550;
  }
  
  ${IconWrapper} {
     position: absolute;
     top: 18px;
     left: 12px;
  }
`

export const Small = styled.div`
  width: 300px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #314550;
`

export const BottomTextWrapper = styled.div`
  height: 80px;
`
export const AgreementWrapper = styled.div`
  position: relative;
  height: 80px;

  label {
    @media (max-width: 959px) {
      position: relative;
    }
  }
`
