import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as moment from 'moment'
import { map } from 'lodash'

import defaultTheme from '../datepicker.theme'
import { Show } from '../../../'
import { CustomDatePicker } from './components'
import { Wrapper, CalendarIcon, RangeButton, Ranges, Value, ValueWrapper } from './predefined-datepicker.styles'

const predefinedValues = [
  { title: 'Since your last visit', startDate: moment(), endDate: moment() },
  { title: 'Since yesterday', startDate: moment().add(-1, 'days'), endDate: moment() },
  { title: 'Last 7 days', startDate: moment().add(-7, 'days'), endDate: moment() },
  { title: 'Last 30 days', startDate: moment().add(-1, 'month'), endDate: moment() },
  { title: 'This week', startDate: moment().startOf('isoWeek'), endDate: moment() },
  { title: 'This month', startDate: moment().startOf('month'), endDate: moment() },
  {
    title: 'Last month',
    startDate: moment().add(-1, 'month').startOf('month'),
    endDate: moment().add(-1, 'month').endOf('month'),
  },
]

interface PredefinedDatePickerProps {
  dateRange?: any
  ranges?: any
  onDateChange?: any
  firstDayOfWeek?: any
  calendars?: any
  theme?: any
  className?: any
}

export class PredefinedDatePicker extends React.PureComponent<PredefinedDatePickerProps> {
  public static defaultProps = {
    ranges: predefinedValues,
    calendars: 1,
    firstDayOfWeek: 1,
  }

  public state = {
    isRangesOpen: false,
    isPickerOpen: false,
    dateRange: this.props.dateRange || this.props.ranges[1],
    position: {},
  }

  private ranges: any
  private valueWrapper: any

  public componentDidMount() {
    this.onDateChange(this.state.dateRange)
  }

  public onDateChange = (dateRange) => {
    this.setState({ dateRange, isPickerOpen: false, isRangesOpen: false })
    this.props.onDateChange(dateRange)
  }

  public openRangePicker = () => this.setState({ isPickerOpen: true, isRangesOpen: false })
  public closeDatePicker = () => this.setState({ isPickerOpen: false })
  public openRanges = () => {
    this.setState({ isRangesOpen: true }, () => {
      const rangesNode = ReactDOM.findDOMNode(this.ranges)
      const buttonNode = ReactDOM.findDOMNode(this.valueWrapper)
      let rangesSizes
      let buttonSizes
      if (rangesNode instanceof Element) {
        rangesSizes = rangesNode.getBoundingClientRect()
      }
      if (buttonNode instanceof Element) {
        buttonSizes = buttonNode.getBoundingClientRect()
      }
      const { width } = rangesSizes
      const viewportWidth = window.innerWidth

      const padding = 10
      const leftOffset = buttonSizes.left + buttonSizes.width + padding
      const rightOffset = viewportWidth - (buttonSizes.left + buttonSizes.width + padding)

      if (rightOffset > width) {
        return this.setState({ position: { left: 0 } })
      }

      if (leftOffset > width) {
        return this.setState({ position: { right: 0 } })
      }

      const offset = ((viewportWidth - width) / 2) - (viewportWidth - leftOffset)
      return this.setState({ position: { right: `${offset}px` } })
    })
  }
  public closeRanges = () => this.setState({ isRangesOpen: false })

  public formatRange = ({ startDate, endDate }) => {
    const start = moment(startDate).format('DD MMM')
    const end = moment(endDate).format('DD MMM')

    return start === end ? start : `${start} - ${end}`
  }

  public render() {
    const { ranges, firstDayOfWeek, calendars, theme, className } = this.props
    const { isRangesOpen, isPickerOpen, dateRange, position } = this.state

    return (
      <Wrapper className={className}>
        <ValueWrapper ref={node => this.valueWrapper = node} onClick={this.openRanges}>
          <CalendarIcon icon="calendar" />
          <Value>{this.formatRange(dateRange)}</Value>
        </ValueWrapper>
        <Show if={isRangesOpen}>
          <Ranges
            position={position}
            ref={node => this.ranges = node}
            onOutsideClick={this.closeRanges}
          >
            {map(ranges, range => (
              <RangeButton key={range.title} onClick={() => this.onDateChange(range)}>
                {range.title}
              </RangeButton>
            ))}
            <RangeButton color="primary" onClick={() => this.openRangePicker()}>
              Custom
            </RangeButton>
          </Ranges>
        </Show>
        <Show if={isPickerOpen}>
          <CustomDatePicker
            position={position}
            firstDayOfWeek={firstDayOfWeek}
            dateRange={dateRange}
            calendars={calendars}
            onInit={this.onDateChange}
            onDateChange={this.onDateChange}
            onOutsideClick={this.closeDatePicker}
            theme={{ ...defaultTheme, theme }}
          />
        </Show>
      </Wrapper>
    )
  }
}
