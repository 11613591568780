import { CONFIG } from '../config'

export const submitEmForm = (data) => {
  return fetch('https://app.emarketeer.com/ext/form/receiver.php', {
    method: 'POST',
    body: data,
  })
}

export const reportEmSignUp = async (email: string) => {
  if (CONFIG.ENV === 'prod') {

    const emFormData = new FormData()
    emFormData.append('m', '312225a866746a20f33de26541f0e7611b4c59a')
    emFormData.append('query_1667008_16_1', email)

    try {
      await submitEmForm(emFormData)
    } catch (e) {
      // ignore exceptions
    }
  }
}
