import * as React from 'react'

import { Wrapper, MatchButton, NameWrapper } from './search-company-list-item.styles'

interface SearchCompanyListItemProps {
  id: string
  company: any
  onMatch: (id) => Promise<any>
}

export const SearchCompanyListItem: React.SFC<SearchCompanyListItemProps> = ({ id, company, onMatch }) => (
  <Wrapper>
    <NameWrapper>{company.name}</NameWrapper>
    <MatchButton
      onClick={() => onMatch(company.companyId)}
    >
      MATCH
    </MatchButton>
  </Wrapper>
)
