import * as React from 'react'

import BaseSwitch from 'rc-switch'

interface BaseSwitchProps {
  onChange?: any
  checked?: boolean
  disabled?: boolean
}

export class Switch extends React.PureComponent<BaseSwitchProps> {
  public render() {
    return (
      <BaseSwitch
        {...this.props}
      />
    )
  }
}
