import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

interface PageTabsItemProps {
  location?: {
    pathname?: any
    search?: any
  }
  to?: any
}

export const PageTabsItem = styled<PageTabsItemProps>(NavLink)`
  margin: 0 4px 14px 0;
  padding: 8px 16px;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s;
  border-radius: 12px;
  &:hover {
    background-color: rgba(255, 255, 255, .4);
  }
  &.active {
    background-color: white;
    font-weight: 500;
  }
`

interface PageTabsPlainItemProps {
  isActive: boolean
}

export const PageTabsPlainItem = styled.div<PageTabsPlainItemProps>`
  margin: 0 4px 14px 0;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: ${({ isActive }) => isActive ? 500 : 400};
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s;
  border-radius: 12px;
  background-color: ${({ isActive }) => isActive ? 'white' : 'none'};
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, .4);
  }
`
