import * as React from 'react'
import styled from 'styled-components'
import { reduce } from 'lodash'

import icons from './svgs'

const defaultSize = 18
const defaultColor = '#314550'

interface IconProps {
  icon: string
  className?: string
  onClick?: () => void
  size?: number,
  color?: string,
}

interface SvgProps {
  size?: number
  color?: string,
}

const iconComponents = reduce(icons, (result, icon, key) => {
  if (icon) {
    result[key] = styled(icon)<SvgProps>`
      width: ${({ size = defaultSize }) => size}px;
      height: ${({ size = defaultSize }) => size}px;
      min-width: ${({ size = defaultSize }) => size}px;
      min-height: ${({ size = defaultSize }) => size}px;
      fill: ${({ color = defaultColor }) => color};
      background-size: contain;
      margin-right: 13px;
    `
  }
  return result
}, {})

export const Icon: React.SFC<IconProps> = (props) => {
  const Svg = iconComponents[props.icon]
  return <Svg {...props} />
}
