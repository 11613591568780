import * as React from 'react'
import { compose, graphql } from 'react-apollo'

import { Button, Card, FadeIn, LoadingOverlay, Show, SidebarLayout, Spinner } from './../../components'
import {
  AvatarWrapper,
  ContentRow,
  FormWrapper,
  NoPictureText,
  PageTitle,
  PageWrapper,
  Picture,
  PictureWrapper,
} from './my-profile.styles'
import { changePassword, updateUser, userQuery } from '../../gql'
import { MyProfileForm, SendOutSetting, UpdateAvatarModal } from './components'
import { error, Events, registerAnalyticEvent, success } from 'utils'
import { BrowserNotificationSetting } from './components/browser-notification'

interface MyProfileProps {
  data?: any
  updateUser?: any
  changePassword?: any
  updateSendoutSettings?: any
}

// @ts-ignore
@compose(
  graphql(userQuery),
  graphql(updateUser, {
    name: 'updateUser',
    options: {
      update: (proxy, { data }) => {
        const options = { query: userQuery }
        const cache = proxy.readQuery(options)
        // @ts-ignore
        cache.getUser = data.updateUser
        proxy.writeQuery({ ...options, data: cache })
      },
    },
  }),
  graphql(changePassword, {
    name: 'changePassword',
  }),
)
export class MyProfile extends React.PureComponent<MyProfileProps> {
  public state = {
    avatarModalIsOpen: false,
    uploadInProgress: false,
  }

  public onProfileSubmit = ({ firstName, lastName }) => {
    this.setState({
      uploadInProgress: true,
    })
    return this.props.updateUser({
      variables: {
        firstName,
        lastName,
      },
    })
      .then(() => this.setState({ uploadInProgress: false }))
      .then(() => success('Profile is updated'))
  }

  public onAvatarSubmit = (picture) => {
    this.setState({
      avatarModalIsOpen: false,
      uploadInProgress: true,
    })
    return this.props.updateUser({
      variables: {
        picture,
      },
    }).then(() => {
      this.setState({
        uploadInProgress: false,
      })
      registerAnalyticEvent(Events.USER_PROFILE_UPLOAD_PHOTO, { imageFormat: 'png'})
      success('Picture is updated')
    }).catch(() => {
      this.setState({
        uploadInProgress: false,
      })
      error('Server error, please try again')
    })
  }

  public onAvatarClick = () => {
    this.setState({
      avatarModalIsOpen: true,
    })
  }

  public onRequestClose = () => {
    this.setState({
      avatarModalIsOpen: false,
    })
  }

  public onPasswordClick = () => {
    const { email } = this.props.data.getUser
    this.setState({
      uploadInProgress: true,
    })
    this.props.changePassword({
      variables: {
        email,
      },
    }).then(() => {
      this.setState({
        uploadInProgress: false,
      })
      success('Email with instructions has been sent to your address')
    })
  }

  public render() {
    const { uploadInProgress } = this.state
    const { getUser, loading } = this.props.data
    return (
      <SidebarLayout>
        <PageWrapper>
          <PageTitle>
            My Profile
          </PageTitle>

          <Show if={loading}>
            <FadeIn timeout={300}>
              <Spinner />
            </FadeIn>
          </Show>

          <Show if={!loading}>
            <FadeIn timeout={300}>
              <Card>
                <Card.Title>
                  PROFILE INFORMATION
                </Card.Title>
                <ContentRow>
                  <FormWrapper>
                    <h3>Personal details</h3>
                    <MyProfileForm
                      initialValues={getUser}
                      onSubmit={this.onProfileSubmit}
                    />
                    <h3>Authentication</h3>
                    <Button
                      disabled={uploadInProgress}
                      onClick={this.onPasswordClick}
                    >
                      CHANGE PASSWORD
                    </Button>
                    <SendOutSetting />
                    <BrowserNotificationSetting />
                  </FormWrapper>
                  <AvatarWrapper>
                    <PictureWrapper noPicture={getUser.picture === null}>
                      <Picture src={getUser.picture} />
                      <NoPictureText>
                        No photo
                      </NoPictureText>
                    </PictureWrapper>
                    <Button
                      disabled={uploadInProgress}
                      onClick={this.onAvatarClick}
                    >
                      CHANGE PHOTO
                    </Button>
                  </AvatarWrapper>
                </ContentRow>
              </Card>
            </FadeIn>
          </Show>

          <UpdateAvatarModal
            isOpen={this.state.avatarModalIsOpen}
            onRequestClose={this.onRequestClose}
            onAvatarSubmit={this.onAvatarSubmit}
          />
        </PageWrapper>
        <Show if={this.state.uploadInProgress}>
          <LoadingOverlay
            active={true}
            spinner={true}
            background="rgba(0,0,0,0.4)"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 20,
            }}
          />
        </Show>
      </SidebarLayout>
    )
  }
}
