import gql from 'graphql-tag'

export const groupsQuery = gql`
  query {
    getGroups {
        _id
        name
    }
  }
`

