import * as React from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { Redirect } from 'react-router-dom'
import { SubmissionError } from 'redux-form'

import { BaseLayout, FadeIn, LoadingOverlay } from '../../components/'
import { ExpiredInvitation, VerifyForm } from './components'
import { LayoutWrapper, Wrapper } from './verify.styles'
import { RegistrationApi } from '../../api/registration'
import { Events, registerAnalyticEvent, error } from 'utils'
import { rootUrl } from '../../config'

interface VerifyProps {
  match?: any
}

@connect(null, { push })
export class Verify extends React.PureComponent<VerifyProps> {
  public state = {
    loading: true,
    codeNotValid: false,
  }

  public async componentDidMount() {
    const { ticketId } = this.props.match.params

    const ticketVerificationResponse = await fetch(`${rootUrl}/user/verify-invite/code/${ticketId}`)

    if (ticketVerificationResponse.status !== 200) {
      this.setState({codeNotValid: true})
    }

    this.setState({ loading: false })
  }

  public onSubmit = ({ lastName, firstName, password }) => {
    const { ticketId } = this.props.match.params
    this.setState({ loading: true })
    return RegistrationApi.register({
      lastName,
      firstName,
      password,
      ticketId,
    })
      .then((res) => {
        if (res.status !== 200) {
          throw res
        }
        return res.json()
      })
      .then((body) => {
        if (body.url) {
          document.location.href = `https://${body.url}`
          registerAnalyticEvent(Events.USER_INVITE_ACCEPTED, { invitedTicketId: ticketId})
        } else {
          error('Internal server error. Please contact support.')
        }
      })
      .catch((res) => {
        this.setState({ loading: false })
        return res
          .json()
          .then((err) => {
            if (err.message === 'PasswordDictionaryError: Password is too common') {
              throw new SubmissionError({ password: 'Password is too common' })
            } else if (err.message === 'Ticket do not exist') {
              return error('This invite has expired or has never been sent. Contact the owner of the account to receive an invite' )
            }
            error('Internal server error. Please contact support.')
          })
      })
  }

  public render() {
    if (this.state.codeNotValid) {
      return (
        <ExpiredInvitation />
      )
    }

    return (
      <LoadingOverlay
        spinner={true}
        active={this.state.loading}
        style={{ height: '100%' }}
      >
        <LayoutWrapper>
          <BaseLayout>
            <Wrapper>
              <FadeIn>
                <h1>Let’s create an account!</h1>
                <h3>Fill out the form to get started in no time.</h3>
                <VerifyForm
                  onSubmit={this.onSubmit}
                />
              </FadeIn>
            </Wrapper>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={true}
              position="top-center"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
            />
          </BaseLayout>
        </LayoutWrapper>
      </LoadingOverlay>
    )
  }
}
