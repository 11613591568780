import styled from 'styled-components'
import { Card, Row } from 'components'

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`
export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  margin: 45px 0 26px 0;
`
export const InfoCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 300px;
  margin: 7px;
  @media (min-width: 800px) {
    min-width: 450px;
  }

  ${Row} {
    align-items: center;
    justify-content: space-between;
  }
`
export const Inner = styled.div`
  padding: 0 24px 32px;
  
  p {
    width: 430px;
    font-weight: 300;
    font-size: 14px;
  }
`
export const TextWrapper = styled.div`
  max-width: 360px;
  margin-left: 42px;
  
  h3 {
    font-weight: 300;
    font-size: 24px;
  }
  
  p {
    font-size: 14px;
  }
`
export const Block = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  
  a {
    text-decoration-line: underline;
    color: #61869E;
  }
`
