import * as React from 'react'
import { get, truncate } from 'lodash'

import { Show } from 'components'
import { colorScale } from 'utils'
import { CardWrapper, Logo, Footer, Name, Location, LogoWrapper, Score } from './search-result-card.styles'

export const SearchResultCard = ({ company, onClick }) => {
  const { name, id, logo, locations, score } = company
  const city = get(locations, '[0].city')
  const country = get(locations, '[0].country')
  return (
    <CardWrapper
      key={id}
      onClick={onClick}
    >
      <LogoWrapper>
        <Logo src={logo || '/images/company-logo.png'}/>
      </LogoWrapper>
      <Footer>
        <Name>{truncate(name, { length: 48 })}</Name>
        <Show if={city || country}>
          <Location>{city ? city + ', ' : ''}{country}</Location>
        </Show>
        <Score color={colorScale(score)}>{score}</Score>
      </Footer>
    </CardWrapper>
  )
}
