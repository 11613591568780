import styled from 'styled-components'
import { Icon } from 'components'
import { Spinner } from '../../common/spinner.component'

export const Arrow = styled(Icon).attrs({
  size: 8,
  icon: 'filterArrow'
})`
  margin: 0;
  transition: transform 0.3s;

  .ant-select-open & {
    transform: rotate(180deg);
  }
`

export const EmbeddedSpinner = styled(Spinner).attrs({
  size: 24,
})`
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  margin: 0;
  left: unset;
`
