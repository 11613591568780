export class Group {
  public id: string
  public description: string
  public name: string

  constructor(data: any) {
    this.id = data._id
    this.description = data.description
    this.name = data.name
  }

  public getVariables = (): any => {
    return {
      _id: this.id,
    }
  }
}
