import { createStructuredSelector } from 'reselect'
import { sidePanelSelector, filterIdSelector, filterIdToEditSelector } from '../../../../selectors'

export const filterSwitcherSelector = createStructuredSelector({
  activeTab: (x: any) => x.filterPanel.activeTab,
  sidePanelVisible: sidePanelSelector('filterList'),
  companiesDateRange: (x: any) => x.prospectsList.dateRange,
  filterId: filterIdSelector,
  filterIdToEdit: filterIdToEditSelector
})
