import * as React from 'react'
import { reduxForm, Field } from 'redux-form'

import { FormDropdown, Row } from '../../../../../../../../components'
import { validation } from '../../../../../../../../utils/input-validation'
import { Label, Form } from './create-company-modal-form.styles'

const required = validation.required()

interface CreateCompanyModalFormProps {
  users: any[]
  categories: any[]
  businesses: any[]
  handleSubmit: any
}

class CreateCompanyForm extends React.PureComponent<CreateCompanyModalFormProps> {
  public render() {
    const { users, businesses, categories, handleSubmit } = this.props
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>
            Our Contact
          </Label>
          <Field
            name="userId"
            component={FormDropdown(users, 'Select contact')}
            type="text"
            validate={[required]}
          />
        </Row>
        <Row>
          <Label>
            Category
          </Label>
          <Field
            name="categoryId"
            component={FormDropdown(categories, 'Select category')}
            type="text"
            validate={[required]}
          />
        </Row>
        <Row>
          <Label>
            Business
          </Label>
          <Field
            name="businessId"
            component={FormDropdown(businesses, 'Select business')}
            type="text"
            validate={[required]}
          />
        </Row>
      </Form>
    )
  }
}

export const CreateCompanyModalForm = reduxForm({ form: 'createCompanyModalForm' })(CreateCompanyForm as any) as any
