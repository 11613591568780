import styled from 'styled-components'

export const Count = styled.div`
  background: #314550;
  border-radius: 10px;
  min-width: 30px;
  padding: 8px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
