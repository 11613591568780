import styled from 'styled-components'
import { Wrapper } from '../../signup.styles'
import logo from './images/leadexplorer-logo.png'

export const Logo = styled.img.attrs({src: logo})`
  width: 100px;
  height: 105px;
  margin-top: 64px;
  margin-bottom: 32px;
`

export const Title = styled.h1`
  ${Wrapper} & {
   margin-top: 0;
  }
`

export const DesktopTitle = styled.span`
  @media (max-width: 799px) {
    display: none;
  }
`

export const MobileTitle = styled.span`
  @media (min-width: 800px) {
    display: none;
  }
`
