import * as React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { Wrapper } from './trial-info.styles'
import { setTrialProspectsDateRange } from 'actions'

interface TrialInfoProps {
  setTrialProspectsDateRange?: () => void
  status: any
}

@connect(null, {
  setTrialProspectsDateRange
})
export class TrialInfo extends React.PureComponent<TrialInfoProps> {
  public componentDidMount(): void {
    if (get(this.props.status, 'getPaymentSubscriptionStatus.trial')) {
      this.props.setTrialProspectsDateRange()
    }
  }

  public render() {
    const { status } = this.props
    if (get(status, 'getPaymentSubscriptionStatus.trial')) {
      const daysLeft = get(status, 'getPaymentSubscriptionStatus.trialStatus.daysLeft')
      return (
        <Wrapper>
          Trial version, {daysLeft} days left
        </Wrapper>
      )
    }
    return null
  }
}
