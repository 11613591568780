import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  ol {
    counter-reset: item
  }
  ol > li {
    display: block;
    position: relative;
  }
  ol > li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    position: absolute;
    width: 80px;
    left: -90px;
    font-size: 24px;
    font-weight: 700;
    text-align: right;
  }
  article ol > li {
    margin-bottom: 16px;

    &:before {
      font-size: 16px;
      font-weight: 400;
    }
  }
  ol.hidden {
    padding-left: 0;
  }
  ol.hidden > li:before {
    content: '';
  }
  section:first-child {
    margin-bottom: 96px;
  }
  h3 {
    font-size: 16px;
  }
`

export const AgreementText = () => {
  return (
    <Wrapper>
      <section>
        <header>
          <h1>Terms of Service Leadexplorer</h1>
          <div>Version: 1.1</div>
          <div>Date: 2018-09-26</div>
        </header>
        <p>These general conditions regulate the relation between eMarketeer AB (org. nr 556631- 4497) and the customer and constitute an integrated part of the agreement reached between the parties with respect to the Service Leadexplorer ("Agreement"). Note that this includes “Data Processing Agreement” (Appendix A), which is an integrated part of this agreement.</p>
        <ol>
          <li>
            <article>
              <h2>eMarketeer AB</h2>
              <p>eMarketeer AB hereafter called “eMarketeer” is a leading provider of tools to make marketing and sales work smarter and in alignment. We believe that tools for marketing and sales communication should be more effective, more powerful, more fun and a lot easier to use. eMarketeer provides tools like Leadexplorer to find and manage leads online.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Leadexplorer</h2>
              <p>Leadexplorer offers an online lead generation service that allows the customer to transform unknown web visitors to real sales opportunities.</p>
              <p>In the terms of service set out in this agreement with appendices (the 'agreement'), eMarketeer offers the customer the service Leadexplorer, hereafter called “Service”. The Service is a SaaS-service, whereby eMarketeer is responsible for hosting, support and maintenance (HSM).</p>
              <h3>Hosting</h3>
              <p>The operation environment of the Service provided by eMarketeer is being hosted by Amazon Web Services (AWS). AWS handles hosting in a manner certified by external parties, including FBI’s Criminal Justice Information Service, Cloud Security Alliance, Cyber Essentials Plus, The Department of Defense (DoD) Cloud Security Model (SRG), Federal Risk and Authorization Management Program, The Family Educational Rights and Privacy Act (FERPA), The Federal Information Processing Standard (FIPS) Publication 140-2, ISO 9001.</p>
              <p>AWS maintains certification with robust security standards, such as ISO 27001, SOC 1/2/3 and PCI DSS Level 1. Their commitment in providing a secure hosting covers physical access to used hardware as well as handling catastrophic events like power outage and internet outage.</p>
              <p>More details on all aspect of our hosting can be found here:</p>
              <p><a href="https://d0.awsstatic.com/whitepapers/compliance/AWS_Risk_and_Compliance_Whitepaper.pdf">https://d0.awsstatic.com/whitepapers/compliance/AWS_Risk_and_Compliance_Whitepaper.pdf</a></p>
              <h3>Storage of customer data</h3>
              <p>Data is stored within EU.</p>
              <h3>Backup and restore</h3>
              <p>Database is backed up every day, and old backups are kept for 30 days. This backup is intended to be used to restore services in case of a catastrophic event, e.g. server crash.</p>
              <p>More details about security and data protection can be found in eMarketeer <a href="http://www.emarketeer.com/wordpress/wp-content/uploads/Information-Security-Policy.pdf">Information Security Policy</a></p>
            </article>
          </li>
          <li>
            <article>
              <h2>Training</h2>
              <p>Training for users of the Service is provided by video and eMarketeer provides additional training and consultation services in accordance to the current price list.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Support</h2>
              <p>eMarketeer provides support for the Service via e-mail and chat. The support is available Monday-Friday 09.00 to 17.00 CET and 09.00 to noon CET on the day before holiday, excluded public holidays. The support is available to registered users. The following topics are covered by support obligations:</p>
              <ul>
                <li>Advise in administration of Leadexplorer</li>
                <li>Diagnosis of problems or performance deficiencies of the Service</li>
                <li>Resolution of the problem or performance deficiencies of the Service</li>
              </ul>
              <p>The following topics are not covered by support obligations:</p>
              <ul>
                <li>Production of activities in the Service</li>
                <li>Use of other applications that are integrated to the Service</li>
                <li>Due to the nature of the Internet, eMarketeer cannot guarantee the continuous and uninterrupted availability and accessibility of the Service.</li>
              </ul>
              <p>In the event that eMarketeer deems that the need for support for customer cannot be met in an acceptable and practical manner over phone or e-mail, eMarketeer will offer consultancy services in accordance with current price list.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Scope of the Service</h2>
              <p>eMarketeer shall provide the Service in accordance with the agreement including any supplemental agreements.</p>
            </article>
          </li>
          <li>
            <ol className="hidden">
              <li>
            <article>
              <h2>Rights of Customer</h2>
              <p>By signing this agreement, the Customer gives his or her full consent to receive communication from eMarketeer in forms of email, SMSs and/or phone. The Customer has ‘the right to be forgotten’ and can be withdrawn the consent at any time by contacting eMarketeer via email <a href="mailto:privacy@emarketeer.com">privacy@emarketeer.com</a></p>
                  <ol>
                    <li>The Customer is hereby granted a non-exclusive, non-transferable right to use the Service in accordance with the agreement.</li>
                    <li>The usage of the Service requires personal registration and log-in.</li>
                    <li>eMarketeer owns or holds through the license from rights holders, all rights to the Service and any new versions thereof.</li>
                    <li>The Customer shall not make or permit sale transfer or service bureau use of the Service or the making of any modifications, additions or enhancements to the Service, or cause or permit the reverse engineering, disassembly, or de-compilation thereof. Copyright and all other rights concerning modifications of the service shall inure to eMarketeer without any separate compensation.</li>
                  </ol>
            </article>
              </li>
            </ol>
          </li>
          <li>
            <article>
              <h2>System requirements and access to the Internet</h2>
              <p>Access to the Service requires Internet access, a web browser and a Google Analytics account.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Updates and new functionality</h2>
              <ol>
                <li>eMarketeer carries out the ongoing development of the Service and the Service will be upgraded with new functionality from time to time. eMarketeer has the right to upgrade the Service without notice, provided that the modified functionality does not diminish performance or accessibility. General modifications are provided as part of eMarketeer hereunder. Major upgrades are made available as soon as general availability is at hand.</li>
                <li>The Customer has the option to enhance the Service with additional functions from eMarketeer. Once delivered to the customer, such functions are thereafter included to the Service.
                </li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Maintenance, support and availability</h2>
              <ol>
                <li>eMarketeer shall use reasonable efforts to ensure that the Service is available over the Internet without interruptions. Errors under the Service control shall be corrected by eMarketeer. Customer reports deviations in the functionality of the Service. eMarketeer shall always be allowed to rectify errors within a reasonable time before a breach of contract is at hand.</li>
                <li>eMarketeer shall have the right to take actions that affect the availability of the Service provided that such effect is justified from a technical or security perspective. eMarketeer informs the customer in advance of any scheduled maintenance on Status page.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Data protection</h2>
              <ol>
                <li>The Customer owns the rights to its data as data controller, and eMarketeer acts as a data processor on the Customer's behalf. All processing by eMarketeer of the personal data and other data provided by the Customer shall be in accordance with the applicable laws. eMarketeer ’s processing of personal data on behalf of the Customer shall therefore only be done in order to provide the service and shall be subject to the Customer’s written instructions.</li>
                <li>As eMarketeer is a data processor and the Customer is a data controller, the parties obligations regarding the processing of personal data is regulated in the data processor agreement attached as Appendix A. With signing this document, the Customer accepts both these terms of service and the data processor agreement.</li>
                <li>The Customer is obligated to keep user logins and passwords to the Service secret from any unauthorized users or third parties.</li>
                <li>The Customer is obligated to ensure that the personal data provided by the Customer and used in the Product is processed by the Customer in accordance with all applicable laws. The Customer is obligated to ensure that the Customer’s data provided in the Service, including personal data, do not violate any third-party intellectual property rights and/or any applicable legislation. eMarketeer is entitled to delete any data that in the sole discretion of eMarketeer constitutes a breach of the aforesaid undertaking by the Customer, and the Customer will not be entitled to any compensation in that respect.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Liability</h2>
              <ol>
                <li>eMarketeer is not liable for any data provided by the Customer in the Service.</li>
                <li>With the limitations pursuant to this agreement, eMarketeer is liable for direct damages due to wilful misconduct or gross negligence by eMarketeer.</li>
                <li>In no event eMarketeer shall be liable to the Customer or any party related to the Customer for any indirect, incidental, consequential, special, exemplary, or punitive damages(including, without limitation, damages for loss of business profits, business interruption, loss of business information, loss of data or other indirect losses, such as lost profits, diminished production, costs of retaining a consultant, costs of equipment and similar costs or losses.</li>
                <li>eMarketeer’s obligation to pay damages in the case of breach of contract is, in the absence of intent or gross negligence by eMarketeer, limited to an amount equal to the accumulated subscription fees paid by the customer during the preceding 6 month-period from the point in time when the breach of contract was claimed.</li>
                <li>eMarketeer hereby warrants that eMarketeer does not infringe upon any right held by a third party. Customer shall without delay and in writing notify eMarketeer of claims presented by third parties concerning infringements of copyright or other intellectual property right on account of the customer's use of the service.</li>
                <li>In the event that a third party presents claims purely on account of the customer's use of the service, the customer shall keep eMarketeer indemnified from such claims.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Sub-consultants and third-party products</h2>
              <ol>
                <li>eMarketeer is entitled to freely appoint sub-consultants. eMarketeer is responsible for work performed by the sub-consultant, however not for any errors or defects caused by third-party software.</li>
                <li>eMarketeer shall not be liable for defects in the equipment for Internet access of the Customer.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Secrecy</h2>
              <ol>
                <li>All business information disclosed by one party to the other in connection with this agreement, shall be treated as confidential information unless such information is part, or later becomes part, of the public domain through no fault of the other party or it was later obtained by the other party from independent sources free from any duty of confidentiality. Each party's confidential information shall be held in strict confidence by the other party, both for the duration of this agreement and thereafter, exercising the same standard of care as it uses to protect its own confidential information, and shall not be used or disclosed by the other party for any purpose except as necessary to implement or perform this agreement, or except as required by law.</li>
                <li>eMarketeer undertakes to ensure that its staff has signed the confidentiality agreement (compliant with GDPR) in relation to the information of the customer included in the Service.</li>
                <li>The Customer is responsible for ensuring that usernames and passwords are guarded safely, are used only by the intended users and do not come into the hands of any unauthorized users. Furthermore, the Customer is responsible for any unauthorized use of the Service through its equipment or systems.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Protecting and handling personal information</h2>
              <ol>
                <li>The Customer is using both manual and automatic processing of personal data that exists in the Service. The processing of personal data is regulated by privacy laws and the customer is responsible for the correct management of such data in accordance with current local as well as international legislation. eMarketeer will never share personal data with any third party in other circumstances than what will be needed to comply with the agreement.</li>
                <li>eMarketeer reserves the right to review, process and store the personal data and customer information that the customer stores in the Service otherwise eMarketeer has no right to use the customer information beyond what is necessary to comply with the agreement.</li>
                <li>To prevent unauthorized access and/or use of the Service, eMarketeer uses secure login via HTTPS (Hypertext Transfer Protocol Secure), a protocol for encrypted transport of data via the HTTP protocol as well as personal user credentials.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Fees and payment</h2>
              <ol>
                <li>Customer shall pay fees and expenses in accordance with the agreement. All amounts are exclusive of VAT and other taxes and duties.</li>
                <li>Payment of the Service is done by credit card in advance.</li>
                <li>Additional services offered by eMarketeer, such as consulting, are invoiced separately.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Terms and termination</h2>
              <p>This agreement is valid once the Customer has agreed to the terms of the agreement in the Service or started using the Service. The agreement shall remain valid during the hole contract period and as long as the parties have obligations concerning personal data processing activities towards one another.</p>
              <ol>
                <li>
                  <p>Subscriptions, you can pay monthly or yearly in advance for a discount.</p>
                  <p>Subscription: Yearly<br />
                    Yearly subscriptions have a contract period of 12 month. Your subscription will auto renew unless we receive notice of termination prior to the end of your contract period.
                    You are free to cancel your subscription of the service any time. Notice that no refunds are provided, you can use the Service until the end of your contract period after which your credit card will not be charged again.
                  </p>
                  <p>Subscription: Monthly<br />
                    You are free to cancel your subscription of the service any time. Notice that no refunds are provided, you can use the Service until the end of your monthly billing period after which your credit card will not be charged again.</p>
                </li>
                <li>Each party is entitled to rescind the agreement in the event that the other party is in material breach thereof and does not rectify the breach within thirty days from obtaining written notice thereof. Each party is entitled to rescind the agreement in the event that the other party is declared bankrupt, enters into composition proceedings or liquidation or can in other ways be assumed to be insolvent.</li>
                <li>
                  eMarketeer shall have the right to terminate the Customer’s account to the Service with immediate effect if the customer uses the service:
                  <ul>
                    <li>In a way that constitutes a criminal act or contrary to applicable law (especially personal data protection legislation and anti-spamming legislation);</li>
                    <li>In a way that constitutes a risk of damage to eMarketeer or a third party;</li>
                    <li>In a way that constitutes a breach of eMarketeer safety instructions;</li>
                    <li>In the case of non-payment of fees or costs pursuant to the agreement</li>
                  </ul>
                </li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Consequences of termination</h2>
              <ol>
                <li>Upon termination of the agreement, it is customer's responsibility to copy and save previously stored information. eMarketeer is responsible for customer data to be erased from the Service.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Relief</h2>
              <ol>
                <li>
                  <p>A party (including its sub-contractors) is relieved from liability for a failure to perform an obligation under the agreement if such failure is due to a circumstance of the type stated below (Relieving circumstance) and the circumstance prevents or makes substantially more difficult the timely performance of such obligation.</p>
                  <p>A Relieving circumstance shall be deemed to include inter alia acts or omissions of authorities, new or amended legislation, leaving of personnel, illness or other reduction of work capacity, death, conflicts on the labour market, blockade, fire, flood, loss or destruction of property or data of major significance or a major accident.</p>
                </li>
                <li>If a party wishes to claim relief pursuant to the above provisions, then he shall without unreasonable delay inform the other party thereof in order to be able to invoke such a claim. Notwithstanding the above (with regard to relief from liability), a party may under the stated circumstances rescind the agreement if the other party's performance of a certain obligation has been delayed by more than three months.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Assignment</h2>
              <ol>
                <li>Customer may not in wholly or partly assign its rights and/or obligations under this agreement to any third party without the prior written approval of eMarketeer.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Changes</h2>
              <ol>
                <li>eMarketeer reserves the right to change prices with a 30-day notice in addition to general changes in the price list on a yearly basis.</li>
                <li>Notwithstanding the above, eMarketeer shall always have the right to with immediate effect carry out changes (including price) that are caused by acts or omissions of authorities or new or amended legislation.</li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Disputes and governing law</h2>
              <ol>
                <li>Any dispute arising out of or in connection with this agreement shall be finally settled by arbitration in accordance with the provisions of the Swedish Arbitration Act (SFS 1999:116).<br />The arbitration proceedings shall be conducted in English and take place in Stockholm.</li>
                <li>This agreement shall be construed in accordance with and be governed by the laws of Sweden.</li>
              </ol>
            </article>
          </li>
        </ol>
      </section>
      <section>
        <header>
          <h1>Data Processing Agreement Leadexplorer</h1>
          <p>Appendix A, Terms of Service Leadexplorer</p>
          <div>Version: 1.4</div>
          <div>Date: 2018-09-20</div>
        </header>
        <ol>
          <li>
            <article>
              <h2>Purpose</h2>
              <p>The purpose of this Data Processing Agreement (“DPA”) is to regulate the Processor’s processing of personal data on behalf of the Controller whilst providing the Leadexplorer service (“the Service”) and constitute an integrated part of the Leadexplorer Terms of Service (“TOS”).</p>
              <p>This Data Processing Agreement governs the Processor’s rights and obligations, in order to ensure that all Processing of Personal Data is conducted in compliance with applicable data protection legislation.</p>
              <p>Processing of Personal Data (as defined below) is subject to requirements and obligations pursuant to applicable law. When the Controller is a legal entity established in the European Economic Area (the "EEA") relevant data protection legislation will include the Norwegian data protection legislation and the present EU- Regulation 2016/679 dated April 27th 2016. The parties agree to amend this Data Processing Agreement to the extent necessary due to any mandatory new requirements following from the EU Regulation 2016/679 and the revised Electronic Communications Regulation (“ePrivacy”)</p>
              <p>“Personal Data” shall mean any information relating to an identified or identifiable natural person, as further defined in applicable law and EU- Regulation 2016/679</p>
              <p>“Processing” of Personal Data shall mean any use, operation or set of operations which is performed upon personal data, whether or not by automatic means, such as collection, transfer, storage, alteration, disclosure as further defined in applicable law and EU- Regulation 2016/679</p>
              <p>“Third Countries” shall mean countries outside of the EU/EEA area which are not recognized as countries providing adequate protection of Personal Data.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Controller’s responsibilities (Customer)</h2>
              <p>In order to access the Service, the Controller must provide certain data to the Processor, including correct name, contact data and email address of the users. In addition, the users of the Service must allow the Processor to store and retrieve session information through the use of “cookies” which are necessary to enable the login/logout procedures used in the Service and to ensure that unauthorized persons do not gain access to the Services.</p>
              <p>The Controller acknowledges and accepts that any personal data that the Controller uploads onto the Service, such as uploaded personal data pertaining to the Controller’s own customers, may be transferred to a third party (sub processor) based in the European Economic Area (EEA) which will provide for hosting of the Service, including the provisioning of all hardware, infrastructure, data storage and communication lines. The obligations of the third party in regards to personal data are set forth in a separate data processing agreement between Processor and the third party within the framework of this DPA. All data in the Service are stored on servers located in Europe.</p>
              <p>The Controller is responsible for complying with the Service’s procedures, guidelines, updates and changes at any given time.</p>
              <p>The Controller can process personal data only with the requirements of Data Protection Laws and Regulations. For the avoidance of doubt, Controller’s instructions for the Processing of Personal Data shall comply with Data Protection Laws and Regulations.</p>
              <p>The Controller confirms that the Controller:</p>
              <ul>
                <li>Has sufficient lawful basis for Processing of Personal Data;</li>
                <li>Has the right to use the Processor for Processing of the Personal Data;</li>
                <li>Has the responsibility for the correctness, integrity, content, reliability and legality of the Personal Data;</li>
                <li>Complies with applicable law on notification to and authorizations from relevant authorities;</li>
                <li>Has informed the Data Subject in accordance with applicable law</li>
              </ul>
              <p>The Controller shall:</p>
              <ul>
                <li>Reply to requests from the Data Subjects regarding the Processing of Personal Data pursuant to this Data Processing Agreement;</li>
                <li>Assess the necessity for specific measures as set forth in this Data Processing Agreement Art. 3.3.2 and 3.3.4, and order such measures from the Processor.</li>
              </ul>
              <p>The Controller shall implement sufficient technical and organizational measures to ensure and demonstrate compliance with the EU Regulation 2016/679 from the time it enters into force in EU.</p>
              <p>The Controller has a duty to notify any personal data breaches to the relevant authorities and, if necessary, the Data Subjects without undue delay in accordance with applicable law.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Processor’s responsibilities (eMarketeer AB)</h2>
              <ol>
                <li>
                  <h3>Compliance</h3>
                  <p>The Processor shall comply with all provisions for the protection of Personal Data set out in this Data Processing Agreement and in applicable data protection legislation with relevance for Processing of Personal Data. The Processor shall provide the Controller with assistance to ensure and document that the Controller complies with its requirements under the applicable data protection legislation.</p>
                </li>
                <li>
                  <h3>Restrictions on use</h3>
                  <p>The Processor shall only Process Personal Data on, and in accordance with, the instructions from the Controller. The Processor shall not Process Personal Data without prior written agreement with the Controller or without written instructions from the Controller beyond what is necessary to fulfil its obligations towards the Controller under the Agreement.</p>
                </li>
                <li>
                  <h3>Information Security</h3>
                  <ol>
                    <li>
                      <h3>Duty to ensure information security</h3>
                      <p>The Processor shall by means of planned, systematic, organisational and technical measures ensure appropriate information security with regard to confidentiality, integrity and accessibility in connection with the Processing of Personal Data in accordance with the information security provisions in applicable data protection legislation.</p>
                      <p>The measures and documentation regarding internal control shall be made available to the Controller upon request.</p>
                    </li>
                    <li>
                      <h3>Assessment of measures</h3>
                      <p>In deciding which technical and organisational measures should be implemented, the Processor shall, in consultation with the Controller, take into account:</p>
                      <ul>
                        <li>The state of the art</li>
                        <li>The costs of implementation</li>
                        <li>The nature and scope of the processing</li>
                        <li>The context and purpose of the processing,</li>
                        <li>The severity of risks the Processing of Personal Data has for the rights and freedoms of the data subject</li>
                      </ul>
                      <p>The Processor shall, in consultation with the Controller, consider:</p>
                      <ul>
                        <li>Implementing pseudonymisation and encryption of Personal Data</li>
                        <li>the ability to ensure the confidentiality, integrity, availability and resilience of processing systems and services on an ongoing basis</li>
                        <li>the ability to restore the availability and access to personal data in a timely manner in the event of a physical or technical incident</li>
                        <li>a process for, on an ongoing basis, testing, assessing and evaluating regularly the effectiveness of technical and organisational measures for ensuring the security of the Processingj</li>
                      </ul>
                    </li>
                    <li>
                      <h3>Requests from the data subjects</h3>
                      <p>Considering the nature of the Processing, the Processors shall implement appropriate technical and organisational measures to support the Controller's obligation to respond to requests regarding exercising the rights of the data subject.</p>
                    </li>
                    <li>
                      <h3>Assistance to the Controller</h3>
                      <p>The Processor shall assist the Controller in ensuring compliance with applicable law, including assisting the Controller with:</p>
                      <ul>
                        <li>Implementing technical and organisational measures as stated above;</li>
                        <li>Complying with duty of notification to supervisory authorities and data subjects in case of a personal data breach; Conduct data privacy impact assessments;</li>
                        <li>Conduct data privacy impact assessments;</li>
                        <li>Conduct prior consultations with supervisory authorities when a privacy impact assessment makes it it necessary;</li>
                        <li>Notice to the Controller if the Processor is of the opinion that an instruction from the Controller is non-compliant with applicable data protection regulations.</li>
                        <li>Assistance as set out above, shall be carried out to the extent necessary, taking into account the Controller’s need, the nature of the processing and the information available to the Processor.</li>
                      </ul>
                    </li>
                    <li>
                      <h3>Compensation</h3>
                      <p>Assistance from the Processor as set down in this Data Processing Agreement, as well as assistance in relation to specific routines and instructions imposed by the Controller, shall be compensated by the Controller in accordance with the Processor’s regular terms and prices. However, no compensation shall be given for work performed by Processor
                        due to legal requirements, or necessitated by breach of the DPA by Processor.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Discrepancies and data breach notifications</h3>
                  <p>Any use of the information systems and the Personal Data not compliant with established routines, instructions from the Controller or applicable data protection legislation, as well as any security breaches, shall be treated as a discrepancy.</p>
                  <p>The Processor shall have in place routines and systematic processes to follow up discrepancies, which shall include re-establishing of the normal state of affairs, eliminating the cause of the discrepancy and preventing its recurrence.</p>
                  <p>The Processor shall immediately notify the Controller of any breach of this Agreement or of accidental, unlawful or unauthorized access to, use or disclosure of Personal Data, or that the Personal Data may have been compromised or a breach of the integrity of the Personal Data. The Processor shall provide the Controller with all information necessary to enable the Controller to comply with applicable data protection legislation and enabling the Controller to answer any inquiries from the applicable data protection authorities. It is the Controller`s responsibility to notify the applicable Data Protection Authority of discrepancies in accordance with applicable law.</p>
                </li>
                <li>
                  <h3>Confidentiality</h3>
                  <p>The Processor shall keep confidential all Personal Data and other confidential information. The Processor shall ensure that each member of the staff of the Processor, whether employed or hired employee, having access to or being involved with the Processing of Personal Data under the Agreement (i) undertakes a duty of confidentiality and (ii) is informed of and complies with the obligations of this Data Processing Agreement. The duty of confidentiality shall also apply after termination of the Agreement or this Data Processing Agreement.</p>
                </li>
                <li>
                  <h3>Security Audits</h3>
                  <ol>
                    <li>
                      <h3>Audit</h3>
                      <p>Controller may conduct an audit, to verify Processor’s compliance with its obligations under this DPA. Such audit may be conducted by the Controller or a third-party auditor. Processor shall reasonably cooperate and provide such documentation and access as reasonably required by the Controller to conduct the audit. For the avoidance of doubt, Processor shall in no event be obliged to provide any information related to other customers of Processor.</p>
                    </li>
                    <li>Reasonable advance written notice of at least fifteen (15) days is required, unless: (i) data protection law or a competent data protection authority requires an earlier audit, in which case the Processor will be given as much advance notice as possible; or (ii) the circumstances of a Personal Data breach require an earlier audit, in which case the Processor will be given reasonable advance notice. The Controller shall conduct the audit in an expeditious manner, within a reasonable time and in a way to not unreasonably disrupt Processor’s day-to-day business operations.</li>
                    <li>If an audit determines that the Processor has breached its obligations, Processor will promptly remedy the breach at its own cost.</li>
                    <li>The Processor will reasonably support the Controllers in any End-User or Client audit of the Controller relating to the processing of Personal Data by the Processor.</li>
                    <li>
                      <h3>Evidence</h3>
                      <ol>
                        <li>Upon request, the Processor will certify to any Controller that it is in compliance with this DPA by providing adequate evidence in form of (i) the results of a self-audit, (ii) internal company rules of conduct including external evidence of compliance, (iii) approved codes of conduct, or (iv) other appropriate certificates.</li>
                        <li>Evidence of the implementation of measures which are not specific to this DPA may be given in the form of up-to-date attestations, reports or extracts thereof from independent bodies (e.g. external auditors, internal audit, the data protection officer, the IT security department or quality auditors) or suitable certification by way of an IT security or data protection audit.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Use of sub-contractors</h3>
                  <p>Any sub-contractors shall be approved in writing by the Controller before the sub-contractor may Process Personal Data. The Processor is entitled to use sub-contractors and the Controller accepts the use of sub-contractors identified in the Appendix. The Processor shall, by written agreement with any sub-contractor ensure that any Processing of Personal Data carried out by sub-contractors shall be subject to the same obligations and limitations as those imposed on the Processor according to this Data Processing Agreement.</p>
                  <p>If the Processor plans to change sub-contractors or plans to use a new sub-contractor, Processor shall notify the Controller in writing 1 months prior to any Processing by the new sub-contractor, and the Controller is entitled to object to the change of sub-contractors within 1 month. Should the Controller object to the change, Controller may terminate the Agreement upon 3 months’ notice. The notice of termination must be given at the latest 30 days after the Controller objected to the change. To the extent Controller does not terminate the Agreement, the change of sub-processor shall be regarded as accepted.</p>
                </li>
                <li>
                  <h3>Transfer of Personal Data to Third Countries</h3>
                  <p>If the Processor uses sub-suppliers outside the EU/EEA area for Processing of Personal Data, such Processing must be in accordance with the EU Privacy Shield Framework, EU Standard Contractual Clauses for transfer to third countries, or another specifically stated lawful basis for the transfer of personal data to a third country. For the avoidance of doubt, the same applies if the data is stored in the EU/EEA but may be accessed from locations outside the EU/EEA.</p>
                  <p>Should the Controller approve such transfer of Personal Data, the Processor is obligated to cooperate with the Controller in order to ensure compliant transfers.</p>
                </li>
              </ol>
            </article>
          </li>
          <li>
            <article>
              <h2>Liability, breach</h2>
              <p>In the event of breach of this Data Protection Agreement, or a breach of obligations according to applicable law on Processing of Personal Data, the relevant provisions regarding breach in the TOS shall apply.</p>
              <p>Claims from one party due to the other party’s non-compliance with the Data Processing Agreement shall be subject to the same limitations as in the TOS. In assessing whether the limitation in the TOS is reached, claims under this Data Processing Agreement and the TOS shall be viewed in conjunction, and the limitation in the TOS shall be viewed as a total limitation.</p>
              <p>The Processor shall notify the Controller without undue delay if it will or has reason to believe it will be unable to comply with any of its obligations under this Data Protection Agreement.</p>
              <p>Term and termination of the Data Processing Agreement, changes</p>
              <p>This Data Processing Agreement shall be effective from the date it is signed by both parties and until the Processor's obligations in relation to the performance of services in accordance with the TOS is terminated, except for those provisions in the TOS and Data Processing Agreement that continues to apply after such termination.</p>
              <p>Upon termination of this Data Processing Agreement the Personal Data/data shall be returned in a standardized format and medium along with necessary instructions to facilitate the Controller’s further use of the Personal Data/data. The Processor shall first return and subsequently delete all Personal Data and other data. The Processor (and its sub- contractors) shall immediately stop the Processing of Personal Data from the date stipulated by the Controller</p>
              <p>As an alternative to returning the Personal Data (or other data), the Controller may, at its sole discretion, instruct the Processor in writing, that all or parts of the Personal Data (or other data) shall be deleted by the Processor, unless the Processor is prevented by mandatory law from deleting the Personal Data.</p>
              <p>The Processor has no right to keep a copy of any data provided by the Controller in relation to the TOS or this Data Protection Agreement in any format, and all physical and logical access to such Personal Data or other data shall be deleted.</p>
              <p>The Processor shall provide the Controller when requested with a written declaration whereby the Processor warrants that all Personal Data or other data mentioned above has been returned or deleted according to the Controller’s instructions and that the Processor has not kept any copy, print out or kept the data on any medium.</p>
              <p>The obligations pursuant to sections 3.5 and 4 shall continue to apply after termination. Further, the provisions of the Data Processing Agreement shall apply in full to any Personal Data retained by the Processor in violation of this section 5.</p>
              <p>The parties shall amend this Data Protection Agreement upon relevant changes in applicable law.</p>
            </article>
          </li>
          <li>
            <article>
              <h2>Dispute and jurisdiction</h2>
              <p>This Data Processing Agreement shall be governed by and construed in accordance with the laws of Sweden. The legal venue shall be Stockholm District Court.</p>
            </article>
          </li>
        </ol>
      </section>
      <p>A list of our pre-approved subcontractors can be requested via <a href="mailto:privacy@emarketeer.com">privacy@emarketeer.com</a></p>
    </Wrapper>
  )
}
