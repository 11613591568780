import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import * as moment from 'moment'
import { emarketeerIntegrationStatus } from '../../gql/emarketeer/emarketeer.query'

export default [graphql(gql`
query ($id: String!) {
    getCompanyDetails(companyId: $id)
    {
        company {
            name
            domain
            logo
            website
            founded
            bio
            industry
            employeesCount
            phone
            employees {
                fullName
                title
                source
                score
                avatar
            }
            keyPeople {
                fullName
                title
                source
                score
                avatar
                linkedin
            }
            locations {
                lat
                lng
                addressLine1
                addressLine2
                postalCode
                city
                country
            }
            industries {
                name
            }
            score
            profiles {
                linkedincompany {
                    url
                }
                facebook {
                    url
                }
                twitter {
                    url
                }
                youtube {
                    url
                }
            }
        }
        analytics {
            visits
            totalPageViews
            totalPageViewDuration
            visitsStartDate
            topKeywords {
                name
            }
            topPagesByTime {
                fullUrl
                name
                duration
            }
        }
    }
}`, {
  name: 'data',
  options: ({ match }: any) => ({
    variables: {
      id: match.params.id,
    },
  }),
}),
graphql(emarketeerIntegrationStatus, {
  name: 'emarketeerIntegrationStatus',
}),
graphql(gql`
query ($id: String!, $endDate: String!, $startDate: String!, $offset: String) {
    getWebVisits(companyId:$id, endDate: $endDate, startDate: $startDate, offset: $offset) {
        totalCount
        items {
            dateTime
            countryCode
            sourceMedium
            visitor {
                fullName
                gender
                employments {
                    name
                    current
                }
                ageRange
                location
            }
            city
            country
            pageViews
            duration
            deviceCategory
            operatingSystem
            sessionPages {
                fullUrl
                name
                duration
            }
            userLanguage
            userType
        }
    }
  }`, {
    name: 'webVisits',
    options: ({ match, webVisitsDateRange = {} }: any) => ({
      variables: {
        id: match.params.id,
        startDate: (webVisitsDateRange.startDate || moment()).format('YYYY-MM-DD'),
        endDate: (webVisitsDateRange.endDate || moment()).format('YYYY-MM-DD'),
      },
    }),
  }),
]
