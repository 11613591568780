import * as React from 'react'
import styled from 'styled-components'
import { Filter } from './filters'
import { SortButton } from './sort-button'

const CompaniesControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const ControlsItem = styled.div`
  font-size: 14px;
  margin: 0 4px 14px 0;
`

interface CompaniesControlsProps {
  sortItems: any[]
}

export const CompaniesControls: React.FunctionComponent<CompaniesControlsProps> = ({ sortItems }) => (
  <CompaniesControlsContainer>
    <ControlsItem>
      <Filter />
    </ControlsItem>
    <ControlsItem>
      <SortButton
        items={sortItems}
      />
    </ControlsItem>
  </CompaniesControlsContainer>
)
