import styled from 'styled-components'
import { Modal } from '../../../../../../components/modal/modal.component'

export const MainText = styled.div`
  margin-bottom: 72px;
  
  p {
    margin: 0 0 16px;
  }
`

export const BottomText = styled.p`
  margin: 0 0 16px;
  text-align: right;
`

export const Controls = styled(Modal.Controls)`
  & > button {
    margin: 0 0 0 8px;
  }
`
