import * as React from 'react'
import { HeaderBlocksModel } from '../models/header-blocks.model'
import { HeaderBlockItem } from './header-block-item.component'
import { HeaderBlockWrapper } from '../subscription.styles'

export interface HeaderBlockItemProps {
  data: HeaderBlocksModel
}

export const HeaderBlock: React.SFC<HeaderBlockItemProps> = ({ data }) => (
  <HeaderBlockWrapper>
    <HeaderBlockItem {...data.getPriceBlock()}/>
    <HeaderBlockItem {...data.getNextBilling()}/>
    <HeaderBlockItem {...data.getLastBilling()}/>
    <HeaderBlockItem {...data.getRenewal()}/>
  </HeaderBlockWrapper>
)
