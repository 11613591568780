import styled from 'styled-components'
import { Card, Row } from 'components'

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`

export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  margin: 45px 0 26px 0;
`

export const InfoCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 300px;
  margin: 7px;
  @media (min-width: 800px) {
    min-width: 450px;
  }

  ${Row} {
    align-items: center;
    justify-content: space-between;
  }
`

export const StatusText = styled.div`
  font-size: 24px;
  font-weight: 300;
`

export const Inner = styled.div`
  padding: 16px 24px 32px;
`

export const Channel = styled.div`
  height: 46px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ecf1f5;
`

export const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`

export const TextWrapper = styled.div`
  max-width: 360px;
  margin-left: 42px;
  
  h3 {
    font-weight: 300;
    font-size: 24px;
  }
  
  p {
    font-size: 14px;
  }
`
