import * as React from 'react'
import { Query } from 'react-apollo'
import { get, groupBy, last, map, reduce, sortBy } from 'lodash'
import { connect } from 'react-redux'

import { addViewedCompanyIds, setSelectedCompanyId } from 'actions'
import { SidebarLayout, Spinner } from 'components'
import { Column, Container, Wrapper } from './live-view.styles'
import { getLiveViewStateQuery } from '../../gql'
import { LiveViewMap, SideBar } from './components'
import { liveViewSelector } from './live-view.selector'
import { DefaultErrorContainer } from '../errors/default-error'
import { CONFIG } from '../../config'
import { Events, registerAnalyticEvent } from 'utils'

const POOL_INTERVAL = 10000

interface LiveViewProps {
  history?: any
  viewedIds: any[]
  selectedCompanyId?: string
  addViewedCompanyIds?: any
  setSelectedCompanyId?: (id: string) => void
}

@connect(liveViewSelector, {
  setSelectedCompanyId,
  addViewedCompanyIds,
})
export class LiveView extends React.Component<LiveViewProps> {
  public state = {
    autoplay: false,
  }

  public componentDidMount(): void {
    registerAnalyticEvent(Events.LIVE_VIEW_LOAD)
  }

  public onOpenView = ({ companyId }) => {
    this.props.history.push(`/webleads/company/${companyId}/dashboard`)
  }

  public changeAutoplay = () => {
    const isEnabled = this.state.autoplay
    if (!isEnabled) {
      registerAnalyticEvent(Events.LIVE_VIEW_PLAY)
    }
    this.setState({ autoplay: !isEnabled })
  }

  public render(): React.ReactNode {
    return (
      <SidebarLayout noPadding={true}>
        <Query
          query={getLiveViewStateQuery}
          pollInterval={POOL_INTERVAL}
        >
          {({ error, loading, data, networkStatus }) => {
            const companies = this.filterCompanies(get(data, 'getLiveViewState', []))
            return (
              <Wrapper>
                <Column
                  className={this.state.autoplay ? 'gm-autoplay-enabled' : ''}
                >
                  {error ? <DefaultErrorContainer error={error} /> : null}
                  {loading ? <Spinner /> : null}
                  {data && networkStatus !== 1 ? (
                    <LiveViewMap
                      googleMapURL={CONFIG.MAP_URI}
                      loadingElement={<Spinner />}
                      mapElement={<div style={{ flex: 1 }} />}
                      containerElement={<Container />}
                      selectedCompanyId={this.props.selectedCompanyId}
                      onCompanyClick={this.props.setSelectedCompanyId}
                      companies={companies}
                      changeAutoplay={this.changeAutoplay}
                      autoplay={this.state.autoplay}
                      setSelectedCompanyId={this.props.setSelectedCompanyId}
                    />
                  ) : null}
                </Column>
                <SideBar
                  autoplay={this.state.autoplay}
                  selectedCompanyId={this.props.selectedCompanyId}
                  viewedIds={this.props.viewedIds}
                  companies={companies}
                  onOpenView={this.onOpenView}
                  addViewedCompanyIds={this.props.addViewedCompanyIds}
                  onCompanyClick={({ companyId }) => {
                    if (companyId === this.props.selectedCompanyId) {
                      this.props.setSelectedCompanyId(null)
                    } else {
                      this.props.setSelectedCompanyId(companyId)
                    }
                  }}
                />
              </Wrapper>
            )
          }}
        </Query>
      </SidebarLayout>
    )
  }

  private filterCompanies = (companies) => {
    const sortedByCity = {}
    return reduce(map(groupBy(companies, x => x.companyId), (groupedCompanies) => {
      return last(sortBy(groupedCompanies, 'dateHourMinute'))
    }), (result, company) => {
      const key = company.country + company.city
      const old = sortedByCity[key] || []
      sortedByCity[key] = [...old, company.companyId]
      return [...result, {...company, sortedByCity }]
    }, [])
  }
}
