import gql from 'graphql-tag'

export const getAssignedUser = gql`
  query getAssignedUser($companyId: String!) {
    getAssignedUser(companyId: $companyId) {
      email
    }
    getUsers {
      externalId
      firstName
      lastName
      picture
      email
    }
  }
`
