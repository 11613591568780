import { createStructuredSelector } from 'reselect'
import * as moment from 'moment/moment'
import queryString from 'query-string'

export const companySelector = createStructuredSelector({
  webVisitsDateRange: (x, { match, location }) => {
    const query = queryString.parse(location.search)

    const domain = match.params.id
    const name = query.serviceProvider

    if (name === x.webVisits.name && domain === x.webVisits.domain) {
      return x.webVisits.dateRange
    }

    return { startDate: moment().subtract(90, 'days'), endDate: moment() }
  },
})

