import styled from 'styled-components'

import { Card, Button, ProgressiveImage, LoadingCircle } from 'components'

export const Title = styled.div`
  font-weight: 300;
  font-size: 36px;
  margin: 45px 0 26px 0;
`
export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`
export const InfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 300px;
  margin: 7px;
  @media (min-width: 800px) {
    min-width: 450px;
  }
`
export const CardContent = styled(Card.Content)`
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
`
export const WrappingContent = styled(CardContent)`
  flex-wrap: wrap;
`
export const ConnectedAccount = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px 0;
`
export const AccountName = styled.div`
  font-weight: 300;
  font-size: 24px;
`
export const AccountEmail = styled.div`
  font-weight: 300;
  font-size: 14px;
`
export const AccountPhoto = styled(ProgressiveImage)`
  width: 52px;
  height: 52px;
  margin-right: 10px;
  margin-bottom: 10px;
`
export const DisconnectButton = styled(Button)`
  background: #D85251;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
`
export const ConnectButton = styled(Button)`
  background: #61869E;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
`
export const LoadingPhoto = styled(LoadingCircle)`
  width: 52px;
  height: 52px;
  margin-right: 10px;
`
