import styled from 'styled-components'
import { Button } from '../../../../../../components'

export const Wrapper = styled.section`
  color: #314550;
  background: white;
  padding: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const CreateButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`
export const FiltersSection = styled.div`
  margin: 40px 0;
  width: 100%;
`
export const SectionName = styled.div`
  font-size: 18px;
  padding: 0 5px;
  margin-bottom: 12px;
`
export const FilterEntry = styled.div`
  padding: 16px 5px;
  font-weight: 300;
  border-bottom: #CBCBCB 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  &:nth-child(2) {
    border-top: #CBCBCB 1px solid;
  } 
`
