import styled from 'styled-components'

export const Input = styled.input`
  width: 40px;
  height: 32px;
  padding: 0;
  outline: none;
  text-align: center;
  font-size: 18px;
  border: none;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 34px;
  padding: 0;
  cursor: pointer;
  font-size: 18px;
  background-color: #eaeaea;
  border: 1px solid #C4C4C4;
  outline: none;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  
`
