import styled from 'styled-components'

import { Icon } from 'components'

interface ButtonProps {
  disabled: boolean
}

export const Button = styled.div<ButtonProps>`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  transition: background-color .3s;
  cursor: ${({ disabled }) => disabled ? 'none' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};

  &:hover  {
    background: ${({ disabled, theme }) => disabled ? 'transparent' : theme.TOP_MENU_ITEM_HOVER}};
  }
`

export const SearchIcon = styled(Icon).attrs({
  icon: 'search'
})`
  ${Button} & {
    fill: ${({ theme }) => theme.TOP_MENU_FONT_COLOR};
    margin: 0;
  }
`
