import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { times } from 'lodash'

import { smallTableScreen } from '../list-items/list-item.styles'
import { Show } from 'components'

const bgColor = 'rgba(0, 0, 0, 0.12)'
const progress = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 35px 35px;
  }
`
const Animated = styled.div`
  background: ${bgColor};
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
  animation: ${progress} 2s linear infinite;
`
const Wrapper = styled.div`
  padding: 55px 24px 64px;
  margin-bottom: 40px;
  border-radius: 10px;
  background: white;
  @media (max-width: ${smallTableScreen}) {
    padding: 35px 0 30px;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #cbcbcb;
  padding: 16px;
  &:last-child {
    border-bottom: 1px solid #cbcbcb;
  }
  @media (max-width: ${smallTableScreen}) {
    flex-wrap: wrap;
  }
`
const LoadingDate = styled(Animated)`
  width: 85px;
  height: 14px;
  margin: 1px 24px 0 0;
  @media (max-width: ${smallTableScreen}) {
    margin: 5px calc(100% - 75px) 10px 0;
  }
`
const LoadingLogo = styled(Animated)`
  width: 75px;
  height: 75px;
  margin-right: 24px;
  @media (max-width: ${smallTableScreen}) {
    margin-bottom: 10px;
  }
`
const TextWrapper = styled.div`
  flex: 1;
`

interface LoadingTextProps {
  half?: boolean
}

const LoadingText = styled(Animated)<LoadingTextProps>`
  width: ${({ half }) => half ? '40%' : '100%'};
  height: 14px;
  margin-bottom: 8px;
  border-radius: 8px;
`

export const ListView = ({ loading, children, loadMore }) => (
  <Wrapper>
    <Show if={!loading || (loading && loadMore)}>
      {children}
    </Show>
    <Show if={loading}>
      {times(3, i => (
        <Item key={i}>
          <LoadingDate />
          <LoadingLogo />
          <TextWrapper>
            <LoadingText />
            <LoadingText />
            <LoadingText half={true} />
          </TextWrapper>
        </Item>
      ))}
    </Show>
  </Wrapper>
)
