import * as React from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'
import { BaseLayout } from 'components'

import { EmailInput } from './components'
import { RegistrationApi } from '../../api'
import { Events, registerAnalyticEvent, setAnalyticUser } from 'utils'
import { Hr, Wrapper, Small, MobileMessage } from './signup.styles'
import { reportEmSignUp } from '../../utils/em-forms'
import { registerGoogleAnalyticsEvent } from '../../utils/google-analytics'

interface SignupProps {
  history: any
  match: {
    params: { affiliateId: string, email?: string }
  },
  location: { search?: string }
  dispatch?: any
}

// @ts-ignore
@connect()
export class Signup extends React.PureComponent<SignupProps> {
  public state = {
    email: '',
    loading: false
  }

  public componentDidMount() {
    registerAnalyticEvent(Events.SIGNUP_PAGE_VISIT, { referral: document.referrer || 'unknown' })
  }

  public onEmail = (email: string) => {
    const { affiliateId } = this.props.match.params
    this.setState({
      loading: true
    }, () => {
      Promise.all([
        RegistrationApi.sendEmail(email, affiliateId),
        reportEmSignUp(email),
        registerGoogleAnalyticsEvent('Form', 'Submit', 'confirm email address'),
      ]).then(() => {
          this.setState({ email, loading: false })
        })
    })
    setAnalyticUser(email)
    registerAnalyticEvent(Events.SIGNUP_START, { 'email': email.toLowerCase() })
  }

  public render(): React.ReactNode {
    const { loading, email } = this.state

    const { email: urlEmail } = qs.parse(this.props.location.search)

    return (
      <BaseLayout style={{background: 'white'}}>
        { email ? (
          <Wrapper>
            <h1>Check your email</h1>
            <p>We sent a confirmation email to <span>{email}</span>.
              <br />Click the link in the email to see your leads.
            </p>
            <MobileMessage>
              For the best Leadexplorer experience, we recommend switching to a desktop device.
            </MobileMessage>
            <Hr />
            <Small>Haven't received our email? Make sure your email address is correct and check your spam folder!</Small>
          </Wrapper>
        ) : (
          <EmailInput
            email={urlEmail}
            loading={loading}
            onDone={this.onEmail}
          />
        )}
      </BaseLayout>
    )
  }
}
