import { getRegistration } from '../registerServiceWorker'
import { Events, registerAnalyticEvent } from './events'
import { CONFIG } from '../config'

const urlB64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const subscribeToNotifications = async () => {
  const permission = await (window as any).Notification.requestPermission()

  if (permission !== 'granted') {
    return null
  }

  registerAnalyticEvent(Events.GRANTED_NOTIFICATION_PERMISSION)

  let subscription = await getCurrentSubscription()

  if (!subscription) {
    const registration = await getRegistration()
    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(CONFIG.BROWSER_PUSH_APPLICATION_SERVER_KEY)
    })
  }

  return subscription
}

export const saveSubscription = async (subscription, mutation) => {
  if (!subscription) {
    return null
  }

  const subscriptionInput = JSON.parse(JSON.stringify(subscription))

  delete subscriptionInput.expirationTime

  await mutation({
    variables: {
      subscription: subscriptionInput,
    }
  })

  return subscription
}

export const getCurrentSubscription = async () => {
  const registration = await getRegistration()

  if (!registration || !registration.pushManager) {
    return null
  }

  return registration.pushManager.getSubscription()
}

export const isWebPushSupported = () => {
  const { Notification, ServiceWorkerRegistration } = window as any
  return (
    Notification &&
    ServiceWorkerRegistration &&
    'showNotification' in ServiceWorkerRegistration.prototype
  )
}

export const isSubscribed = async () => isWebPushSupported() && await getCurrentSubscription()
