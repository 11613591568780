import * as React from 'react'
import { Query } from 'react-apollo'
import { map, take } from 'lodash'
import { Alignment, Button, Spinner, Link, Show } from '../../../../../../components/index'

import { getCompaniesFromCrm } from '../../../../../../gql/superoffice/superoffice.query'
import { Bold, SuperOfficeLogo, TextWrapper } from '../../crm-insight.styles'
import { Wrapper, BottomText, NoConnectionText, UpperText, SearchTextWrapper, Row, FooterText, CenteredLink } from './search-company-card.styles'
import { SearchCompanyListItem } from './components/search-company-list-item.component'
import { Events, registerAnalyticEvent } from 'utils'

interface SearchCompanyCardProps {
  company: any
  id: string
  toggleCreateModal: () => void
  toggleSearchModal: () => void
  mutation: any
  onMatch: () => void
}



export class SearchCompanyCard extends React.PureComponent<SearchCompanyCardProps> {

  public render() {
    const { company, id, onMatch, toggleCreateModal, toggleSearchModal } = this.props
    const { name, domain = '' } = company
    return (
      <Wrapper>
        <Query query={getCompaniesFromCrm} variables={{ name, domain }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Spinner />
            }

            if (error) {
              return <TextWrapper>Can't connect to CRM due to unknown error</TextWrapper>
            }

            if (data.getCompaniesFromCrm.length) {
              return (
                <SearchTextWrapper>
                  <SuperOfficeLogo />
                  <UpperText>We are not 100% certain. Can you help us?</UpperText>
                  <BottomText>Match with companies in SuperOffice with similar name:</BottomText>
                  {map(take<any>(data.getCompaniesFromCrm, 3), item => (
                    <SearchCompanyListItem
                      key={item.companyId}
                      company={item}
                      id={id}
                      onMatch={(companyId) => this.matchCompany(id, companyId, company.name).then(onMatch)}
                    />
                  ))}
                  <Row alignment={Alignment.right}>
                    <Show if={data.getCompaniesFromCrm.length > 3}>
                      <CenteredLink onClick={toggleSearchModal}>Show more</CenteredLink>
                    </Show>
                    <Link onClick={toggleCreateModal}>Create New</Link>
                    <Link onClick={toggleSearchModal}>Search</Link>
                  </Row>
                </SearchTextWrapper>
              )
            }

            return (
              <TextWrapper>
                <SuperOfficeLogo />
                <NoConnectionText>
                  Hm, we can’t find the company in SuperOffice.
                </NoConnectionText>
                <Button
                  color="primary"
                  onClick={toggleCreateModal}
                >
                  CREATE NEW COMPANY
                </Button>
                <FooterText>
                  Or, <Link onClick={toggleSearchModal}><Bold>search</Bold></Link> for a company in SuperOffice
                </FooterText>
              </TextWrapper>
            )
          }}
        </Query>
      </Wrapper>
    )
  }

  private matchCompany(companyId, crmCompanyId, companyName) {
    registerAnalyticEvent(Events.COMPANY_CRM_MATCH, {
      'companyName': companyName,
    })
    return this.props.mutation({ variables: {
        crmCompanyId,
        companyId,
      }})
  }

}
