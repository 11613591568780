import * as React from 'react'

import { NoAvatar } from './components/no-avatar/no-avatar.component'
import { AvatarPicture } from './avatar.styles'
import { User } from '../../../models/user.model'

interface AvatarProps {
  user?: User
  size?: number
}

export class Avatar extends React.PureComponent<AvatarProps> {
  public render() {
    const { user, size = 40 } = this.props
    if (user) {
      if (user.picture) {
        return <AvatarPicture src={user.picture} size={size}/>
      }
      return (
        <NoAvatar
          size={size}
          firstName={user.firstName}
          lastName={user.lastName}
        />
      )
    }
    return null
  }
}

