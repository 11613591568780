import * as React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Input, Button } from 'components'

interface AddUsersProps {
  handleSubmit: any
}

export class AddUsers extends React.PureComponent<AddUsersProps> {
  public render() {
    const { handleSubmit } = this.props
    return (
      <div>
        <div>New limit:</div>
        <form onSubmit={handleSubmit}>
          <Field
            name="count"
            component={Input as any}
            type="text"
          />
          <Button color="primary">
            Update
          </Button>
        </form>
      </div>
    )
  }
}

const reduxFormOptions = {
  form: 'add-users-form',
}

export const AddUsersForm = reduxForm(reduxFormOptions)(AddUsers as any)
