import * as React from 'react'
import { Modal } from '../../modal/modal.component'
import { AgreementText } from './agreement-text.component'

interface AgreementModalProps {
  show: boolean
  onRequestClose: () => void
}

export class AgreementModal extends React.PureComponent<AgreementModalProps> {
  public render() {
    return (
      <Modal
        isOpen={this.props.show}
        onRequestClose={this.props.onRequestClose}
      >
        <div style={{ height: 500, overflow: 'scroll' }}>
          <AgreementText />
        </div>
        <Modal.Controls>
          <Modal.ApproveButton
            onClick={this.props.onRequestClose}
          >
            Close
          </Modal.ApproveButton>
        </Modal.Controls>
      </Modal>
    )
  }
}
