import gql from 'graphql-tag'

export const IntegrationsQuery = gql`
  query {
    getGoogleAnalyticsSummary {
      enabled
    }
    getSuperOfficeIntegrationStatus(serviceId: "leadexplorer") {
      connected
    }
    getEmarketeerIntegrationStatus {
      connected
    }
    getSlackChannelConfigurations {
      incomingWebhook {
        channel
      }
    }
  }
`
