import * as React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'query-string'
import * as Raven from 'raven-js'
import { CONFIG } from '../../config'

import {
  Webleads,
  Settings,
  Company,
  MyProfile,
  NotFoundContainer,
  DefaultErrorContainer,
  Blacklist,
  Verify,
  SubscriptionExpired,
  PurchasePlan,
  Callback,
  Signup,
  Search,
  LiveView,
  Registration,
} from './..'
import { CheckSetupRoute, RequestNotification } from 'components'
import { SignIn } from '../sign-in'
import { SignInCallbackContainer } from '../sign-in-callback'
import { rootSelector } from './root.selector'
import { UserProvider } from './components/user-provider.component'
import { EmailResolver } from '../email-resolver'

interface RootContainerProps {
  history: any
  profile?: any
  dispatch?: any
  isAuthenticated?: any
}

@withRouter
@connect(rootSelector)
export class RootContainer extends React.PureComponent<RootContainerProps> {
  public state = {
    error: null,
  }

  public componentDidMount() {
    Raven.config(CONFIG.SENTRY.DSN).install()
    Raven.setTagsContext({
      environment: CONFIG.ENVIRONMENT,
    })
  }

  public componentDidCatch(error) {
    this.setState({ error })
    Raven.captureException(error)
  }

  public render() {
    const { isAuthenticated, profile, dispatch } = this.props

    if (this.state.error) {
      return <DefaultErrorContainer error={this.state.error}/>
    }

    if (isAuthenticated && profile) {
      return (
        <UserProvider userId={profile.sub} dispatch={dispatch}>
          <Switch>
            <Route path="/my-profile" component={MyProfile} />
            <CheckSetupRoute exact={true} path="/live-view" component={LiveView}/>
            <CheckSetupRoute exact={true} path="/webleads/company/:id/dashboard" component={Company} />
            <CheckSetupRoute exact={true} path="/webleads/company/:id/financial-report" component={Company} />
            <CheckSetupRoute exact={true} path="/webleads/company/:id/social-media" component={Company} />
            <CheckSetupRoute exact={true} path="/webleads/company/:id/in-media" component={Company} />
            <CheckSetupRoute exact={true} path="/webleads/company/:id/technology" component={Company} />
            <CheckSetupRoute
              path="/webleads/company/:id"
              render={({ match: { params: { id } } }) =>
                <Redirect to={`/webleads/company/${id}/dashboard`} component={Company} />
              }
            />
            <CheckSetupRoute path="/webleads" component={Webleads} />
            <CheckSetupRoute path="/settings" component={Settings} />
            <CheckSetupRoute exact={true} path="/search" component={Search} />
            <CheckSetupRoute exact={true} path="/email-resolver" component={EmailResolver} />
            <Route exact={true} path="/purchase-plan" component={PurchasePlan} />
            <Route exact={true} path="/subscription-expired" component={SubscriptionExpired} />
            <Route exact={true} path="/blacklist" component={Blacklist} />
            <Route path="/callback/done" component={Callback} />
            <Route path="/callback" component={Callback} />
            <Route path="/superoffice/callback" component={Callback} />
            <Route exact={true} path="/" render={() => <Redirect to="/webleads" />} />
            <CheckSetupRoute component={NotFoundContainer} />
          </Switch>
          <RequestNotification />
        </UserProvider>
      )
    }

    return (
      <Switch>
        <Route
          exact={true}
          path="/verify"
          render={({ location }) => {
            const { tenantID, ticketID } = qs.parse(location.search)
            if (!tenantID || !ticketID) {
              return <Redirect to="/" />
            }
            return <Redirect to={`/verify/${ticketID}/${tenantID}`} />
          }}
        />
        <Redirect from="/register-email" to="/signup" />
        <Route exact={true} path="/signup" component={Signup} />
        <Route path="/signup/:affiliateId" component={Signup} />
        <Route path="/verify/:ticketId/:tenantId" component={Verify} />
        <Route path="/sign-in-callback" component={SignInCallbackContainer} />
        <Route path="/callback/done" component={Callback} />
        <Route path="/callback" component={Callback} />
        <Route path="/registration/:verificationId" component={Registration} />
        <Route component={SignIn} />
      </Switch>
    )
  }
}
