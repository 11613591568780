import * as React from 'react'
import { map, reduce } from 'lodash'
import { reduxForm, Field, SubmitHandler } from 'redux-form'

import { Card, TableRow, TableRowItem, TableHeaderItem, Alignment, Spinner, Row, Show, Link, Counter, Button, Checkbox } from '../../../../../components'
import { PlanCardInner, BillingFormWrapper } from './../purchase-plan.styles'
import { InfoCard } from '../../../settings-container.styles'
import { ChoosePlan } from './choose-plan.component'
import { Plan } from '../plan.model'
import { BillingFields } from '../../billing/components/billing-fields.component'
import { validation } from '../../../../../utils/index'
import { AgreementModal } from '../../../../../components/modals/agreement-modal/agreement-modal.component'

const required = validation.required('Field is required')

const CardTitle = (Card as any).Title

interface PurchasePlanFormProps {
  planCode: string
  users: number
  monthlyPlans: Plan[]
  yearlyPlans: Plan[]
  handleSubmit?: SubmitHandler
  initialValues?: any
  onSubmit: any
  countriesList: any
  valid?: boolean
  updating: boolean
}
// @ts-ignore
@reduxForm({
  form: 'purchase-plan',
})
export class PurchasePlanForm extends React.PureComponent<PurchasePlanFormProps> {
  public state = {
    plansRow: 0,
    selectedPlan: null,
    users: 1,
    showModal: false
  }

  public onTermsClick = () => {
    this.setState({ showModal: true })
  }

  public closeModal = () => {
    this.setState({ showModal: false })
  }

  public render() {
    const { planCode, monthlyPlans, yearlyPlans, handleSubmit, countriesList, valid, updating } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="plan_code"
          component={ChoosePlan as any}
          planCode={planCode}
          monthlyPlans={monthlyPlans}
          yearlyPlans={yearlyPlans}
          validate={[required]}
        />
        <InfoCard>
          <CardTitle>
            2. Billing information
          </CardTitle>
          <BillingFormWrapper>
            <BillingFields
              countriesList={countriesList}
              columns={2}
            />
          </BillingFormWrapper>
        </InfoCard>
        <InfoCard>
          <CardTitle>
            3. Terms & checkout
          </CardTitle>
          <PlanCardInner>
            <p>Accept the terms and click “Checkout” to proceed.</p>
            <Row>
              <Field
                name="accept"
                component={Checkbox as any}
                validate={[required]}
              />
              <p>Accept our <Link onClick={this.onTermsClick} color="#61869e">Terms of service</Link></p>
            </Row>
            <Button
              color="primary"
              disabled={!valid || updating}
              updating={updating}
            >
              Checkout
            </Button>
          </PlanCardInner>
        </InfoCard>
        <AgreementModal
          show={this.state.showModal}
          onRequestClose={this.closeModal}
        />
      </form>
    )
  }
}
