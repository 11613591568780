import styled from 'styled-components'

import { LoadingBlock } from 'components'

export const Wrapper = styled.div`
  padding: 40px 24px 0;
  margin-bottom: 30px;
  border-radius: 10px;
  background: white;
`
export const LoadingText = styled(LoadingBlock)`
  margin-bottom: 0;
`

