import * as React from 'react'
import styled from 'styled-components'
import { map, times } from 'lodash'

import { Icon } from '../'

const Wrapper = styled.div`
  display: flex;
  padding-top: 5px;
`
const Star = styled(Icon)`
  margin-left: 3px;
  margin-right: 0;
`

export const StarRating = ({ value = 0, heart, ...other }) => (
  <Wrapper {...other}>
    {map(times(value), (d, i) => <Star key={i} size={15} icon="star" />)}
    {map(times(7 - value), (d, i) => <Star key={i} size={15} icon="emptyStar" />)}
  </Wrapper>
)
