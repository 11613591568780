import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { map } from 'lodash'
import { googleStatus, getGoogleAnalyticsData } from '../../../gql/google'

const enableGaViews = gql`
  mutation enableGaViews($viewId: String!) {
    enableGaView(viewId: $viewId) {
      enabledViewId
    }
  }
`

export const googleAnalyticsSummaryQueries = [
  graphql(getGoogleAnalyticsData, {
    name: 'data',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(googleStatus, {
    name: 'status',
  }),
  graphql(enableGaViews, {
    name: 'enableGaViews',
    options: ({ tenantId }: any) => ({
      variables: { tenantId },
      update: (proxy, { data: { enableGaView: { enabledViewId } } }) => {
        // Read the data from our cache for this query.
        const data: any = proxy.readQuery({ query: getGoogleAnalyticsData, variables: { tenantId } })

        const newData = {
          ...data,
          getGoogleAnalyticsSummary: map(data.getGoogleAnalyticsSummary,
            x => ({ ...x, enabled: x.viewId === enabledViewId })),
        }

        // Write our data back to the cache.
        proxy.writeQuery({ query: getGoogleAnalyticsData, variables: { tenantId }, data: newData })
      },
    }),
  }),
]
