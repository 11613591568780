import * as React from 'react'
import { times } from 'lodash'

import { Show, LoadingBlock } from 'components'
import { Wrapper, LoadingText } from './list-view.styles'
import { ItemRow, WideTableColumn, TableColumn } from '../web-visit/web-visit.styles'

export const ListView = ({ loading, children }) => (
  <Wrapper>
    {children}
    <Show if={loading}>
      {times(3, i => (
        <ItemRow key={i}>
          <TableColumn>
            <LoadingText width="70%" />
          </TableColumn>
          <WideTableColumn>
            <LoadingText width="70%" />
          </WideTableColumn>
          <TableColumn>
            <LoadingText width="70%" />
          </TableColumn>
        </ItemRow>
      ))}
    </Show>
  </Wrapper>
)
