import { connect } from 'react-redux'
import styled from 'styled-components'
import { Icon, RoundedInput } from 'components'

export const EntryContainer = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const AndContainer = styled.div`
  margin: 20px 0;
`
export const FilterFieldContainer = styled.div`
  min-width: 110px;
  margin-right: 8px;
`
export const FilterOperatorContainer = styled.div`
  min-width: 100px;
  margin-right: 8px;
`
export const FilterExpressionInput = styled(RoundedInput)`
  padding: 8px 10px;
  height: 36px;
  font-size: 14px;
  width: 100%;
`
export const FilterExpressionContainer = styled.div`
  flex: 1;
  display: flex;
  
  .react-selectize {
    width: 100%;
  }
`
export const CloseIcon = styled(Icon)`
  margin: 0 0 0 12px;
  cursor: pointer;
`
