import { get } from 'lodash'
import { CONFIG } from '../config'

export const isAuthenticatedSelector = x => x.auth.isAuthenticated
export const prospectsListSelector = x => x.prospectsList
export const tenantIdSelector = x => get(x, `auth.profileData['${CONFIG.REGISTRATION.tenantKey}']`)
export const profileIsUpdatedSelector = x => get(x, `auth.profileData['${CONFIG.REGISTRATION.tenantKey}']`) !== 'ONBOARDING'
export const userIdSelector = x => get(x, 'auth.profileData.sub')
export const profileSelector = x => get(x, 'auth.profileData')
export const authSelector = x => x.auth

