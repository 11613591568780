import * as React from 'react'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { RoundedInput, Button } from 'components'

const Controls = styled.div`
  margin-top: 10px;
  padding-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`
const AddButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  background: #61869E;
  color: white;
  margin: 0;
`
const Wrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: 0 0 20px 0;
`
const Label = styled.div`
  margin: 15px 0 10px 0;
  font-size: 14px;
  font-weight: 300;
`
const Input = styled(RoundedInput)`
  padding: 10px;
  font-size: 18px;
  width: 100%;
  max-width: 400px;
  font-weight: 300;
`

interface AddBlacklistRecordFormProps {
  handleSubmit?: any
  submitting?: boolean
  onSubmit?: any
  invalid?: boolean
}

// @ts-ignore
@reduxForm({
  form: 'addBlacklist',
  // @ts-ignore
  validate: (form: any) => !(form.serviceProvider || form.networkDomain) && { networkDomain: true },
})
export class AddBlacklistRecordForm extends React.Component<AddBlacklistRecordFormProps> {
  public render() {
    const { handleSubmit, invalid, submitting } = this.props

    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <Label>Network Domain</Label>
          <Field
            name="networkDomain"
            component={Input as any}
            type="text"
          />
          <Label>Service Provider</Label>
          <Field
            name="serviceProvider"
            component={Input as any}
            type="text"
          />
          <Controls>
            <AddButton
              type="submit"
              disabled={invalid || submitting}
            >
              Add Record
            </AddButton>
          </Controls>
        </form>
      </Wrapper>
    )
  }
}
