import gql from 'graphql-tag'
import * as moment from 'moment'
import { get } from 'lodash'
import { graphql } from 'react-apollo'
import { getUsers } from '../../gql'
import { googleStatus } from '../../gql/google/google.query'

const getCompanies = gql`
    query getCompanies($offset: String!, $startDate: String!, $endDate: String!, $sortBy: String, $filterId: String!) {
        getCompanies(startDate: $startDate, endDate: $endDate, offset: $offset, sortBy: $sortBy, filterId: $filterId) {
            total
            domains {
                id
                name
                domainName
                logo
                campaign
                pageTitle
                operatingSystem
                serviceProvider
                deviceCategory
                dateTime
                sourceMedium
                utmContent
                address {
                    city
                    country
                    countryCode
                }
                revisit
                sessions
                pageviews
                bounces
                score
                crm
                assignedTo {
                  companyId
                  email
                }
            }
        }
    }
`

export const webleadsQuery = [
  graphql(getCompanies,
    {
      options: ({ prospectsList, filterId }: any) => ({
        variables: {
          startDate: get(prospectsList, 'dateRange.startDate', moment())
            .format('YYYY-MM-DD'),
          endDate: get(prospectsList, 'dateRange.endDate', moment())
            .format('YYYY-MM-DD'),
          sortBy: get(prospectsList, 'sortBy'),
          offset: '0',
          filterId,
        },
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
      }),
    }),
  graphql(getUsers, {
    name: 'getUsers',
  }),
  graphql(googleStatus, {
    name: 'googleStatus',
  }),
]
