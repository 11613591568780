import * as React from 'react'
import { PageTabs } from 'components'
import styled from 'styled-components'

const UserSettingsPageTabs = styled(PageTabs)`
  max-width: calc(100vw - 20px);
  padding-left: 20px;
  background-color: ${({ theme }) => theme.CANVAS_COLOR};
  @media (max-width: 800px) {
    padding: 20px 20px 0 20px;
  }
`

const UserSettingsPageTabsItem = styled(PageTabs.Item)`
  margin: 0 10px 0 0;
`

export const UserSettingsSwitcher = () => (
  <UserSettingsPageTabs>
    <UserSettingsPageTabsItem
      to={'/settings/users'}
      location={location}
    >
      Active Users
    </UserSettingsPageTabsItem>
    <UserSettingsPageTabsItem
      to={'/settings/invitations'}
      location={location}
    >
      Pending Invitations
    </UserSettingsPageTabsItem>
  </UserSettingsPageTabs>
)
