import * as React from 'react'

import { Modal } from 'components'
import { Title, SubTitle, ButtonWrapper, AnalyticsButton, Views } from './analytics-modal.styles'

import { GoogleAnalyticsSummary } from '../accounts'

const GoogleAnalytics = (GoogleAnalyticsSummary as any)

interface AnalyticsModalProps {
  isOpen: boolean
  onDone: any
  toggle: any
}

export class AnalyticsModal extends React.PureComponent<AnalyticsModalProps> {
  public render() {
    const { isOpen, toggle, onDone } = this.props

    return (
      <Modal isOpen={isOpen} onRequestClose={toggle}>
        <Title>Select google analytics views</Title>
        <SubTitle>
          Enable the Google Analytics Views you want to include in Leadexplorer.
        </SubTitle>
        <Views>
          <GoogleAnalytics />
        </Views>
        <ButtonWrapper>
          <AnalyticsButton color="success" onClick={onDone}>Done</AnalyticsButton>
        </ButtonWrapper>
      </Modal>
    )
  }
}
