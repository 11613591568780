import * as React from 'react'
import { chain, join, get } from 'lodash'

import { Icon, TreesRating, Show, Slideshow } from 'components'
import {
  Wrapper,
  LogoContainer,
  Name,
  Description,
  Industry,
  Line,
  Title,
  Address,
  CompanyInformation,
  DetailsContainer,
  Detail,
  DetailTitle,
  Age,
  SocialIconList,
  LogoLoading,
  NameLoading,
  LoadingText,
  WebsiteLink,
  PhoneDetail, SocialMediaIcon, CompanyInformationLeft, CompanyInformationRight, CompanyInfoRow,
} from './company-info.styles'
import { getScreenshotUrl } from 'utils'

interface CompanyInfoProps {
  company: any
  loading: boolean
}

export const CompanyInfo: React.SFC<CompanyInfoProps> = ({ company = {}, loading }) => {
  const { logo, name, website, founded, phone, bio, employeesCount } = company
  const location = get(company, 'locations[0]', {})
  let formattedAddress = location.addressLine1
  if (location.addressLine2) {
    formattedAddress += ` ${location.addressLine2},`
  }
  if (formattedAddress && location.postalCode) {
    formattedAddress += ` ${join([`${location.postalCode} ${location.city}`, location.country], ', ')}`
  } else if (formattedAddress) {
    formattedAddress += ` ${join([location.city, location.country], ', ')}`
  } else if (location.city && location.country) {
    formattedAddress = `${location.city}, ${location.country}`
  }

  const industryText = chain(company.industries).map('name').join(', ').value()
  const socialProfiles = company.profiles || {}

  return (
    <Wrapper>
      <Show if={name}>
          <LogoContainer>
            <Show if={!loading && name}>
              <Slideshow
                images={[
                  { src: logo || '/images/company-logo.png', styles: { backgroundSize: 'contain' } },
                  { src: getScreenshotUrl(website), styles: { backgroundSize: 'cover', position: 'top center' } }]}
                duration={5000}
              />
            </Show>
            <Show if={loading}>
              <LogoLoading/>
            </Show>
          </LogoContainer>
        <Line/>

        <CompanyInfoRow>
          <CompanyInformation>
            <Name>
              <Show if={!loading}>
                {name}
              </Show>
              <Show if={loading}>
                <NameLoading/>
              </Show>
            </Name>

            <Show if={!loading && bio}>
              <Description>
                {bio}
              </Description>
            </Show>
            <Show if={loading}>
              <Description>
                <LoadingText/>
                <LoadingText/>
                <LoadingText half={true}/>
              </Description>
            </Show>
          </CompanyInformation>
        </CompanyInfoRow>

        <CompanyInfoRow>
          <CompanyInformationLeft>
            <Show if={!loading}>
              <Title>Company Information</Title>
              <DetailsContainer>
                <Detail>
                  <DetailTitle>Founded:</DetailTitle>
                  {founded || 'N/A'}
                </Detail>
                <Address>{formattedAddress}</Address>
                <PhoneDetail>
                  <DetailTitle>Phone:</DetailTitle>
                  {phone || 'N/A'}
                </PhoneDetail>
                <Detail>
                  <DetailTitle>Website:</DetailTitle>
                  <WebsiteLink href={website} target="_blank">{website}</WebsiteLink>
                </Detail>
              </DetailsContainer>
            </Show>
          </CompanyInformationLeft>
          <CompanyInformationRight>
            <Age>
              <Title>Years in business</Title>
              <TreesRating value={founded ? (new Date()).getFullYear() - founded : -1}/>
            </Age>
          </CompanyInformationRight>
        </CompanyInfoRow>
        <CompanyInfoRow>
          <Show if={!loading}>
            <CompanyInformationLeft>
              <Title>Industry</Title>
              <Industry>{industryText}</Industry>
              <Show if={loading}>
                <Title>
                  <LoadingText half={true}/>
                </Title>
                <Industry>
                  <LoadingText/>
                  <LoadingText half={true}/>
                </Industry>
              </Show>
            </CompanyInformationLeft>
          </Show>
          <Show if={loading}>
            <CompanyInformation>
              <Title>
                <LoadingText half={true}/>
              </Title>
              <Industry>
                <LoadingText/>
                <LoadingText half={true}/>
              </Industry>
            </CompanyInformation>
          </Show>
          <Show if={!loading}>
            <CompanyInformationRight>
              <Title>Social Media Presence</Title>

              <SocialIconList>
                <Show if={get(socialProfiles, 'twitter.url')}>
                  <a href={get(socialProfiles, 'twitter.url')} rel="noopener noreferrer" target="_blank">
                    <SocialMediaIcon icon="twitterAlt"/>
                  </a>
                </Show>
                <Show if={get(socialProfiles, 'linkedincompany.url')}>
                  <a href={get(socialProfiles, 'linkedincompany.url')} rel="noopener noreferrer" target="_blank">
                    <SocialMediaIcon icon="linkedIn"/>
                  </a>
                </Show>
                <Show if={get(socialProfiles, 'youtube.url')}>
                  <a href={get(socialProfiles, 'youtube.url')} rel="noopener noreferrer" target="_blank">
                    <SocialMediaIcon icon="youtubeAlt"/>
                  </a>
                </Show>
                <Show if={get(socialProfiles, 'facebook.url')}>
                  <a href={get(socialProfiles, 'facebook.url')} rel="noopener noreferrer" target="_blank">
                    <SocialMediaIcon icon="facebookAlt"/>
                  </a>
                </Show>
              </SocialIconList>
            </CompanyInformationRight>
          </Show>
          <Show if={loading}>
            <Title><LoadingText half={true}/></Title>
            <LoadingText/>
            <LoadingText/>
            <LoadingText/>
            <LoadingText/>
          </Show>
        </CompanyInfoRow>
        <Line/>
      </Show>
    </Wrapper>
  )
}
