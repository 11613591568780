import * as React from 'react'
import { padStart } from 'lodash'
import { Mutation } from 'react-apollo'

import {
  Table,
  TableRow,
  TableRowItem,
  Button,
  Row,
} from '../../../../../components/index'
import { BottomCardWrapper, AddCardWrapper } from '../../../settings-container.styles'
import { updateCard, deleteCard, getPaymentSubscriptionStatus } from '../../../../../gql/subscriptions/subscriptions.query'
import { DeleteCardModal } from './delete-card-modal.component'

interface CreditCardProps {
  cardData: any
}

export class CreditCard extends React.PureComponent<CreditCardProps> {
  public state = {
    deleteCardModalIsOpen: false
  }
  public onUpdateClick = (mutation) => {
    return mutation()
      .then((result) => {
        window.location.href = (result as any).data.updatePaymentCard.url
      })
  }
  public onDeleted = (cache, { data: { addTodo } }) => {
    const data = cache.readQuery({ query: getPaymentSubscriptionStatus })
    data.getPaymentSubscriptionStatus.subscription.card = {
      ...data.getPaymentSubscriptionStatus.subscription.card,
      card_id: '',
      expiry_month: '',
      expiry_year: '',
      last_four_digits: '',
    }
    cache.writeQuery({
      query: getPaymentSubscriptionStatus,
      data,
    })
  }
  public toggleDeleteCardModalIsOpen = () => {
    this.setState({
      deleteCardModalIsOpen: !this.state.deleteCardModalIsOpen,
    })
  }
  public render() {
    const { card_id, last_four_digits, expiry_month, expiry_year } = this.props.cardData
    const { deleteCardModalIsOpen } = this.state
    if (card_id === '') {
      return (
        <BottomCardWrapper>
          <h3>
            CREDIT CARD ON RECORD
          </h3>
          <Table>
            <TableRow>
              <TableRowItem maxWidth={140}>
                Card Number
              </TableRowItem>
              <TableRowItem>
                -
              </TableRowItem>
            </TableRow>
            <TableRow>
              <TableRowItem maxWidth={140}>
                Expire Date
              </TableRowItem>
              <TableRowItem>
                -/-
              </TableRowItem>
            </TableRow>
          </Table>
          <Mutation
            mutation={updateCard}
            variables={{
              redirect_uri: `https://${window.location.host}/settings/subscription`
            }}
          >
            {(mutation, { loading }) => (
              <Button
                onClick={() => this.onUpdateClick(mutation)}
                color="primary"
                updating={loading}
                disabled={loading}
              >
                Add card
              </Button>
            )}
          </Mutation>
        </BottomCardWrapper>
      )
    }
    return (
      <BottomCardWrapper>
        <h3>
          CREDIT CARD ON RECORD
        </h3>
        <Table>
          <TableRow>
            <TableRowItem maxWidth={140}>
              Card Number
            </TableRowItem>
            <TableRowItem>
              **** **** **** {last_four_digits}
            </TableRowItem>
          </TableRow>
          <TableRow>
            <TableRowItem maxWidth={140}>
              Expire Date
            </TableRowItem>
            <TableRowItem>
              {padStart(expiry_month, 2, '0')} / {expiry_year}
            </TableRowItem>
          </TableRow>
        </Table>
        <Row>
          <Mutation
            mutation={updateCard}
            variables={{
              redirect_uri: `https://${window.location.host}/settings/subscription`
            }}
          >
            {(mutation, { loading }) => (
              <Button
                onClick={() => this.onUpdateClick(mutation)}
                color="primary"
                updating={loading}
                disabled={loading}
              >
                Update card
              </Button>
            )}
          </Mutation>
          <Button
            color="alert"
            onClick={this.toggleDeleteCardModalIsOpen}
          >
            Delete card
          </Button>
        </Row>
        <Mutation
          mutation={deleteCard}
          variables={{ card_id }}
          update={this.onDeleted}
        >
          {(mutation, { loading }) => (
            <DeleteCardModal
              onRequestClose={this.toggleDeleteCardModalIsOpen}
              isOpen={deleteCardModalIsOpen}
              digits={last_four_digits}
              mutation={mutation}
              loading={loading}
            />
          )}
        </Mutation>
      </BottomCardWrapper>
    )
  }
}
