import * as React from 'react'
import { get } from 'lodash'
import { push as Menu } from 'react-burger-menu'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ReduxToastr from 'react-redux-toastr'
import { graphql } from 'react-apollo'

import { Wrapper, Layout, Content, SidebarContent, OuterContainer } from './sidebar-layout.styles'
import { PageHeader, HeaderMenu } from 'components'
import { userQuery } from '../../../gql'
import { User } from '../../../models/user.model'

interface SidebarLayoutProps {
  data?: any
  children: any
  noSearch?: boolean
  noPadding?: boolean
  pathname?: string
}

// @ts-ignore
@graphql(userQuery)
@connect((state) => ({
  pathname: get(state, 'router.location.pathname')
}), { push })
export class SidebarLayout extends React.PureComponent<SidebarLayoutProps> {
  public mql: MediaQueryList

  public state = {
    isSmallScreen: false,
    isSidebarOpen: false,
  }

  public componentDidMount() {
    this.mql = window.matchMedia('(min-width: 801px)')
    this.mql.addListener(this.handleMediaQueryChanged)
    this.handleMediaQueryChanged()
  }

  public componentWillUnmount() {
    if (this.mql) {
      this.mql.removeListener(this.handleMediaQueryChanged)
    }
  }

  public onHeaderClick = () => {
    const { isSmallScreen } = this.state
    if (isSmallScreen) {
      return this.setState({ isSidebarOpen: true })
    }
    return push('/')
  }

  public getSidebarContent = () => (
    <SidebarContent>
      <HeaderMenu
        pathname={this.props.pathname}
        isSmallScreen={true}
        user={new User (this.props.data.getUser)}
      />
    </SidebarContent>
  )

  public handleMediaQueryChanged = () => {
    const { matches } = this.mql
    this.setState({ isSmallScreen: !matches, isSidebarOpen: false })
  }

  public render() {
    const { children, noSearch = false } = this.props
    const { isSmallScreen, isSidebarOpen } = this.state

    return (
      <OuterContainer id="outer-container">
        <Menu
          animation="reveal"
          pageWrapId="page-wrap"
          outerContainerId="outer-container"
          isOpen={isSidebarOpen}
          onStateChange={({ isOpen }) => isOpen ? null : this.setState({ isSidebarOpen: false })}
        >
          {this.getSidebarContent()}
        </Menu>
        <PageHeader
          onHeaderClick={this.onHeaderClick}
          isSmallScreen={isSmallScreen}
          noSearch={noSearch}
        />
        <Wrapper id="page-wrap">
          <Layout
            noPadding={false}
            {...this.props}
          >
            <Content>
              {children}
            </Content>
          </Layout>
        </Wrapper>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={true}
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
      </OuterContainer>
    )
  }
}
