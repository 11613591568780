import styled from 'styled-components'
import { map } from 'lodash'
import { RoundedInput, FormDropdown, Button } from '../../../../../../components'

export const CompanyNameInput = styled(RoundedInput)`
  width: 100%;
  font-size: 18px;
  padding: 13px 13px;
  display: flex;
  margin-bottom: 10px;
  background: #EAEAEA;
  border: none;
  :focus {
    outline: none;
  }
`
export const Input = styled(RoundedInput)`
  font-size: 18px;
  padding: 10px 13px;
  width: 100%;
  margin-bottom: 10px;
  background: #EAEAEA;
  border: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    font-weight: 300;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 800px;
`
export const DoubleInputs = styled.div`
  display: flex;
  flex-direction: row;
`
export const ZipCodeInput = styled(Input)`
  width: 140px;
  margin-right: 12px;
`
export const DropdownWrapper = styled.div`
  margin-bottom: 10px;
`
export const SaveButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  background: #61869E;
  color: white;
  margin: 0;
  width: 140px;
`
export const Controls = styled.div`
  padding-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
