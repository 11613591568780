export const SET_PROSPECTS_DATE_RANGE = 'SET_PROSPECTS_DATE_RANGE'
export const SET_PROSPECTS_SORTING = 'SET_PROSPECTS_SORTING'
export const SET_TRIAL_PROSPECTS_DATE_RANGE = 'SET_TRIAL_PROSPECTS_DATE_RANGE'

interface SortingPayload {
  sortBy: 'score' | 'dateTime'
}

export const setProspectsDateRange = payload => ({ type: SET_PROSPECTS_DATE_RANGE, payload })
export const setProspectsSorting = (payload: SortingPayload) => ({ type: SET_PROSPECTS_SORTING, payload })
export const setTrialProspectsDateRange = () => ({ type: SET_TRIAL_PROSPECTS_DATE_RANGE })
