import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled, { StyledComponentClass } from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: normal;
  font-weight: 700;
  font-style: normal;
  border-bottom: 1px solid #ECF1F5;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px 16px;
  align-items: center;
`

interface CardItems extends StyledComponentClass<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, HTMLDivElement> {
  Title?: any
  Content?: any
  ContentRow?: any
  Footer?: any
}
export const Card: CardItems = styled.div`
  font-family: Roboto;
  background: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #314550;
`

Card.Title = Title
Card.Content = Content
Card.ContentRow = ContentRow
Card.Footer = Footer
