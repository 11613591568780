import { SWITCH_FILTER_PANEL, SET_FILTER_ID, SET_FILTER_ID_FOR_EDIT } from 'actions'

const defaultState = {
  activeTab: 'list',
  selectedFilterId: 'allLeads',
  filterToEditId: null
}

export const filterPanel = (state = defaultState, action) => {
  switch (action.type) {
    case SWITCH_FILTER_PANEL:
      return {
        ...state,
        activeTab: action.tab,
        }

    case SET_FILTER_ID:
      return {
        ...state,
        selectedFilterId: action.id,
      }

    case SET_FILTER_ID_FOR_EDIT:
      return {
        ...state,
        filterToEditId: action.id,
      }

    default:
      return state
  }
}
