import styled from 'styled-components'

interface LayoutProps {
  noPadding: boolean
}

export const Layout = styled.div<LayoutProps>`
  display: flex;
  flex: 1;
  padding: ${({ noPadding }) => noPadding ? '0' : '0 60px'};
  @media (max-width: 800px) {
    padding: ${({ noPadding }) => noPadding ? '0' : '0 10px'};
  }
`
export const Content = styled.section`
  flex: 1;
  min-height: calc(100vh - 60px);
  color: #314550;
  display: flex;
  flex-direction: column;
`
export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.CANVAS_COLOR};
  padding-top: 60px;

  @media (max-width: 800px) {
    height: calc(100% - 60px);
  }
`
export const SidebarContent = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.TOP_MENU_COLOR};
`
export const OuterContainer = styled.div`
  display: flex;
  flex: 1;
`
