import styled from 'styled-components'

import superOfficeLogo from '../../images/superoffice-logo.svg'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  border-top: 1px solid #E3E3E3;
`

export const CompanyWrapper = styled.div`
  display: flex;
  min-height: 70px;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 32px;
`

export const Logo = styled.img`
  width: 70px;
  height: 70px;
  margin-right: 16px;
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Name = styled.div`
  font-weight: 300;
  font-size: 24px;
`
export const Address = styled.div`
  font-size: 14px;
`

export const SuperOfficeLogo = styled.img.attrs({
  src: superOfficeLogo,
})`
  width: 60px;
  height: 45px;
  margin-left: auto;
`
