import * as React from 'react'

import { TableRow, TableRowItem, Alignment } from '../../../../../components/index'
import { PaymentStatus, DownloadButton } from '../invoices.styles'
import { Invoice } from '../models/invoice.model'
import downloadIcon from '../images/download.svg'

interface InvoiceListItemProps {
  invoice: Invoice
}

export class InvoiceListItem extends React.PureComponent<InvoiceListItemProps> {
  public onDownloadClick = () => {
    (window as any).open(this.props.invoice.url)
  }

  public render() {
    const { invoice } = this.props
    return (
      <TableRow>
        <TableRowItem>
          {invoice.date}
        </TableRowItem>
        <TableRowItem size={2}>
          {invoice.id}
        </TableRowItem>
        <TableRowItem>
          <PaymentStatus color={invoice.getColor()}>{invoice.status}</PaymentStatus>
        </TableRowItem>
        <TableRowItem>
          {invoice.amount}
        </TableRowItem>
        <TableRowItem alignment={Alignment.right}>
          <DownloadButton
            onClick={this.onDownloadClick}
          >
            <img src={downloadIcon} />
          </DownloadButton>
        </TableRowItem>
      </TableRow>
    )
  }
}
