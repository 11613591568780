import * as React from 'react'
import styled from 'styled-components'

import { Card, Show, FadeIn, Spinner } from '../../../components'
import { AddBlacklistRecordForm } from './add-blacklist-record-form.component'
import { BlacklistTable } from './blacklist-table.component'

const Container = styled(Card)`
  padding: 20px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  font-size: 24px;
`
const Subtitle = styled.div`
  font-size: 18px;
  margin: 20px 0;
`
const SpinnerHolder = styled.div`
  height: 400px;
`

export const BlacklistCard = ({ records, loading, addRecord, deleteRecord }) => (
  <Container>
    <Title>Blacklist</Title>
    <Subtitle>New Record</Subtitle>
    <AddBlacklistRecordForm onSubmit={addRecord} />
    <Subtitle>Existing Records</Subtitle>
    <Show if={!loading}>
      <BlacklistTable records={records} deleteRecord={deleteRecord} />
    </Show>
    <Show if={loading}>
      <FadeIn timeout={300}>
        <SpinnerHolder>
          <Spinner />
        </SpinnerHolder>
      </FadeIn>
    </Show>
  </Container>
)
