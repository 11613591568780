import * as React from 'react'
import { connect } from 'react-redux'
import { times, map, find, get, filter } from 'lodash'
import { compose, graphql } from 'react-apollo'

import { ProgressiveImage, Show, Icon } from 'components'
import { DeleteUserModal } from '../components'
import { AddIcon, AddUserCard, Avatar, LoadingCard, Name, PersonIcon, Position, UserCard, UserCards, UserDescription, Settings, DropdownText } from './users-list.styles'
import { getUsers, userQuery, updateOtherUser } from '../../../../../gql'
import { Dropdown } from 'components'
import { User } from '../../../../../models/user.model'
import { Group } from '../../../../../models/group.model'
import { toggleInviteUserModal } from 'actions'

interface UsersSettingsProps {
  data: any
  user: any
  deleteUser: any
  updateOtherUser: any
  toggleInviteUserModal: any
}

// @ts-ignore
@compose(
  graphql(getUsers),
  graphql(updateOtherUser, {
    name: 'updateOtherUser',
  }),
  graphql(userQuery, {
    name: 'user',
  }),
)
@connect(null, { toggleInviteUserModal })
export class UsersList extends React.Component<UsersSettingsProps> {
  public state = {
    isDeleteUserModalOpen: false,
    userToDeleteEmail: null,
    firstName: null,
    lastName: null,
  }

  public toggleDeleteUserModal = (userToDeleteEmail: string, firstName: string, lastName: string) => {
    this.setState({
      isDeleteUserModalOpen: !this.state.isDeleteUserModalOpen,
      userToDeleteEmail,
      firstName,
      lastName,
    })
  }

  public closeDeleteUserModal = () => {
    this.setState({ isDeleteUserModalOpen: !this.state.isDeleteUserModalOpen })
  }

  public render() {
    const { data = {} } = this.props
    const email = get(this.props, 'user.getUser.email')

    return (
        <UserCards>
          <Show if={!data.loading}>
            <AddUserCard onClick={() => this.props.toggleInviteUserModal(null)}>
              <AddIcon>+</AddIcon>
            </AddUserCard>
            {map(data.getUsers, (user, i) => {
              const userModel = new User(user)
              const items = [{
                title: <DropdownText>Delete user</DropdownText>,
                onClick: () => this.toggleDeleteUserModal(userModel.email, userModel.firstName, userModel.lastName),
              }]
              if (!userModel.isAdmin()) {
                items.push({
                  title: <DropdownText>Change to Admin</DropdownText>,
                  onClick: () => {
                    userModel.groups.push(new Group({ _id: '1bee0ad3-d423-453e-bf80-f051ed54bf23' }))
                    return this.props.updateOtherUser({
                      variables: {
                        email: user.email,
                        groups: userModel.groups.map(group => group.getVariables()),
                      },
                    })
                  },
                })
              } else {
                items.push({
                  title: <DropdownText>Change to User</DropdownText>,
                  onClick: () => {
                    userModel.groups = userModel.groups.filter(group => group.id !== '1bee0ad3-d423-453e-bf80-f051ed54bf23')

                    if (userModel.groups.length === 0) {
                      userModel.groups.push(new Group( { _id: '520ceea1-3de9-409f-9e0f-fa329a8199fb' }))
                    }

                    return this.props.updateOtherUser({
                      variables: {
                        email: user.email,
                        groups: userModel.groups.map(group => group.getVariables()),
                      },
                    })
                  },
                })
              }

              return (
                <UserCard key={i}>
                  <Avatar>
                    <Show if={user.picture}>
                      <ProgressiveImage src={user.picture} loadingAnimation={true} />
                    </Show>
                    <Show if={!user.picture}>
                      <PersonIcon icon="person" size={120} />
                    </Show>

                  </Avatar>
                  <UserDescription>
                    <Name>
                      <Show if={user.firstName || user.lastName}>
                        {user.firstName} {user.lastName}
                        <Show if={email === user.email}>
                          {' (you)'}
                        </Show>
                      </Show>
                      <Show if={!(user.firstName || user.lastName)}>
                        {user.email}
                      </Show>
                    </Name>
                    <Position>
                      { userModel.isAdmin() ? 'Admin' : 'User'}
                    </Position>

                    <Show if={email !== user.email}>
                      <Settings>
                        <Dropdown
                          itemHeight={34}
                          items={ items }
                        >
                          <Icon
                            size={16}
                            icon="threeDots"
                          />
                        </Dropdown>
                      </Settings>
                    </Show>
                  </UserDescription>
                </UserCard>
              )})}
          </Show>
          <DeleteUserModal
            toggle={this.toggleDeleteUserModal}
            isOpen={this.state.isDeleteUserModalOpen}
            email={this.state.userToDeleteEmail}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            onDone={() => this.setState({ isDeleteUserModalOpen: false })}
            onRequestClose={this.closeDeleteUserModal}
          />
          <Show if={data.loading}>
            {times(3, i => (
              <LoadingCard key={i} />
            ))}
          </Show>
        </UserCards>
    )
  }
}
