import styled from 'styled-components'
import { Button } from 'components'

export const Title = styled.div`
  font-weight: 300;
  font-size: 24px;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
`
export const SubTitle = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: #333333;
  padding: 20px 0;
  text-align: center;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const AnalyticsButton = styled(Button)`
  width: 150px;
`
export const Views = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`

