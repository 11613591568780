import * as React from 'react'
import { withTheme } from 'styled-components'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import { map, filter, join, get } from 'lodash'
import mapStyles from '../../../../styles/google-maps'
import { Show } from 'components'
import { Label, CompanyName, Line } from './map-view.styles'
import { getDeviceText } from 'utils'

interface MapViewProps {
  company: any
  locations: any
  hasCoordinates: boolean
  googleMapURL: any
  loadingElement: any
  containerElement: any
  mapElement: any
  theme?: any
  sessions: any
}

// @ts-ignore
@withTheme
// @ts-ignore
@withScriptjs
// @ts-ignore
@withGoogleMap
export class MapView extends React.Component<MapViewProps> {
  public state = {
    visibleLabel: null,
    centered: false,
    maxZoom: 20,
    sessionLocation: null,
    locationUndefined: false,
  }

  private timeout: any

  public componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  public onMapMounted = (mapComponent) => {
    const { locations = [] } = this.props

    if (mapComponent && !this.state.centered) {
      if (this.props.hasCoordinates && locations.length > 1) {
        const { LatLngBounds, LatLng } = google.maps

        const definedLocations: any = filter(locations, location => location.lat && location.lng)

        const coords = map(definedLocations, location => new LatLng(location.lat, location.lng))

        const bounds = new LatLngBounds()

        coords.forEach(coord => bounds.extend(coord))

        this.setState({ maxZoom: 9 })

        mapComponent.fitBounds(bounds)

        this.timeout = setTimeout(() => {
          this.setState({ maxZoom: 20 })
        }, 500)
      }

      this.setState({ centered: true })
    }
  }

  public render() {
    const { locations, company, hasCoordinates, theme, sessions } = this.props

    if (!locations.length && sessions.length && !this.state.sessionLocation && !this.state.locationUndefined) {
      const { city, country } = sessions[0]
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: `${city} ${country}`}, (result: any) => {
        if (!result[0]) {
          return this.setState({
            locationUndefined: true,
          })
        }

        const { location } = result[0].geometry
        this.setState({
          sessionLocation: {
            lat: location.lat(),
            lng: location.lng()
          }
        })
      })
      return null
    }

    const { sessionLocation } = this.state
    const { Point } = google.maps
    const lat = get(locations, '[0].lat') || get(sessionLocation, 'lat') || 48.815979
    const lng = get(locations, '[0].lng') || get(sessionLocation, 'lng') || 16.397186
    const isDefaultTheme = theme.THEME_ID === 'DEFAULT'

    const redIcon = {
      url: `/images/map/markers/${isDefaultTheme ? 'main' : 'super-office-main'}.svg`,
    }
    const sessionsIcon = {
      url: `/images/map/markers/${isDefaultTheme ? 'visit' : 'super-office-visit'}.svg`,
    }

    const definedLocations = filter(locations, location => location.lat && location.lng)

    const session = sessions[0] || {}

    return (
      <GoogleMap
        ref={mapComponent => this.onMapMounted(mapComponent)}
        defaultZoom={hasCoordinates || sessionLocation ? 9 : 4}
        defaultCenter={{ lat, lng }}
        options={{
          styles: mapStyles[theme.THEME_ID],
          maxZoom: this.state.maxZoom,
          draggable: true,
          disableDefaultUI: true,
        }}
      >
        <Show if={hasCoordinates}>
          {map(definedLocations, (location: any, i) => (
            <MarkerWithLabel
              icon={redIcon}
              key={i}
              position={{ ...location }}
              labelAnchor={new Point(-30, 70)}
              onMouseOver={() => this.setState({ visibleLabel: i })}
              onMouseOut={() => this.setState({ visibleLabel: null })}
              labelVisible={i === this.state.visibleLabel}
              zIndex={5}
            >
              <Label>
                <CompanyName>
                  {company.name}
                </CompanyName>
                <Line>
                  {location.addressLine1}
                </Line>
                <Line>{join([location.city, location.country], ', ')}</Line>
              </Label>
            </MarkerWithLabel>
          ))}
        </Show>
        <Show if={!hasCoordinates && sessionLocation}>
          <MarkerWithLabel
            icon={sessionsIcon}
            position={{ ...sessionLocation }}
            labelAnchor={new Point(-30, 70)}
            onMouseOver={() => this.setState({ visibleLabel: 'session' })}
            onMouseOut={() => this.setState({ visibleLabel: null })}
            labelVisible={'session' === this.state.visibleLabel}
            zIndex={5}
          >
            <Label>
              <CompanyName>
                Last visitor
              </CompanyName>
              <Line>{join([session.city, session.country], ', ')}</Line>
              <Line>Using {getDeviceText(session.deviceCategory, session.operatingSystem)}</Line>
            </Label>
          </MarkerWithLabel>
        </Show>
      </GoogleMap>
    )
  }
}
