import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled, { StyledComponentClass } from 'styled-components'
import { PageTabsItem, PageTabsPlainItem } from './page-tabs-item'

interface PageTabsItems extends StyledComponentClass<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, HTMLDivElement> {
  Item?: any
  PlainItem?: any
}

export const PageTabs: PageTabsItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  align-items: center;
  padding: 14px 0 0 0;
`

PageTabs.Item = PageTabsItem
PageTabs.PlainItem = PageTabsPlainItem
