import * as React from 'react'

import { Modal } from '../../../../../components'
import { error } from '../../../../../utils/toast'

interface DeleteCardModalProps {
  isOpen: boolean
  digits: string
  onRequestClose: () => void
  mutation: () => any
  loading: boolean
}

export class DeleteCardModal extends React.PureComponent<DeleteCardModalProps> {
  public onClick = () => {
    const { onRequestClose, mutation } = this.props
    mutation()
      .then(onRequestClose)
      .catch(() => {
        error('Something went wrong')
        onRequestClose()
      })
  }
  public render() {
    const { isOpen, digits, onRequestClose, loading } = this.props
    return (
      <Modal
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        style={{
          content: {
            maxWidth: 500,
          }
        }}
      >
        <Modal.Title>
          Delete card
        </Modal.Title>
        <p>Are you sure you want to delete card with number  **** **** **** {digits}?</p>
        <Modal.Controls>
          <Modal.ApproveButton
            disabled={loading}
            updating={loading}
            onClick={this.onClick}
          >
            Yes
          </Modal.ApproveButton>
          <Modal.CancelButton
            onClick={onRequestClose}
          >
            No
          </Modal.CancelButton>
        </Modal.Controls>
      </Modal>
    )
  }
}
