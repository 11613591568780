import styled from 'styled-components'
import { times, map, find } from 'lodash'

import { LoadingBlock, Icon } from 'components'

export const UserCards = styled.div`
  padding: 0 5px 20px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const UserCard = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  background: white;
  height: 300px;
  margin: 15px;
  @media (max-width: 500px) {
    width: 100%;
  };
`
export const UserDescription = styled.div`
  position: relative;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
`
export const Name = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  word-break: break-word;
  text-align: center;
`
export const Position = styled.div`
  font-weight: 300;
  font-size: 14px;
`
export const Avatar = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px #EAEAEA solid;
`
export const LoadingCard = styled(LoadingBlock)`
  width: 250px;
  height: 300px;
  margin: 15px;
  border-radius: 0;
  @media (max-width: 500px) {
    width: 100%;
  };
`
export const AddUserCard = styled(UserCard)`
  background: #A6A6A6;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const AddIcon = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 50%;
  border: 4px solid #FFFFFF;
  font-size: 110px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
`
export const PersonIcon = styled(Icon)`
  margin: 0;
`

export const Settings = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const DropdownText = styled.div`
  font-size: 12px;
  line-height: 18px;
`
